import { z } from 'zod'
import { AsyncFn, TimeZoneId, ZonedDateTime } from '../../common'
import { guidSchema, isoDateStringSchema, timeZoneIdSchema } from '../../contracts'
import { AccountingIntegrationType } from '../Accounting/AccountingServiceTypes'
import { JobAppointmentGuid } from '../Appointments/Appointments'
import { Guid, bzOptional } from '../common-schemas'
import { TimeWindowDto } from '../DateTime/TimeWindow'
import { EquipmentType, EquipmentTypeSchema } from '../Equipment/EquipmentType'
import { defaultInvoiceDisclaimer } from '../Finance/CompanyBillingProfileTypes'
import { InvoiceTerm } from '../Finance/Invoicing/InvoiceTypes'
import { INSTALL_JOB_PROJECT_TYPE } from '../Job'
import { AttributionLinkingStrategyValues } from '../LeadSources/AttributionLinkingStrategy'
import { CompanyLeadSourceGuid } from '../LeadSources/CompanyLeadSource'
import { telephoneNumberSchema } from '../PhoneNumbers/Phone'
import { PhotoGuid } from '../Photos/Photos'
import { PricebookItemTypeEnum, PricebookTaxRateGuid } from '../Pricebook/PricebookTypes'
import { RoleId } from '../Role'
import { UserGuidContainer } from '../Users/User'

export const CompanySchema = z.object({
  companyGuid: guidSchema,
  timezone: timeZoneIdSchema,
  name: z.string(),
  merchantId: bzOptional(z.string()),
  logoUrl: bzOptional(z.string()),
  hiddenEquipmentTypes: bzOptional(z.array(z.nativeEnum(EquipmentType))),
  visibleInstallProjectTypes: bzOptional(
    z.array(z.enum(INSTALL_JOB_PROJECT_TYPE as [(typeof INSTALL_JOB_PROJECT_TYPE)[number]])),
  ),
  locationMunicipalityFieldEnabled: bzOptional(z.boolean()),
  confirmationNotificationSuppressedByDefault: bzOptional(z.boolean()),
  defaultAccountManagerUserGuid: bzOptional(guidSchema),
  maintenancePlansEnabled: bzOptional(z.boolean()),
  zipCode: bzOptional(z.string()),
})

export type CompanyGuid = string
export type Company = z.infer<typeof CompanySchema>

export type CompanyFinancialConfig = {
  merchantId?: string
  wisetackEnabled?: boolean
  wisetackMerchantId?: string
  quickbooksOnlineEnabled?: boolean
  quickbooksDesktopEnabled?: boolean
  quickbooksDesktopConnected?: boolean
  accountingIntegrationType?: AccountingIntegrationType
  accountingPayoutAccountsConfigured?: boolean
  qbdConductorEndUserId?: string
}
export type CompanyFinancialConfigReader = AsyncFn<CompanyGuidContainer, CompanyFinancialConfig>

export const companyFinancialConfigWriterInputSchema = z.object({
  companyGuid: guidSchema,
  merchantId: bzOptional(z.string()),
  wisetackEnabled: bzOptional(z.boolean()),
  wisetackMerchantId: bzOptional(z.string()),
})

export type CompanyFinancialConfigWriterInput = z.infer<typeof companyFinancialConfigWriterInputSchema>

export type CompanyFinancialConfigWriter = AsyncFn<CompanyFinancialConfigWriterInput>

export const companyGuidContainerSchema = z.object({
  companyGuid: guidSchema,
})

export type CompanyGuidContainer = z.infer<typeof companyGuidContainerSchema>

export const companyGuidAndTimeZoneSchema = z.object({
  companyGuid: guidSchema,
  timezone: timeZoneIdSchema,
})

export type CompanyGuidAndTimeZone = z.infer<typeof companyGuidAndTimeZoneSchema>

export type ForCompany<T> = T & CompanyGuidContainer
export type ForFullCompany<T> = T & { company: Company }

export const forCompanyEntityGuidSchema = z.object({
  companyGuid: guidSchema,
  entityGuid: guidSchema,
})

export type ForCompanyEntityGuid = z.infer<typeof forCompanyEntityGuidSchema>

export type ActingCompanyUserIds = CompanyGuidContainer & UserGuidContainer

export type ForCompanyUser<T> = ForCompany<T> & UserGuidContainer
export type ForCompanyUserWithTimeZone<T> = ForCompanyUser<T> & { tzId: TimeZoneId }

export type CompanyTimeWindowRequest = {
  companyGuid: CompanyGuid
  start: ZonedDateTime
  end: ZonedDateTime
}

export type CompanyTimeWindowDtoRequest = Company & TimeWindowDto

export const companySimpleTimeWindowDtoRequestSchema = z.object({
  companyGuid: guidSchema,
  start: isoDateStringSchema,
  end: isoDateStringSchema,
})

export type CompanySimpleTimeWindowDtoRequest = CompanyGuidContainer & TimeWindowDto

export const companySimpleItemLimitRequestSchema = z.object({
  companyGuid: guidSchema,
  limit: z.number(),
})

export type CompanySimpleItemLimitRequest = z.infer<typeof companySimpleItemLimitRequestSchema>

type TechnicianGuid = string

export type TechnicianApptsRequest =
  | {
      query: 'for-technician-in-date-range'
      technicianGuid: TechnicianGuid
      companyGuid: CompanyGuid
      start: ZonedDateTime
      end: ZonedDateTime
    }
  | {
      query: 'for-job-appointment-guid'
      jobAppointmentGuid: JobAppointmentGuid
      technicianGuid?: TechnicianGuid | null
      companyGuid: CompanyGuid
    }
  | {
      query: 'for-job-appointment-reference-number'
      jobAppointmentReferenceNumber: string
      technicianGuid?: TechnicianGuid | null
      companyGuid: CompanyGuid
    }
  | {
      query: 'for-job-appointment-assignment-guid'
      jobAppointmentAssignmentGuid: Guid
      companyGuid: CompanyGuid
    }

export type ICompanyNameReader = AsyncFn<CompanyGuidContainer, string>
export type CompanyTimeZoneReader = AsyncFn<CompanyGuidContainer, TimeZoneId>
export type ICompanyReader = AsyncFn<CompanyGuidContainer, Company>

export type ResyncForCompany = AsyncFn<CompanyGuidContainer>

export type CompanyCreator = AsyncFn<
  ForCompanyUser<{ companyName: string; timezoneId?: TimeZoneId; skipExistenceCheck?: boolean }>
>

interface CompanyUserWriterRequest {
  companyGuid: string
  userGuid: string
  addedByUserGuid: string
}

export type CompanyUserWriter = AsyncFn<CompanyUserWriterRequest>

export type CompaniesReader = AsyncFn<undefined, Company[]>

export const DEFAULT_BLURB =
  "Your local experts in residential heating, ventilation, and air conditioning services are here to ensure your home's comfort and air quality. Specializing in installation, maintenance, and repair, our team of experienced professionals is dedicated to providing top-notch HVAC solutions. Known for our reliability, prompt service, and unwavering commitment to customer satisfaction, we are your go-to choice for keeping your home comfortable all year round."

export const companyCreatorV2InputSchema = z.object({
  company: z.object({
    companyGuid: bzOptional(guidSchema),
    name: z.string().min(1),
    timezone: timeZoneIdSchema,
  }),
  billingProfile: z.object({
    fullLegalName: z.string().min(1),
    contractorLicenseNumber: bzOptional(z.string()),
    websiteUrl: z.string().url(),
    websiteDisplayName: z.string().min(1),
    emailAddress: z.string().email(),
    phoneNumber: z.string(),
    logoUrl: z.string().url(),
    businessAddress: z.object({
      line1: z.string().min(1),
      line2: bzOptional(z.string()),
      city: z.string().min(1),
      stateAbbreviation: z.string().min(1),
      zipCode: z.string().min(1),
    }),
    defaultPricebookTaxRate: z.object({
      name: z.string().min(1),
      rate: z.number().min(0),
      version: z.number(),
    }),
    invoiceAndEstimates: z.object({
      invoiceTerm: z.nativeEnum(InvoiceTerm),
      invoiceMemo: z.string(),
      estimateDisclaimer: z.string(),
      invoiceDisclaimer: z.string().default(defaultInvoiceDisclaimer),
    }),
  }),
  teamMembers: z
    .object({
      firstName: z.string().min(1),
      lastName: z.string().min(1),
      emailAddress: z.string().email(),
      phoneNumber: telephoneNumberSchema,
      roles: z.nativeEnum(RoleId).array().min(1),
      hasDesktopAccess: z.boolean(),
      hasMobileAccess: z.boolean(),
      isSuperAdmin: z.boolean(),
      password: bzOptional(z.string()),
    })
    .array()
    .min(1),
  leadSources: z
    .object({
      canonicalLeadSourceName: z.string().min(1),
      leadSourceNameOverride: bzOptional(z.string()),
      attributionLinkingStrategy: z.enum(AttributionLinkingStrategyValues),
      attributionLinkingStrategyOverride: bzOptional(z.enum(AttributionLinkingStrategyValues)),
      attributionPrompt: bzOptional(z.string()),
      attributionPromptOverride: bzOptional(z.string()),
    })
    .array()
    .min(1),
  hiddenEquipmentTypes: EquipmentTypeSchema.array(),
})

export type CompanyCreatorV2Input = z.infer<typeof companyCreatorV2InputSchema>

export type CompanyCreatorV2Output = ForCompany<{
  defaultTaxRateGuid: PricebookTaxRateGuid
  companyLeadSourceGuids: CompanyLeadSourceGuid[]
  defaultPricebookPhotos: Record<PricebookItemTypeEnum, PhotoGuid | undefined>
}>

export type CompanyCreatorV2 = AsyncFn<CompanyCreatorV2Input, CompanyCreatorV2Output>
