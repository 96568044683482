import { ComprehensiveJobDetails } from '@breezy/backend/src/application-types'
import {
  BzAddress,
  BzContact,
  BzDateFns,
  ComprehensiveAppointmentDetails,
  DUMMY_PREQUAL_RECORD_GUID,
  Location,
  PhotoRecord,
  bzExpect,
  isNullish,
  noOp,
  toArray,
} from '@breezy/shared'
import { faExclamationTriangle } from '@fortawesome/pro-regular-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Modal } from 'antd'
import cn from 'classnames'
import React, { useCallback, useMemo, useState } from 'react'
import { Link, useNavigate, useParams } from 'react-router-dom'
import { useSearchParam } from 'react-use'
import { SectionedCard } from 'src/adam-components/SectionedCard/SectionedCard'
import CreateOrEditNewAccountLocationForm from 'src/components/CreateOrEditNewAccountLocationForm/CreateOrEditNewAccountLocationForm'
import { ChecklistsDrawer } from 'src/components/collapsibles'
import { ContactsCollapsible } from 'src/components/collapsibles/ContactsCollapsible/ContactsCollapsible'
import { EstimatesCollapsibleV2 } from 'src/components/collapsibles/EstimatesCollapsible/EstimatesCollapsibleV2'
import { InvoicesV2CollapsibleV2 } from 'src/components/collapsibles/InvoicesCollapsible/InvoicesV2CollapsibleV2'
import { LocationsCollapsible } from 'src/components/collapsibles/LocationsCollapsible/LocationsCollapsible'
import { PaymentsCollapsible } from 'src/components/collapsibles/PaymentsCollapsible/PaymentsCollapsible'
import { VisitsCollapsible } from 'src/components/collapsibles/VisitsCollapsible/VisitsCollapsible'
import { WisetackFinancingCollapsibleV2 } from 'src/components/collapsibles/WisetackFinancingCollapsible/WisetackFinancingCollapsibleV2'
import BzDrawer from 'src/elements/BzDrawer/BzDrawer'
import { useQuery, useSubscription } from 'urql'
import AccountManagerBanner from '../../components/Accounts/AccountManagerBanner/AccountManagerBanner'
import { JobActivityFeed } from '../../components/ActivityFeed/ActivityFeed'
import { BehindFeatureFlag } from '../../components/BehindFeatureFlag'
import { EditJobModal } from '../../components/EditJobModal/EditJobModal'
import { useFinancingWizard } from '../../components/Financing/hooks/useFinancingWizard'
import JobAttachments from '../../components/JobAttachments/JobAttachments'
import { LinkedCallsListView } from '../../components/LinkedCallsListView/LinkedCallsListView'
import { LinkedJobsCollapsible } from '../../components/LinkedJobsCollapsible/LinkedJobsCollapsible'
import { LoadingSpinner } from '../../components/LoadingSpinner'
import MaintenancePlanJobDiscountBanner from '../../components/MaintenancePlanWorkDiscountBanner/MaintenancePlanWorkDiscountBanner'
import { MigrationJunkView } from '../../components/MigrationJunk/MigrationJunkView'
import UpsertAppointmentDrawer from '../../components/NewAppointmentModal/UpsertAppointmentDrawer'
import NotesListCard from '../../components/Notes/NotesListCard/NotesListCard'
import BzTabs, {
  BzTab,
  useURLSettableTabIndex,
} from '../../components/Page/BzTabs/BzTabs'
import { Page } from '../../components/Page/Page'
import ThreeColumnTemplate from '../../components/Page/ThreeColumnTemplate'
import { PhotoDetailDrawer } from '../../components/PhotoDetailDrawer/PhotoDetailDrawer'
import { AsyncPhotoUpload } from '../../components/Upload/AsyncPhotoUpload'
import { useSynchronousUpload } from '../../components/Upload/Upload'
import MaintenancePlansCollapsible from '../../components/collapsibles/MaintenancePlansCollapsible/MaintenancePlansCollapsible'
import { ActionItem } from '../../elements/ActionItems/ActionItems'
import BzColumn from '../../elements/BzColumn/BzColumn'
import { useCanManageJob } from '../../hooks/permission/useCanManageJob'
import { useCanUseIntegratedPhone } from '../../hooks/permission/useCanUseIntegratedPhone'
import { trpc } from '../../hooks/trpc'
import useAppNavigation from '../../hooks/useAppNav'
import { useFeatureFlag } from '../../hooks/useFeatureFlags'
import { useIntercom } from '../../hooks/useIntercom'
import { useQueryStringBoolean } from '../../hooks/useQueryStringBoolean'
import { useAddRecentRecord } from '../../hooks/useRecentRecord'
import { useWisetackEnabled } from '../../providers/CompanyFinancialConfigWrapper'
import {
  useExpectedCompany,
  useExpectedCompanyTimeZoneId,
} from '../../providers/PrincipalUser'
import { useMessage } from '../../utils/antd-utils'
import { LocationIcon } from '../../utils/feature-icons'
import {
  MaintenancePlanWizard,
  useMaintenancePlanWizardFlags,
} from '../CreateOrEditMaintenancePlanPage/MaintenancePlanWizard'
import {
  COMPANY_CONFIG_QUERY,
  LINKED_CALLS_FOR_JOB_SUBSCRIPTION,
  LINKED_JOBS_QUERY,
  LINKS_TO_JOBS_QUERY,
} from './JobDetailsPage.gql'
import JobInfoCard from './JobInfoCard'
import { NotesList } from './components/NotesList'
import { PhotosList } from './components/PhotosList'
export const JOB_DETAILS_TAB_KEY = 'tab'

const JobDetailsPage: React.FC = () => {
  const jobGuid = bzExpect(
    useParams().jobGuid,
    'jobGuid',
    'JobGuid is required',
  )

  return (
    <Page requiresCompanyUser={true}>
      <AuthJobDetailsPage jobGuid={jobGuid} />
    </Page>
  )
}

const AuthJobDetailsPage = ({ jobGuid }: { jobGuid: string }) => {
  const message = useMessage()
  const { canManage } = useCanManageJob(jobGuid)
  const wisetackEnabled = useWisetackEnabled()
  useAddRecentRecord('jobs', jobGuid)

  const [editingPhoto, setEditingPhoto] = useState<PhotoRecord | null>(null)
  const [editJobFormModalOpen, updateEditJobFormModalOpen] = useState(false)
  const [serviceLocation, setServiceLocation] = useState<Location | null>(null)
  const [pointOfContact, setPointOfContact] = useState<BzContact | null>(null)
  const [createAppointmentModalOpen, setCreateAppointmentModalOpen] =
    useQueryStringBoolean('createAppointment', true)

  const canUseIntegratedPhone = useCanUseIntegratedPhone()

  const jobLeadGuid = useSearchParam('jobLeadGuid') ?? undefined

  const { companyGuid } = useExpectedCompany()

  const fetchMigrationJunkQuery = trpc.migrationJunk[
    'migration-junk:query'
  ].useQuery({
    jobGuid,
  })

  const fetchComprehensiveJobDetailsQuery = trpc.jobs[
    'comprehensive-job-details:query'
  ].useQuery(
    {
      type: 'by-job-guid',
      companyGuid: useExpectedCompany().companyGuid,
      jobGuid,
    },
    {
      select: data => {
        if (data.length !== 1) {
          throw new Error(`Failed to locate job by guid = ${jobGuid}`)
        }
        return new ComprehensiveJobDetails(data[0])
      },
      onSuccess(comprehensiveJobDetails) {
        setServiceLocation(comprehensiveJobDetails.getServiceLocation())
        setPointOfContact(comprehensiveJobDetails.getPointOfContact())
      },
      onError(error) {
        console.error(error)
      },
    },
  )

  const comprehensiveJobDetails = fetchComprehensiveJobDetailsQuery.data

  const photoElements = useMemo(() => {
    if (!comprehensiveJobDetails) {
      return null
    }
    return comprehensiveJobDetails.getPhotos().map(photo => (
      <div
        className={cn(
          'mb-2 flex flex-col items-center',
          canManage ? 'hover:cursor-pointer' : '',
        )}
        key={photo.photoGuid}
        onClick={canManage ? () => setEditingPhoto(photo) : undefined}
      >
        <img src={photo.cdnUrl} alt="belongs to job" />
      </div>
    ))
  }, [comprehensiveJobDetails, canManage])

  useIntercom({
    isLauncherVisible:
      isNullish(editingPhoto) &&
      !editJobFormModalOpen &&
      !createAppointmentModalOpen,
  })

  const photoLinks = useMemo(() => ({ jobGuid }), [jobGuid])
  const onPhotoUploadChange = useSynchronousUpload(() =>
    fetchComprehensiveJobDetailsQuery.refetch(),
  )

  const [{ data: callsData, fetching: callsFetching }] = useSubscription({
    query: LINKED_CALLS_FOR_JOB_SUBSCRIPTION,
    variables: {
      jobGuid,
      companyGuid,
    },
    pause: !canUseIntegratedPhone,
  })

  const tabs = useMemo<BzTab[]>(() => {
    const tabs: BzTab[] = []

    if (canUseIntegratedPhone && callsData && callsData.jobs.length > 0) {
      tabs.push({
        title: 'Calls',
        content: (
          <LinkedCallsListView
            calls={callsData.jobs[0].integratedPhoneCallJobs.map(
              call => call.integratedPhoneCall,
            )}
            isLoading={callsFetching}
            jobGuid={jobGuid}
          />
        ),
      })
    }

    tabs.push(
      {
        title: 'Notes',
        content: comprehensiveJobDetails && (
          <BehindFeatureFlag
            enabledFeatureFlag="linkedJobs"
            render={
              <NotesList
                jobGuid={comprehensiveJobDetails.getJobGuid()}
                editable={canManage}
              />
            }
            fallback={
              <NotesListCard
                linkData={{
                  primaryNoteType: 'JOB',
                  jobGuid: comprehensiveJobDetails.getJobGuid(),
                }}
                editable={canManage}
                title=""
              />
            }
          />
        ),
      },
      {
        title: 'Photos',
        content: (
          <BehindFeatureFlag
            enabledFeatureFlag="linkedJobs"
            render={
              <>
                {comprehensiveJobDetails && (
                  <PhotosList
                    job={comprehensiveJobDetails}
                    canManagePhotos={canManage}
                    onPhotoUploaded={() =>
                      fetchComprehensiveJobDetailsQuery.refetch()
                    }
                    onPhotoDeleted={() =>
                      fetchComprehensiveJobDetailsQuery.refetch()
                    }
                  />
                )}
              </>
            }
            fallback={
              <div className="w-full">
                {canManage && (
                  <AsyncPhotoUpload
                    links={photoLinks}
                    onPhotoUploadChange={onPhotoUploadChange}
                  />
                )}
                <div className="mb-3 w-full columns-2 gap-2">
                  {photoElements}
                </div>
                {editingPhoto && (
                  <PhotoDetailDrawer
                    photo={editingPhoto}
                    onClose={() => {
                      setEditingPhoto(null)
                    }}
                    onDelete={() => {
                      setEditingPhoto(null)
                      fetchComprehensiveJobDetailsQuery.refetch()
                    }}
                    editable={canManage}
                  />
                )}
              </div>
            }
          />
        ),
      },
      {
        title: 'Attachments',
        content: (
          <JobAttachments
            comprehensiveJobDetails={comprehensiveJobDetails ?? undefined}
            disableUpload={!canManage}
            editable={canManage}
            jobGuid={jobGuid}
            refetch={fetchComprehensiveJobDetailsQuery.refetch}
          />
        ),
      },
      {
        title: 'Activity',
        content: (
          <JobActivityFeed companyGuid={companyGuid} jobGuid={jobGuid} />
        ),
      },
    )

    if (
      fetchMigrationJunkQuery.data &&
      Object.keys(fetchMigrationJunkQuery.data.junk).length > 0
    ) {
      tabs.push({
        title: fetchMigrationJunkQuery.data.uiLabel ?? 'Legacy Migration Data',
        content: (
          <MigrationJunkView migrationJunk={fetchMigrationJunkQuery.data} />
        ),
      })
    }

    return tabs
  }, [
    comprehensiveJobDetails,
    canManage,
    photoLinks,
    onPhotoUploadChange,
    photoElements,
    editingPhoto,
    jobGuid,
    fetchComprehensiveJobDetailsQuery,
    companyGuid,
    fetchMigrationJunkQuery.data,
    canUseIntegratedPhone,
    callsData,
    callsFetching,
  ])
  const maintPlansV3Enabled = useFeatureFlag('createMaintenancePlanV2')

  const [activeTabIndex, setActiveTabIndex] = useURLSettableTabIndex(
    tabs,
    0,
    JOB_DETAILS_TAB_KEY,
  )

  const loanRecordsForJobQuery = trpc.financing[
    'loan-records:for-job'
  ].useQuery({ jobGuid })

  // If there isn't a valid account guid, we pass in a dummy guid to satisfy the trpc runtime type validation
  // which returns an empty array for the prequal records
  const prequalRecordsForAccountQuery = trpc.financing[
    'prequal-records:for-account'
  ].useQuery(
    {
      accountGuid:
        comprehensiveJobDetails?.getAccount().accountGuid ??
        DUMMY_PREQUAL_RECORD_GUID,
    },
    {
      enabled:
        wisetackEnabled && !!comprehensiveJobDetails?.getAccount().accountGuid,
    },
  )

  const refetchWisetack = useCallback(() => {
    loanRecordsForJobQuery.refetch()
    prequalRecordsForAccountQuery.refetch()
  }, [loanRecordsForJobQuery, prequalRecordsForAccountQuery])

  const onEdit = useCallback(() => updateEditJobFormModalOpen(true), [])
  const maintenancePlans = useMemo(() => {
    if (!comprehensiveJobDetails) {
      return []
    }
    return maintPlansV3Enabled
      ? comprehensiveJobDetails.getMaintenancePlans()
      : toArray(comprehensiveJobDetails.getMaintenancePlan())
  }, [comprehensiveJobDetails, maintPlansV3Enabled])

  const accountLocationObj = useMemo(() => {
    if (!comprehensiveJobDetails) return undefined
    return {
      accountGuid: comprehensiveJobDetails.getAccount().accountGuid,
      locationGuid: comprehensiveJobDetails.getServiceLocation().locationGuid,
    }
  }, [comprehensiveJobDetails])

  const transactionLinks = useMemo(
    () => ({
      accountGuid: comprehensiveJobDetails?.getAccount().accountGuid ?? '',
      jobGuid: comprehensiveJobDetails?.getJobGuid() ?? '',
      locationGuid: serviceLocation?.locationGuid,
    }),
    [comprehensiveJobDetails, serviceLocation?.locationGuid],
  )

  const [
    maintenancePlanWizardOpen,
    openMaintenancePlanWizard,
    closeMaintenancePlanWizard,
  ] = useMaintenancePlanWizardFlags('mpw', 'job-details')
  const [companyConfigQuery] = useQuery({
    query: COMPANY_CONFIG_QUERY,
    variables: { companyGuid },
  })

  const linkedJobsQuery = useQuery({
    query: LINKED_JOBS_QUERY,
    variables: { jobGuid },
  })

  const linkedJobs = useMemo(() => {
    if (isNullish(linkedJobsQuery[0].data?.jobsWithLinkedJob)) {
      return []
    }

    const linkedJobs = bzExpect(linkedJobsQuery[0].data?.jobsWithLinkedJob)

    return linkedJobs.map(job => ({
      jobGuid: job.linked_job.jobGuid,
      jobType: job.linked_job.jobType.name,
      createdAt: job.linked_job.createdAt,
      address: BzAddress.create(
        job.linked_job.location.address,
      ).streetAddressLine1And2Condensed(),
      status: job.linked_job.jobLifecycleStatus.name,
    }))
  }, [linkedJobsQuery])

  const createNewMaintenancePlan = useCallback(() => {
    openMaintenancePlanWizard()
  }, [openMaintenancePlanWizard])

  const [editingLocation, setEditingLocation] = useState<Location | undefined>(
    undefined,
  )

  const [upsertLocationDrawerOpen, setUpsertLocationDrawerOpen] =
    useState(false)

  const timezoneId = useExpectedCompanyTimeZoneId()

  const [editingAppointment, setEditingAppointment] = useState<
    ComprehensiveAppointmentDetails | undefined
  >(undefined)

  const [appointmentToCancel, setAppointmentToCancel] = useState<
    ComprehensiveAppointmentDetails | undefined
  >(undefined)

  const cancelAppointmentMutation = trpc.appointments[
    'appointments:cancel'
  ].useMutation({
    onSuccess: () => {
      message.success(`Appointment canceled`)
      fetchComprehensiveJobDetailsQuery.refetch()
    },
  })

  const [selectedAppointment, setSelectedAppointment] = useState<
    ComprehensiveAppointmentDetails | undefined
  >(undefined)

  if (fetchComprehensiveJobDetailsQuery.isLoading) {
    return <LoadingSpinner />
  }

  if (fetchComprehensiveJobDetailsQuery.isError) {
    return <div>{`Failed to load job id: ${jobGuid}`}</div>
  }

  if (!fetchComprehensiveJobDetailsQuery.isSuccess || !comprehensiveJobDetails)
    return null

  const activeTab = tabs[activeTabIndex]

  const columnTwo = (
    <BzColumn>
      <BzTabs
        tabs={tabs}
        activeTabIndex={activeTabIndex}
        setActiveTabIndex={setActiveTabIndex}
      />
      {activeTab.content}
    </BzColumn>
  )

  const columnThree = (
    <SectionedCard
      noBorder
      sections={[
        {
          content: (
            <>
              <MaintenancePlansCollapsible
                titleOverride="Maintenance Plan"
                plans={maintenancePlans}
                activeMaintenancePlanGuid={comprehensiveJobDetails.getLinkedMaintenancePlanGuid()}
                hideCanceledPlans
                createNew={createNewMaintenancePlan}
              />
              {maintenancePlanWizardOpen && (
                <MaintenancePlanWizard
                  onRamp="job-details"
                  accountGuid={accountLocationObj?.accountGuid}
                  locationGuid={accountLocationObj?.locationGuid}
                  onClose={() => {
                    closeMaintenancePlanWizard()
                    fetchComprehensiveJobDetailsQuery.refetch()
                  }}
                />
              )}

              <ContactsCollapsible
                contacts={
                  pointOfContact
                    ? [
                        {
                          contactGuid: pointOfContact.contactGuid,
                          name: pointOfContact.fullName,
                          emailAddress:
                            pointOfContact.primaryEmailAddress?.emailAddress,
                          phoneNumber:
                            pointOfContact.primaryPhoneNumber?.phoneNumber,
                          notificationPreference:
                            pointOfContact.notificationPreferenceType,
                        },
                      ]
                    : []
                }
              />

              <BehindFeatureFlag
                enabledFeatureFlag="linkedJobs"
                render={
                  linkedJobs.length > 0 && (
                    <LinkedJobsCollapsible jobs={linkedJobs} />
                  )
                }
              />

              {comprehensiveJobDetails && (
                <LocationsCollapsible
                  locations={[
                    bzExpect(
                      comprehensiveJobDetails
                        .getAccountLocations()
                        .find(
                          al =>
                            al.location.locationGuid ===
                            comprehensiveJobDetails.getServiceLocation()
                              .locationGuid,
                        ),
                    ).location,
                  ]}
                  onEdit={location => {
                    setEditingLocation(location)
                    setUpsertLocationDrawerOpen(true)
                  }}
                />
              )}

              <VisitsCollapsible
                editable={canManage}
                visits={comprehensiveJobDetails.getAppointments().map(appt => ({
                  description: appt.description,
                  checklists: appt.appointmentChecklistInstances,
                  appointment: {
                    appointmentGuid: appt.appointmentGuid,
                    appointmentStatus: appt.appointmentStatus,
                    appointmentType: appt.appointmentType,
                    appointmentWindowStart: BzDateFns.formatISO(
                      BzDateFns.parseZonedDateTime(
                        appt.timeWindow.start,
                        timezoneId,
                      ),
                      timezoneId,
                    ),
                    appointmentWindowEnd: BzDateFns.formatISO(
                      BzDateFns.parseZonedDateTime(
                        appt.timeWindow.end,
                        timezoneId,
                      ),
                      timezoneId,
                    ),
                  },
                  job: {
                    jobTypeName: appt.jobType.name,
                    displayId: comprehensiveJobDetails
                      .getDisplayId()
                      .toString(),
                    assignedTech: !isNullish(appt.assignments[0])
                      ? {
                          name:
                            appt.assignments[0].technician.user.firstName +
                            ' ' +
                            appt.assignments[0].technician.user.lastName,
                          assignmentStart: BzDateFns.formatISO(
                            BzDateFns.parseZonedDateTime(
                              appt.assignments[0].timeWindow.start,
                              timezoneId,
                            ),
                            timezoneId,
                          ),
                          assignmentEnd: BzDateFns.formatISO(
                            BzDateFns.parseZonedDateTime(
                              appt.assignments[0].timeWindow.end,
                              timezoneId,
                            ),
                            timezoneId,
                          ),
                        }
                      : undefined,
                  },
                  location: {
                    locationGuid: appt.locationGuid,
                    address: {
                      line1: appt.address.line1,
                    },
                  },
                }))}
                timezoneId={timezoneId}
                onCreateVisit={() => setCreateAppointmentModalOpen(true)}
                onEditVisit={({ appointmentGuid }) => {
                  const appt = comprehensiveJobDetails
                    .getAppointments()
                    .find(curr => curr.appointmentGuid === appointmentGuid)

                  if (!appt) {
                    message.error('Appointment not found')
                    return
                  }

                  setEditingAppointment(appt)
                }}
                onCancelVisit={({ appointmentGuid }) => {
                  const appt = comprehensiveJobDetails
                    .getAppointments()
                    .find(curr => curr.appointmentGuid === appointmentGuid)

                  if (!appt) {
                    message.error('Appointment not found')
                    return
                  }

                  setAppointmentToCancel(appt)
                }}
                onViewChecklist={checklistInstance => {
                  const appt = comprehensiveJobDetails
                    .getAppointments()
                    .find(
                      curr =>
                        curr.appointmentGuid ===
                        checklistInstance.jobAppointmentGuid,
                    )

                  if (!appt) {
                    message.error('Appointment not found')
                    return
                  }

                  setSelectedAppointment(appt)
                }}
              />

              {wisetackEnabled && (
                <WisetackFinancingCollapsibleV2
                  accountGuid={comprehensiveJobDetails.getAccount().accountGuid}
                  loanRecords={loanRecordsForJobQuery.data ?? []}
                  prequalRecords={prequalRecordsForAccountQuery.data ?? []}
                  refetch={refetchWisetack}
                />
              )}

              <EstimatesCollapsibleV2
                links={transactionLinks}
                allowExternalCreate={canManage}
              />

              <InvoicesV2CollapsibleV2
                links={{
                  accountGuid: transactionLinks.accountGuid,
                  jobGuid,
                }}
                allowExternalCreate={canManage}
              />

              <PaymentsCollapsible
                payments={comprehensiveJobDetails
                  .getPayments()
                  .map(payment => ({
                    paymentRecordGuid: payment.guid,
                    paymentMethod: payment.paymentMethod,
                    amountUsd: payment.amountUsd,
                    referenceNumber: payment.referenceNumber,
                    status: payment.status,
                    occuredAt: payment.occurredAt,
                    displayId: payment.displayId,
                    jobGuid: payment.jobGuid,
                    invoiceGuid: payment.invoiceGuid,
                    loanRecord: payment.loanRecord,
                  }))}
              />

              {comprehensiveJobDetails && (
                <BzDrawer
                  title="Edit Location"
                  icon={LocationIcon}
                  item={
                    upsertLocationDrawerOpen
                      ? { onCancel: () => setUpsertLocationDrawerOpen(false) }
                      : undefined
                  }
                  destroyOnClose
                  preferredWidth={720}
                >
                  <CreateOrEditNewAccountLocationForm
                    flexRowSpaceX="space-x"
                    labelClassName="semibold_14_22 grey9"
                    showDivider
                    showCancelSubmitButtons
                    accountGuid={
                      comprehensiveJobDetails.getAccount().accountGuid
                    }
                    editingLocation={editingLocation}
                    onLocationUpdated={() => {
                      setUpsertLocationDrawerOpen(false)
                      setEditingLocation(undefined)
                      message.success('Successfully updated location')
                      fetchComprehensiveJobDetailsQuery.refetch()
                    }}
                    onCancelButtonPressed={() =>
                      setUpsertLocationDrawerOpen(false)
                    }
                  />
                </BzDrawer>
              )}

              {editingAppointment && (
                <UpsertAppointmentDrawer
                  mode="edit"
                  open={!isNullish(editingAppointment)}
                  onCancel={() => setEditingAppointment(undefined)}
                  onAppointmentEdited={() => {
                    setEditingAppointment(undefined)
                    fetchComprehensiveJobDetailsQuery.refetch()
                  }}
                  jobGuid={comprehensiveJobDetails.getJobGuid()}
                  jobClass={editingAppointment.jobType.jobClass}
                  comprehensiveAppointment={editingAppointment}
                  appointmentGuid={editingAppointment.appointmentGuid}
                />
              )}

              <Modal
                title="Are you sure?"
                open={!!appointmentToCancel}
                onOk={() => {
                  appointmentToCancel &&
                    cancelAppointmentMutation.mutate({
                      jobAppointmentGuid: appointmentToCancel.appointmentGuid,
                    })
                  setAppointmentToCancel(undefined)
                }}
                onCancel={() => setAppointmentToCancel(undefined)}
                okButtonProps={{ danger: true }}
              >
                <div className="column">
                  <FontAwesomeIcon
                    icon={faExclamationTriangle}
                    size="4x"
                    color="#ffcc33"
                    style={{ marginBottom: 12 }}
                  />
                  <div className="text-center">
                    Once you cancel this appointment, you will not be able to
                    un-cancel it.
                  </div>
                </div>
              </Modal>

              {!isNullish(selectedAppointment?.appointmentChecklistInstances) &&
                selectedAppointment?.appointmentChecklistInstances.length && (
                  <ChecklistsDrawer
                    appointment={selectedAppointment}
                    onClose={() => setSelectedAppointment(undefined)}
                  />
                )}
            </>
          ),
        },
      ]}
    />
  )

  return (
    <>
      <div className="flex h-full max-h-full min-h-0 w-full flex-col">
        {companyConfigQuery.data?.companyConfigByPk?.accountManagerEnabled && (
          <AccountManagerBanner
            accountManager={comprehensiveJobDetails.getAccount().accountManager}
          />
        )}

        <MaintenancePlanJobDiscountBanner job={comprehensiveJobDetails} />
        <ThreeColumnTemplate
          columnOne={
            <ColumnOne
              comprehensiveJobDetails={comprehensiveJobDetails}
              canManage={canManage}
              onEdit={onEdit}
              refetch={fetchComprehensiveJobDetailsQuery.refetch}
              serviceLocation={serviceLocation}
              transactionLinks={transactionLinks}
            />
          }
          columnTwo={columnTwo}
          columnThree={columnThree}
        />
      </div>
      {comprehensiveJobDetails && (
        <EditJobModal
          jobGuid={comprehensiveJobDetails.getJobGuid()}
          open={editJobFormModalOpen}
          onJobUpdateSuccess={() => {
            updateEditJobFormModalOpen(false)
            message.success('Successfully updated job')
            fetchComprehensiveJobDetailsQuery.refetch()
          }}
          onClose={() => updateEditJobFormModalOpen(false)}
        />
      )}
      <UpsertAppointmentDrawer
        mode="create"
        jobLeadGuid={jobLeadGuid}
        open={createAppointmentModalOpen}
        onCancel={() => setCreateAppointmentModalOpen(false)}
        onAppointmentCreated={() => {
          setCreateAppointmentModalOpen(false)
          fetchComprehensiveJobDetailsQuery.refetch()
        }}
        jobGuid={jobGuid}
        jobClass={comprehensiveJobDetails.getJobType().jobClass}
        labelClassName="semibold_14_22 grey9"
      />
    </>
  )
}

type ColumnOneProps = {
  comprehensiveJobDetails: ComprehensiveJobDetails
  canManage: boolean
  onEdit: () => void
  refetch: () => void
  serviceLocation: Location | null
  transactionLinks: {
    accountGuid: string
    jobGuid: string
    locationGuid?: string
  }
}

const ColumnOne = React.memo<ColumnOneProps>(
  ({
    comprehensiveJobDetails,
    canManage,
    onEdit,
    refetch,
    serviceLocation,
    transactionLinks,
  }) => {
    const navigate = useNavigate()
    const appNav = useAppNavigation()
    const wisetackEnabled = useWisetackEnabled()
    const linkedJobsEnabled = useFeatureFlag('linkedJobs')

    const { showFinancingWizard, financingWizard } = useFinancingWizard({
      accountGuid: comprehensiveJobDetails.getAccount().accountGuid,
      jobGuid: comprehensiveJobDetails.getJobGuid(),
      onCancel: refetch,
    })

    const [linksToJobsQueryRes] = useQuery({
      query: LINKS_TO_JOBS_QUERY,
      variables: {
        jobGuid: comprehensiveJobDetails.getJobGuid(),
      },
    })

    const [linkedJobsQueryRes] = useQuery({
      query: LINKED_JOBS_QUERY,
      variables: {
        jobGuid: comprehensiveJobDetails.getJobGuid(),
      },
    })

    const actionItems: ActionItem[] = useMemo(() => {
      const items: ActionItem[] = [
        {
          title: 'Create New Estimate',
          onClick: () =>
            navigate(
              `/jobs/${comprehensiveJobDetails.getJobGuid()}/new-estimate`,
            ),
        },
        {
          title: 'Create New Invoice',
          onClick: () =>
            appNav.navigateToCreateNewInvoiceV2(
              comprehensiveJobDetails.getAccount().accountGuid,
              comprehensiveJobDetails.getJobGuid(),
            ),
        },
      ]

      if (wisetackEnabled) {
        items.push({
          title: 'Send Financing',
          onClick: showFinancingWizard,
        })
      }

      if (linkedJobsEnabled) {
        if (
          linksToJobsQueryRes.data &&
          linksToJobsQueryRes.data.jobsWithLinkedJob.length > 0
        ) {
          const { job } = linksToJobsQueryRes.data.jobsWithLinkedJob[0]

          items.push({
            title: (
              <span>
                Linked Job:{' '}
                <Link to={`/jobs/${job.jobGuid}`}>
                  #{job.displayId} ({job.jobType.name})
                </Link>
              </span>
            ),
            onClick: noOp,
          })

          // We can only allow jobs to be linked to one job. If this job links to another job, just return early.
          // If it doesn't, then we proceed with adding the create linked job option only if it isn't already
          // linked to another job.
          return items
        }

        if (
          linkedJobsQueryRes.data &&
          linkedJobsQueryRes.data.jobsWithLinkedJob.length === 0
        ) {
          items.push({
            title: 'Create Linked Job',
            onClick: () =>
              appNav.navigateToCreateNewJob({
                accountGuid: comprehensiveJobDetails.getAccount().accountGuid,
                locationGuid:
                  comprehensiveJobDetails.getServiceLocation().locationGuid,
                linkedJobGuid: comprehensiveJobDetails.getJobGuid(),
              }),
          })
        }
      }

      return items
    }, [
      appNav,
      comprehensiveJobDetails,
      linkedJobsEnabled,
      linkedJobsQueryRes.data,
      linksToJobsQueryRes.data,
      navigate,
      showFinancingWizard,
      wisetackEnabled,
    ])

    return (
      <SectionedCard
        accentBarColor="#389E0D"
        sections={[
          {
            content: (
              <>
                {comprehensiveJobDetails && (
                  <JobInfoCard
                    comprehensiveJobDetails={comprehensiveJobDetails}
                    navigateToAccountDetails={() =>
                      appNav.navigateToAccountDetailsPage(
                        comprehensiveJobDetails.getAccount().accountGuid,
                      )
                    }
                    navigateToServiceLocationDetails={() =>
                      appNav.navigateToLocationDetailsPage(
                        comprehensiveJobDetails.getServiceLocation()
                          .locationGuid,
                      )
                    }
                    serviceLocation={serviceLocation ?? undefined}
                    actionItems={actionItems}
                    editable={canManage}
                    onEdit={onEdit}
                    refetchComprehensiveJobDetails={refetch}
                  />
                )}
                {financingWizard}
              </>
            ),
          },
        ]}
      />
    )
  },
)

export default JobDetailsPage
