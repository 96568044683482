import React from 'react'

type FormDescriptionProps = React.PropsWithChildren
export const FormDescription = React.memo<FormDescriptionProps>(
  ({ children }) => (
    <div className="text-sm leading-[22px] text-bz-text-description">
      {children}
    </div>
  ),
)
