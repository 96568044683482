import {
  formatEquipmentTypeString,
  InstalledEquipment,
  InstalledEquipmentSummary,
  InstalledHvacSystem,
  isNullish,
  ProbabilityOfEquipmentReplacement,
} from '@breezy/shared'
import { faPen } from '@fortawesome/pro-regular-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import classNames from 'classnames'
import { LabeledItemGrid } from 'src/adam-components/LabeledItemGrid'
import { SectionedCard } from 'src/adam-components/SectionedCard/SectionedCard'
import { EmDash } from 'src/elements/EmDash/EmDash'

interface HvacSystemEquipmentLineItemProps {
  equipment: InstalledEquipmentSummary
  onEquipmentEdit?: (equipment: InstalledEquipmentSummary) => void
  onEquipmentView?: (equipment: InstalledEquipmentSummary) => void
}

const HvacSystemEquipmentLineItem = (
  props: HvacSystemEquipmentLineItemProps,
) => {
  const { equipment } = props

  const now = new Date()
  const yearsEquipmentAge = InstalledEquipment.yearsEquipmentAge(now, equipment)

  const probabilityOfEquipmentReplace =
    InstalledEquipment.getProbabilityOfEquipmentReplacement(now, equipment)

  const probabilityOfReplacementClassName =
    probabilityOfEquipmentReplace ===
    ProbabilityOfEquipmentReplacement.EXTREMELY_PROBABLE
      ? 'text-green-700 font-semibold'
      : probabilityOfEquipmentReplace ===
        ProbabilityOfEquipmentReplacement.HIGH_PROBABILITY
      ? 'text-orange-700 font-semibold'
      : ''

  return (
    <div className="flex flex-row items-center justify-end gap-2">
      <span
        className={classNames({
          'text-bz-primary': !isNullish(props.onEquipmentView),
          'cursor-pointer': !isNullish(props.onEquipmentView),
        })}
        onClick={
          props.onEquipmentView
            ? () => props.onEquipmentView?.(equipment)
            : undefined
        }
      >
        {formatEquipmentTypeString(equipment.equipmentType)}
        {equipment.manufacturer && ` (${equipment.manufacturer})`}
        {' ('}
        {yearsEquipmentAge === 1 && (
          <span className={probabilityOfReplacementClassName}>1 year old</span>
        )}
        {yearsEquipmentAge !== 1 && (
          <span className={probabilityOfReplacementClassName}>
            {yearsEquipmentAge} years old
          </span>
        )}
        {')'}
      </span>

      {!isNullish(props.onEquipmentEdit) && (
        <FontAwesomeIcon
          icon={faPen}
          className="cursor-pointer"
          onClick={() => props.onEquipmentEdit?.(equipment)}
        />
      )}
    </div>
  )
}

export interface HvacSystemCardProps {
  // TODO: Refactor so this only takes in the minimal amount of data
  hvacSystem: InstalledHvacSystem
  onHvacSystemEdit?: (hvacSystem: InstalledHvacSystem) => void
  onEquipmentEdit?: (equipment: InstalledEquipmentSummary) => void
  onEquipmentView?: (equipment: InstalledEquipmentSummary) => void
}

export const HvacSystemCard = (props: HvacSystemCardProps) => {
  const { hvacSystem } = props

  return (
    <SectionedCard
      small
      dashed
      sections={[
        {
          verticalPaddingClassName: 'p-3',
          content: (
            <div className="flex flex-row items-center justify-between">
              <span className="font-semibold">{hvacSystem.friendlyName}</span>

              {!isNullish(props.onHvacSystemEdit) && (
                <FontAwesomeIcon
                  icon={faPen}
                  className="cursor-pointer"
                  onClick={() => props.onHvacSystemEdit?.(hvacSystem)}
                />
              )}
            </div>
          ),
        },
        {
          content: (
            <LabeledItemGrid
              items={[
                'Equipment',
                ...hvacSystem.installedEquipment
                  .map(equipment => (
                    <HvacSystemEquipmentLineItem
                      key={equipment.installedEquipmentGuid}
                      equipment={equipment}
                      onEquipmentEdit={props.onEquipmentEdit}
                      onEquipmentView={props.onEquipmentView}
                    />
                  ))
                  .map(lineItem => [lineItem, ''])
                  .flat()
                  .slice(0, hvacSystem.installedEquipment.length * 2 - 1),
                'Zoning',
                ...hvacSystem.zoningInfo.zones
                  .map(zone => [zone.name, ' '])
                  .flat()
                  .slice(0, hvacSystem.zoningInfo.zones.length * 2 - 1),
                'Filter Size',
                hvacSystem.filterSize ?? <EmDash />,
                'System Notes',
                hvacSystem.notes ?? <EmDash />,
              ]}
            />
          ),
        },
      ]}
    />
  )
}
