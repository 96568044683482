import { useMemo } from 'react'
import { useQuery } from 'urql'
import { DocumentType, gql } from '../../generated'

export const COMPANY_UNIQUE_ZIP_CODES_QUERY = gql(/* GraphQL */ `
  query GetCompanyUniqueZipCodes($companyGuid: uuid!) {
    locations(
      where: { companyGuid: { _eq: $companyGuid } }
      distinctOn: [addressGuid]
    ) {
      address {
        zipCode
      }
    }
  }
`)

export type CompanyUniqueZipCodesQuery = DocumentType<
  typeof COMPANY_UNIQUE_ZIP_CODES_QUERY
>

export const convertQueryToCompanyUniqueZipCodes = (
  data: CompanyUniqueZipCodesQuery | undefined,
) => {
  if (!data?.locations) return []

  const uniqueZipCodes = new Set(
    data.locations.map(location => location.address?.zipCode).filter(Boolean),
  )

  return Array.from(uniqueZipCodes).sort()
}

export const useFetchCompanyUniqueZipCodes = (companyGuid: string) => {
  const fetchUniqueZipCodesQuery = useQuery({
    query: COMPANY_UNIQUE_ZIP_CODES_QUERY,
    variables: { companyGuid },
  })
  const [{ data: zipCodesData, fetching: fetchingZipCodes }] =
    fetchUniqueZipCodesQuery

  const zipCodes = useMemo(() => {
    return convertQueryToCompanyUniqueZipCodes(zipCodesData)
  }, [zipCodesData])

  return {
    zipCodes,
    fetchingZipCodes,
    fetchUniqueZipCodesQuery,
  }
}
