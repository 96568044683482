import { CalculatePaths, isNullish, R } from '@breezy/shared'
import { useCallback, useMemo } from 'react'
import { useNavigate } from 'react-router-dom'
import { OnsitePageCollapsibleSection } from 'src/adam-components/OnsitePage/OnsitePageCollapsibleSection'
import {
  EstimateCard,
  type EstimateCardEstimate,
} from 'src/components/Cards/EstimateCard'
import GqlQueryLoader from 'src/components/GqlQueryLoader/GqlQueryLoader'
import { LoadingSpinner } from 'src/components/LoadingSpinner'
import { EstimatesBoolExp } from 'src/generated/user/graphql'
import { useFeatureFlag } from 'src/hooks/useFeatureFlags'
import { useExpectedCompanyTimeZoneId } from 'src/providers/PrincipalUser'
import { useSubscription } from 'urql'
import {
  ESTIMATES_FOR_COLLAPSIBLE_SUBSCRIPTION,
  LINKED_ESTIMATES_FOR_COLLAPSIBLE_SUBSCRIPTION,
} from './EstimateCollapsible.gql'

export interface EstimatesCollapsibleV2Props {
  links: {
    accountGuid: string
    jobGuid?: string
    jobAppointmentGuid?: string
    locationGuid?: string
  }

  allowExternalCreate?: boolean
}

export const EstimatesCollapsibleV2 = (props: EstimatesCollapsibleV2Props) => {
  const navigate = useNavigate()
  const timezoneId = useExpectedCompanyTimeZoneId()
  const linkedJobsEnabled = useFeatureFlag('linkedJobs')

  // In V2 we require a job guid so we can't have a + unless we have the job guid.
  const allowCreate =
    !isNullish(props.links.jobGuid) && (props.allowExternalCreate ?? true)

  const onCreateEstimate = useCallback(() => {
    props.links.jobGuid &&
      navigate(
        CalculatePaths.newEstimate({
          jobGuid: props.links.jobGuid,
          jobAppointmentGuid: props.links.jobAppointmentGuid,
        }),
      )
  }, [navigate, props.links.jobAppointmentGuid, props.links.jobGuid])

  const whereExpression = useMemo<EstimatesBoolExp>(() => {
    let where: EstimatesBoolExp = {
      job: {
        accountGuid: {
          _eq: props.links.accountGuid,
        },
      },
    }

    if (props.links.jobGuid) {
      where = R.mergeDeepRight(where, {
        jobGuid: {
          _eq: props.links.jobGuid,
        },
      })
    }
    if (props.links.locationGuid) {
      where = R.mergeDeepRight(where, {
        job: {
          locationGuid: {
            _eq: props.links.locationGuid,
          },
        },
      })
    }

    return where
  }, [props.links.accountGuid, props.links.jobGuid, props.links.locationGuid])

  const estimatesQuery = useSubscription({
    query: ESTIMATES_FOR_COLLAPSIBLE_SUBSCRIPTION,
    variables: {
      where: whereExpression,
    },
  })

  const linkedEstimatesQuery = useSubscription({
    query: LINKED_ESTIMATES_FOR_COLLAPSIBLE_SUBSCRIPTION,
    variables: { jobGuid: props.links.jobGuid ?? '' },
    pause: isNullish(props.links.jobGuid) || !linkedJobsEnabled,
  })

  const estimates = useMemo<EstimateCardEstimate[]>(() => {
    if (!estimatesQuery[0].data) {
      return []
    }

    return estimatesQuery[0].data.estimates.map(estimate => ({
      estimateGuid: estimate.estimateGuid,
      displayId: estimate.displayId.toString(),
      issuedAt: estimate.createdAt,
      options: estimate.estimateOptions.map(option => ({
        estimateOptionGuid: option.estimateOptionGuid,
        isSelected: option.isSelected,
        sequence: option.seq,
        totalUsc: option.totalUsc,
        displayName: option.displayName,
      })) satisfies EstimateCardEstimate['options'],
      status: estimate.status,
      createdBy: estimate.createdByUser
        ? { name: estimate.createdByUser.fullName }
        : undefined,
      job: {
        jobGuid: estimate.job.jobGuid,
        displayId: estimate.job.displayId.toString(),
        jobTypeName: estimate.job.jobType.name,
      },
    }))
  }, [estimatesQuery])

  const linkedEstimates = useMemo<EstimateCardEstimate[]>(() => {
    if (!linkedEstimatesQuery[0].data?.jobsByPk) {
      return []
    }

    return linkedEstimatesQuery[0].data.jobsByPk.linkedJobEstimates.map(
      ({ estimate }) => ({
        estimateGuid: estimate.estimateGuid,
        displayId: estimate.displayId.toString(),
        issuedAt: estimate.createdAt,
        options: estimate.estimateOptions.map(option => ({
          estimateOptionGuid: option.estimateOptionGuid,
          isSelected: option.isSelected,
          sequence: option.seq,
          totalUsc: option.totalUsc,
          displayName: option.displayName,
        })) satisfies EstimateCardEstimate['options'],
        status: estimate.status,
        createdBy: estimate.createdByUser
          ? { name: estimate.createdByUser.fullName }
          : undefined,
        linkedFromJob: {
          jobGuid: estimate.job.jobGuid,
          displayId: estimate.job.displayId.toString(),
          jobTypeName: estimate.job.jobType.name,
        },
      }),
    )
  }, [linkedEstimatesQuery])

  return (
    <GqlQueryLoader
      query={estimatesQuery}
      render={() => (
        <OnsitePageCollapsibleSection
          smallTitle
          title="Estimates"
          count={estimates.length + linkedEstimates.length}
          defaultCollapsed={estimates.length + linkedEstimates.length === 0}
          onAdd={allowCreate ? onCreateEstimate : undefined}
        >
          <div className="flex flex-col gap-2">
            {estimates.map(estimate => (
              <EstimateCard
                key={estimate.estimateGuid}
                estimate={estimate}
                timezoneId={timezoneId}
              />
            ))}

            <GqlQueryLoader
              query={linkedEstimatesQuery}
              render={() => (
                <>
                  {linkedEstimates.map(estimate => (
                    <EstimateCard
                      key={estimate.estimateGuid}
                      estimate={estimate}
                      timezoneId={timezoneId}
                    />
                  ))}
                </>
              )}
              loadingComponent={<LoadingSpinner />}
              errorComponent={<span>Failed to load linked estimates</span>}
            />
          </div>
        </OnsitePageCollapsibleSection>
      )}
      loadingComponent={
        <OnsitePageCollapsibleSection title="Estimates" smallTitle>
          <LoadingSpinner />
        </OnsitePageCollapsibleSection>
      }
      errorComponent={
        <OnsitePageCollapsibleSection title="Estimates" smallTitle>
          <span>Failed to load estimates</span>
        </OnsitePageCollapsibleSection>
      }
    />
  )
}
