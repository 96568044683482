import { z } from 'zod'
import { AsyncFn } from '../../common'
import { guidSchema, isoDateStringSchema } from '../../contracts/_common'
import { ForCompanyUser } from '../Company/Company'
import { PaymentStatus } from '../Finance/Payments/PaymentTypes'
import { bzOptional } from '../common-schemas'
import { InvoiceV2StatusSchema } from './InvoicesV2'

export const InvoiceCreatedEventTypeSchema = z.literal('CREATED')
export const InvoiceUpdatedEventTypeSchema = z.literal('UPDATED')
export const InvoicePresentedEventTypeSchema = z.literal('PRESENTED')
export const InvoiceSentEventTypeSchema = z.literal('SENT')
export const InvoiceFinancingApplicationSentEventTypeSchema = z.literal('FINANCING_APPLICATION_SENT')
export const InvoiceSignedEventTypeSchema = z.literal('SIGNED')
export const InvoicePaymentMadeEventTypeSchema = z.literal('PAYMENT_MADE')
export const InvoiceQboSyncEventTypeSchema = z.literal('QBO_SYNC')
export const InvoiceManualStatusChangeEventTypeSchema = z.literal('MANUAL_STATUS_CHANGE')
export const InvoiceAccountingSyncEventTypeSchema = z.literal('ACCOUNTING_SYNC')

export const InvoiceEventTypeSchema = z.union([
  InvoiceCreatedEventTypeSchema,
  InvoiceUpdatedEventTypeSchema,
  InvoicePresentedEventTypeSchema,
  InvoiceSentEventTypeSchema,
  InvoiceFinancingApplicationSentEventTypeSchema,
  InvoiceSignedEventTypeSchema,
  InvoicePaymentMadeEventTypeSchema,
  InvoiceQboSyncEventTypeSchema,
  InvoiceManualStatusChangeEventTypeSchema,
  InvoiceAccountingSyncEventTypeSchema,
])

const BaseInvoiceEventInputSchema = z.object({
  invoiceGuid: guidSchema,
  occurredAt: bzOptional(isoDateStringSchema),
})

const InvoiceCreatedEventInputSchema = BaseInvoiceEventInputSchema.extend({
  type: InvoiceCreatedEventTypeSchema,
})
const InvoiceUpdatedEventInputSchema = BaseInvoiceEventInputSchema.extend({
  type: InvoiceUpdatedEventTypeSchema,
})
const InvoicePresentedEventInputSchema = BaseInvoiceEventInputSchema.extend({
  type: InvoicePresentedEventTypeSchema,
})
const InvoiceSentEventInputSchema = BaseInvoiceEventInputSchema.extend({
  type: InvoiceSentEventTypeSchema,
  data: z.object({
    deliveryMethod: z.enum(['EMAIL', 'SMS']),
  }),
})
const InvoiceFinancingApplicationSentEventInputSchema = BaseInvoiceEventInputSchema.extend({
  type: InvoiceFinancingApplicationSentEventTypeSchema,
  data: z.object({
    applicationType: z.enum(['LOAN_APPLICATION', 'PREQUAL_APPLICATION']),
  }),
})
const InvoiceSignedEventInputSchema = BaseInvoiceEventInputSchema.extend({
  type: InvoiceSignedEventTypeSchema,
})
const InvoicePaymentMadeEventInputSchema = BaseInvoiceEventInputSchema.extend({
  type: InvoicePaymentMadeEventTypeSchema,
  data: z.object({
    paymentRecordGuid: guidSchema,
  }),
})

const InvoiceQboSyncEventInputSchema = BaseInvoiceEventInputSchema.extend({
  type: InvoiceQboSyncEventTypeSchema,
})
const InvoiceAccountingSyncEventInputSchema = BaseInvoiceEventInputSchema.extend({
  type: InvoiceAccountingSyncEventTypeSchema,
  data: z.object({
    accountingIntegrationType: z.enum(['QBD', 'QBO']),
  }),
})
const InvoiceManualStatusChangeEventInputSchema = BaseInvoiceEventInputSchema.extend({
  type: InvoiceManualStatusChangeEventTypeSchema,
  data: z.object({
    status: InvoiceV2StatusSchema,
  }),
})

export type InvoiceManualStatusChangeEventInput = z.infer<typeof InvoiceManualStatusChangeEventInputSchema>

const InvoiceEventInputSchema = z.discriminatedUnion('type', [
  InvoiceCreatedEventInputSchema,
  InvoiceUpdatedEventInputSchema,
  InvoicePresentedEventInputSchema,
  InvoiceSentEventInputSchema,
  InvoiceFinancingApplicationSentEventInputSchema,
  InvoiceSignedEventInputSchema,
  InvoicePaymentMadeEventInputSchema,
  InvoiceQboSyncEventInputSchema,
  InvoiceAccountingSyncEventInputSchema,
  InvoiceManualStatusChangeEventInputSchema,
])
export type InvoiceEventInput = z.infer<typeof InvoiceEventInputSchema>
export const InvoiceWriteEventInputSchema = z.object({
  input: InvoiceEventInputSchema,
})
export type InvoiceWriteEventInput = z.infer<typeof InvoiceWriteEventInputSchema>
export type InvoiceEventHandler = AsyncFn<ForCompanyUser<InvoiceEventInput>>

export type InvoiceEventInfoItem = string
export const BaseInvoiceEventSchema = z.object({
  invoiceEventGuid: guidSchema,
  invoiceGuid: guidSchema,
  companyGuid: guidSchema,
  title: z.string(),
  infoItems: z.array(z.string()),
  occurredAt: isoDateStringSchema,
  // This is the user that created the event
  createdBy: guidSchema,
  metadata: z.record(z.unknown()),
})

const InvoiceCreatedEventSchema = BaseInvoiceEventSchema.extend({
  eventType: InvoiceCreatedEventTypeSchema,
})

const InvoicePaymentMadeEventSchema = BaseInvoiceEventSchema.extend({
  eventType: InvoicePaymentMadeEventTypeSchema,
  metadata: z.object({
    paymentRecordGuid: guidSchema,
  }),
})

const InvoiceUpdatedEventSchema = BaseInvoiceEventSchema.extend({
  eventType: InvoiceUpdatedEventTypeSchema,
})

const InvoicePresentedEventSchema = BaseInvoiceEventSchema.extend({
  eventType: InvoicePresentedEventTypeSchema,
})

const InvoiceSentEventSchema = BaseInvoiceEventSchema.extend({
  eventType: InvoiceSentEventTypeSchema,
  metadata: z.object({
    deliveryMethod: z.enum(['EMAIL', 'SMS']),
  }),
})

const InvoiceFinancingApplicationSentEventSchema = BaseInvoiceEventSchema.extend({
  eventType: InvoiceFinancingApplicationSentEventTypeSchema,
})

const InvoiceSignedEventSchema = BaseInvoiceEventSchema.extend({
  eventType: InvoiceSignedEventTypeSchema,
})

const InvoiceAccountingSyncEventSchema = BaseInvoiceEventSchema.extend({
  eventType: InvoiceAccountingSyncEventTypeSchema,
  metadata: z.object({
    accountingIntegrationType: z.string(),
  }),
})

const InvoiceManualStatusChangeEventSchema = BaseInvoiceEventSchema.extend({
  eventType: InvoiceManualStatusChangeEventTypeSchema,
  metadata: z.object({
    status: InvoiceV2StatusSchema,
  }),
})
const InvoiceQboSyncEventSchema = BaseInvoiceEventSchema.extend({
  eventType: InvoiceQboSyncEventTypeSchema,
})

const InvoiceEventSchema = z.discriminatedUnion('eventType', [
  InvoiceCreatedEventSchema,
  InvoiceUpdatedEventSchema,
  InvoicePresentedEventSchema,
  InvoiceSentEventSchema,
  InvoiceFinancingApplicationSentEventSchema,
  InvoiceSignedEventSchema,
  InvoicePaymentMadeEventSchema,
  InvoiceQboSyncEventSchema,
  InvoiceAccountingSyncEventSchema,
  InvoiceManualStatusChangeEventSchema,
])

export type InvoiceEvent = z.infer<typeof InvoiceEventSchema>
export type InvoiceEventType = InvoiceEvent['eventType']

// TODO: It seems silly to have 3 different schemas to support different variants of what should
// be a single discriminated union. My ideal is to do something like:
// ```
// type InvoiceHistoryInfo = Pick<InvoiceEvent, 'title' | 'occurredAt' | 'eventType', 'metadata'> & { createdByUserFullName: string }
// ```
// But for some reason it converts from a discriminated union to just a normal union for metadata and we can't have that.
// If I was better at TypeScript, I'd figure out a way to derive the
// different variants of the discriminated union but I'm going to brute-force it for now :sadge:.
const BaseInvoiceHistoryInfoSchema = z.object({
  title: z.string(),
  occurredAt: isoDateStringSchema,
  createdByUserFullName: z.string(),
})

const InvoicePaymentMadeEventHistoryInfoSchema = BaseInvoiceHistoryInfoSchema.extend({
  eventType: InvoicePaymentMadeEventTypeSchema,
  metadata: z.object({
    paymentRecordGuid: guidSchema,
  }),
})
export type InvoicePaymentMadeEventHistoryInfo = z.infer<typeof InvoicePaymentMadeEventHistoryInfoSchema>

const InvoiceManualStatusChangeEventHistoryInfoSchema = BaseInvoiceHistoryInfoSchema.extend({
  eventType: InvoiceManualStatusChangeEventTypeSchema,
  metadata: z.object({
    status: InvoiceV2StatusSchema,
  }),
})
export type InvoiceManualStatusChangeEventHistoryInfo = z.infer<typeof InvoiceManualStatusChangeEventHistoryInfoSchema>

const InvoiceCreatedEventHistoryInfoSchema = BaseInvoiceHistoryInfoSchema.extend({
  eventType: InvoiceCreatedEventTypeSchema,
  metadata: z.object({}),
})

const InvoiceUpdatedEventHistoryInfoSchema = BaseInvoiceHistoryInfoSchema.extend({
  eventType: InvoiceUpdatedEventTypeSchema,
  metadata: z.object({}),
})

const InvoicePresentedEventHistoryInfoSchema = BaseInvoiceHistoryInfoSchema.extend({
  eventType: InvoicePresentedEventTypeSchema,
  metadata: z.object({}),
})

const InvoiceSentEventHistoryInfoSchema = BaseInvoiceHistoryInfoSchema.extend({
  eventType: InvoiceSentEventTypeSchema,
  metadata: z.object({
    deliveryMethod: z.enum(['EMAIL', 'SMS']),
  }),
})

const InvoiceFinancingApplicationSentEventHistoryInfoSchema = BaseInvoiceHistoryInfoSchema.extend({
  eventType: InvoiceFinancingApplicationSentEventTypeSchema,
  metadata: z.object({
    applicationType: z.enum(['LOAN_APPLICATION', 'PREQUAL_APPLICATION']),
  }),
})

const InvoiceSignedEventHistoryInfoSchema = BaseInvoiceHistoryInfoSchema.extend({
  eventType: InvoiceSignedEventTypeSchema,
  metadata: z.object({}),
})

const InvoiceQboSyncEventHistoryInfoSchema = BaseInvoiceHistoryInfoSchema.extend({
  eventType: InvoiceQboSyncEventTypeSchema,
  metadata: z.object({}),
})

const InvoiceAccountingSyncEventHistoryInfoSchema = BaseInvoiceHistoryInfoSchema.extend({
  eventType: InvoiceAccountingSyncEventTypeSchema,
  metadata: z.object({
    accountingIntegrationType: z.string(),
  }),
})

const InvoiceEventHistoryInfoSchema = z.discriminatedUnion('eventType', [
  InvoiceCreatedEventHistoryInfoSchema,
  InvoiceUpdatedEventHistoryInfoSchema,
  InvoicePresentedEventHistoryInfoSchema,
  InvoiceSentEventHistoryInfoSchema,
  InvoiceFinancingApplicationSentEventHistoryInfoSchema,
  InvoiceSignedEventHistoryInfoSchema,
  InvoicePaymentMadeEventHistoryInfoSchema,
  InvoiceQboSyncEventHistoryInfoSchema,
  InvoiceAccountingSyncEventHistoryInfoSchema,
  InvoiceManualStatusChangeEventHistoryInfoSchema,
])

export type InvoiceHistoryInfo = z.infer<typeof InvoiceEventHistoryInfoSchema>

export type InvoiceEventGenerator = AsyncFn<
  ForCompanyUser<InvoiceEventInput> & {
    createdByUserFirstNameInitial?: string
  },
  InvoiceEvent
>

export const recordableInvoiceEventPaymentStatuses = [
  PaymentStatus.FAILED,
  PaymentStatus.PENDING,
  PaymentStatus.PROCESSING,
  PaymentStatus.PAID,
]
