import { PageHeader } from '@ant-design/pro-components'
import { BzDateFns, IsoDateString } from '@breezy/shared'
import { faFileInvoiceDollar, faTrash } from '@fortawesome/pro-light-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Button, Row } from 'antd'
import { useCallback, useMemo, useState } from 'react'
import { useMutation } from 'urql'
import {
  ConfirmationModal,
  useConfirmationModal,
} from '../../adam-components/OnsiteModal/useConfirmationModal'
import { LoadingSpinner } from '../../components/LoadingSpinner/LoadingSpinner'
import PageTitle from '../../elements/PageTitle/PageTitle'
import { ReadInvoiceTemplatesQuery } from '../../generated/user/graphql'
import { useInvoiceTemplates } from '../../hooks/useInvoiceTemplates'
import { useExpectedCompanyTimeZoneId } from '../../providers/PrincipalUser'
import { InvoiceTemplateUpsertDrawer } from './InvoiceTemplateUpsertDrawer'
import { DELETE_INVOICE_TEMPLATE_MUTATION } from './InvoiceTemplates.gql'

export const NumberLabelPair = ({
  kpi,
  description,
}: {
  kpi: string
  description: string
}) => (
  <div className={`flex w-[140px] flex-col`}>
    <div className="text-sm font-semibold leading-[22px] text-[#1F1F1F]">
      {kpi}
    </div>
    <div className="text-sm font-normal leading-[22px] text-[#8C8C8C]">
      {description}
    </div>
  </div>
)

type InvoiceTemplateItemProps = {
  invoiceTemplateGuid: string
  templateName: string
  updatedAt: IsoDateString
  numLineItems: number
  onMutate: () => void
  onEdit: () => void
}

const InvoiceTemplateItem = ({
  invoiceTemplateGuid,
  templateName,
  updatedAt,
  numLineItems,
  onMutate,
  onEdit,
}: InvoiceTemplateItemProps) => {
  const tzId = useExpectedCompanyTimeZoneId()
  const formattedUpdatedAt = useMemo(
    () => BzDateFns.format(BzDateFns.parseISO(updatedAt, tzId), 'MM/dd/yy'),
    [updatedAt, tzId],
  )

  const [{ fetching: deleteInvoiceTemplateFetching }, deleteInvoiceTemplate] =
    useMutation(DELETE_INVOICE_TEMPLATE_MUTATION)

  const [withDeleteConfirmation, deleteConfirmProps] = useConfirmationModal()

  const deleteTemplate = useCallback(async () => {
    await deleteInvoiceTemplate({ invoiceTemplateGuid })
    onMutate()
  }, [deleteInvoiceTemplate, invoiceTemplateGuid, onMutate])

  return (
    <>
      <Row className="row center-children-v flex-between flex w-full gap-x-8 rounded-lg border border-solid border-[#D9D9D9] px-6 py-4">
        <div className="center-children-v flex flex-1 flex-row gap-x-4">
          <div className="center-children-vh flex h-9 w-9 items-center rounded-full bg-daybreak-blue-100">
            <FontAwesomeIcon
              icon={faFileInvoiceDollar}
              className="h-4 w-4 text-[#002C8C]"
            />
          </div>

          <div className="my-auto text-base font-semibold leading-6 text-[#1F1F1F]">
            {templateName}
          </div>
        </div>

        <NumberLabelPair
          kpi={`${numLineItems}`}
          description={`Line item${numLineItems === 1 ? '' : 's'}`}
        />

        <NumberLabelPair
          kpi={`${formattedUpdatedAt}`}
          description="Last updated"
        />

        <div className="flex flex-row gap-2">
          <Button onClick={onEdit}>Edit</Button>
          <Button
            danger
            onClick={() => withDeleteConfirmation(deleteTemplate)}
            icon={<FontAwesomeIcon icon={faTrash} />}
            loading={deleteInvoiceTemplateFetching}
          />
        </div>
      </Row>
      <ConfirmationModal
        {...deleteConfirmProps}
        danger
        header={`Are you sure you wish to delete template ${templateName}?`}
        confirmText="Delete"
      />
    </>
  )
}

type InvoiceTemplateListProps = {
  templates: InvoiceTemplates
  onMutate: () => void
  onEdit: (invoiceTemplateGuid: string) => void
}

const InvoiceTemplateList = ({
  templates,
  onMutate,
  onEdit,
}: InvoiceTemplateListProps) => {
  if (templates.length === 0) {
    return (
      <div className="flex min-h-[300px] flex-col items-center justify-center bg-[#FAFAFA]">
        <div className="flex flex-col items-center">
          <div className="mb-4 flex h-[56px] w-[56px] items-center rounded-[100px] border border-solid border-[#F5F5F5] bg-white p-2 shadow-[0px_1px_2px_0px_rgba(37,37,37,0.15)]">
            <FontAwesomeIcon
              icon={faFileInvoiceDollar}
              className="m-auto h-[24px] w-[24px] text-daybreak-blue-400"
            />
          </div>
          <div className="mb-2 text-center text-[20px] font-semibold leading-[28px] text-[#434343]">
            No invoice templates
          </div>
          <div className="mb-6 max-w-[512px] text-center text-[14px] font-normal leading-[22px] tracking-[-0.14px] text-[#595959]">
            Invoices templates will be displayed here
          </div>
        </div>
      </div>
    )
  }

  return (
    <div className="flex flex-col gap-4">
      {templates.map(template => (
        <InvoiceTemplateItem
          key={template.invoiceTemplateGuid}
          {...template}
          numLineItems={template.invoiceTemplateCartItems.length}
          onMutate={onMutate}
          onEdit={() => onEdit(template.invoiceTemplateGuid)}
        />
      ))}
    </div>
  )
}

type InvoiceTemplates = NonNullable<
  ReadInvoiceTemplatesQuery['invoiceTemplates']
>

export type InvoiceTemplateItemData = NonNullable<
  ReadInvoiceTemplatesQuery['invoiceTemplates']
>[0]

export const InvoiceTemplatesPage = () => {
  const [drawerState, setDrawerState] = useState<
    InvoiceTemplateItemData | 'add' | false
  >(false)
  const { templates, fetching, refetch } = useInvoiceTemplates()

  const onEdit = useCallback(
    (invoiceTemplateGuid: string) => {
      const templateToEdit = templates.find(
        template => template.invoiceTemplateGuid === invoiceTemplateGuid,
      )
      if (templateToEdit) {
        setDrawerState(templateToEdit)
      }
    },
    [templates, setDrawerState],
  )

  return (
    <div className="flex w-full min-w-[700px] flex-col">
      <PageHeader
        title={
          <PageTitle title="Invoice Templates" icon={faFileInvoiceDollar} />
        }
        className="mb-2"
      />
      {fetching ? (
        <LoadingSpinner />
      ) : (
        <div className="flex min-h-0 flex-col gap-y-6">
          <div className="card flex min-h-0 w-full flex-1 flex-col overflow-auto py-8">
            <div className="flex flex-row justify-between">
              <div className="mb-6 max-w-[512px] text-sm font-normal leading-[22px] text-bz-gray-700">
                Invoice templates allow you to create, customize, and manage
                standardized templates to streamline billing and ensure
                consistency across all job types.
              </div>
              <Button
                type="primary"
                onClick={() => {
                  setDrawerState('add')
                }}
              >
                Add New Template
              </Button>
            </div>
            <InvoiceTemplateList
              templates={templates}
              onMutate={refetch}
              onEdit={onEdit}
            />
          </div>
        </div>
      )}
      <InvoiceTemplateUpsertDrawer
        isOpen={drawerState !== false}
        onClose={() => setDrawerState(false)}
        onMutate={refetch}
        editTemplate={
          !!drawerState && drawerState !== 'add' ? drawerState : undefined
        }
      />
    </div>
  )
}

export default InvoiceTemplatesPage
