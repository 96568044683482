import {
  AddressDto,
  BzDateFns,
  formatUsc,
  getDueDate,
  IsoDateString,
  streetAddressLine1And2Condensed,
} from '@breezy/shared'

import classNames from 'classnames'
import React, { useMemo } from 'react'
import { SectionedCard } from '../../../adam-components/SectionedCard/SectionedCard'
import useIsMobile from '../../../hooks/useIsMobile'
import { useStrictContext } from '../../../utils/react-utils'
import { InvoiceContext, InvoiceDataContext } from '../invoiceUtils'

const TEXT_SEPARATOR = <span className="mx-1">•</span>

type AddressInfoProps = {
  header: string
  items: (React.ReactNode | undefined)[]
}

const AddressInfo = React.memo<AddressInfoProps>(({ header, items }) => (
  <div className="min-w-0 flex-1">
    <div className="mb-2 font-semibold">{header}</div>
    <div className="text-sm leading-[22px]">
      {items.filter(Boolean).map((item, index) => (
        <div key={index} className="break-words">
          {item}
        </div>
      ))}
    </div>
  </div>
))

type ContentCell = {
  label: string
  date?: IsoDateString
  value?: string
}

export const InvoiceInfoContent = React.memo(() => {
  const { info, invoiceTotals } = useStrictContext(InvoiceDataContext)
  const isMobile = useIsMobile()
  const {
    logoUrl,
    companyName,
    businessAddress,
    businessEmail,
    businessPhoneNumber,
    businessWebsite,
    displayId,
    tzId,
    jobGuid,
  } = useStrictContext(InvoiceContext)

  const businessInfoItems = useMemo(() => {
    const items: string[] = []

    if (businessAddress) {
      items.push(
        streetAddressLine1And2Condensed(
          businessAddress.line1,
          businessAddress.line2,
        ),
      )
      items.push(
        `${businessAddress.city}, ${businessAddress.stateAbbreviation} ${businessAddress.zipCode}`,
      )
    }
    if (businessPhoneNumber) {
      items.push(businessPhoneNumber)
    }
    if (businessEmail) {
      items.push(businessEmail)
    }
    if (businessWebsite) {
      items.push(businessWebsite)
    }
    return items
  }, [businessAddress, businessEmail, businessPhoneNumber, businessWebsite])

  const showServiceContactInfo =
    info.billingAddress.type === 'same_as_service' ||
    info.billingContactGuid !== info.serviceContact.contactGuid

  // If they set an ad-hoc billing address or choose the account's default billing address, we want to show that
  // address and show whoever they chose as the billing contact. If the billing address is "same as service address"
  // and the billing contact is the same as the service contact, then we want to show "(same as service address)". But
  // if the billing contact is different from the service contact, we have to show it and also show the full address.
  const billingAddressItems = useMemo(() => {
    let address: AddressDto | undefined = undefined
    if (info.resolvedBillingAddress) {
      address = info.resolvedBillingAddress
    } else if (
      info.billingContact.contactGuid !== info.serviceContact.contactGuid
    ) {
      address = info.serviceAddress
    }
    if (!address) {
      return ['(same as service address)']
    }
    return [
      <span className="font-semibold">{info.billingContact.fullName}</span>,
      address.line1,
      address.line2,
      `${address.city}, ${address.stateAbbreviation}`,
      address.zipCode,
      info.billingContact.primaryPhoneNumber?.phoneNumber,
      info.billingContact.primaryEmailAddress?.emailAddress,
    ]
  }, [
    info.billingContact.contactGuid,
    info.billingContact.fullName,
    info.billingContact.primaryEmailAddress?.emailAddress,
    info.billingContact.primaryPhoneNumber?.phoneNumber,
    info.resolvedBillingAddress,
    info.serviceAddress,
    info.serviceContact.contactGuid,
  ])

  const [topCells, bottomCells] = useMemo(() => {
    const topCells: ContentCell[] = []
    const bottomCells: ContentCell[] = []

    if (info.customerPurchaseOrderNumber) {
      topCells.push({
        label: 'PO#',
        value: info.customerPurchaseOrderNumber,
      })
    }
    topCells.push({
      label: 'Issued Date',
      date: info.issuedAt ?? BzDateFns.nowISOString(),
    })
    // We only want to have the service completed date for invoices that are for a job
    if (jobGuid) {
      topCells.push({
        label: 'Service Completed',
        value: BzDateFns.localDateToFormattedDateString(
          info.serviceCompletionDate,
          'MMM. d, yyyy'.replace('May.', 'May'),
        ),
      })
    }

    topCells.push({
      label: 'Due Date',
      date:
        info.dueAt ??
        getDueDate(
          info.issuedAt ?? BzDateFns.nowISOString(),
          info.invoiceTerm,
          tzId,
        ),
    })

    bottomCells.push({
      label: 'Total',
      value: formatUsc(invoiceTotals.totalUsc),
    })

    if (invoiceTotals.processingPaymentsUsc > 0) {
      bottomCells.push({
        label: 'Processing',
        value: formatUsc(invoiceTotals.processingPaymentsUsc),
      })
    }

    if (invoiceTotals.paidUsc > 0) {
      bottomCells.push({
        label: 'Paid',
        value: formatUsc(invoiceTotals.paidUsc),
      })
    }

    if (invoiceTotals.refundedUsc > 0) {
      bottomCells.push({
        label: 'Refunded',
        value: formatUsc(invoiceTotals.refundedUsc),
      })
    }

    bottomCells.push({
      label: 'Balance Due',
      value: formatUsc(invoiceTotals.dueUsc),
    })

    return [topCells, bottomCells]
  }, [
    info.customerPurchaseOrderNumber,
    info.dueAt,
    info.invoiceTerm,
    info.issuedAt,
    info.serviceCompletionDate,
    invoiceTotals.dueUsc,
    invoiceTotals.paidUsc,
    invoiceTotals.processingPaymentsUsc,
    invoiceTotals.refundedUsc,
    invoiceTotals.totalUsc,
    jobGuid,
    tzId,
  ])

  return (
    <div>
      <div
        className={classNames(
          'mb-3 flex items-start border-0 border-b border-dashed border-bz-gray-500',
          isMobile ? 'mb-4 flex-col pb-4' : 'mb-3 flex-row pb-5',
        )}
      >
        <div
          className={classNames(
            'mr-6 min-w-0 flex-1',
            isMobile ? 'order-2' : 'order-1',
          )}
        >
          <div
            className={classNames(
              'font-semibold',
              isMobile ? 'mb-1 text-base' : 'mb-2 text-xl',
            )}
          >
            {companyName}
          </div>
          <div className="flex max-w-full flex-row flex-wrap text-sm leading-[22px]">
            {businessInfoItems.map((item, index) => (
              <div key={index}>
                {item}
                {index !== businessInfoItems.length - 1 ? TEXT_SEPARATOR : null}
              </div>
            ))}
          </div>
        </div>
        <img
          src={logoUrl}
          alt={companyName}
          className={classNames(
            ' object-contain',
            isMobile
              ? 'order-1 mb-4 max-h-[66px] w-full max-w-[40%] object-left-top'
              : 'order-2  h-[88px] min-h-[88px] w-[214px] min-w-[214px] object-right-top',
          )}
        />
      </div>
      <div
        className={classNames(
          'flex',
          isMobile ? 'flex-col' : 'flex-row  items-start',
        )}
      >
        <div
          className={classNames(
            'flex min-w-0 flex-1 flex-row items-start space-x-4',
            isMobile ? 'mb-4' : 'mr-4',
          )}
        >
          <AddressInfo
            header="Service Address"
            items={[
              <span className="font-semibold">
                {info.serviceContact.fullName}
              </span>,
              info.serviceAddress.line1,
              info.serviceAddress.line2,
              `${info.serviceAddress.city}, ${info.serviceAddress.stateAbbreviation}`,
              info.serviceAddress.zipCode,
              showServiceContactInfo &&
                info.serviceContact.primaryPhoneNumber?.phoneNumber,
              showServiceContactInfo &&
                info.serviceContact.primaryEmailAddress?.emailAddress,
            ]}
          />
          <AddressInfo header="Billing Address" items={billingAddressItems} />
        </div>
        <SectionedCard
          className={isMobile ? 'w-full' : 'max-w-[50%]'}
          small
          dashed
          sections={[
            <div className="whitespace-nowrap font-semibold">
              Invoice #{displayId}
            </div>,
            ...[topCells, bottomCells].map((cells, i) => (
              <div key={i} className="grid grid-cols-[auto_auto] gap-y-2">
                {cells.map(({ label, date, value }) => (
                  <React.Fragment key={label}>
                    <div className="pr-4 font-semibold">{label}</div>
                    <div className="text-right">
                      {date
                        ? BzDateFns.format(
                            BzDateFns.parseISO(date, tzId),
                            date.includes('May')
                              ? 'MMM d, yyyy'
                              : 'MMM. d, yyyy',
                          )
                        : value}
                    </div>
                  </React.Fragment>
                ))}
              </div>
            )),
          ]}
        />
      </div>
    </div>
  )
})
