import { bzExpect, formatUsc, isNullish } from '@breezy/shared'
import { faPenToSquare } from '@fortawesome/pro-regular-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { ObjectCard } from 'src/adam-components/ObjectCard/ObjectCard'

export interface TechnicianRevenueAttributionCardProps {
  technician: {
    name: string
    revenueAttribution: {
      soldRevenueUsc: number
      deductionsUsc: number
      commissionableBaseUsc?: number
      commissionRate?: number
      commissionUsc?: number
      earnedRevenueUsc: number
      bonusUsc?: number
    }
  }
  canManageTechnicianPerformance?: boolean
  onEdit?: () => void
}

export const TechnicianRevenueAttributionCard = (
  props: TechnicianRevenueAttributionCardProps,
) => {
  const technician = props.technician
  const revenueAttribution = technician.revenueAttribution

  const shouldShowCommissionInfo =
    !isNullish(props.technician.revenueAttribution.commissionRate) &&
    !isNullish(props.technician.revenueAttribution.commissionableBaseUsc) &&
    !isNullish(props.technician.revenueAttribution.commissionUsc) &&
    props.technician.revenueAttribution.commissionRate > 0

  return (
    <ObjectCard paddingClassName="p-3">
      <ObjectCard.InfoItem
        title={<ObjectCard.InfoItem.Title text={technician.name} />}
        info={
          <>
            {props.canManageTechnicianPerformance && (
              <FontAwesomeIcon
                icon={faPenToSquare}
                onClick={props.onEdit}
                className="cursor-pointer"
              />
            )}
          </>
        }
      />

      <ObjectCard.Divider />

      <div className="flex w-full flex-col gap-1">
        <ObjectCard.InfoItem
          title={<ObjectCard.InfoItem.Title text="Sold Revenue" size="sm" />}
          info={formatUsc(revenueAttribution.soldRevenueUsc)}
        />

        <ObjectCard.InfoItem
          title={<ObjectCard.InfoItem.Title text="Deductions" size="sm" />}
          info={formatUsc(revenueAttribution.deductionsUsc)}
        />

        {shouldShowCommissionInfo && (
          <>
            <ObjectCard.InfoItem
              title={
                <ObjectCard.InfoItem.Title
                  text="Commisionable Base"
                  size="sm"
                />
              }
              info={formatUsc(
                bzExpect(revenueAttribution.commissionableBaseUsc),
              )}
            />

            <ObjectCard.InfoItem
              title={
                <ObjectCard.InfoItem.Title
                  text={`Commision (${(
                    bzExpect(revenueAttribution.commissionRate) * 100
                  ).toFixed(2)}%)`}
                  size="sm"
                />
              }
              info={formatUsc(bzExpect(revenueAttribution.commissionUsc))}
            />
          </>
        )}

        <ObjectCard.InfoItem
          title={<ObjectCard.InfoItem.Title text="Earned Revenue" size="sm" />}
          info={formatUsc(revenueAttribution.earnedRevenueUsc)}
        />

        {!isNullish(revenueAttribution.bonusUsc) && (
          <ObjectCard.InfoItem
            title={<ObjectCard.InfoItem.Title text="Bonus" size="sm" />}
            info={formatUsc(revenueAttribution.bonusUsc)}
          />
        )}
      </div>
    </ObjectCard>
  )
}
