import { z } from 'zod'
import { bzOptional } from '..'
import { guidSchema, isoDateStringSchema, localTimeStringSchema } from '../../contracts'
import { JobClass } from '../Job'

export const ONLINE_BOOKING_SERVICE_TYPES = ['MAINTENANCE', 'SERVICE', 'ESTIMATE'] as const
export type OnlineBookingServiceType = (typeof ONLINE_BOOKING_SERVICE_TYPES)[number]

export const OnlineBookingServiceTypeDisplayNames: Record<OnlineBookingServiceType, string> = {
  MAINTENANCE: 'Maintenance',
  SERVICE: 'Service',
  ESTIMATE: 'Estimate',
}

export const OnlineBookingServiceTypeConfigDescriptions: Record<OnlineBookingServiceType, string> = {
  MAINTENANCE:
    'Set up and manage regular maintenance services to keep your customers’ HVAC systems running efficiently',
  SERVICE: 'Set up and manage one-time service calls to address specific equipment issues',
  ESTIMATE: 'Set up and manage estimate requests to provide customers with quotes for new equipment',
}

export const OnlineBookingServiceTypeDescriptions: Record<OnlineBookingServiceType, string> = {
  MAINTENANCE: 'I’d like a maintenance service for my equipment',
  SERVICE: 'My equipment isn’t functioning as expected',
  ESTIMATE: 'I’m interested in replacing my current equipment',
}

export const OnlineBookingServiceTypeToJobClassMap: Record<OnlineBookingServiceType, JobClass> = {
  MAINTENANCE: JobClass.MAINTENANCE,
  SERVICE: JobClass.SERVICE,
  ESTIMATE: JobClass.ESTIMATE_REPLACE,
}

export const OnlineBookingServiceTypeToJobClasses: Record<OnlineBookingServiceType, JobClass[]> = {
  MAINTENANCE: [JobClass.MAINTENANCE],
  SERVICE: [JobClass.SERVICE],
  ESTIMATE: [JobClass.ESTIMATE_REPLACE, JobClass.ESTIMATE_REPAIR, JobClass.SALES],
}

export const tryParseOnlineBookingServiceType = (value: string): OnlineBookingServiceType | undefined => {
  return ONLINE_BOOKING_SERVICE_TYPES.find(type => type === value)
}

export const ONLINE_BOOKING_TYPES = ['REQUEST', 'INSTANT'] as const
export type OnlineBookingType = (typeof ONLINE_BOOKING_TYPES)[number]

export const OnlineBookingTypeDisplayNames: Record<OnlineBookingType, string> = {
  REQUEST: 'Request',
  INSTANT: 'Instant',
}

export const getBookingLinkUrl = (accountAppUrl: string, companyGuid: string) =>
  `${accountAppUrl}/book-now?companyGuid=${companyGuid}`

export const ONLINE_BOOKING_EARLIEST_AVAILABLE_TIMES = [
  '12 hours',
  '1 day',
  '2 days',
  '3 days',
  '4 days',
  '5 days',
  '6 days',
  '7 days',
] as const

export type OnlineBookingEarliestAvailableTime = (typeof ONLINE_BOOKING_EARLIEST_AVAILABLE_TIMES)[number]

export const ONLINE_BOOKING_LATEST_AVAILABLE_TIMES = ['14 days', '21 days', '30 days', '45 days', '60 days'] as const

export type OnlineBookingLatestAvailableTime = (typeof ONLINE_BOOKING_LATEST_AVAILABLE_TIMES)[number]

export const BookableArrivalWindowSchema = z.object({
  companyAppointmentArrivalWindowGuid: guidSchema,
  arrivalWindowStartTime: localTimeStringSchema,
  arrivalWindowEndTime: localTimeStringSchema,
})

export type BookableArrivalWindow = z.infer<typeof BookableArrivalWindowSchema>

const InstantBookingWeeklyScheduleDaySchema = z.object({
  enabled: z.boolean().default(false),
  bookableArrivalWindows: z.array(BookableArrivalWindowSchema).default([]),
})

const InstantBookingWeeklyScheduleSchema = z.object({
  Sunday: InstantBookingWeeklyScheduleDaySchema,
  Monday: InstantBookingWeeklyScheduleDaySchema,
  Tuesday: InstantBookingWeeklyScheduleDaySchema,
  Wednesday: InstantBookingWeeklyScheduleDaySchema,
  Thursday: InstantBookingWeeklyScheduleDaySchema,
  Friday: InstantBookingWeeklyScheduleDaySchema,
  Saturday: InstantBookingWeeklyScheduleDaySchema,
})

export type InstantBookingWeeklySchedule = z.infer<typeof InstantBookingWeeklyScheduleSchema>

export const OnlineBookingServiceTypeConfigSchema = z.object({
  onlineBookingServiceTypeGuid: guidSchema,
  companyGuid: guidSchema,
  serviceType: z.enum(ONLINE_BOOKING_SERVICE_TYPES),
  bookingType: z.enum(ONLINE_BOOKING_TYPES),
  earliestAvailability: bzOptional(z.enum(ONLINE_BOOKING_EARLIEST_AVAILABLE_TIMES)),
  latestAvailability: bzOptional(z.enum(ONLINE_BOOKING_LATEST_AVAILABLE_TIMES)),
  legalBlurb: bzOptional(z.string()),
  bookableJobTypeGuids: z.array(guidSchema),
  bookableTechnicianGuids: z.array(guidSchema),
  instantBookingWeeklySchedule: InstantBookingWeeklyScheduleSchema,
})

export type OnlineBookingServiceTypeConfig = z.infer<typeof OnlineBookingServiceTypeConfigSchema>

export const OnlineBookingServiceTypeMetadataSchema = z.object({
  onlineBookingServiceTypeGuid: guidSchema,
  serviceType: z.enum(ONLINE_BOOKING_SERVICE_TYPES),
  bookingType: z.enum(ONLINE_BOOKING_TYPES),
  updatedAt: isoDateStringSchema,
})

export type OnlineBookingServiceTypeMetadata = z.infer<typeof OnlineBookingServiceTypeMetadataSchema>

type DefaultOnlineBookingServiceTypeConfig = {
  serviceType: OnlineBookingServiceType
  bookingType: OnlineBookingType
}

export const DEFAULT_ONLINE_BOOKING_SERVICE_TYPE_CONFIGS: DefaultOnlineBookingServiceTypeConfig[] = [
  {
    serviceType: 'MAINTENANCE',
    bookingType: 'REQUEST',
  },
  {
    serviceType: 'SERVICE',
    bookingType: 'REQUEST',
  },
  {
    serviceType: 'ESTIMATE',
    bookingType: 'REQUEST',
  },
]
