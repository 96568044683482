import { z } from 'zod'
import { R } from '../../common'
import { timeZoneIdSchema } from '../../contracts'
import { bzOptional } from '../common-schemas'

const DUMMY_LOGO_URL = 'https://public-breezy-tenant-assets.s3.amazonaws.com/default_logo.png'

export const ChanceWeightsSchema = z.tuple([z.number(), z.number(), z.number()])
export const FourChanceWeightsSchema = z.tuple([z.number(), z.number(), z.number(), z.number()])

export const dbSeederRequestCompanySchema = z.object({
  timezoneId: timeZoneIdSchema.default('America/Los_Angeles').describe('Timezone ID'),
  companyOwnerFirstName: bzOptional(z.string().describe('Company Owner First Name')),
  companyOwnerLastName: bzOptional(z.string().describe('Company Owner Last Name')),
  companyName: bzOptional(z.string().describe('Company Name // Will default to the owner name + HVAC')),
  companyFullLegalName: bzOptional(
    z.string().describe('Company Full Legal Name // Will default to the business name + ", Inc."'),
  ),
  websiteUrl: bzOptional(
    z.string().describe('Website URL // Will to a sanitized version of the company name + "https", ".com", etc.'),
  ),
  websiteDisplayName: bzOptional(
    z.string().describe('Website Display Name // Will default to the company name, with capitalization, + ".com"'),
  ),
  logoUrl: z.string().default(DUMMY_LOGO_URL).describe('Logo URL // Will default to a generic logo'),
})

export const dbSeederRequestAccountSchema = z.object({
  fixedAddresses: z
    .boolean()
    .default(false)
    .describe(
      'Fixed Addresses // If true, will pull from a set of 1000 hard-coded addresses. Zip code, city, and state options are ignored. If the number of required addresses exceeds 1000, the seeder will error.',
    ),
  consistentAccountFirstName: z
    .string()
    .default('Dustin')
    .describe(
      'Consistent Account First Name // First Name of the primary contact for the account that is always there.',
    ),
  consistentAccountLastName: z
    .string()
    .default('Kane')
    .describe('Consistent Account Last Name // Last Name of the primary contact of the account that is always there.'),
  minAccounts: z.number().int().min(1).default(500).describe('Min Accounts'),
  maxAccounts: z.number().int().min(1).default(750).describe('Max Accounts'),
  minAccountNotes: z
    .number()
    .int()
    .min(1)
    .default(10)
    .describe('Min Account Notes // Minimum number of notes to generate per account'),
  maxAccountNotes: z
    .number()
    .int()
    .min(1)
    .default(30)
    .describe('Max Account Notes // Minimum number of notes to generate per account'),
  accountNoteTaggedChance: z
    .number()
    .min(0)
    .max(1)
    .default(0.5)
    .describe('Chance Account Note is Tagged // Chance an account note has at least one other user tagged'),
  accountNoteNumTagsWeights: ChanceWeightsSchema.default([0.7, 0.2, 0.1]).describe(
    'Number of People Tagged in Account Note (1, 2, 3) // If an account has a note, chances that 1, 2, or 3 users will be tagged.',
  ),
  minTagsPerAccount: z.number().int().min(1).default(1).describe('Min Tags Per Account'),
  maxTagsPerAccount: z.number().int().min(1).default(5).describe('Max Tags Per Account'),
  minRemindersPerAccount: z.number().int().min(0).default(1).describe('Min Reminders Per Account'),
  maxRemindersPerAccount: z.number().int().min(0).default(5).describe('Max Reminders Per Account'),
  accountReminderCompleteChance: z.number().min(0.1).max(1).default(0.5).describe('Account Reminder Complete Chance'),
  additionalContactChance: z
    .number()
    .min(0)
    .max(1)
    .default(0.1)
    .describe('Additional Contact Chance // Chance that an account will have two contacts'),
  numLocationWeights: ChanceWeightsSchema.default([0.65, 0.3, 0.05]).describe(
    'Number of Location Chances (1, 2, 3) // Chances that an account will have 1, 2, or 3 locations.',
  ),
  accountEquipmentRecordedChance: z.number().min(0).max(1).default(0.5).describe('Account Equipment Chance'),
  equipmentCountWeights: ChanceWeightsSchema.default([0.5, 0.3, 0.2]).describe(
    'Pieces of Equipment Chances (1, 2, 3) // Chances that an account that has equipment will have 1, 2, or 3 pieces of equipment',
  ),
  maxEquipmentAge: z.number().int().min(1).default(18).describe('Max Equipment Age (years)'),
  maxEquipmentLifeExpectancy: z.number().int().min(1).default(20).describe('Max Equipment Life Expectancy (years)'),
  minAccountCreatedDaysAgo: z
    .number()
    .int()
    .min(1)
    .default(7)
    .describe('Min Account Created Days Ago // Accounts will be "created" at least this many days in the past'),
  maxAccountCreatedDaysAgo: z
    .number()
    .int()
    .min(1)
    .default(365 * 2)
    .describe('Max Account Created Days Ago'),
  accountContactTypeWeights: ChanceWeightsSchema.default([0.4, 0.1, 0.5]).describe(
    'Contact Types (Email, Phone, Both) // Percentage of people who have just an email, just a phone number, or both',
  ),
  minZip: bzOptional(
    z
      .number()
      .int()
      .describe(
        'Min Zip Code // When combined with Max Zip Code, only generates addresses in this range. If either is blank, zips will be randomly generated. We do no validation that the zip codes you entered are valid.',
      ),
  ),
  maxZip: bzOptional(
    z
      .number()
      .int()
      .describe(
        'Max Zip Code // When combined with Min Zip Code, only generates addresses in this range. If either is blank, zips will be randomly generated. We do no validation that the zip codes you entered are valid.',
      ),
  ),
  city: bzOptional(
    z
      .string()
      .describe(
        'City // When specified, only generates addresses in this city. If blank, cities will be randomly generated.',
      ),
  ),
  state: bzOptional(
    z
      .string()
      .describe(
        'State (two letters) // When specified, only generates addresses in this state. Must be the two-letter abbreviation for the state. If blank, states will be randomly generated.',
      ),
  ),
  shouldGeocodeAddresses: bzOptional(
    z
      .boolean()
      .default(false)
      .describe(
        'Geocode Addresses? // This will geocode the addresses for maps features to work. note this will increase the generation time.',
      ),
  ),
})

export const dbSeederRequestJobSchema = z.object({
  maxJobsPerAccount: z.number().int().min(1).default(3).describe('Max Jobs Per Account'),
  minJobNotes: z
    .number()
    .int()
    .min(1)
    .default(5)
    .describe('Min Job Notes // Minimum number of notes to generate per job'),
  maxJobNotes: z
    .number()
    .int()
    .min(1)
    .default(10)
    .describe('Max Job Notes // Minimum number of notes to generate per job'),
  jobNoteTaggedChance: z
    .number()
    .min(0)
    .max(1)
    .default(0.5)
    .describe('Chance Job Note is Tagged // Chance a job note has at least one other user tagged'),
  jobNoteNumTagsWeights: ChanceWeightsSchema.default([0.7, 0.2, 0.1]).describe(
    'Number of People Tagged in Job Note (1, 2, 3) // If a job has a note, chances that 1, 2, or 3 users will be tagged.',
  ),
  minTagsPerJob: z.number().int().min(1).default(1).describe('Min Tags Per Job'),
  maxTagsPerJob: z.number().int().min(1).default(5).describe('Max Tags Per Job'),
  daysAfterWhichInstallsShouldBeClosed: z
    .number()
    .int()
    .min(1)
    .default(90)
    .describe(
      "Days After Which Installs Should Be Closed // When we generate an install job, if it's older than this many days we'll mark it as closed.",
    ),
  daysAfterWhichJobsShouldBeClosed: z
    .number()
    .int()
    .min(1)
    .default(30)
    .describe(
      "Days After Which Jobs Should Be Closed // When we generate a NON-install job, if it's older than this many days we'll mark it as closed.",
    ),
  jobAtLeastScheduledChance: z
    .number()
    .min(0)
    .max(1)
    .default(0.65)
    .describe(
      'Job At Least Scheduled Chance // Chance that a job has at least reached the "Scheduled" status. This applies AFTER jobs have been filtered by the "days after which jobs should be closed" flag dates.',
    ),
  jobInProgressLimitChance: z
    .number()
    .min(0)
    .max(1)
    .default(0.1)
    .describe(
      'Job In Progress Throttle Chance // Chance that a generated job is currently in progress. This is to limit the number of jobs that are currently in progress. This applies AFTER jobs have been filtered by the "days after which jobs should be closed" flag dates',
    ),

  jobClassWeights: FourChanceWeightsSchema.default([0.075, 0.125, 0.4, 0.4]).describe(
    'Job Class Chances (Install, Service, Maint., Sales) // The first box is the percentage of jobs that are Install, the second is Service, the third is Maintenance, and the fourth is Sales',
  ),
})

export const dbSeederRequestAppointmentSchema = z.object({
  chanceOfNoAssignment: z.number().min(0).max(1).default(0.15).describe('Chance Appointment is Unassigned'),
  maxDaysInFutureForAppointment: z
    .number()
    .int()
    .min(1)
    .default(15)
    .describe(
      'Max Days In Future For Appointment // How far in the future we can make an arrival window for an appointment',
    ),
  additionalTechForInstallChance: z
    .number()
    .min(0)
    .max(1)
    .default(0.5)
    .describe('Two techs for Install Chance // Chance an install job gets a second technician'),
  twoDayInstallChance: z
    .number()
    .min(0)
    .max(1)
    .default(0.5)
    .describe('Two Day Install Chance // Chance an install will take two days'),

  appointmentCanceledChance: z
    .number()
    .min(0)
    .max(1)
    .default(0.05)
    .describe('Appointment Canceled Chance // Chance that an appointment will be canceled'),

  appointmentConfirmedChance: z
    .number()
    .min(0)
    .max(1)
    .default(0.5)
    .describe('Appointment Confirmed Chance // Chance that an appointment was confirmed by the customer'),
})

export const dbSeederRequestInvoiceSchema = z.object({
  invoiceExistenceRate: z
    .number()
    .min(0)
    .max(1)
    .default(0.8)
    .describe('Existence Rate // Chance that an invoice will be created for an appointment'),
  invoiceCustomerPurchaseOrderChance: z
    .number()
    .min(0)
    .max(1)
    .default(0.5)
    .describe('Customer Purchase Order Chance // Chance that an invoice will have a customer purchase order number'),
  invoiceDiscountChance: z
    .number()
    .min(0)
    .max(1)
    .default(0.5)
    .describe('Invoice Discount Chance // Chance that an invoice will have a discount'),
  invoiceMinCartItems: z
    .number()
    .int()
    .min(1)
    .default(1)
    .describe('Min Cart Items // Minimum number of cart items per invoice'),
  invoiceMaxCartItems: z
    .number()
    .int()
    .min(1)
    .default(4)
    .describe('Max Cart Items // Maximum number of cart items per invoice'),
  loanApplicationChance: z
    .number()
    .min(0)
    .max(1)
    .default(0.8)
    .describe('Loan Application Chance // Chance a loan application will be created for an invoice'),
  twoEquipmentInstallChance: z
    .number()
    .min(0)
    .max(1)
    .default(0.5)
    .describe('Two Equipment Install Chance // Chance an install will have two pieces of equipment instead of one'),
  invoiceHoursCreatedAfterAppointmentStart: z
    .number()
    .int()
    .min(1)
    .default(60)
    .describe(
      "Max hours after appointment to create // Maximum number of hours after the start of an appointment that we'll pretend the invoice was created at.",
    ),
  pastDueDecayConstant: z
    .number()
    .min(0)
    .max(1)
    .default(0.288)
    .describe(
      'Past Due Decay Constant // Constant (k) for past due decay function: P_1 = P_0 * e ^(-kn), where n is the number of days since issuance, `P_1` is the probability the invoice at `n` days old is delinquent, `P_0` is a seed probability defined next (probability of delinquency after one day), `e` is the natural number, and `k` is this number. We use a decay function to decide if an invoice is delinquent so as subsequent days pass, fewer and fewer invoices are unpaid.',
    ),
  pastDueOneDayChance: z
    .number()
    .min(0)
    .max(1)
    .default(0.8)
    .describe(
      'One day past due Invoice Chance // Chance that an invoice will be one day overdue. Seed (P_0) for the decay function described earlier.',
    ),
  pastDueInvoiceChance: z
    .number()
    .min(0)
    .max(1)
    .default(0.1)
    .describe(
      "Past Due Invoice Chance // Chance that, regardless of decay function, an invoice with a due date in the past will be delinquent. This is to throw a wrench in and allow an invoice that's 100 days old to be overdue.",
    ),
  pendingInvoicePaidChance: z
    .number()
    .min(0)
    .max(1)
    .default(0.2)
    .describe("Pending Invoice Paid Chance // Chance that an invoice that isn't due yet has already been paid"),
  pendingInvoicePartialPaidChance: z
    .number()
    .min(0)
    .max(1)
    .default(0.1)
    .describe(
      "Pending Invoice Partial Payment Chance // Chance that an invoice that isn't due yet and isn't fully paid has a partial payment",
    ),
  numberOfPaymentsWeights: ChanceWeightsSchema.default([0.8, 0.1, 0.1]).describe(
    'Number of payments Chances (1, 2, 3) // Chances that a paid (or partially paid) invoice will have 1, 2, or 3 payments.',
  ),
  tilledAuthorizationFlatFeeUsc: z
    .number()
    .int()
    .min(0)
    .default(30)
    .describe('Tilled Charge Authorization Flat Fee (Cents) // Charge per payment (flat fee)'),
  tilledAuthorizationFeeRate: z
    .number()
    .min(0)
    .max(1)
    .default(0.03)
    .describe('Tilled Charge Authorization Fee // Percentage of payment charged by Tilled'),
  maintenanceUpsellChance: z
    .number()
    .min(0)
    .max(1)
    .default(0.1)
    .describe('Maint. Upsell Chance // Chance an invoice for a maintenance job has an additional item on it'),
  minMaintenanceUpsellPrice: z
    .number()
    .int()
    .min(0)
    .default(50)
    .describe('Min Maint. Upsell Price (Dollars) // Minimum amount a maintenance upsell can be'),
  maxMaintenanceUpsellPrice: z
    .number()
    .int()
    .min(0)
    .default(500)
    .describe('Min Maint. Upsell Price (Dollars) // Minimum amount a maintenance upsell can be'),
  dynamicPricingChance: z
    .number()
    .min(0)
    .max(0)
    .default(0)
    .describe(
      'Dynamic Pricing Chance // Chance that a dynamic pricing multiplier will be applied to the invoice. Note that estimates share this option. Currently disabled due to not working.',
    ),
})

export const dbSeederRequestMPSchema = z.object({
  mpChance: z
    .number()
    .min(0)
    .max(1)
    .default(0.5)
    .describe('MP Chance // Chance an account will have a maintenance plan'),
  mpDiscountChance: z
    .number()
    .min(0)
    .max(1)
    .default(0.5)
    .describe('MP Discount Chance // Chance a maintenance plan will come with a discount on other jobs'),
  minMpDiscountRate: z
    .number()
    .min(0)
    .max(1)
    .default(0.05)
    .describe(
      'Min MP Discount Rate // If a maintenance plan comes with a discount, this is the minimum discount percentage',
    ),
  maxMpDiscountRate: z
    .number()
    .min(0)
    .max(1)
    .default(0.25)
    .describe(
      'Max MP Discount Rate // If a maintenance plan comes with a discount, this is the maximum discount percentage',
    ),
  minMpPrice: z.number().int().min(1).default(900).describe('Min MP Price (Cents) - Monthly'),
  maxMpPrice: z.number().int().min(1).default(2400).describe('Max MP Price (Cents) - Monthly'),
  defaultMpPrice: z.number().int().min(1).default(1200).describe('Default MP Price (Cents) - Monthly'),
  defaultMpPriceOdds: z
    .number()
    .min(0)
    .max(1)
    .default(0.8)
    .describe(
      'Odds of Default MP Price (no discounts/upcharges/variation) // The odds that the price of the maintenance plan will be the default price, with no discounts, upcharges, or variation.',
    ),
  minMpUpcharge: z.number().int().min(0).default(0).describe('Min MP Upcharge (Cents)'),
  maxMpUpcharge: z.number().int().min(1).default(3200).describe('Max MP Upcharge (Cents)'),
  minMpDiscount: z
    .number()
    .int()
    .min(0)
    .default(0)
    .describe(
      'Min MP Discount (Cents) // This applies to the price of the plan, not the discounts they get from being a member.',
    ),
  maxMpDiscount: z
    .number()
    .int()
    .min(1)
    .default(2400)
    .describe(
      'Max MP Discount (Cents) // This applies to the price of the plan, not the discounts they get from being a member.',
    ),
})

export const dbSeederRequestPayoutSchema = z.object({
  approxTilledPayoutHourUtc: z
    .number()
    .int()
    .min(0)
    .max(23)
    .default(5)
    .describe('Approx. Tilled Payout Hour (UTC) // The approximate hour (in UTC) at which Tilled sends payouts.'),
  approxTilledPayoutMinutesUtc: z
    .number()
    .int()
    .min(0)
    .max(59)
    .default(30)
    .describe('Approx. Tilled Payout Minutes (UTC) // The approximate minutes (in UTC) at which Tilled sends payouts.'),
  approxTilledPayoutMinuteVariance: z
    .number()
    .int()
    .min(0)
    .max(180)
    .default(30)
    .describe(
      'Approx. Tilled Payout Minute Variance // The payout time will be the start hour and start minutes plus or minus this number of minutes.',
    ),
})

export const dbSeederRequestEstimatesSchema = z.object({
  estimateExistenceRate: z
    .number()
    .min(0)
    .max(1)
    .default(0.8)
    .describe('Existence Rate // Chance that an estimate will be created for an appointment'),
  estimateDescriptionChance: z
    .number()
    .min(0)
    .max(1)
    .default(0.5)
    .describe('Description Chance // Chance that an estimate will have a description'),
  estimateAcceptedOnBehalfOfRate: z
    .number()
    .min(0)
    .max(1)
    .default(0.05)
    .describe(
      'Accepted On Behalf Of Rate // Chance that when an estimate was accepted, it was accepted by the technician on behalf of the customer',
    ),
  estimateMinOptions: z
    .number()
    .int()
    .min(1)
    .default(1)
    .describe('Min Options // Minimum number of options we generate per estimate'),
  estimateMaxOptions: z
    .number()
    .int()
    .min(1)
    .default(4)
    .describe('Max Options // Maximum number of options we generate per estimate'),
  estimateOptionDescriptionChance: z
    .number()
    .min(0)
    .max(1)
    .default(0.5)
    .describe('Option Desc. Chance // Chance that an estimate option will have a description'),
  estimateOptionDisplayNameChance: z
    .number()
    .min(0)
    .max(1)
    .default(0.5)
    .describe('Option Name Chance // Chance that an estimate option will have a name (as opposed to "Option 1")'),
  estimateOptionRecommendedChance: z
    .number()
    .min(0)
    .max(1)
    .default(0.9)
    .describe('Option Recommended Chance // Chance that one of the estimate options is recommended'),
  estimateDiscountChance: z
    .number()
    .min(0)
    .max(1)
    .default(0.2)
    .describe('Discount Chance // Chance that an estimate option has a discount'),
  estimateMinCartItems: z
    .number()
    .int()
    .min(1)
    .default(1)
    .describe('Min Cart Items // Minimum number of cart items per option'),
  estimateMaxCartItems: z
    .number()
    .int()
    .min(1)
    .default(4)
    .describe('Max Cart Items // Maximum number of cart items per option'),
  estimateHoursCreatedAfterAppointmentStart: z
    .number()
    .int()
    .min(1)
    .default(12)
    .describe(
      "Max hours after appointment to create // Maximum number of hours after the start of an appointment that we'll pretend the estimate was created at.",
    ),
})

export const dbSeederRequestTechnicianPerformanceSchema = z.object({
  ttoChance: z
    .number()
    .min(0)
    .max(1)
    .default(0.2)
    .describe('TTO Chance // Chance that a technician turnover will occur for a job'),
  minCommissionRate: z
    .number()
    .min(0)
    .max(1)
    .default(0.02)
    .describe('Min Commission Rate (Percent) // Minimum commission rate for a job team member'),
  maxCommissionRate: z
    .number()
    .min(0)
    .max(1)
    .default(0.05)
    .describe('Max Commission Rate (Percent) // Maximum commission rate for a job team member'),
  minBonusUsc: z
    .number()
    .int()
    .min(0)
    .default(100)
    .describe('Min Bonus USC (Cents) // Minimum bonus for a job team member'),
  maxBonusUsc: z
    .number()
    .int()
    .min(0)
    .default(5000)
    .describe('Max Bonus USC (Cents) // Maximum bonus for a job team member'),
})

export const dbSeederRequestMiscSchema = z.object({
  minTags: z.number().int().min(1).default(10).describe('Min Tags'),
  maxTags: z.number().int().min(1).default(20).describe('Max Tags'),
  enableTilledErrorPricebookItems: z
    .boolean()
    .default(false)
    .describe('Tilled Error Pricebook Items // Adds Pricebook Items used for testing Tilled errors'),
  enableProfitRhinoPricebook: z
    .boolean()
    .default(true)
    .describe('Profit Rhino Pricebook // Adds Pricebook Items and Categories used for testing Profit Rhino'),
})

export const dbSeederRequestGroups = [
  ['Company', dbSeederRequestCompanySchema],
  ['Accounts', dbSeederRequestAccountSchema],
  ['Jobs', dbSeederRequestJobSchema],
  ['Appointments', dbSeederRequestAppointmentSchema],
  ['Invoices', dbSeederRequestInvoiceSchema],
  ['Estimates', dbSeederRequestEstimatesSchema],
  ['Maintenance Plans', dbSeederRequestMPSchema],
  ['Payouts', dbSeederRequestPayoutSchema],
  ['Technician Performance', dbSeederRequestTechnicianPerformanceSchema],
  ['Misc', dbSeederRequestMiscSchema],
] as const

export const dbSeederRequestSchema = z.object({
  ...dbSeederRequestCompanySchema.shape,
  ...dbSeederRequestAccountSchema.shape,
  ...dbSeederRequestJobSchema.shape,
  ...dbSeederRequestAppointmentSchema.shape,
  ...dbSeederRequestInvoiceSchema.shape,
  ...dbSeederRequestEstimatesSchema.shape,
  ...dbSeederRequestMPSchema.shape,
  ...dbSeederRequestPayoutSchema.shape,
  ...dbSeederRequestTechnicianPerformanceSchema.shape,
  ...dbSeederRequestMiscSchema.shape,
})

export const largeDBSeederRequest = (() => {
  const shape = dbSeederRequestSchema.shape

  const defaultValues: Partial<z.infer<typeof dbSeederRequestSchema>> = {}

  for (const key of R.keys(shape)) {
    // This is obviously correct. TS always has trouble doing things like this. Plus our `bzOptional` is messing up the
    // typing of `defaultValue()`
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    defaultValues[key] = (shape[key]._def as any).defaultValue?.()
  }

  return defaultValues as z.infer<typeof dbSeederRequestSchema>
})()

export const smallDBSeederRequest: DBSeederRequestBase = {
  ...largeDBSeederRequest,
  minAccounts: Math.floor(largeDBSeederRequest.minAccounts / 8),
  maxAccounts: Math.floor(largeDBSeederRequest.maxAccounts / 8),
  maxAccountCreatedDaysAgo: Math.floor(largeDBSeederRequest.maxAccountCreatedDaysAgo / 8),
  jobInProgressLimitChance: 0.15,
  fixedAddresses: true,
  enableProfitRhinoPricebook: false,
  enableTilledErrorPricebookItems: true,
}

export const salesDemoDBSeederRequest: DBSeederRequestBase = {
  ...largeDBSeederRequest,
  fixedAddresses: true,
  minAccounts: 200,
  maxAccounts: 210,
  shouldGeocodeAddresses: true,
  jobAtLeastScheduledChance: 0.7,
  jobInProgressLimitChance: 0.25,
}

export const DB_SEEDER_SETTINGS_PRESETS = {
  SalesDemo: { request: salesDemoDBSeederRequest, name: 'Sales Demo 🫡', rank: 0 },
  Cypress: { request: { ...smallDBSeederRequest, dynamicPricingChance: 0 }, name: 'Cypress 🌱', rank: 1 },
  Large: { request: largeDBSeederRequest, name: 'Large 🔱', rank: 2 },
} as const

export type DBSeederRequestBase = z.infer<typeof dbSeederRequestSchema>

export type DBSeederRequest = DBSeederRequestBase & {
  rootUserGuid: string
}

export type DBSeederPerformanceReportItem = {
  name: string
  duration: number
  percentage: string
}

export type DBSeederResponse = {
  companyGuid: string
  companyName: string
  performanceReport: DBSeederPerformanceReportItem[]
}
