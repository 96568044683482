import { TimeZoneId } from '@breezy/shared'
import { useState } from 'react'
import { OnsiteBasicModal } from 'src/adam-components/OnsiteModal/OnsiteModal'
import { OnsitePageCollapsibleSection } from 'src/adam-components/OnsitePage/OnsitePageCollapsibleSection'
import {
  VisitCard,
  VisitCardProps,
  VisitCardVisit,
} from 'src/components/Cards/VisitCard'

export interface VisitsCollapsibleProps {
  visits: VisitCardVisit[]
  timezoneId: TimeZoneId
  editable?: boolean
  onCreateVisit?: () => void
  onEditVisit?: VisitCardProps['onEdit']
  onCancelVisit?: VisitCardProps['onCancelVisit']
  onViewChecklist?: VisitCardProps['onViewChecklist']
}

export const VisitsCollapsible = (props: VisitsCollapsibleProps) => {
  const [descriptionModal, setDescriptionModal] = useState<
    { open: false } | { open: true; description: string }
  >({ open: false })

  return (
    <OnsitePageCollapsibleSection
      smallTitle
      title="Visits"
      count={props.visits.length}
      defaultCollapsed={props.visits.length === 0}
      onAdd={props.editable ? props.onCreateVisit : undefined}
    >
      <div className="flex flex-col gap-2">
        {props.visits.map(visit => (
          <VisitCard
            key={visit.appointment.appointmentGuid}
            visit={visit}
            editable={props.editable}
            timezoneId={props.timezoneId}
            onEdit={props.onEditVisit}
            onCancelVisit={props.onCancelVisit}
            onViewDescription={description =>
              setDescriptionModal({ open: true, description })
            }
            onViewChecklist={props.onViewChecklist}
          />
        ))}
      </div>

      <OnsiteBasicModal
        header="Visit Description"
        open={descriptionModal.open}
        onClose={() => setDescriptionModal({ open: false })}
      >
        {descriptionModal.open && descriptionModal.description !== ''
          ? descriptionModal.description
          : 'No description provided'}
      </OnsiteBasicModal>
    </OnsitePageCollapsibleSection>
  )
}
