import {
  BzDateFns,
  IsoDateString,
  PaymentMethod,
  TimeZoneId,
} from '@breezy/shared'
import { Tag } from 'antd'
import { Fragment } from 'react'
import { LabeledItemGrid } from 'src/adam-components/LabeledItemGrid'
import { SectionedCard } from 'src/adam-components/SectionedCard/SectionedCard'
import { EmDash } from 'src/elements/EmDash/EmDash'
import { Link } from 'src/elements/Link/Link'
import { useIsTechnicianApp } from 'src/hooks/useIsTechnicianApp'

export type JobCardJob = {
  jobGuid: string
  jobTypeName: string
  displayId: string
  createdAt: IsoDateString
  jobLifecycleStatus: {
    name: string
    color: string
  }
  location: {
    locationGuid: string
    address: {
      line1: string
    }
  }
  tags: {
    name: string
    color: string
    label?: string
    disabled?: boolean
  }[]
  completedAt?: IsoDateString
  invoice?: {
    invoiceGuid: string
    displayId: string
    totalUsc: number
  }
  payment?: {
    paymentRecordGuid: string
    paymentMethod: PaymentMethod
    amountUsd: number
  }
  estimate?: {
    estimateGuid: string
    displayId: string
    totalUsc: number
  }
  appointments?: {
    appointmentGuid: string
    appointmentType: string
    appointmentDate: IsoDateString
  }[]
}

export interface JobCardProps {
  job: JobCardJob
  timezoneId: TimeZoneId
}

export const JobCard = (props: JobCardProps) => {
  const { job } = props

  const isTechApp = useIsTechnicianApp()

  return (
    <SectionedCard
      small
      dashed
      sections={[
        {
          verticalPaddingClassName: 'p-3',
          content: (
            <div className="flex flex-row items-center justify-between">
              <Link to={`/jobs/${job.jobGuid}`} bold className="text-sm">
                {job.jobTypeName} #{job.displayId}
              </Link>

              <Tag
                style={{
                  color: job.jobLifecycleStatus.color,
                  background: `hsl(from ${job.jobLifecycleStatus.color} h s l / 0.1)`,
                  borderColor: job.jobLifecycleStatus.color,
                }}
              >
                {job.jobLifecycleStatus.name}
              </Tag>
            </div>
          ),
        },
        {
          content: (
            <LabeledItemGrid
              items={[
                'Location',
                <Link
                  to={`/locations/${job.location.locationGuid}`}
                  className="text-sm"
                >
                  {job.location.address.line1}
                </Link>,
                'Created Date',
                BzDateFns.formatFromISO(
                  job.createdAt,
                  'MMM dd, yyyy',
                  props.timezoneId,
                ),
                'Completion Date',
                job.completedAt ? (
                  BzDateFns.formatFromISO(
                    job.completedAt,
                    'MMM dd, yyyy',
                    props.timezoneId,
                  )
                ) : (
                  <EmDash />
                ),
                // TODO: Show invoice, estimate, and payment record here
                // 'Invoice',
                // job.invoice ? (
                //   <Link
                //     to={`/invoices/${job.invoice.invoiceGuid}`}
                //     className="text-sm"
                //   >
                //     #{job.invoice.displayId} ({formatUsc(job.invoice.totalUsc)})
                //   </Link>
                // ) : (
                //   <EmDash />
                // ),
                // 'Payment',
                // job.payment ? (
                //   <Link
                //     to={`${SharedRoutes.PAYMENT_DETAILS.build({
                //       params: {
                //         paymentRecordGuid: job.payment.paymentRecordGuid,
                //       },
                //     })}`}
                //     className="text-sm"
                //   >
                //     {paymentMethodDisplayName(job.payment.paymentMethod)}) (
                //     {formatMoney(job.payment.amountUsd)})
                //   </Link>
                // ) : (
                //   <EmDash />
                // ),
                // 'Estimate',
                // job.estimate ? (
                //   <Link
                //     to={`/estimates/${job.estimate.estimateGuid}`}
                //     className="text-sm"
                //   >
                //     #{job.estimate.displayId}
                //   </Link>
                // ) : (
                //   <EmDash />
                // ),
                ...(job.appointments
                  ? job.appointments
                      .map(appt => (
                        <Fragment key={appt.appointmentGuid}>
                          {isTechApp ? (
                            <Link
                              to={`/appointments/${appt.appointmentGuid}`}
                              className="text-sm"
                            >
                              {appt.appointmentType} (
                              {BzDateFns.formatFromISO(
                                appt.appointmentDate,
                                'MMM dd, yyyy',
                                props.timezoneId,
                              )}
                              )
                            </Link>
                          ) : (
                            <span>
                              {appt.appointmentType} (
                              {BzDateFns.formatFromISO(
                                appt.appointmentDate,
                                'MMM dd, yyyy',
                                props.timezoneId,
                              )}
                              )
                            </span>
                          )}
                        </Fragment>
                      ))
                      .map((appt, idx) => [`Appointment #${idx + 1}`, appt])
                      .flat()
                  : []),
              ]}
            />
          ),
        },
      ]}
    />
  )
}
