import { BzDateFns, DayOfTheWeek } from '@breezy/shared'
import { useCallback, useMemo } from 'react'
import { useFormContext } from 'react-hook-form'
import {
  WeeklySchedule,
  WeeklyScheduleAvailability,
} from '../../components/WeeklyScheduleAvailability/WeeklyScheduleAvailability'
import { useDirtyingSetValue } from '../../elements/Forms/useDirtyingSetValue'
import { typedMemo } from '../../utils/react-utils'
import {
  ArrivalWindowGuid,
  CompanyArrivalWindowOption,
  InstantBookingWeeklyScheduleFormData,
  OnlineBookingEditServiceTypeForm,
} from './utils'

const toWeeklySchedule = ({
  formData,
}: {
  formData: InstantBookingWeeklyScheduleFormData
}): WeeklySchedule<ArrivalWindowGuid> => {
  return Object.entries(formData).reduce((acc, [day, daySchedule]) => {
    acc[day as DayOfTheWeek] = {
      enabled: daySchedule.enabled,
      values: daySchedule.arrivalWindowGuids,
    }
    return acc
  }, {} as WeeklySchedule<ArrivalWindowGuid>)
}

const toFormData = ({
  weeklySchedule,
}: {
  weeklySchedule: WeeklySchedule<ArrivalWindowGuid>
}): InstantBookingWeeklyScheduleFormData => {
  return Object.entries(weeklySchedule).reduce((acc, [day, daySchedule]) => {
    acc[day as DayOfTheWeek] = {
      enabled: daySchedule.enabled,
      arrivalWindowGuids: daySchedule.values,
    }
    return acc
  }, {} as InstantBookingWeeklyScheduleFormData)
}

type OnlineBookingWeeklyScheduleAvailabilityProps = {
  serviceTypeArrivalWindowOptions: CompanyArrivalWindowOption[]
}

export const OnlineBookingWeeklyScheduleAvailability = typedMemo(
  ({
    serviceTypeArrivalWindowOptions,
  }: OnlineBookingWeeklyScheduleAvailabilityProps) => {
    const { watch, setValue: rawSetValue } =
      useFormContext<OnlineBookingEditServiceTypeForm>()
    const instantBookingWeeklySchedule = watch('instantBookingWeeklySchedule')

    const setValue = useDirtyingSetValue(rawSetValue)
    const onChange = useCallback(
      (value: WeeklySchedule<ArrivalWindowGuid>) => {
        setValue(
          'instantBookingWeeklySchedule',
          toFormData({ weeklySchedule: value }),
        )
      },
      [setValue],
    )

    const options = useMemo(() => {
      return serviceTypeArrivalWindowOptions.map(option => ({
        label: BzDateFns.localTimeWindowToFormattedTimeWindowString({
          start: option.arrivalWindowStartTime,
          end: option.arrivalWindowEndTime,
        }),
        value: option.arrivalWindowGuid,
      }))
    }, [serviceTypeArrivalWindowOptions])

    return (
      <WeeklyScheduleAvailability
        weeklySchedule={toWeeklySchedule({
          formData: instantBookingWeeklySchedule,
        })}
        onChange={onChange}
        options={options}
      />
    )
  },
)
