import { FileLinks, FileRecord, nextGuid, PhotoLinks } from '@breezy/shared'
import { faFile } from '@fortawesome/pro-regular-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React, { useCallback, useState } from 'react'
import { trpc } from '../../hooks/trpc'
import {
  AsyncUploadWrapper,
  GetExtraData,
  OnUploadChange,
  UploadButton,
  UseUploadProps,
  WriteRecord,
} from './Upload'

type OnFileUploadChange = OnUploadChange<FileUploadExtraData, FileRecord>

/**
 * Creates a default list of properties that can be used to upload generic files.
 *
 * Usage:
 * ```typescriptreact
 * function MyComponent() {
 *   const fileUploadOpts = useFileUpload(
 *     (_, record) => console.log(record),
 *     { jobGuid: "JOB_GUID" }
 *   )
 *
 *   return (
 *     <AsyncUploadWrapper {...fileUploadOpts}>
 *       <Button>Upload</Button>
 *     </AsyncUploadWrapper>
 *   )
 * }
 * ```
 */
export const useFileUpload = (
  onFileUploadChange: OnFileUploadChange,
  links?: PhotoLinks,
): UseUploadProps<FileUploadExtraData, FileRecord, PhotoLinks> & {
  loading: boolean
} => {
  const [loading, setLoading] = useState(false)
  const onUploadChange = useCallback<OnFileUploadChange>(
    (data, fileRecord) => {
      setLoading(!data.recordWritten)
      onFileUploadChange(data, fileRecord)
    },
    [onFileUploadChange],
  )

  const getExtraData = useCallback<GetExtraData<FileUploadExtraData>>(data => {
    const fileGuid = nextGuid()
    const cdnFileNameAndPath = `${fileGuid}/${data.filename}`
    return {
      fileGuid,
      cdnFileNameAndPath,
      ...data,
    }
  }, [])

  const writeFileRecord = trpc.files['files:write-file-record'].useMutation()

  const writeRecord = useCallback<WriteRecord<FileUploadExtraData, FileRecord>>(
    async data => {
      const fileRecord = await writeFileRecord.mutateAsync({
        fileGuid: data.fileGuid,
        cdnUrl: data.cdnUrl,
        resourceUrn: data.resourceUrn,
        fileName: data.filename,
        metadata: {},
        fileSizeBytes: data.bytes,
        fileTypeMime: data.type,
        storageStrategy: 'S3',
        ...(links ?? {}),
      })
      return fileRecord
    },
    [links, writeFileRecord],
  )

  return {
    isImage: false,
    writeRecord,
    onUploadChange,
    links,
    loading,
    getExtraData,
  }
}

type FileUploadExtraData = {
  cdnFileNameAndPath: string
  fileGuid: string
}

type AsyncFileUploadProps = {
  onFileUploadChange: OnFileUploadChange
  links?: FileLinks
}

export const AsyncFileUpload = React.memo<AsyncFileUploadProps>(
  ({ onFileUploadChange, links }) => {
    const fileUploadOpts = useFileUpload(onFileUploadChange, links)

    return (
      <AsyncUploadWrapper {...fileUploadOpts}>
        <UploadButton
          icon={<FontAwesomeIcon icon={faFile} />}
          loading={fileUploadOpts.loading}
        >
          Choose file
        </UploadButton>
      </AsyncUploadWrapper>
    )
  },
)
