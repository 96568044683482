import {
  DateTimeFormatter,
  ENGLISH_LOCALE,
  formatEquipmentTypeString,
  InstalledEquipmentSummary,
  isNullish,
  LocalDate,
} from '@breezy/shared'
import { faCopy, faPen } from '@fortawesome/pro-regular-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useMemo } from 'react'
import { LabeledItemGrid } from 'src/adam-components/LabeledItemGrid'
import { SectionedCard } from 'src/adam-components/SectionedCard/SectionedCard'
import CopyToClipboard from 'src/elements/CopyToClipboard/CopyToClipboard'
import { EmDash } from 'src/elements/EmDash/EmDash'

export interface EquipmentCardProps {
  // TODO: Refactor so this only takes in the minimal amount of data
  equipment: InstalledEquipmentSummary
  onTitleClick?: (equipment: InstalledEquipmentSummary) => void
  onEdit?: (equipment: InstalledEquipmentSummary) => void
}

export const EquipmentCard = (props: EquipmentCardProps) => {
  const { equipment } = props

  const equipmentAge = useMemo(() => {
    if (!equipment.installationDate) {
      return 0
    }
    return Math.floor(
      equipment.installationDate.until(LocalDate.now()).toTotalMonths() / 12,
    )
  }, [equipment.installationDate])

  return (
    <SectionedCard
      small
      dashed
      sections={[
        {
          verticalPaddingClassName: 'p-3',
          content: (
            <div className="flex flex-row items-center justify-between">
              {!isNullish(props.onTitleClick) ? (
                <span
                  className="cursor-pointer text-sm font-semibold text-bz-primary"
                  onClick={() => props.onTitleClick?.(equipment)}
                >
                  {formatEquipmentTypeString(equipment.equipmentType)}
                </span>
              ) : (
                <span className="text-sm font-semibold">
                  {formatEquipmentTypeString(equipment.equipmentType)}
                </span>
              )}

              {!isNullish(props.onEdit) && (
                <FontAwesomeIcon
                  icon={faPen}
                  className="cursor-pointer"
                  onClick={() => props.onEdit?.(equipment)}
                />
              )}
            </div>
          ),
        },
        {
          content: (
            <LabeledItemGrid
              items={[
                'Manufacturer',
                equipment.manufacturer ?? <EmDash />,
                'Installed On',
                equipment.installationDate ? (
                  `${equipment.installationDate.format(
                    DateTimeFormatter.ofPattern('MMM d, yyyy').withLocale(
                      ENGLISH_LOCALE,
                    ),
                  )} (${equipmentAge} year${equipmentAge === 1 ? '' : 's'} old)`
                ) : (
                  <EmDash />
                ),
                'Installed By',
                equipment.installationParty ?? <EmDash />,
                'Serial Number',
                equipment.serialNumber ? (
                  <CopyToClipboard
                    payload={equipment.serialNumber}
                    label={
                      <span className="flex items-center justify-end gap-2">
                        {equipment.serialNumber}
                        <FontAwesomeIcon icon={faCopy} />
                      </span>
                    }
                  />
                ) : (
                  <EmDash />
                ),
                'Model Number',
                equipment.modelNumber ? (
                  <CopyToClipboard
                    payload={equipment.modelNumber}
                    label={
                      <span className="flex items-center justify-end gap-2">
                        {equipment.modelNumber}
                        <FontAwesomeIcon icon={faCopy} />
                      </span>
                    }
                  />
                ) : (
                  <EmDash />
                ),
                'Est. End of Life',
                equipment.estimatedEndOfLifeDate ? (
                  equipment.estimatedEndOfLifeDate.format(
                    DateTimeFormatter.ofPattern('MMM d, yyyy').withLocale(
                      ENGLISH_LOCALE,
                    ),
                  )
                ) : (
                  <EmDash />
                ),
                'Avg. Life Expectancy',
                equipment.averageLifeExpectancyYears ?? <EmDash />,
              ]}
            />
          ),
        },
      ]}
    />
  )
}
