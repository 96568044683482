import {
  BzDateFns,
  CalculatePaths,
  formatUsc,
  IsoDateString,
  MaintenancePlanPaymentFlow,
  TimeZoneId,
  upperPascalCaseToHumanReadable,
} from '@breezy/shared'
import { LabeledItemGrid } from 'src/adam-components/LabeledItemGrid'
import { SectionedCard } from 'src/adam-components/SectionedCard/SectionedCard'
import { Link } from 'src/elements/Link/Link'
import StatusTag from 'src/elements/StatusTag/StatusTag'

export interface MaintenancePlanCardProps {
  maintenancePlan: {
    maintenancePlanGuid: string
    location: {
      locationGuid: string
      address: {
        line1: string
      }
    }
    billing: {
      frequency?: string
      anchorDay?: string
    }
    activationDate?: IsoDateString
    renewalDate: IsoDateString
    paymentFlow: MaintenancePlanPaymentFlow
    numCoveredEquipment: number
    yearlyRevenueUsc: number
    planTypeName: string
    isActiveMaintenancePlanGuid?: boolean
  }
  timezoneId: TimeZoneId
}

export const MaintenancePlanCard = (props: MaintenancePlanCardProps) => {
  const { maintenancePlan: mp } = props

  return (
    <SectionedCard
      dashed
      small
      sections={[
        {
          verticalPaddingClassName: 'p-3',
          content: (
            <div
              className="flex flex-row items-center justify-between"
              data-testid="maintenance-plan-card-title"
            >
              <Link
                to={`/maintenance-plans/${mp.maintenancePlanGuid}`}
                bold
                className="text-sm"
              >
                {mp.planTypeName} Plan
              </Link>

              {props.maintenancePlan.isActiveMaintenancePlanGuid && (
                <StatusTag color={'green'} text={'Active'} />
              )}
            </div>
          ),
        },
        {
          content: (
            <LabeledItemGrid
              items={[
                'Location',
                <Link
                  to={CalculatePaths.locationDetails({
                    locationGuid: mp.location.locationGuid,
                  })}
                >
                  {mp.location.address.line1}
                </Link>,
                // TODO: Add next visits here
                // 'Visit #1 (Mar 2024)',
                // 'Unscheduled',
                // 'Visit #2 (Oct 2024)',
                // 'Unscheduled',
                ...(mp.billing.frequency
                  ? [
                      'Billing Frequency',
                      upperPascalCaseToHumanReadable(mp.billing.frequency),
                    ]
                  : []),
                ...(mp.billing.anchorDay
                  ? ['Billing Anchor Day', mp.billing.anchorDay]
                  : []),
                ...(mp.activationDate
                  ? [
                      'Activation Date',
                      BzDateFns.parseISO(mp.activationDate, props.timezoneId)
                        .toDateString()
                        .split(' ')
                        .slice(1)
                        .join(' '),
                    ]
                  : []),
                'Renewal Date',
                BzDateFns.parseISO(mp.renewalDate, props.timezoneId)
                  .toDateString()
                  .split(' ')
                  .slice(1)
                  .join(' '),
                'Auto Renews',
                mp.paymentFlow === MaintenancePlanPaymentFlow.AUTO
                  ? 'Yes'
                  : 'No',
                'Covered Equipment',
                mp.numCoveredEquipment,
                'Yearly Revenue',
                formatUsc(mp.yearlyRevenueUsc),
              ]}
            />
          ),
        },
      ]}
    />
  )
}
