import {
  BzDateFns,
  formatMoney,
  formatUsc,
  InvoiceStatuses,
  InvoiceTerm,
  InvoiceV2Status,
  IsoDateString,
  PaymentMethod,
  paymentMethodDisplayName,
  TimeZoneId,
} from '@breezy/shared'
import { LabeledItemGrid } from 'src/adam-components/LabeledItemGrid'
import { SectionedCard } from 'src/adam-components/SectionedCard/SectionedCard'
import { EmDash } from 'src/elements/EmDash/EmDash'
import { Link } from 'src/elements/Link/Link'
import InvoiceStatusTag from '../Invoicing/InvoiceStatusTag/InvoiceStatusTag'

export type InvoiceCardInvoice = {
  invoiceGuid: string
  displayId: string
  status: InvoiceStatuses | InvoiceV2Status | 'Draft'
  issuedAt?: IsoDateString
  totalUsc: number
  invoiceTerm: `${InvoiceTerm}`
  job?: {
    jobGuid: string
    displayId: string
    jobTypeName: string
  }
  maintenancePlan?: {
    maintenancePlanGuid: string
  }
  payment?: {
    paymentRecordGuid: string
    paymentMethod: PaymentMethod
    amountUsd: number
  }
}

export interface InvoiceCardProps {
  invoice: InvoiceCardInvoice
  timezoneId: TimeZoneId
}

export const InvoiceCard = (props: InvoiceCardProps) => {
  const { invoice } = props

  const isCoveredByMaintenancePlan =
    invoice.maintenancePlan && invoice.totalUsc === 0

  const isRecurringPayment = invoice.invoiceTerm === 'AUTO'

  const titleSuffix = isCoveredByMaintenancePlan
    ? '- Covered - Zero Ticket'
    : isRecurringPayment
    ? '- Plan Auto-Payment'
    : ''

  const title = `#${invoice.displayId} ${titleSuffix}`.trimEnd()

  return (
    <SectionedCard
      small
      dashed
      sections={[
        {
          verticalPaddingClassName: 'p-3',
          content: (
            <div className="flex flex-row items-center justify-between">
              <Link
                to={`/invoice/${invoice.invoiceGuid}`}
                bold
                className="text-sm"
              >
                {title}
              </Link>

              <InvoiceStatusTag status={invoice.status} />
            </div>
          ),
        },
        {
          content: (
            <LabeledItemGrid
              items={[
                'Issued Date',
                invoice.issuedAt ? (
                  BzDateFns.formatFromISO(
                    invoice.issuedAt,
                    'MMM d, yyyy',
                    props.timezoneId,
                  )
                ) : (
                  <EmDash />
                ),
                'Total',
                formatUsc(invoice.totalUsc),
                ...(isCoveredByMaintenancePlan
                  ? ['Covered By Maintenance Plan', 'Yes']
                  : []),
                ...(isRecurringPayment ? [] : []),
                ...(invoice.job
                  ? [
                      'Job',
                      <Link
                        to={`/jobs/${invoice.job.jobGuid}`}
                        className="text-sm"
                      >
                        {invoice.job.jobTypeName} #{invoice.job.displayId}
                      </Link>,
                    ]
                  : []),
                ...(invoice.payment
                  ? [
                      'Payment',
                      <span>
                        {paymentMethodDisplayName(
                          invoice.payment.paymentMethod,
                        )}
                        ) ({formatMoney(invoice.payment.amountUsd)})
                      </span>,
                    ]
                  : []),
              ]}
            />
          ),
        },
      ]}
    />
  )
}
