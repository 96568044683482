import { OnsitePageCollapsibleSection } from 'src/adam-components/OnsitePage/OnsitePageCollapsibleSection'
import {
  ContactCard,
  ContactCardContact,
} from 'src/components/Cards/ContactCard'

export interface ContactsCollapsibleProps {
  contacts: ContactCardContact[]
  title?: string
  editable?: boolean
  disabled?: boolean
  onContactAdd?: () => void
  onContactEdit?: (contact: ContactCardContact) => void
  onContactMakePrimary?: (contact: ContactCardContact) => void
  onContactArchive?: (contact: ContactCardContact) => void
  onContactUnarchive?: (contact: ContactCardContact) => void
  onContactDelete?: (contact: ContactCardContact) => void
}

export const ContactsCollapsible = (props: ContactsCollapsibleProps) => {
  return (
    <OnsitePageCollapsibleSection
      smallTitle
      title={props.title ?? 'Point of Contact'}
      count={props.contacts.length}
      defaultCollapsed={props.contacts.length === 0}
      onAdd={props.editable ? props.onContactAdd : undefined}
      addButtonTestId="contacts-collapsible-add-button"
    >
      <div className="flex flex-col gap-2">
        {props.contacts.map(contact => (
          <ContactCard
            key={contact.contactGuid}
            contact={contact}
            onEdit={props.editable ? props.onContactEdit : undefined}
            onMakePrimary={
              props.editable ? props.onContactMakePrimary : undefined
            }
            onArchive={props.editable ? props.onContactArchive : undefined}
            onUnarchive={props.editable ? props.onContactUnarchive : undefined}
            onDelete={props.editable ? props.onContactDelete : undefined}
          />
        ))}
      </div>
    </OnsitePageCollapsibleSection>
  )
}
