import {
  AssignableApptViewModelWithBusinessProjections,
  AssignmentDTO,
  BzAddress,
  BzDuration,
  User,
  UserGuid,
  buildAppointmentTitle,
  bzExpect,
  formatEquipmentJobLink,
  getTechnicianRolesFromUserRoles,
  isNullish,
  richJobTypeDescriptor,
  toPhone,
  toPlural,
  toRoleDisplayName,
  toSimpleContactInfo,
} from '@breezy/shared'
import { faAdd, faClose } from '@fortawesome/pro-light-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Button, Col, Drawer, Modal, Popconfirm, Row, Select } from 'antd'
import React, { useCallback, useMemo, useState } from 'react'
import { Link } from 'react-router-dom'
import { MaybeDataPoint } from '../../../elements/MaybeDataPoint/MaybeDataPoint'
import ThinDivider from '../../../elements/ThinDivider'
import { trpc } from '../../../hooks/trpc'
import { AppointmentStatusTag } from '../../../pages/ScheduleV2Page/AppointmentStatusTag'
import AssignmentStatusTag from '../../../pages/TechnicianSchedulePage/AssignmentStatusTag'
import { gray7 } from '../../../themes/theme'
import AddressMultiLineView from '../../Addresses/AddressMultiLineView/AddressMultiLineView'
import { ContactLink } from '../../ContactLabel/ContactLabel'
import ContactRowCircleButtons from '../../Contacts/ContactRowCircleButtons'
import { DateFormat, DateTimeWindow, DateView } from '../../Dates'
import { EndOfAppointmentAlertBanner } from '../../EndOfAppointmentAlertBanner/EndOfAppointmentAlertBanner'
import { LoadingSpinner } from '../../LoadingSpinner'
import NotesListCard from '../../Notes/NotesListCard/NotesListCard'
import TechnicianResourceView from '../../PersonResourceView/TechnicianResourceView'
import SimplePriorityTag from '../../SimplePriorityTag'
import { TagList } from '../../Tags'
import { getStyles } from '../TimelineEventItem/TimelineEventItemStyles'
import { toRoleShortName } from '../scheduleUtils'
import { ItemSegment, ItemSegmentTitle } from './components/ItemSegment'

type Tech = Pick<
  User,
  | 'userGuid'
  | 'firstName'
  | 'lastName'
  | 'roles'
  | 'deactivatedAt'
  | 'emailAddress'
  | 'phoneNumbers'
>

type AssignableWithMaybeTechnician = {
  appt: AssignableApptViewModelWithBusinessProjections
  technicians?: Tech[]
}

type AssignableViewProps = AssignableWithMaybeTechnician & {
  onClose: () => void
  availableTechnicians: User[]
  removeAssignment: (assignment: AssignmentDTO) => void
  addTechnicians: (userGuids: UserGuid[]) => void
}

type AppointmentDetailsDrawerProps = {
  assignable?: AssignableWithMaybeTechnician
  availableTechnicians: User[]
  removeAssignment: (assignment: AssignmentDTO) => void
  addTechnicians: (userGuids: UserGuid[]) => void
  cancelAppointment?: (assignmentGuid: string) => void
  onClose: () => void
}

interface NewTechSelectorProps {
  assignedTechs: Tech[]
  availableTechs: Tech[]
  onClose: () => void
  addTechnicians: (userGuids: UserGuid[]) => void
}

const NewTechSelector = React.memo<NewTechSelectorProps>(
  ({ assignedTechs, availableTechs, onClose, addTechnicians }) => {
    const [newTechGuids, setNewTechGuids] = useState<UserGuid[]>([])

    const techsNotAssigned = useMemo(() => {
      const usedTechGuids = new Set<UserGuid>()

      for (const tech of assignedTechs) {
        usedTechGuids.add(tech.userGuid)
      }
      return availableTechs.filter(
        tech =>
          !usedTechGuids.has(tech.userGuid) &&
          getTechnicianRolesFromUserRoles(tech.roles).length,
      )
    }, [assignedTechs, availableTechs])

    const techMenuOptions = useMemo(
      () =>
        techsNotAssigned.map(tech => ({
          label: `${tech.firstName} ${tech.lastName} - ${tech.roles
            .map(role => toRoleShortName(role.role))
            .join(', ')}`,
          value: tech.userGuid,
        })),
      [techsNotAssigned],
    )

    const onSave = useCallback(() => {
      addTechnicians(newTechGuids)
      onClose()
    }, [addTechnicians, newTechGuids, onClose])

    const [addTechniciansMode, setAddTechniciansMode] = useState(false)

    return addTechniciansMode ? (
      <div className="mt-1 flex flex-row items-center pl-10">
        <div className="flex-1">
          <Select
            mode="multiple"
            showSearch
            className="min-w-[300px]"
            placeholder="Select technicians"
            optionFilterProp="label"
            value={newTechGuids}
            onChange={setNewTechGuids}
            options={techMenuOptions}
          />
        </div>
        <Button type="primary" className="ml-4" onClick={onSave}>
          Save
        </Button>
      </div>
    ) : (
      <div
        className="mt-1 flex cursor-pointer flex-row items-center"
        onClick={() => setAddTechniciansMode(true)}
      >
        <Button shape="circle" icon={<FontAwesomeIcon icon={faAdd} />} />
        <div className="ml-2">Add technicians</div>
      </div>
    )
  },
)

const AppointmentDetailsView = ({
  appt,
  technicians,
  availableTechnicians,
  addTechnicians,
  removeAssignment,
  cancelAppointment,
  onClose,
}: AssignableViewProps & {
  cancelAppointment?: (appointmentGuid: string) => void
}) => {
  const jobIcon = appt ? getStyles(appt.jobType.jobClass).icon : undefined
  const jobDetailsQuery = trpc.jobs['jobs:fetch-by-guid'].useQuery({
    jobGuid: appt.jobGuid,
  })
  const jobDetails = jobDetailsQuery.data

  const techMap = useMemo(() => {
    const map: Record<UserGuid, Tech | undefined> = {}
    for (const tech of technicians ?? []) {
      map[tech.userGuid] = tech
    }
    return map
  }, [technicians])

  const [cancelApptModalOpen, setCancelApptModalOpen] = useState(false)

  return (
    appt && (
      <Col className="full-width">
        <Row className="flex-between mb-4 items-start">
          <Col className="flex-1">
            <Row>
              {jobIcon && (
                <div
                  style={{
                    marginRight: 16,
                    marginTop: 'auto',
                    marginBottom: 'auto',
                  }}
                >
                  <FontAwesomeIcon icon={jobIcon} size="2x" />
                </div>
              )}
              <Col className="mb-3 flex-1">
                <h2 className="m-0 min-w-0 flex-1 leading-tight">
                  {buildAppointmentTitle(appt)}
                </h2>
                <div style={{ fontWeight: 400, color: gray7 }}>
                  Appointment ID #{appt.appointment.referenceNumber}
                </div>
              </Col>

              <div style={{ marginLeft: 24, marginTop: 8 }}>
                <AppointmentStatusTag
                  status={appt.appointment.appointmentStatus}
                />
              </div>
            </Row>
          </Col>
          <Button
            onClick={onClose}
            style={{ fontSize: 18, borderWidth: 0, marginTop: 0 }}
          >
            X
          </Button>
        </Row>

        {appt.appointment.endOfAppointmentNextSteps && (
          <Row>
            <Col xs={24}>
              <div className="mb-4">
                <EndOfAppointmentAlertBanner
                  endOfAppointmentNextSteps={
                    appt.appointment.endOfAppointmentNextSteps
                  }
                />
              </div>
            </Col>
          </Row>
        )}

        <ItemSegment
          title={
            <ItemSegmentTitle
              title={toPlural(technicians?.length ?? 0, 'Assign Technician')}
            />
          }
        >
          <div>
            {appt.assignments?.length ? (
              appt.assignments.map(assignment => {
                const tech = bzExpect(
                  techMap[assignment.technicianUserGuid],
                  `Expected tech for assignment ${assignment.assignmentGuid} (tech guid ${assignment.technicianUserGuid}) to be included in the list of technicians.`,
                )
                return (
                  <div
                    className="grid grid-cols-3 items-center"
                    key={tech.userGuid}
                  >
                    <TechnicianResourceView
                      userGuid={tech.userGuid}
                      displayName={`${tech.firstName} ${tech.lastName}`}
                      displayCategory={toRoleDisplayName(tech.roles[0].role)}
                      avatarData={{
                        avatarAltShortString: `${tech.firstName.slice(
                          0,
                          1,
                        )}${tech.lastName.slice(0, 1)}`,
                        userGuid: tech.userGuid,
                        deactivatedAt: tech.deactivatedAt,
                      }}
                    />
                    <div className="ml-10 place-self-start">
                      <ItemSegment
                        title={<ItemSegmentTitle title="Scheduled" darkTitle />}
                      >
                        <DateTimeWindow
                          startWindowIsoWithOffsetTimestamp={
                            assignment.timeWindow.start
                          }
                          endWindowIsoWithOffsetTimestamp={
                            assignment.timeWindow.end
                          }
                          includeDate={true}
                          overrideClassName="regular_12_20"
                        />
                      </ItemSegment>
                    </div>
                    <div className="flex items-center place-self-end">
                      <ContactRowCircleButtons
                        contact={{
                          email: tech.emailAddress,
                          phone: toPhone(tech.phoneNumbers[0]),
                        }}
                        showEmail={false}
                      />
                      <AssignmentStatusTag
                        assignmentStatus={assignment.assignmentStatus}
                      />
                      <Popconfirm
                        placement="left"
                        title="Are you sure you want to remove this technician?"
                        onConfirm={() => removeAssignment(assignment)}
                      >
                        <Button
                          className="ml-2 hover:bg-[#fff2f0] hover:text-[#ff7875]"
                          type="text"
                          icon={<FontAwesomeIcon icon={faClose} />}
                        />
                      </Popconfirm>
                    </div>
                  </div>
                )
              })
            ) : (
              <div className="mt-2">
                No technician assigned. Drag this appointment to the schedule to
                add a technician.
              </div>
            )}
          </div>
          {!!technicians?.length && (
            <NewTechSelector
              addTechnicians={addTechnicians}
              assignedTechs={technicians ?? []}
              availableTechs={availableTechnicians}
              onClose={onClose}
            />
          )}
        </ItemSegment>
        <ThinDivider />

        <ItemSegment title={<ItemSegmentTitle title="Point of Contact" />}>
          <div className="flex-between">
            <ContactLink {...appt} />
            <ContactRowCircleButtons
              contact={toSimpleContactInfo(appt.contact)}
            />
          </div>
        </ItemSegment>
        <ThinDivider />

        <ItemSegment
          title={
            <div className="mb-3 flex w-full flex-row items-center">
              <ItemSegmentTitle title="Appointment Details" />

              {!isNullish(cancelAppointment) && (
                <Button
                  className="ml-auto"
                  onClick={() => setCancelApptModalOpen(true)}
                >
                  Cancel Visit
                </Button>
              )}
            </div>
          }
        >
          <Row className="flex-between">
            <ItemSegment
              title={<ItemSegmentTitle title="Visit Type" darkTitle />}
            >
              {appt.appointment.appointmentType}
            </ItemSegment>
            <ItemSegment
              title={<ItemSegmentTitle title="Location" darkTitle />}
            >
              <AddressMultiLineView
                displayName={appt.location.displayName}
                address={BzAddress.create(appt.location.address)}
              />
            </ItemSegment>
            <ItemSegment
              title={<ItemSegmentTitle title="Visit Type" darkTitle />}
            >
              <DateView
                isoWithOffsetTimestamp={appt.appointment.timeWindow.start}
                zone={{
                  type: 'company-timezone-of-principal-user',
                }}
                format={DateFormat['eeee, MMM d, yyyy']}
              />
            </ItemSegment>

            <ItemSegment
              title={<ItemSegmentTitle title="Arrival Window" darkTitle />}
            >
              <DateTimeWindow
                startWindowIsoWithOffsetTimestamp={
                  appt.appointment.timeWindow.start
                }
                endWindowIsoWithOffsetTimestamp={
                  appt.appointment.timeWindow.end
                }
                includeDate={false}
                overrideClassName="regular_14_22"
              />
            </ItemSegment>
          </Row>
          <Row className="no-flex-wrap mb-2">
            <ItemSegment
              title={<ItemSegmentTitle title="Description" darkTitle />}
            >
              <MaybeDataPoint>{appt.appointment.description}</MaybeDataPoint>
            </ItemSegment>
          </Row>
          <Row>
            {jobDetails &&
              jobDetails.account.tags &&
              jobDetails.account.tags.length > 0 && (
                <ItemSegment
                  title={<ItemSegmentTitle title="Account Tags" darkTitle />}
                >
                  <TagList tags={jobDetails.account.tags} />
                </ItemSegment>
              )}
          </Row>
        </ItemSegment>
        <ThinDivider />

        <ItemSegment title={<ItemSegmentTitle title="Job Details" />}>
          <Col>
            <Row className="flex-between">
              <Row>
                <ItemSegment
                  title={<ItemSegmentTitle title="Job Type" darkTitle />}
                >
                  <p>{richJobTypeDescriptor(appt.jobType.name)}</p>
                </ItemSegment>
                <div className="ml3" />
                <ItemSegment title={<ItemSegmentTitle title="Job" darkTitle />}>
                  <Link
                    className="react-plain-link"
                    to={`/jobs/${appt.jobGuid}`}
                  >
                    #{appt.displayId}
                  </Link>
                </ItemSegment>
                <div className="ml3" />
                <ItemSegment
                  title={<ItemSegmentTitle title="Equipment" darkTitle />}
                >
                  <p>
                    {jobDetails && (
                      <>
                        {jobDetails.equipmentTypeJobLinks
                          .map(formatEquipmentJobLink)
                          .join(', ')}
                      </>
                    )}
                  </p>
                </ItemSegment>
              </Row>
              <Row>
                <ItemSegment
                  title={<ItemSegmentTitle title="Est Duration" darkTitle />}
                >
                  <p>
                    {BzDuration.parse(
                      appt.businessProjections.estimatedDuration,
                    ).formatHoursAndMinutesShort()}
                  </p>
                </ItemSegment>
                <div className="ml3" />
                <ItemSegment
                  title={<ItemSegmentTitle title="Priority" darkTitle />}
                >
                  <SimplePriorityTag
                    priority={appt.businessProjections.priority}
                  />
                </ItemSegment>
              </Row>
            </Row>
            <Col>
              {jobDetails && (
                <>
                  <ItemSegment
                    title={<ItemSegmentTitle title="Summary" darkTitle />}
                  >
                    <div className="mb-2">{jobDetails.summary}</div>
                  </ItemSegment>

                  <Col>
                    {jobDetails.tags && jobDetails.tags.length > 0 && (
                      <ItemSegment
                        title={<ItemSegmentTitle title="Job Tags" darkTitle />}
                      >
                        <TagList tags={jobDetails.tags} />
                      </ItemSegment>
                    )}
                  </Col>
                  <ThinDivider />
                  <NotesListCard
                    title="Job Notes"
                    linkData={{
                      primaryNoteType: 'JOB',
                      jobGuid: jobDetails.jobGuid,
                    }}
                    allowAddNote={false}
                  />
                </>
              )}
              {!jobDetails && (
                <div style={{ height: 200 }}>
                  <LoadingSpinner />
                </div>
              )}
            </Col>
          </Col>
        </ItemSegment>

        <Modal
          open={cancelApptModalOpen}
          onOk={() => cancelAppointment?.(appt.appointment.guid)}
          onCancel={() => setCancelApptModalOpen(false)}
        >
          <span>Are you sure you want to cancel this appointment?</span>
        </Modal>
      </Col>
    )
  )
}

export const AppointmentDetailsDrawer = ({
  assignable,
  availableTechnicians,
  addTechnicians,
  removeAssignment,
  cancelAppointment,
  onClose,
}: AppointmentDetailsDrawerProps) => (
  <Drawer
    title="Appointment Details"
    closable
    onClose={onClose}
    open={!!assignable}
    size="large"
    styles={{ header: { display: 'none' }, body: { paddingBottom: 80 } }}
  >
    {assignable && (
      <AppointmentDetailsView
        appt={assignable.appt}
        technicians={assignable.technicians}
        onClose={onClose}
        availableTechnicians={availableTechnicians}
        addTechnicians={addTechnicians}
        removeAssignment={removeAssignment}
        cancelAppointment={cancelAppointment}
      />
    )}
  </Drawer>
)
