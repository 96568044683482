import { Button, Form, Input, Modal } from 'antd'
import { useCallback, useState } from 'react'
import { trpc } from '../../../hooks/trpc'
import { useMessage } from '../../../utils/antd-utils'

export type QuickbooksDesktopEnableModalProps = {
  companyGuid: string
  companyName: string
  companyEmail: string
  open: boolean
  onClose: () => void
}

export const QuickbooksDesktopEnableModal = ({
  companyGuid,
  companyName,
  companyEmail,
  open,
  onClose,
}: QuickbooksDesktopEnableModalProps) => {
  const message = useMessage()
  const [conductorEndUserId, setConductorEndUserId] = useState('')

  const enableMutation =
    trpc.devTools['devtools:enable-quickbooks-desktop'].useMutation()
  const createConnectionMutation =
    trpc.devTools['devtools:create-quickbooks-desktop-connection'].useMutation()

  const handleEnable = useCallback(async () => {
    try {
      await enableMutation.mutateAsync({
        companyGuid,
        conductorEndUserId,
      })
      message.success('QuickBooks Desktop integration enabled successfully')
      onClose()
    } catch (err) {
      message.error('Failed to enable QuickBooks Desktop integration')
    }
  }, [companyGuid, conductorEndUserId, enableMutation, message, onClose])

  const handleCreateConnection = useCallback(async () => {
    try {
      const result = await createConnectionMutation.mutateAsync({
        companyGuid,
        companyName,
        email: companyEmail,
      })
      Modal.success({
        title: 'Connection Created',
        content: (
          <div>
            <p>
              This field is just for your information -- It's already been saved
              to the database.
            </p>
            <p>Conductor End User ID: {result.conductorEndUserId}</p>
            <p className="font-bold text-red-500">
              Important: Copy this URL now - it will only be shown once! You
              will need to send this link to the company you are onboarding, so
              they can setup the secure connection between Conductor and
              QuickBooks Desktop.
            </p>
            <p>
              Auth URL:{' '}
              <a
                href={result.authConnectionUrl}
                target="_blank"
                rel="noreferrer"
              >
                {result.authConnectionUrl}
              </a>
            </p>
          </div>
        ),
        width: 600,
      })
      onClose()
    } catch (err) {
      message.error('Failed to create QuickBooks Desktop connection')
    }
  }, [
    companyGuid,
    companyName,
    companyEmail,
    createConnectionMutation,
    message,
    onClose,
  ])

  return (
    <Modal
      title="Enable QuickBooks Desktop Integration"
      open={open}
      onCancel={onClose}
      footer={null}
    >
      <Form layout="vertical">
        <Form.Item label="Conductor End User ID">
          <Input
            value={conductorEndUserId}
            onChange={e => setConductorEndUserId(e.target.value)}
            placeholder="Enter existing Conductor End User ID"
          />
        </Form.Item>

        <div className="flex justify-end gap-2">
          <Button
            onClick={handleCreateConnection}
            disabled={conductorEndUserId.length > 0}
          >
            Create New Connection
          </Button>
          <Button
            type="primary"
            disabled={!conductorEndUserId}
            onClick={handleEnable}
          >
            Enable with Existing ID
          </Button>
        </div>
      </Form>
    </Modal>
  )
}
