import classNames from 'classnames'
import React from 'react'

type FormHeaderProps = React.PropsWithChildren<{
  className?: string
}>

const FormHeader = React.memo<FormHeaderProps>(
  ({ children, className = 'mb-3' }) => (
    <div className={classNames('text-base font-semibold', className)}>
      {children}
    </div>
  ),
)

export default FormHeader
