import { gql } from '../../generated'

export const ONLINE_BOOKING_SERVICE_TYPE_CONFIG_UPSERT = gql(/* GraphQL */ `
  mutation UpsertOnlineBookingServiceTypeConfig(
    $onlineBookingServiceTypeGuid: uuid!
    $config: OnlineBookingServiceTypeConfigsInsertInput!
    $onConflict: OnlineBookingServiceTypeConfigsOnConflict
  ) {
    deleteOnlineBookingServiceTypeBookableJobTypes(
      where: {
        onlineBookingServiceTypeGuid: { _eq: $onlineBookingServiceTypeGuid }
      }
    ) {
      __typename
    }
    deleteOnlineBookingServiceTypeBookableTechnicians(
      where: {
        onlineBookingServiceTypeGuid: { _eq: $onlineBookingServiceTypeGuid }
      }
    ) {
      __typename
    }
    deleteInstantBookingWeeklyScheduleEntries(
      where: {
        onlineBookingServiceTypeGuid: { _eq: $onlineBookingServiceTypeGuid }
      }
    ) {
      __typename
    }
    insertOnlineBookingServiceTypeConfigsOne(
      object: $config
      onConflict: $onConflict
    ) {
      __typename
    }
  }
`)
