import { groupBy } from '@breezy/shared'
import cn from 'classnames'
import React, { CSSProperties } from 'react'
import { blue6 } from '../../themes/theme'

export interface SubNavSidebarProps {
  tab: string
  items: {
    group?: string
    icon: React.ReactNode
    text: string
    id: string
    style?: CSSProperties
  }[]
  select?: (id: string) => void
}

export const SubNavSidebar = React.memo<SubNavSidebarProps>(
  ({ items, select, tab }) => {
    const groups = groupBy(items, 'group')
    const makeItem = (item: (typeof items)[0]) => (
      <div
        onClick={() => {
          select?.(item.id)
        }}
        className={cn(
          'text-grey-700 mb-1 flex cursor-pointer select-none items-center gap-2.5 py-2 pl-3',
          {
            [`border-left-2 border-y-0 border-r-0 border-solid bg-zinc-50 border-[${blue6}] text-[${blue6}]`]:
              item.id === tab,
          },
        )}
        key={item.id}
        data-sub-navbar-item-id={item.id}
      >
        {item.icon && <div>{item.icon}</div>}
        <div>{item.text}</div>
      </div>
    )

    const groupElements = Object.entries(groups).map(group => (
      <div className="mb-4" key={group[0]}>
        {!!group[0] && group[0] !== 'undefined' && (
          <div className="semibold_14_22 grey7 pb-2">{group[0]}</div>
        )}
        {group[1].map(item => makeItem(item))}
      </div>
    ))

    return (
      <div className="w-60 overflow-y-scroll border-x border-y-0 border-solid border-slate-200 bg-white px-4 pb-20 pt-9">
        {groupElements}
      </div>
    )
  },
)
