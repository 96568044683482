import {
  QBO_ERROR_CODES,
  QboErrorCode,
  QboStaleInfo,
  parseQboErrorInfo,
} from '@breezy/shared'

import { Button, Spin, Tooltip } from 'antd'
import { useCallback, useMemo } from 'react'
import { RouterInputs, trpc } from '../../../hooks/trpc'

import { isNullish } from '@breezy/shared'
import { faSpinnerThird } from '@fortawesome/pro-light-svg-icons'
import {
  faCircleCheck,
  faExclamationCircle,
} from '@fortawesome/pro-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { HookAPI } from 'antd/es/modal/useModal'
import { TooltipPlacement } from 'antd/lib/tooltip'
import cn from 'classnames'
import React from 'react'
import { Styled } from '../../../utils/Stylable'
import { useMessage, useModal } from '../../../utils/antd-utils'
import { typedMemo } from '../../../utils/react-utils'
import {
  QuickbooksAuthorizeButton,
  QuickbooksAuthorizeButtonProps,
  useQuickbooksAuthorizeButtonProps,
} from './QuickbooksAuthorizeButton'

export const triggerQBOUnauthModal = (
  Modal: ReturnType<typeof useModal>,
  qboAuthorizeButtonProps: QuickbooksAuthorizeButtonProps,
  retry: () => Promise<unknown>,
) => {
  Modal.error({
    title: 'Connect to Quickbooks',
    content: (
      <>
        <p>
          Please authorize Breezy to use your Quickbooks account, then retry.
        </p>
        <div>
          <QuickbooksAuthorizeButton {...qboAuthorizeButtonProps} />
        </div>
      </>
    ),
    okText: 'Retry',
    closable: true,
    okCancel: true,
    cancelText: 'Never mind',
    onOk: async () => {
      // If we don't try/catch this, then if the next one causes an error, it
      // won't close the original modal (and you get modals stacked on
      // modals). But since a new failure will make a new modal, we don't care
      // about what happens in the "catch".
      try {
        await retry()
      } catch (e) {
        // noop
      }
    },
  })
}

export type QuickbooksSyncButtonBaseProps = Styled<{
  size?: 'sm' | 'md' | 'lg'
  loading?: boolean
  placement?: TooltipPlacement
  tooltip?: string
}>

export type BasicQuickbooksSyncButtonBaseProps =
  QuickbooksSyncButtonBaseProps & {
    onSyncClick: () => unknown
    isStale?: boolean
  }

export const BasicQuickbooksSyncButton =
  React.memo<BasicQuickbooksSyncButtonBaseProps>(
    ({
      style,
      className,
      size,
      loading,
      placement = 'top',
      tooltip = 'Sync with Quickbooks',
      onSyncClick,
      isStale,
    }) => {
      const sizeClassName = useMemo(() => {
        switch (size) {
          case 'lg':
            return 'w-12 h-12'
          case 'sm':
            return 'w-7 h-7'
          default:
            return 'w-8 h-8'
        }
      }, [size])

      // this is so I can pass it to the onClick without it passing the `e` to the prop
      const ourOnSyncClick = useCallback(() => {
        onSyncClick()
      }, [onSyncClick])

      return (
        <div className={cn(sizeClassName, className)} style={style}>
          <Spin
            spinning={loading}
            size="large"
            indicator={<FontAwesomeIcon icon={faSpinnerThird} spin />}
          >
            <Tooltip
              placement={placement}
              title={
                isNullish(isStale)
                  ? tooltip
                  : isStale
                  ? 'Out-of-sync with Quickbooks'
                  : 'Synced with Quickbooks'
              }
              // https://stackoverflow.com/questions/71111609/ant-design-tooltip-shows-scroll-bar
              overlayStyle={{ position: 'fixed' }}
            >
              <Button
                shape="circle"
                className={cn(
                  'relative h-full w-full border-2 border-transparent p-0 leading-none hover:border-[#40a9ff] hover:opacity-100',
                  {
                    'opacity-30': !loading && !isNullish(isStale) && !isStale,
                  },
                )}
                onClick={ourOnSyncClick}
              >
                <img
                  alt="Sync with Quickbooks"
                  src="https://d3j5nzmmhjc8v6.cloudfront.net/quickbooks_logo.png"
                  className="h-full w-full"
                />

                {!isNullish(isStale) && (
                  <div
                    className={cn('absolute right-[-5px] top-[-5px]', {
                      'text-yellow-500': isStale,
                      'text-[#1890FF]': !isStale,
                    })}
                  >
                    <FontAwesomeIcon
                      className="rounded-full bg-white"
                      icon={isStale ? faExclamationCircle : faCircleCheck}
                    />
                  </div>
                )}
              </Button>
            </Tooltip>
          </Spin>
        </div>
      )
    },
  )

type BaseQuickbooksSyncButtonProps = QuickbooksSyncButtonBaseProps & {
  onSuccess?: () => void
  staleInfo?: QboStaleInfo
}

type QuickbooksSyncAccountButtonProps = BaseQuickbooksSyncButtonProps & {
  params: RouterInputs['accounting']['accounting-app:sync-account']
}

type QuickbooksSyncInvoiceButtonProps = BaseQuickbooksSyncButtonProps & {
  params: RouterInputs['accounting']['accounting-app:sync-invoice']
}

type QuickbooksSyncPayoutButtonProps = BaseQuickbooksSyncButtonProps & {
  params: RouterInputs['accounting']['accounting-app:sync-payout']
}

const onQuickbooksSyncError = ({
  errorCode,
  message,
  onSyncClick,
  Modal,
  qboAuthorizeButtonProps,
}: {
  errorCode: QboErrorCode
  message: string
  onSyncClick: (force?: boolean) => Promise<unknown>
  Modal: HookAPI
  qboAuthorizeButtonProps: QuickbooksAuthorizeButtonProps
}) => {
  if (errorCode === QBO_ERROR_CODES.STALE) {
    Modal.warning({
      title: 'Overwrite Quickbooks changes?',
      content: (
        <>
          <p>
            {message} Syncing will overwrite those changes with Breezy's data.
          </p>
          <p>Would you like to sync anyway?</p>
        </>
      ),
      okText: 'Sync',
      closable: true,
      okCancel: true,
      cancelText: 'Never mind',
      onOk: async () => {
        // If we don't try/catch this, then if the next one causes an error, it
        // won't close the original modal (and you get modals stacked on
        // modals). But since a new failure will make a new modal, we don't care
        // about what happens in the "catch".
        try {
          await onSyncClick(true)
        } catch (e) {
          // noop
        }
      },
    })
    return
  } else if (errorCode === QBO_ERROR_CODES.AUTH) {
    triggerQBOUnauthModal(Modal, qboAuthorizeButtonProps, onSyncClick)
    return
  }
}

export const QuickbooksSyncAccountButton = typedMemo(
  ({
    params,
    loading,
    staleInfo,
    onSuccess,
    ...passthrough
  }: QuickbooksSyncAccountButtonProps) => {
    const message = useMessage()
    const Modal = useModal()
    const qboAuthorizeButtonProps = useQuickbooksAuthorizeButtonProps()

    const syncMutation = trpc.accounting[
      'accounting-app:sync-account'
    ].useMutation({
      onError: e => {
        const qboErrorInfo = parseQboErrorInfo(e.message)
        let content = e?.message || 'Please try again later or contact support.'
        if (qboErrorInfo) {
          const [errorCode, message] = qboErrorInfo
          content = message
          onQuickbooksSyncError({
            errorCode,
            message: content,
            onSyncClick,
            Modal,
            qboAuthorizeButtonProps,
          })
          return
        }

        console.error(JSON.stringify(e, null, 2))

        Modal.error({
          title: 'Could not sync with Quickbooks',
          content,
        })
      },
      onSuccess: () => {
        message.success('Successfully synced with Quickbooks.')
        onSuccess?.()
      },
    })

    const onSyncClick = useCallback(
      (force?: boolean) =>
        syncMutation.mutateAsync({
          ...params,
          force,
        }),
      [params, syncMutation],
    )

    return (
      <BasicQuickbooksSyncButton
        {...passthrough}
        isStale={staleInfo?.stale}
        loading={loading || syncMutation.isLoading}
        onSyncClick={onSyncClick}
      />
    )
  },
)

export const QuickbooksSyncInvoiceButton = typedMemo(
  ({
    params,
    loading,
    staleInfo,
    onSuccess,
    ...passthrough
  }: QuickbooksSyncInvoiceButtonProps) => {
    const message = useMessage()
    const Modal = useModal()
    const qboAuthorizeButtonProps = useQuickbooksAuthorizeButtonProps()

    const syncMutation = trpc.accounting[
      'accounting-app:sync-invoice'
    ].useMutation({
      onError: e => {
        const qboErrorInfo = parseQboErrorInfo(e.message)
        let content = e?.message || 'Please try again later or contact support.'
        if (qboErrorInfo) {
          const [errorCode, message] = qboErrorInfo
          content = message
          onQuickbooksSyncError({
            errorCode,
            message: content,
            onSyncClick,
            Modal,
            qboAuthorizeButtonProps,
          })
          return
        }

        console.error(JSON.stringify(e, null, 2))

        Modal.error({
          title: 'Could not sync with Quickbooks',
          content,
        })
      },
      onSuccess: () => {
        message.success('Successfully synced with Quickbooks.')
        onSuccess?.()
      },
    })

    const onSyncClick = useCallback(
      (force?: boolean) =>
        syncMutation.mutateAsync({
          ...params,
          force,
        }),
      [params, syncMutation],
    )

    return (
      <BasicQuickbooksSyncButton
        {...passthrough}
        isStale={staleInfo?.stale}
        loading={loading || syncMutation.isLoading}
        onSyncClick={onSyncClick}
      />
    )
  },
)

export const QuickbooksSyncPayoutButton = typedMemo(
  ({
    params,
    loading,
    staleInfo,
    onSuccess,
    ...passthrough
  }: QuickbooksSyncPayoutButtonProps) => {
    const message = useMessage()
    const Modal = useModal()
    const qboAuthorizeButtonProps = useQuickbooksAuthorizeButtonProps()

    const syncMutation = trpc.accounting[
      'accounting-app:sync-payout'
    ].useMutation({
      onError: e => {
        const qboErrorInfo = parseQboErrorInfo(e.message)
        let content = e?.message || 'Please try again later or contact support.'
        if (qboErrorInfo) {
          const [errorCode, message] = qboErrorInfo
          content = message
          onQuickbooksSyncError({
            errorCode,
            message: content,
            onSyncClick,
            Modal,
            qboAuthorizeButtonProps,
          })
          return
        }

        console.error(JSON.stringify(e, null, 2))

        Modal.error({
          title: 'Could not sync with Quickbooks',
          content,
        })
      },
      onSuccess: () => {
        message.success('Successfully synced with Quickbooks.')
        onSuccess?.()
      },
    })

    const onSyncClick = useCallback(
      (force?: boolean) =>
        syncMutation.mutateAsync({
          ...params,
          force,
        }),
      [params, syncMutation],
    )

    return (
      <BasicQuickbooksSyncButton
        {...passthrough}
        isStale={staleInfo?.stale}
        loading={loading || syncMutation.isLoading}
        onSyncClick={onSyncClick}
      />
    )
  },
)
