import { ComprehensiveJobDetails } from '@breezy/backend/src/application-types'
import { Button, Card, Row } from 'antd'
import React, { ReactNode, useMemo, useState } from 'react'
import { useQuery } from 'urql'
import { USERS_INFO_FOR_REVENUE_ATTRIBUTION_QUERY } from '../../components/JobOutcomesModal/JobOutcomesModal.gql'
import { LoadingSpinner } from '../../components/LoadingSpinner'
import { FetchJobTechnicianPerformanceInfoQuery } from '../../generated/user/graphql'
import { useCanManageTechnicianPerformance } from '../../hooks/permission/useCanManageTechnicianPerformance'
import { useCanViewTechnicianPerformance } from '../../hooks/permission/useCanViewTechnicianPerformance'
import { TechnicianPerformanceInfo } from './TechnicianPerformanceSummary'

type RevenueAttributionCardProps = {
  comprehensiveJobDetails: ComprehensiveJobDetails
  editable: boolean
  onEdit?: () => void
  jobTechnicianPerformanceInfo: FetchJobTechnicianPerformanceInfoQuery
}

const DEFAULT_TECHNICIAN_PERFORMANCE_INFO: TechnicianPerformanceInfo = {
  totalDeductionsUsc: 0,
  totalEarnedRevenueUsc: 0,
  totalSoldRevenueUsc: 0,
}

export const RevenueAttributionCard: React.FC<RevenueAttributionCardProps> = ({
  comprehensiveJobDetails,
  editable,
  onEdit,
  jobTechnicianPerformanceInfo,
}) => {
  const canManageTechnicianPerformance = useCanManageTechnicianPerformance()
  const canViewTechnicianPerformance = useCanViewTechnicianPerformance()

  const [showAllTechnicians, setShowAllTechnicians] = useState(false)

  const technicianGuidToPerformanceMap = useMemo(() => {
    const map: Record<string, TechnicianPerformanceInfo> = {}

    jobTechnicianPerformanceInfo.jobTechnicianEarnedRevenueSummaries.forEach(
      ({ technicianUserGuid, totalEarnedRevenueUsc }) => {
        if (technicianUserGuid) {
          map[technicianUserGuid] = {
            ...DEFAULT_TECHNICIAN_PERFORMANCE_INFO,
            totalEarnedRevenueUsc: totalEarnedRevenueUsc ?? 0,
          }
        }
      },
    )

    jobTechnicianPerformanceInfo.jobTechnicianSalesCommissionAndBonus.forEach(
      ({
        technicianUserGuid,
        totalSoldRevenueUsc,
        totalDeductionsUsc,
        commissionableBaseUsc,
        commissionUsc,
        commissionRate,
        bonusUsc,
      }) => {
        if (technicianUserGuid) {
          map[technicianUserGuid] = {
            ...(map[technicianUserGuid] || DEFAULT_TECHNICIAN_PERFORMANCE_INFO),
            totalDeductionsUsc: totalDeductionsUsc ?? 0,
            totalSoldRevenueUsc: totalSoldRevenueUsc ?? 0,
            commissionableBaseUsc,
            commissionUsc,
            commissionRate,
            bonusUsc,
          }
        }
      },
    )
    return map
  }, [
    jobTechnicianPerformanceInfo.jobTechnicianEarnedRevenueSummaries,
    jobTechnicianPerformanceInfo.jobTechnicianSalesCommissionAndBonus,
  ])

  const [{ fetching: fetchingTechnicianUsersInfo }] = useQuery({
    query: USERS_INFO_FOR_REVENUE_ATTRIBUTION_QUERY,
    variables: {
      userGuids: Object.keys(technicianGuidToPerformanceMap),
    },
    pause: Object.keys(technicianGuidToPerformanceMap).length === 0,
  })

  const firstTechnicianGuid = useMemo(() => {
    return Object.keys(technicianGuidToPerformanceMap)[0] ?? undefined
  }, [technicianGuidToPerformanceMap])

  let cardContent: ReactNode = null

  if (fetchingTechnicianUsersInfo) {
    cardContent = <LoadingSpinner />
  } else if (comprehensiveJobDetails.getJobLifecycleStatus().isWorkComplete) {
    if (!firstTechnicianGuid) {
      cardContent = (
        <div>
          No users have been attributed to revenue for this job! Click
          <strong>{` Edit `}</strong>
          to configure this.
        </div>
      )
    }
  } else {
    // TODO Query what Lifecycle status is the first for "Work Completed", and report on that
    //in the text below. It should say "that has been set to Work Complete", which is "X". See
    // Figma design for details
    cardContent = (
      <div>
        You will be able to attribute revenue once the job contains a{' '}
        <strong>paid invoice</strong> and has been updated to the status that
        has been set to <strong>Work Completed</strong>.
      </div>
    )
  }

  const cta = useMemo(() => {
    if (editable && canManageTechnicianPerformance) {
      return (
        <Button onClick={onEdit} size="small">
          Edit
        </Button>
      )
    } else {
      return null
    }
  }, [canManageTechnicianPerformance, editable, onEdit])

  if (!canViewTechnicianPerformance || !cardContent) {
    return null
  }

  return (
    <Card
      size="small"
      bodyStyle={{ padding: '0px', margin: '0px' }}
      style={{ backgroundColor: '#fafafa', padding: '12px 16px 12px 16px' }}
    >
      <Row style={{ width: '100%' }} justify="space-between">
        <h3 style={{ marginBottom: '2px' }}>Revenue Attribution</h3>
        {cta}
      </Row>

      {cardContent}
      {comprehensiveJobDetails.getJobLifecycleStatus().isWorkComplete &&
        !showAllTechnicians &&
        Object.keys(technicianGuidToPerformanceMap).length > 1 && (
          <Row justify="center">
            <Button type="link" onClick={() => setShowAllTechnicians(true)}>
              View more
            </Button>
          </Row>
        )}
      {comprehensiveJobDetails.getJobLifecycleStatus().isWorkComplete &&
        showAllTechnicians && (
          <Row justify="center">
            <Button type="link" onClick={() => setShowAllTechnicians(false)}>
              View less
            </Button>
          </Row>
        )}
    </Card>
  )
}
