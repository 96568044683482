import { noOp, twilioPhoneNumberToBreezyPhone } from '@breezy/shared'
import { motion } from 'framer-motion'
import React, { useEffect, useMemo, useState } from 'react'
import { createPortal } from 'react-dom'
import Draggable from 'react-draggable'
import useAppNav from '../../hooks/useAppNav'
import OutgoingCallView from './CallOutgoing'
import ActiveCallView from './CallViewActive'
import { IncomingCallView } from './CallViewIncoming'
import { ActiveCall, IncomingCall, OutboundCall } from './TwilioTypes'

type CallOverlayProps = {
  incomingCall: IncomingCall | null
  activeCall: ActiveCall | null
  outgoingCall: OutboundCall | null
}

const rPos = '16px'

export const CallOverlay = React.memo<CallOverlayProps>(
  ({ incomingCall, activeCall, outgoingCall }) => {
    const [showActivePreview, setShowActivePreview] = useState(false)
    const [showIncomingPreview, setShowIncomingPreview] = useState(false)
    const [showOutgoingPreview, setShowOutgoingPreview] = useState(false)
    const [position, setPosition] = useState({
      top: 'calc(50% - 111px)',
      right: rPos,
      transform: 'translateY(-50%)',
    })
    const appNav = useAppNav()

    useEffect(() => {
      const updatePosition = () => {
        setPosition({
          top: 'calc(50% - 111px)',
          right: rPos,
          transform: 'translateY(-50%)',
        })
      }

      window.addEventListener('resize', updatePosition)
      return () => window.removeEventListener('resize', updatePosition)
    }, [])

    const primaryContent = useMemo(() => {
      if (activeCall) {
        return (
          <ActiveCallView
            {...activeCall}
            isActiveCall
            contactName={activeCall.contactName ?? activeCall.from}
            contactIsKnown={(activeCall.avatarText ?? '') === '?'}
            phoneNumber={activeCall.from}
            onHangup={() => activeCall.hangup()}
            onMute={() => activeCall.twilio?.mute(true)}
            onUnmute={() => activeCall.twilio?.mute(false)}
            onViewAccount={
              activeCall.accountGuid
                ? () =>
                    appNav.navigateToAccountDetailsPage(
                      activeCall.accountGuid ?? '',
                    )
                : undefined
            }
            onCreateJob={() =>
              appNav.navigateToCreateNewJob({
                firstName: activeCall.contactName?.split(' ')[0],
                lastName: activeCall.contactName?.split(' ')[1],
                createNewAccount: !activeCall.contactGuid,
                accountGuid: activeCall.accountGuid,
                contactGuid: activeCall.contactGuid,
                leadSourceGuid: activeCall.leadSourceGuid,
                phoneNumber: twilioPhoneNumberToBreezyPhone(activeCall.from),
                linkToIntegratedCallGuid: activeCall.integratedPhoneCallGuid,
              })
            }
          />
        )
      }
      if (showActivePreview) {
        return (
          <ActiveCallView
            answeredAt={new Date()}
            isActiveCall
            contactName={'Adam Sadowski'}
            phoneNumber={'(206) 555-1234'}
            avatarText={'AS'}
            leadSourceName={"Angie's List"}
            contactIsKnown={true}
            onHangup={() => setShowActivePreview(false)}
            onMute={noOp}
            onUnmute={noOp}
            onViewAccount={noOp}
            onCreateJob={noOp}
          />
        )
      }

      if (incomingCall) {
        return (
          <IncomingCallView
            avatarText={incomingCall.avatarText}
            contactName={incomingCall.contactName || incomingCall.from}
            phoneNumber={incomingCall.from}
            leadSourceName={incomingCall.leadSourceName}
            contactIsKnown={(incomingCall.avatarText ?? '') === '?'}
            onAccept={() => incomingCall.accept()}
            onReject={() => incomingCall.reject()}
          />
        )
      }
      if (showIncomingPreview) {
        return (
          <IncomingCallView
            avatarText={'AS'}
            phoneNumber={'+16467375788'}
            contactName={'Adam Sadowski'}
            leadSourceName={"Angie's List"}
            contactIsKnown={true}
            onAccept={() => {
              setShowIncomingPreview(false)
              setShowActivePreview(true)
            }}
            onReject={() => setShowIncomingPreview(false)}
          />
        )
      }

      if (outgoingCall) {
        return (
          <OutgoingCallView
            {...outgoingCall}
            contactIsKnown={outgoingCall.avatarText !== '?'}
            phoneNumber={outgoingCall.to}
            onHangup={() => outgoingCall.hangup()}
            onMute={() => outgoingCall.twilio?.mute(true)}
            onUnmute={() => outgoingCall.twilio?.mute(false)}
          />
        )
      }
      if (showOutgoingPreview) {
        return (
          <OutgoingCallView
            startedAt={new Date()}
            contactName={'Adam Sadowski'}
            phoneNumber={'(206) 555-1234'}
            avatarText={'AS'}
            leadSourceName={"Angie's List"}
            contactIsKnown={true}
            onHangup={() => setShowOutgoingPreview(false)}
            onMute={noOp}
            onUnmute={noOp}
          />
        )
      }
      return null
    }, [
      activeCall,
      showActivePreview,
      incomingCall,
      showIncomingPreview,
      appNav,
      outgoingCall,
      showOutgoingPreview,
    ])

    if (primaryContent) {
      return createPortal(
        <Draggable>
          <div
            style={{
              position: 'fixed',
              top: position.top,
              right: position.right,
              transform: position.transform,
              zIndex: 111111,
              cursor: 'grab',
            }}
          >
            <motion.div
              initial={{ x: 500, opacity: 1 }}
              animate={{ x: 0, opacity: 1 }}
              transition={{ type: 'spring', stiffness: 120, damping: 20 }}
            >
              {primaryContent}
            </motion.div>
          </div>
        </Draggable>,
        document.body,
      )
    }

    return null
  },
)
