export enum PermissionV1 {
  ALL_ACTIONS = 'ALL_ACTIONS',
  ENABLE_DEMO_MODE = 'ENABLE_DEMO_MODE',
  READ_ANY_USER = 'READ_ANY_USER',
  READ_COMPANY_USERS = 'READ_COMPANY_USERS',
  CREATE_COMPANY_USER = 'CREATE_COMPANY_USER',
  CREATE_COMPANY = 'CREATE_COMPANY',
  DELETE_COMPANY = 'DELETE_COMPANY',
  EDIT_COMPANY = 'EDIT_COMPANY',
  READ_COMPANY = 'READ_COMPANY',
  READ_ALL_COMPANIES = 'READ_ALL_COMPANIES',
  MANAGE_ACCOUNTS = 'MANAGE_ACCOUNTS',
  READ_ACCOUNT = 'READ_ACCOUNT',
  MANAGE_CONTACT = 'MANAGE_CONTACT',
  EXECUTE_DEV_TOOLS = 'EXECUTE_DEV_TOOLS',
  MANAGE_JOBS = 'MANAGE_JOBS',
  MANAGE_COMPANY_USERS = 'MANAGE_COMPANY_USERS',
  VIEW_JOBS = 'VIEW_JOBS',
  MANAGE_TECHNICIAN_CAPACITY = 'MANAGE_TECHNICIAN_CAPACITY',
  IMPERSONATE_USER = 'IMPERSONATE_USER',
  SCHEDULE_ASSIGNMENTS = 'SCHEDULE_ASSIGNMENTS',
  PRICING_ADMIN = 'PRICING_ADMIN',
  VIEW_PRICING = 'VIEW_PRICING',
  VIEW_LOCATIONS = 'VIEW_LOCATIONS',
  MANAGE_LOCATIONS = 'MANAGE_LOCATIONS',
  VIEW_EQUIPMENT = 'VIEW_EQUIPMENT',
  MANAGE_EQUIPMENT = 'MANAGE_EQUIPMENT',
  COLLECT_PAYMENTS = 'COLLECT_PAYMENTS',
  VIEW_PAYMENTS = 'VIEW_PAYMENTS',
  VIEW_CONTACTS = 'VIEW_CONTACTS',
  VIEW_TECHNICIAN_ASSIGNMENTS = 'VIEW_TECHNICIAN_ASSIGNMENTS',
  MANAGE_INVOICING = 'MANAGE_INVOICING',
  VIEW_INVOICING = 'VIEW_INVOICING',
  ISSUE_REFUNDS = 'ISSUE_REFUNDS',
  MANAGE_APPOINTMENT_ASSIGNMENTS = 'MANAGE_APPOINTMENT_ASSIGNMENTS',
  MANAGE_COMPANY_SETTINGS = 'MANAGE_COMPANY_SETTINGS',
  PHOTOS_ADMIN = 'PHOTOS_ADMIN',
  VIEW_MAINTENANCE_PLANS = 'VIEW_MAINTENANCE_PLANS',
  MANAGE_MAINTENANCE_PLANS = 'MANAGE_MAINTENANCE_PLANS',
  MANAGE_QUICKBOOKS_INTEGRATION = 'MANAGE_QUICKBOOKS_INTEGRATION',
  MANAGE_NOTES = 'MANAGE_NOTES',
  VIEW_SCHEDULE = 'VIEW_SCHEDULE',
  USE_OFFICE_EXPERIENCE = 'USE_OFFICE_EXPERIENCE',
  USE_FIELD_EXPERIENCE = 'USE_FIELD_EXPERIENCE',
  VIEW_APPOINTMENT_CHECKLISTS = 'VIEW_APPOINTMENT_CHECKLISTS',
  MANAGE_APPOINTMENT_CHECKLISTS = 'MANAGE_APPOINTMENT_CHECKLISTS',
  EDIT_APPOINTMENT_CHECKLIST_INSTANCES = 'EDIT_APPOINTMENT_CHECKLIST_INSTANCES',
  DEACTIVATE_USER = 'DEACTIVATE_USER',
  MANAGE_JOB_LIFECYCLES = 'MANAGE_JOB_LIFECYCLES',
  READ_JOB_LIFECYCLES = 'READ_JOB_LIFECYCLES',
  MANAGE_JOB_TYPES = 'MANAGE_JOB_TYPES',
}

export enum PermissionV2 {
  // System permissions
  READ_ANY_USER = 'V2_READ_ANY_USER',
  IMPERSONATE_USER = 'V2_IMPERSONATE_USER',
  CREATE_COMPANY = 'V2_CREATE_COMPANY',
  DELETE_COMPANY = 'V2_DELETE_COMPANY',
  EDIT_COMPANY = 'V2_EDIT_COMPANY',
  READ_ALL_COMPANIES = 'V2_READ_ALL_COMPANIES',
  ENABLE_DEMO_MODE = 'V2_ENABLE_DEMO_MODE',
  EXECUTE_DEV_TOOLS = 'V2_EXECUTE_DEV_TOOLS',
  LOAN_SIMULATOR = 'V2_LOAN_SIMULATOR',

  // Super admin permissions
  ALL_ACTIONS = 'V2_ALL_ACTIONS',

  // Global permissions
  READ_COMPANY = 'V2_READ_COMPANY',
  READ_COMPANY_USERS = 'V2_READ_COMPANY_USERS',

  // Self-serve permissions
  USE_OFFICE_EXPERIENCE = 'V2_USE_OFFICE_EXPERIENCE',
  OFFICE_ACCOUNTS_JOBS_MANAGE = 'V2_OFFICE_ACCOUNTS_JOBS_MANAGE',
  OFFICE_ACCOUNTS_JOBS_VIEW = 'V2_OFFICE_ACCOUNTS_JOBS_VIEW',
  OFFICE_SCHEDULE_APPOINTMENTS_MANAGE = 'V2_OFFICE_SCHEDULE_APPOINTMENTS_MANAGE',
  OFFICE_SCHEDULE_APPOINTMENTS_VIEW = 'V2_OFFICE_SCHEDULE_APPOINTMENTS_VIEW',
  OFFICE_FINANCIAL_INFORMATION_MANAGE = 'V2_OFFICE_FINANCIAL_INFORMATION_MANAGE',
  OFFICE_FINANCIAL_INFORMATION_ESTIMATES_VIEW = 'V2_OFFICE_FINANCIAL_INFORMATION_ESTIMATES_VIEW',
  OFFICE_FINANCIAL_INFORMATION_INVOICES_VIEW = 'V2_OFFICE_FINANCIAL_INFORMATION_INVOICES_VIEW',
  OFFICE_FINANCIAL_INFORMATION_PAYMENTS_VIEW = 'V2_OFFICE_FINANCIAL_INFORMATION_PAYMENTS_VIEW',
  OFFICE_FINANCIAL_INFORMATION_PAYOUTS_VIEW = 'V2_OFFICE_FINANCIAL_INFORMATION_PAYOUTS_VIEW',
  OFFICE_REPORTS_MANAGE = 'V2_OFFICE_REPORTS_MANAGE',
  OFFICE_REPORTS_VIEW = 'V2_OFFICE_REPORTS_VIEW',
  OFFICE_TECHNICIAN_PERFORMANCE_VIEW = 'V2_OFFICE_TECHNICIAN_PERFORMANCE_VIEW',
  OFFICE_TECHNICIAN_PERFORMANCE_MANAGE = 'V2_OFFICE_TECHNICIAN_PERFORMANCE_MANAGE',
  OFFICE_TIMESHEETS_VIEW = 'V2_OFFICE_TIMESHEETS_VIEW',
  OFFICE_TIMESHEETS_MANAGE = 'V2_OFFICE_TIMESHEETS_MANAGE',
  OFFICE_COMPANY_SETTINGS_MANAGE_TEAM = 'V2_OFFICE_COMPANY_SETTINGS_MANAGE_TEAM',
  OFFICE_COMPANY_SETTINGS_MANAGE_PRICEBOOK = 'V2_OFFICE_COMPANY_SETTINGS_MANAGE_PRICEBOOK',
  OFFICE_COMPANY_SETTINGS_MANAGE_MAINTENANCE_PLANS = 'V2_OFFICE_COMPANY_SETTINGS_MANAGE_MAINTENANCE_PLANS',
  OFFICE_COMPANY_SETTINGS_MANAGE_COMPANY_HOURS = 'V2_OFFICE_COMPANY_SETTINGS_MANAGE_COMPANY_HOURS',
  OFFICE_COMPANY_SETTINGS_MANAGE_JOB_LIFECYCLES = 'V2_OFFICE_COMPANY_SETTINGS_MANAGE_JOB_LIFECYCLES',
  OFFICE_COMPANY_SETTINGS_MANAGE_JOB_TYPES = 'V2_OFFICE_COMPANY_SETTINGS_MANAGE_JOB_TYPES',
  OFFICE_COMPANY_SETTINGS_MANAGE_CHECKLISTS = 'V2_OFFICE_COMPANY_SETTINGS_MANAGE_CHECKLISTS',
  OFFICE_COMPANY_SETTINGS_MANAGE_TAGS = 'V2_OFFICE_COMPANY_SETTINGS_MANAGE_TAGS',
  OFFICE_COMPANY_SETTINGS_MANAGE_LEAD_SOURCES = 'V2_OFFICE_COMPANY_SETTINGS_MANAGE_LEAD_SOURCES',
  OFFICE_COMPANY_SETTINGS_MANAGE_NOTIFICATIONS = 'V2_OFFICE_COMPANY_SETTINGS_MANAGE_NOTIFICATIONS',
  OFFICE_COMPANY_SETTINGS_MANAGE_DRIP_MARKETING = 'V2_OFFICE_COMPANY_SETTINGS_MANAGE_DRIP_MARKETING',
  OFFICE_COMPANY_SETTINGS_MANAGE_APPOINTMENT_SETTINGS = 'V2_OFFICE_COMPANY_SETTINGS_MANAGE_APPOINTMENT_SETTINGS',
  OFFICE_COMPANY_SETTINGS_MANAGE_JOB_REQUESTS = 'V2_OFFICE_COMPANY_SETTINGS_MANAGE_JOB_REQUESTS',
  OFFICE_COMPANY_SETTINGS_MANAGE_ASSISTANT_SETTINGS = 'V2_OFFICE_COMPANY_SETTINGS_MANAGE_ASSISTANT_SETTINGS',
  OFFICE_COMPANY_SETTINGS_MANAGE_ONLINE_BOOKING = 'V2_OFFICE_COMPANY_SETTINGS_MANAGE_ONLINE_BOOKING',
  OFFICE_INTEGRATION_ZAPIER_ACCOUNT_CONNECT = 'V2_OFFICE_INTEGRATION_ZAPIER_ACCOUNT_CONNECT',
  OFFICE_INTEGRATED_PHONE = 'V2_OFFICE_INTEGRATED_PHONE',

  USE_FIELD_EXPERIENCE = 'V2_USE_FIELD_EXPERIENCE',
  FIELD_ACCOUNTS_CONTACTS_MANAGE_FULL = 'V2_FIELD_ACCOUNTS_CONTACTS_MANAGE_FULL',
  FIELD_ACCOUNTS_CONTACTS_VIEW_FULL = 'V2_FIELD_ACCOUNTS_CONTACTS_VIEW_FULL',
  FIELD_ACCOUNTS_CONTACTS_MANAGE_ASSIGNED = 'V2_FIELD_ACCOUNTS_CONTACTS_MANAGE_ASSIGNED',
  FIELD_ACCOUNTS_CONTACTS_VIEW_ASSIGNED = 'V2_FIELD_ACCOUNTS_CONTACTS_VIEW_ASSIGNED',
  FIELD_JOB_INFORMATION_MANAGE_FULL = 'V2_FIELD_JOB_INFORMATION_MANAGE_FULL',
  FIELD_JOB_INFORMATION_MANAGE_ASSIGNED = 'V2_FIELD_JOB_INFORMATION_MANAGE_ASSIGNED',
  FIELD_JOB_INFORMATION_CREATE = 'V2_FIELD_JOB_INFORMATION_CREATE',
  FIELD_SCHEDULE_CREATE = 'V2_FIELD_SCHEDULE_CREATE',
  FIELD_SCHEDULE_APPOINTMENTS_ASSIGNED_MANAGE = 'V2_FIELD_SCHEDULE_APPOINTMENTS_ASSIGNED_MANAGE',
  FIELD_SCHEDULE_APPOINTMENTS_ASSIGNED_MANAGE_TODAY = 'V2_FIELD_SCHEDULE_APPOINTMENTS_ASSIGNED_MANAGE_TODAY',
  FIELD_SCHEDULE_APPOINTMENTS_ASSIGNED_MANAGE_CURRENT = 'V2_FIELD_SCHEDULE_APPOINTMENTS_ASSIGNED_MANAGE_CURRENT',
  FIELD_SCHEDULE_APPOINTMENTS_VIEW_HISTORICAL_ASSIGNED = 'V2_FIELD_SCHEDULE_APPOINTMENTS_VIEW_HISTORICAL_ASSIGNED',
  FIELD_SCHEDULE_APPOINTMENTS_TEAM_MANAGE = 'V2_FIELD_SCHEDULE_APPOINTMENTS_TEAM_MANAGE',
  FIELD_SCHEDULE_APPOINTMENTS_TEAM_VIEW = 'V2_FIELD_SCHEDULE_APPOINTMENTS_TEAM_VIEW',
  FIELD_SCHEDULE_APPOINTMENTS_TEAM_NONE = 'V2_FIELD_SCHEDULE_APPOINTMENTS_TEAM_NONE',

  FIELD_MAINTENANCE_PLANS_MANAGE_FULL = 'V2_FIELD_MAINTENANCE_PLANS_MANAGE_FULL',
  FIELD_MAINTENANCE_PLANS_MANAGE_ASSIGNED = 'V2_FIELD_MAINTENANCE_PLANS_MANAGE_ASSIGNED',
  FIELD_MAINTENANCE_PLANS_VIEW_ASSIGNED = 'V2_FIELD_MAINTENANCE_PLANS_VIEW_ASSIGNED',

  CAPABILITY_CUSTOM_DISCOUNT_CREATION_ENABLED = 'V2_CAPABILITY_CUSTOM_DISCOUNT_CREATION_ENABLED',
  CAPABILITY_AI_ESTIMATE_DESCRIPTIONS_ENABLED = 'V2_CAPABILITY_AI_ESTIMATE_DESCRIPTIONS_ENABLED',
}

export const V2SystemPermissions = [
  PermissionV2.READ_ANY_USER,
  PermissionV2.IMPERSONATE_USER,
  PermissionV2.CREATE_COMPANY,
  PermissionV2.DELETE_COMPANY,
  PermissionV2.EDIT_COMPANY,
  PermissionV2.READ_ALL_COMPANIES,
  PermissionV2.ENABLE_DEMO_MODE,
  PermissionV2.EXECUTE_DEV_TOOLS,
  PermissionV2.LOAN_SIMULATOR,
]

export const isPermissionV1 = (x: unknown): x is PermissionV1 => {
  return typeof x === 'string' && !x.startsWith('V2_') && Object.keys(PermissionV1).includes(x)
}

export const isPermissionV2 = (x: unknown): x is PermissionV2 => {
  return typeof x === 'string' && x.startsWith('V2_') && Object.values(PermissionV2).includes(x as PermissionV2)
}

export type Permission = PermissionV1 | PermissionV2
export const isPermission = (x: unknown): x is Permission => {
  return isPermissionV1(x) || isPermissionV2(x)
}

export const PERMISSION_V2_DEFAULT_DESKTOP_ACCESS_PERMISSIONS: PermissionV2[] = [
  PermissionV2.USE_OFFICE_EXPERIENCE,
  PermissionV2.OFFICE_ACCOUNTS_JOBS_MANAGE,
  PermissionV2.OFFICE_ACCOUNTS_JOBS_VIEW,
  PermissionV2.OFFICE_SCHEDULE_APPOINTMENTS_MANAGE,
  PermissionV2.OFFICE_SCHEDULE_APPOINTMENTS_VIEW,
  PermissionV2.OFFICE_FINANCIAL_INFORMATION_MANAGE,
  PermissionV2.OFFICE_FINANCIAL_INFORMATION_ESTIMATES_VIEW,
  PermissionV2.OFFICE_FINANCIAL_INFORMATION_INVOICES_VIEW,
  PermissionV2.OFFICE_FINANCIAL_INFORMATION_PAYMENTS_VIEW,
  PermissionV2.OFFICE_FINANCIAL_INFORMATION_PAYOUTS_VIEW,
  PermissionV2.OFFICE_COMPANY_SETTINGS_MANAGE_LEAD_SOURCES,
  PermissionV2.OFFICE_REPORTS_MANAGE,
  PermissionV2.OFFICE_REPORTS_VIEW,
  PermissionV2.OFFICE_TECHNICIAN_PERFORMANCE_VIEW,
  PermissionV2.OFFICE_TECHNICIAN_PERFORMANCE_MANAGE,
  PermissionV2.OFFICE_TIMESHEETS_VIEW,
  PermissionV2.OFFICE_TIMESHEETS_MANAGE,
  PermissionV2.OFFICE_COMPANY_SETTINGS_MANAGE_TEAM,
  PermissionV2.OFFICE_COMPANY_SETTINGS_MANAGE_PRICEBOOK,
  PermissionV2.OFFICE_COMPANY_SETTINGS_MANAGE_MAINTENANCE_PLANS,
  PermissionV2.OFFICE_COMPANY_SETTINGS_MANAGE_COMPANY_HOURS,
  PermissionV2.OFFICE_COMPANY_SETTINGS_MANAGE_JOB_LIFECYCLES,
  PermissionV2.OFFICE_COMPANY_SETTINGS_MANAGE_JOB_TYPES,
  PermissionV2.OFFICE_COMPANY_SETTINGS_MANAGE_CHECKLISTS,
  PermissionV2.OFFICE_COMPANY_SETTINGS_MANAGE_TAGS,
  PermissionV2.OFFICE_COMPANY_SETTINGS_MANAGE_NOTIFICATIONS,
  PermissionV2.OFFICE_COMPANY_SETTINGS_MANAGE_DRIP_MARKETING,
  PermissionV2.OFFICE_COMPANY_SETTINGS_MANAGE_APPOINTMENT_SETTINGS,
  PermissionV2.OFFICE_COMPANY_SETTINGS_MANAGE_JOB_REQUESTS,
  PermissionV2.OFFICE_COMPANY_SETTINGS_MANAGE_ASSISTANT_SETTINGS,
  PermissionV2.OFFICE_COMPANY_SETTINGS_MANAGE_ONLINE_BOOKING,
  PermissionV2.OFFICE_INTEGRATION_ZAPIER_ACCOUNT_CONNECT,
  PermissionV2.OFFICE_INTEGRATED_PHONE,
  PermissionV2.READ_COMPANY_USERS,
  PermissionV2.READ_COMPANY,
  PermissionV2.CAPABILITY_CUSTOM_DISCOUNT_CREATION_ENABLED,
  PermissionV2.CAPABILITY_AI_ESTIMATE_DESCRIPTIONS_ENABLED,
] as const

export const PERMISSION_V2_DEFAULT_MOBILE_ACCESS_PERMISSIONS: PermissionV2[] = [
  PermissionV2.USE_FIELD_EXPERIENCE,
  PermissionV2.FIELD_JOB_INFORMATION_MANAGE_FULL,
  PermissionV2.FIELD_JOB_INFORMATION_MANAGE_ASSIGNED,
  PermissionV2.FIELD_JOB_INFORMATION_CREATE,
  PermissionV2.FIELD_ACCOUNTS_CONTACTS_MANAGE_FULL,
  PermissionV2.FIELD_ACCOUNTS_CONTACTS_VIEW_FULL,
  PermissionV2.FIELD_ACCOUNTS_CONTACTS_MANAGE_ASSIGNED,
  PermissionV2.FIELD_ACCOUNTS_CONTACTS_VIEW_ASSIGNED,
  PermissionV2.FIELD_SCHEDULE_APPOINTMENTS_ASSIGNED_MANAGE,
  PermissionV2.FIELD_SCHEDULE_APPOINTMENTS_ASSIGNED_MANAGE_TODAY,
  PermissionV2.FIELD_SCHEDULE_APPOINTMENTS_ASSIGNED_MANAGE_CURRENT,
  PermissionV2.FIELD_SCHEDULE_APPOINTMENTS_VIEW_HISTORICAL_ASSIGNED,
  PermissionV2.FIELD_SCHEDULE_APPOINTMENTS_TEAM_NONE,
  PermissionV2.FIELD_SCHEDULE_CREATE,
  PermissionV2.READ_COMPANY_USERS,
  PermissionV2.READ_COMPANY,
  PermissionV2.CAPABILITY_AI_ESTIMATE_DESCRIPTIONS_ENABLED,
] as const
