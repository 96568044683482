import {
  AccountingIntegrationType,
  AccountingIntegrationTypeFriendlyNames,
} from '@breezy/shared/src/domain/Accounting/AccountingServiceTypes'
import { useCallback } from 'react'
import { useMessage, useModal } from '../utils/antd-utils'
import { trpc } from './trpc'

type UseSyncPayoutProps = {
  accountingIntegrationType: AccountingIntegrationType
  payoutGuid: string
  onSuccess?: () => void
  onError?: (error: string) => void
}

export const useSyncPayout = ({
  accountingIntegrationType,
  payoutGuid,
  onSuccess,
  onError,
}: UseSyncPayoutProps) => {
  const friendlyName =
    AccountingIntegrationTypeFriendlyNames[accountingIntegrationType]
  const message = useMessage()
  const Modal = useModal()

  const syncMutation = trpc.accounting[
    'accounting-app:sync-payout'
  ].useMutation({
    onError: e => {
      const content = e?.message || 'Please try again later or contact support.'

      console.error(JSON.stringify(e, null, 2))

      Modal.error({
        title: `Could not sync with ${friendlyName} - Payout ${payoutGuid}`,
        content,
      })
      onError?.(content)
    },
    onSuccess: () => {
      message.success(`Successfully synced with ${friendlyName}.`)
      onSuccess?.()
    },
  })

  const onSyncClick = useCallback(
    (force?: boolean) =>
      syncMutation.mutateAsync({
        payoutGuid,
        force,
      }),
    [syncMutation, payoutGuid],
  )
  return { onSyncClick, loading: syncMutation.isLoading }
}
