import {
  PaymentWorkflowOption,
  PaymentWorkflowOptionSchema,
  formatMoney,
  isNullish,
  noOp,
  usCentsToUsd,
} from '@breezy/shared'
import { faPiggyBank } from '@fortawesome/pro-light-svg-icons'
import { faInfoCircle } from '@fortawesome/pro-regular-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { zodResolver } from '@hookform/resolvers/zod'
import { Form, Tooltip } from 'antd'
import classNames from 'classnames'
import React, { useCallback, useMemo } from 'react'
import { useForm } from 'react-hook-form'
import { z } from 'zod'
import { NumberField } from '../../../../elements/Forms/NumberField'
import { ReactHookFormItem } from '../../../../elements/Forms/ReactHookFormItem'
import RenderIf from '../../../../elements/RenderIf/RenderIf'
import ThinDivider from '../../../../elements/ThinDivider'
import {
  PAYMENT_WORKFLOW_OPTIONS,
  PaymentWorkflowOptionConfig,
} from '../../PaymentWorkflowWizard'

export const PaymentWorkflowConfigFormSchema = z
  .object({
    paymentAmountUsd: z
      .number()
      .min(0.01, 'Payment amount must be greater than $0'),
    paymentWorkflowOption: PaymentWorkflowOptionSchema,
    hiddenAmountUsd: z.number(),
  })
  .superRefine((data, ctx) => {
    if (data.paymentAmountUsd && data.paymentAmountUsd > data.hiddenAmountUsd) {
      ctx.addIssue({
        code: z.ZodIssueCode.custom,
        path: ['paymentAmountUsd'],
        message: `Payment amount must be less than or equal to the invoice amount of ${formatMoney(
          data.hiddenAmountUsd,
        )}`,
      })
    }
  })

export type PaymentWorkflowConfigFormData = z.infer<
  typeof PaymentWorkflowConfigFormSchema
>

export type PaymentWorkflowConfigSubmitFormData = Omit<
  PaymentWorkflowConfigFormData,
  'hiddenAmountUsd'
>

export type PaymentWorkflowConfigFormProps = PaymentWorkflowOptionConfig & {
  amountUsd: number
  onSubmit: (data: PaymentWorkflowConfigSubmitFormData) => void
  editableAmount?: boolean
  defaultPaymentAmountUsc?: number
  paymentAmountLabel?: string
}

const PaymentWorkflowConfigForm = React.memo<PaymentWorkflowConfigFormProps>(
  ({
    onSubmit,
    defaultPaymentAmountUsc,
    amountUsd,
    paymentInfo,
    editableAmount = true,
    paymentAmountLabel = 'Enter a payment amount',
    ...props
  }) => {
    const {
      control,
      formState: { errors },
      watch,
    } = useForm<PaymentWorkflowConfigFormData>({
      defaultValues: {
        paymentAmountUsd: usCentsToUsd(defaultPaymentAmountUsc ?? 0),
        paymentWorkflowOption: 'Payment Link' as PaymentWorkflowOption,
        hiddenAmountUsd: amountUsd,
      },
      mode: 'onChange',
      reValidateMode: 'onChange',
      resolver: zodResolver(PaymentWorkflowConfigFormSchema),
    })

    const visibleOptions = useMemo(
      () =>
        PAYMENT_WORKFLOW_OPTIONS.filter(
          option =>
            !props.hiddenOptions?.includes(
              option.label as PaymentWorkflowOption,
            ),
        ),
      [props.hiddenOptions],
    )
    const allOptionsDisabled = useMemo(() => amountUsd <= 0, [amountUsd])

    const enabledOptions = useMemo(
      () =>
        allOptionsDisabled
          ? []
          : visibleOptions.filter(
              option =>
                !props.disabledOptions
                  ?.map(o => o.option)
                  .includes(option.label as PaymentWorkflowOption),
            ),
      [props.disabledOptions, visibleOptions, allOptionsDisabled],
    )

    const disabledOptions = useMemo(
      () =>
        allOptionsDisabled
          ? visibleOptions.map(option => ({
              label: option.label as PaymentWorkflowOption,
              info: 'No payment due',
              hide: false,
            }))
          : props.disabledOptions?.map(option => ({
              label: option.option,
              info: option.info,
              hide: option.hide,
            })) ?? [],
      [props.disabledOptions, visibleOptions, allOptionsDisabled],
    )

    const formPaymentAmountUsd = watch('paymentAmountUsd')

    const onSubmitInner = useCallback(
      (paymentOption: PaymentWorkflowOption) => {
        onSubmit({
          paymentAmountUsd: formPaymentAmountUsd,
          paymentWorkflowOption: paymentOption,
        })
      },
      [onSubmit, formPaymentAmountUsd],
    )

    return (
      <Form layout="vertical">
        {
          <RenderIf if={!isNullish(defaultPaymentAmountUsc)}>
            <ReactHookFormItem
              control={control}
              name="paymentAmountUsd"
              label={paymentAmountLabel}
              errors={errors}
              render={({ field }) => (
                <NumberField
                  className="w-full"
                  dataDdActionName="BZ Payment Workflow - Set Payment Amount"
                  isMoney
                  disabled={!editableAmount}
                  {...field}
                />
              )}
            />
            {paymentInfo}
            <ThinDivider
              dividerStyle="dotted"
              styleOverrides={{
                marginTop: 24,
                marginBottom: 24,
              }}
            />
            <ReactHookFormItem
              control={control}
              name="paymentWorkflowOption"
              label="Choose a payment option"
              errors={errors}
              render={({ field }) => (
                <div className="grid grid-cols-2 gap-3 md:grid-cols-3">
                  {enabledOptions.map(option => (
                    <PaymentWorkflowOptionCard
                      key={option.label}
                      option={option.label}
                      status={errors.paymentAmountUsd ? 'disabled' : 'default'}
                      onClick={
                        errors.paymentAmountUsd
                          ? noOp
                          : () => onSubmitInner(option.label)
                      }
                    />
                  ))}
                  {disabledOptions.map(option => (
                    <>
                      {!option.hide && (
                        <PaymentWorkflowOptionCard
                          key={option.label}
                          option={option.label}
                          info={option.info}
                          status="disabled"
                          onClick={noOp}
                        />
                      )}
                    </>
                  ))}
                </div>
              )}
            />
          </RenderIf>
        }
      </Form>
    )
  },
)

type PaymentWorkflowOptionCardProps = {
  option: PaymentWorkflowOption
  status: 'default' | 'disabled'
  info?: string
  onClick: () => void
}

const PaymentWorkflowOptionCard = React.memo<PaymentWorkflowOptionCardProps>(
  ({ option, status, info, onClick }) => {
    const optionConfig = useMemo(
      () =>
        PAYMENT_WORKFLOW_OPTIONS.find(config => config.label === option) ?? {
          icon: faPiggyBank,
        },
      [option],
    )
    return (
      <div
        onClick={onClick}
        data-testid={`paymentOptionCard-${option}`}
        data-dd-action-name={`BZ Payment Workflow - Select Option - ${option}`}
        className={classNames(
          'relative flex flex-col items-center justify-center gap-2 rounded-lg border border-solid border-bz-gray-500 px-4 py-6 font-semibold',
          {
            'cursor-pointer text-bz-gray-1000': status === 'default',
            'cursor-not-allowed bg-bz-gray-400 text-bz-gray-600':
              status === 'disabled',
          },
        )}
      >
        <RenderIf if={!!info}>
          <div className="absolute right-1 top-0">
            <Tooltip title={info} placement="top">
              <FontAwesomeIcon icon={faInfoCircle} />
            </Tooltip>
          </div>
        </RenderIf>
        <FontAwesomeIcon
          className={classNames('h-8 w-8', {
            'text-bz-gray-1000': status === 'default',
            'text-bz-gray-600': status === 'disabled',
          })}
          icon={optionConfig.icon}
        />
        <div>{option}</div>
      </div>
    )
  },
)

export default PaymentWorkflowConfigForm
