import { CompanyGuid, OnlineBookingServiceTypeMetadata } from '@breezy/shared'
import React from 'react'

import { useQuery } from 'urql'
import { GqlMultiQueryLoader } from '../../components/GqlQueryLoader/GqlQueryLoader'
import { DocumentType, gql } from '../../generated'
import {
  convertQueryToCompanyUniqueZipCodes,
  useFetchCompanyUniqueZipCodes,
} from '../../hooks/fetch/useFetchCompanyUniqueZipCodes'
import { OnlineBookingCompanyConfigFormData } from './utils'

const FETCH_ONLINE_BOOKING_COMPANY_CONFIG_QUERY = gql(/* GraphQL */ `
  query FetchOnlineBookingCompanyConfig($companyGuid: uuid!) {
    companiesByPk(companyGuid: $companyGuid) {
      onlineBookingCompanyConfig {
        limitBookingsToServiceAreaEnabled
        brandingColorHex
        afterHoursBannerHtml
        serviceAreaZipCodes {
          zipCode
        }
      }
      onlineBookingServiceTypeConfigs {
        serviceType
        bookingType
        onlineBookingServiceTypeGuid
        updatedAt
      }
    }
  }
`)

type FetchOnlineBookingCompanyConfigQuery = DocumentType<
  typeof FETCH_ONLINE_BOOKING_COMPANY_CONFIG_QUERY
>

const convertQueryToOnlineBookingCompanyConfig = (
  data: FetchOnlineBookingCompanyConfigQuery,
): OnlineBookingCompanyConfigFormData => {
  return {
    limitBookingsToServiceAreaEnabled:
      data?.companiesByPk?.onlineBookingCompanyConfig
        ?.limitBookingsToServiceAreaEnabled ?? false,
    brandingColorHex:
      data?.companiesByPk?.onlineBookingCompanyConfig?.brandingColorHex ??
      '#1677FF',
    afterHoursBannerHtml:
      data?.companiesByPk?.onlineBookingCompanyConfig?.afterHoursBannerHtml ??
      '',
    serviceAreaZipCodes: (
      data?.companiesByPk?.onlineBookingCompanyConfig?.serviceAreaZipCodes ?? []
    ).map(zipCode => zipCode.zipCode),
  }
}

const convertQueryToOnlineBookingServiceTypes = (
  data: FetchOnlineBookingCompanyConfigQuery,
): OnlineBookingServiceTypeMetadata[] => {
  return (
    data?.companiesByPk?.onlineBookingServiceTypeConfigs.map(serviceType => ({
      onlineBookingServiceTypeGuid: serviceType.onlineBookingServiceTypeGuid,
      serviceType: serviceType.serviceType,
      bookingType: serviceType.bookingType,
      updatedAt: serviceType.updatedAt,
    })) ?? []
  )
}

const useFetchOnlineBookingCompanyConfig = (companyGuid: CompanyGuid) => {
  const companyInfoQuery = useQuery({
    query: FETCH_ONLINE_BOOKING_COMPANY_CONFIG_QUERY,
    variables: {
      companyGuid,
    },
  })

  return companyInfoQuery
}

type WithOnlineBookingCompanyConfigProps = {
  companyGuid: CompanyGuid
  loadingComponent?: JSX.Element
  render: (
    companyInfo: OnlineBookingCompanyConfigFormData & {
      zipCodes: string[]
      onlineBookingServiceTypes: OnlineBookingServiceTypeMetadata[]
    },
    refetch: () => void,
  ) => JSX.Element
}

export const WithOnlineBookingCompanyConfig =
  React.memo<WithOnlineBookingCompanyConfigProps>(
    ({ companyGuid, render, loadingComponent }) => {
      const companyInfoQuery = useFetchOnlineBookingCompanyConfig(companyGuid)
      const { fetchUniqueZipCodesQuery } =
        useFetchCompanyUniqueZipCodes(companyGuid)

      return (
        <GqlMultiQueryLoader
          queries={[companyInfoQuery, fetchUniqueZipCodesQuery]}
          loadingComponent={loadingComponent}
          idleComponent={loadingComponent}
          render={data => {
            return render(
              {
                ...convertQueryToOnlineBookingCompanyConfig(data[0]),
                onlineBookingServiceTypes:
                  convertQueryToOnlineBookingServiceTypes(data[0]),
                zipCodes: convertQueryToCompanyUniqueZipCodes(data[1]),
              },
              companyInfoQuery[1],
            )
          }}
        />
      )
    },
  )
