import { gql } from '../../generated'

export const UPDATE_JOB_USE_MAINTENANCE_PLAN_CREDIT = gql(/* GraphQL */ `
  mutation UpdateJobUseMaintenancePlanCredit(
    $jobGuid: uuid!
    $useCredit: Boolean!
  ) {
    updateJobsByPk(
      pkColumns: { jobGuid: $jobGuid }
      _set: { useMaintenancePlanCredit: $useCredit }
    ) {
      jobGuid
      useMaintenancePlanCredit
    }
  }
`)

export const FETCH_JOB_TECHNICIAN_PERFORMANCE_INFO = gql(/* GraphQL */ `
  query FetchJobTechnicianPerformanceInfo($jobGuid: uuid!) {
    jobTechnicianEarnedRevenueSummaries(where: { jobGuid: { _eq: $jobGuid } }) {
      technicianUserGuid
      totalEarnedRevenueUsc
      technician {
        fullName
        userGuid
      }
    }
    jobTechnicianSalesCommissionAndBonus(
      where: { jobGuid: { _eq: $jobGuid } }
    ) {
      technicianUserGuid
      totalSoldRevenueUsc
      totalDeductionsUsc
      commissionableBaseUsc
      commissionUsc
      commissionRate
      bonusUsc
      technician {
        fullName
        userGuid
      }
    }
  }
`)

export const FETCH_JOB_WORK_COMPLETED_AT_SUBSCRIPTION = gql(/* GraphQL */ `
  subscription FetchJobWorkCompletedAtLiveQuery($jobGuid: uuid!) {
    jobsByPk(jobGuid: $jobGuid) {
      workCompletedAt
    }
  }
`)
