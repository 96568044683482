import {
  AccountType,
  BzDateFns,
  getDisplayNameForAccountType,
  IsoDateString,
  TimeZoneId,
} from '@breezy/shared'
import { LabeledItemGrid } from 'src/adam-components/LabeledItemGrid'
import { SectionedCard } from 'src/adam-components/SectionedCard/SectionedCard'
import { Link } from 'src/elements/Link/Link'

export type AccountCardAccount = {
  accountGuid: string
  displayName: string
  accountType: AccountType
  createdOn?: IsoDateString
  leadSource?: string
}

export interface AccountCardProps {
  account: AccountCardAccount
  timezoneId: TimeZoneId
}

export const AccountCard = (props: AccountCardProps) => {
  const { account } = props

  return (
    <SectionedCard
      small
      dashed
      sections={[
        {
          verticalPaddingClassName: 'p-3',
          content: (
            <div className="flex flex-row items-center justify-between">
              <Link
                to={`/accounts/${account.accountGuid}`}
                bold
                className="text-sm"
              >
                {account.displayName}
              </Link>
            </div>
          ),
        },
        {
          content: (
            <LabeledItemGrid
              items={[
                'Type',
                getDisplayNameForAccountType(account.accountType),
                ...(account.createdOn
                  ? [
                      'Created On',
                      BzDateFns.formatFromISO(
                        account.createdOn,
                        'MMM dd, yyyy',
                        props.timezoneId,
                      ),
                    ]
                  : []),
                ...(account.leadSource
                  ? ['Lead Source', account.leadSource]
                  : []),
              ]}
            />
          ),
        },
      ]}
    />
  )
}
