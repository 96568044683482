import {
  DiscountType,
  JobGuid,
  MaintenancePlanStatus,
  SimplifiedDiscount,
  nextGuid,
} from '@breezy/shared'
import { useMemo } from 'react'
import { Client, useQuery } from 'urql'
import { DiscountPickerDiscount } from '../components/Pricebook/DiscountMultiPicker'
import { gql } from '../generated'

type MaintenancePlanJobDiscountProps = {
  readonly jobGuid?: JobGuid
}

const JOB_MAINTENANCE_PLAN_DISCOUNT_QUERY = gql(/* GraphQL */ `
  query JobMaintenancePlanDiscount($jobGuid: uuid!) {
    jobsByPk(jobGuid: $jobGuid) {
      jobGuid
      jobType {
        jobClass
      }
      maintenancePlan {
        status
        discounts
      }
    }
  }
`)

export const useMaintenancePlanJobDiscount = ({
  jobGuid,
}: MaintenancePlanJobDiscountProps): SimplifiedDiscount | undefined => {
  const [{ data, fetching }] = useQuery({
    query: JOB_MAINTENANCE_PLAN_DISCOUNT_QUERY,
    pause: !jobGuid,
    variables: { jobGuid: jobGuid ?? '' },
  })

  if (fetching) return undefined
  if (!data?.jobsByPk) return undefined

  const jobClass = data.jobsByPk.jobType.jobClass
  const maintenancePlan = data.jobsByPk.maintenancePlan
  if (!maintenancePlan) return undefined
  if (maintenancePlan.status !== MaintenancePlanStatus.ACTIVE) return undefined

  const mpDiscounts = maintenancePlan.discounts ?? []
  const matchingDiscount = mpDiscounts.find(
    discount => discount.discountJobClass === jobClass,
  )
  if (!matchingDiscount) return undefined

  return {
    type: DiscountType.RATE,
    value: matchingDiscount.discountRate,
  }
}

export const useMaintenancePlanJobOnsiteDiscount = ({
  jobGuid,
}: MaintenancePlanJobDiscountProps): DiscountPickerDiscount[] => {
  const mpSimplifiedDiscount = useMaintenancePlanJobDiscount({ jobGuid })
  const mpOnsiteDiscounts = useMemo(() => {
    if (!mpSimplifiedDiscount) return []

    const onsiteDiscount: DiscountPickerDiscount = {
      discountGuid: nextGuid(),
      name: 'Maintenance Plan Discount',
      descriptionHtml: 'Maintenance Plan Discount',
      type: DiscountType.RATE,
      discountRate: mpSimplifiedDiscount.value,
    }
    return [onsiteDiscount]
  }, [mpSimplifiedDiscount])

  return mpOnsiteDiscounts
}

export const queryMaintenancePlanJobOnsiteDiscount = async (
  urqlClient: Client,
  jobGuid: JobGuid,
): Promise<DiscountPickerDiscount[]> => {
  const jobDiscountQuery = await urqlClient
    .query(JOB_MAINTENANCE_PLAN_DISCOUNT_QUERY, { jobGuid })
    .toPromise()
  if (jobDiscountQuery.error) {
    throw new Error(
      `Failed to fetch job discount: ${jobDiscountQuery.error.message}`,
    )
  }

  const data = jobDiscountQuery.data
  if (!data?.jobsByPk) return []

  const jobClass = data.jobsByPk.jobType.jobClass
  const maintenancePlan = data.jobsByPk.maintenancePlan
  if (
    !maintenancePlan ||
    maintenancePlan.status !== MaintenancePlanStatus.ACTIVE
  )
    return []

  const mpDiscounts = maintenancePlan.discounts ?? []
  const matchingDiscount = mpDiscounts.find(
    discount => discount.discountJobClass === jobClass,
  )
  if (!matchingDiscount) return []

  const onsiteDiscount: DiscountPickerDiscount = {
    discountGuid: nextGuid(),
    name: 'Maintenance Plan Discount',
    descriptionHtml: 'Maintenance Plan Discount',
    type: DiscountType.RATE,
    discountRate: matchingDiscount.discountRate,
  }

  return [onsiteDiscount]
}
