import {
  BzDateFns,
  EstimateV2Status,
  formatUsc,
  isNullish,
  IsoDateString,
  TimeZoneId,
} from '@breezy/shared'
import { LabeledItemGrid } from 'src/adam-components/LabeledItemGrid'
import { SectionedCard } from 'src/adam-components/SectionedCard/SectionedCard'
import { Link } from 'src/elements/Link/Link'
import StatusTag from 'src/elements/StatusTag/StatusTag'
import { getEstimateV2StatusDisplayInfo } from 'src/pages/EstimatesFlow/estimatesFlowUtils'

export type EstimateCardEstimate = {
  estimateGuid: string
  displayId: string
  status: EstimateV2Status
  issuedAt: IsoDateString
  options: {
    estimateOptionGuid: string
    displayName?: string
    sequence: number
    isSelected: boolean
    totalUsc: number
  }[]
  createdBy?: {
    name: string
  }
  job?: {
    jobGuid: string
    displayId: string
    jobTypeName: string
  }
  linkedFromJob?: {
    jobGuid: string
    displayId: string
    jobTypeName: string
  }
}

export interface EstimateCardProps {
  estimate: EstimateCardEstimate
  timezoneId: TimeZoneId
}

export const EstimateCard = (props: EstimateCardProps) => {
  const { estimate } = props

  const statusInfo = getEstimateV2StatusDisplayInfo(estimate.status)

  const totalUsc: number | undefined =
    estimate.options.length === 1
      ? estimate.options[0].totalUsc
      : estimate.options.find(option => option.isSelected)?.totalUsc

  return (
    <SectionedCard
      dashed
      small
      sections={[
        {
          verticalPaddingClassName: 'p-3',
          content: (
            <div className="flex flex-row items-center justify-between">
              <Link
                to={`/estimates/${estimate.estimateGuid}`}
                bold
                className="text-sm"
              >
                #{estimate.displayId}
              </Link>

              <StatusTag
                color={statusInfo.statusTagColor}
                text={statusInfo.label}
              />
            </div>
          ),
        },
        {
          content: (
            <LabeledItemGrid
              items={[
                'Issued Date',
                BzDateFns.formatFromISO(
                  estimate.issuedAt,
                  'MMM d, yyyy',
                  props.timezoneId,
                ),
                ...(!isNullish(totalUsc) ? ['Total', formatUsc(totalUsc)] : []),
                'Prepared By',
                estimate.createdBy ? estimate.createdBy.name : 'Technician',
                ...(estimate.job
                  ? [
                      'Job',
                      <Link
                        to={`/jobs/${estimate.job.jobGuid}`}
                        className="text-sm"
                      >
                        {estimate.job.jobTypeName} #{estimate.job.displayId}
                      </Link>,
                    ]
                  : []),
                ...(estimate.linkedFromJob
                  ? [
                      'Linked From Job',
                      <Link
                        to={`/jobs/${estimate.linkedFromJob.jobGuid}`}
                        className="text-sm"
                      >
                        {estimate.linkedFromJob.jobTypeName} #
                        {estimate.linkedFromJob.displayId}
                      </Link>,
                    ]
                  : []),
              ]}
            />
          ),
        },
      ]}
    />
  )
}
