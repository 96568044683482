import { faSquareArrowUpRight } from '@fortawesome/pro-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Button, Tooltip } from 'antd'
import cn from 'classnames'
import { useMemo } from 'react'
import { m } from '../../../utils/react-utils'
import { QuickbooksSyncButtonBaseProps } from './QuickbooksSyncButton'

type QuickbooksViewLinkButtonProps = QuickbooksSyncButtonBaseProps & {
  onClick: () => void
}

export const QuickbooksViewLinkButton = m<QuickbooksViewLinkButtonProps>(
  ({
    style,
    className,
    size,
    placement = 'top',
    tooltip = 'View on Quickbooks',
    onClick,
  }) => {
    const sizeClassName = useMemo(() => {
      switch (size) {
        case 'lg':
          return 'w-12 h-12'
        case 'sm':
          return 'w-7 h-7'
        default:
          return 'w-8 h-8'
      }
    }, [size])

    return (
      <div className={cn(sizeClassName, className)} style={style}>
        <Tooltip
          placement={placement}
          title={tooltip}
          // https://stackoverflow.com/questions/71111609/ant-design-tooltip-shows-scroll-bar
          overlayStyle={{ position: 'fixed' }}
        >
          <Button
            className={cn(
              'relative h-full w-full rounded-full border-2 border-transparent p-0 leading-none shadow-none hover:border-[#40a9ff] hover:opacity-100',
            )}
            onClick={onClick}
          >
            <img
              alt="Sync with Quickbooks"
              src="https://d3j5nzmmhjc8v6.cloudfront.net/quickbooks_logo.png"
              className="h-full w-full"
            />

            <div className="absolute right-[-5px] top-[-5px] text-[#1890FF]">
              <FontAwesomeIcon
                className="rounded-full bg-white"
                icon={faSquareArrowUpRight}
              />
            </div>
          </Button>
        </Tooltip>
      </div>
    )
  },
)
