import {
  DayOfTheWeek,
  DayOfTheWeekAbbreviation,
  DAYS_OF_THE_WEEK,
} from '@breezy/shared'
import { faCheck, faX } from '@fortawesome/pro-light-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Select, Switch } from 'antd'
import classNames from 'classnames'
import { useCallback } from 'react'
import { BzSelectOption } from '../../elements/BzSelect/BzSelectTypes'
import { typedMemo } from '../../utils/react-utils'

export type DaySchedule<T extends string> = {
  enabled: boolean
  values: T[]
}

export type WeeklySchedule<T extends string> = Record<
  DayOfTheWeek,
  DaySchedule<T>
>

type WeeklyScheduleAvailabilityProps<T extends string> = {
  weeklySchedule: WeeklySchedule<T>
  onChange: (value: WeeklySchedule<T>) => void
  options: BzSelectOption[]
}

export const WeeklyScheduleAvailability = typedMemo(
  <T extends string>({
    weeklySchedule,
    onChange,
    options,
  }: WeeklyScheduleAvailabilityProps<T>) => {
    const onToggleWithAutoSelect = useCallback(
      (day: DayOfTheWeek) => {
        const newEnabled = !weeklySchedule[day].enabled
        onChange({
          ...weeklySchedule,
          [day]: {
            enabled: newEnabled,
            values: newEnabled ? options.map(opt => opt.value) : [],
          },
        })
      },
      [weeklySchedule, onChange, options],
    )

    const onDayChange = useCallback(
      (day: DayOfTheWeek, values: T[]) => {
        onChange({
          ...weeklySchedule,
          [day]: { ...weeklySchedule[day], values },
        })
      },
      [weeklySchedule, onChange],
    )

    return (
      <div className="flex flex-col gap-4">
        {DAYS_OF_THE_WEEK.map(day => (
          <DayScheduleRow
            key={day}
            {...{
              day,
              daySchedule: weeklySchedule[day],
              onToggle: onToggleWithAutoSelect,
              onChange: onDayChange,
              options,
            }}
          />
        ))}
      </div>
    )
  },
)

type DayScheduleRowProps<T extends string> = {
  day: DayOfTheWeek
  daySchedule: DaySchedule<T>
  onToggle: (day: DayOfTheWeek) => void
  onChange: (day: DayOfTheWeek, values: T[]) => void
  options: BzSelectOption[]
}

const DayScheduleRow = typedMemo(
  <T extends string>({
    day,
    daySchedule,
    onToggle,
    onChange,
    options,
  }: DayScheduleRowProps<T>) => {
    return (
      <div className="grid grid-cols-[100px_1fr] items-center gap-3">
        <div className="flex items-center gap-4">
          <Switch
            checked={daySchedule.enabled}
            onChange={() => onToggle(day)}
            checkedChildren={<FontAwesomeIcon icon={faCheck} />}
            unCheckedChildren={<FontAwesomeIcon icon={faX} />}
          />
          <div className="w-8 text-sm font-semibold leading-[22px]">
            {DayOfTheWeekAbbreviation[day]}
          </div>
        </div>
        {!daySchedule.enabled ? (
          <div className="flex h-8 items-center justify-center rounded-md bg-bz-fill-tertiary px-3 text-sm text-gray-500">
            Unavailable
          </div>
        ) : null}
        <Select
          mode="multiple"
          key={day}
          className={classNames({
            hidden: !daySchedule.enabled,
          })}
          title={`${DayOfTheWeekAbbreviation[day]} Availability`}
          options={options}
          value={daySchedule.values}
          onChange={values => onChange(day, values as T[])}
        />
      </div>
    )
  },
)
