import {
  NotificationPreferenceType,
  toNotificationPreferenceTypeDisplayName,
} from '@breezy/shared'
import { faEllipsis } from '@fortawesome/pro-regular-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Dropdown, MenuProps } from 'antd'
import { useCallback, useMemo } from 'react'
import { LabeledItemGrid } from 'src/adam-components/LabeledItemGrid'
import { SectionedCard } from 'src/adam-components/SectionedCard/SectionedCard'
import { EmDash } from 'src/elements/EmDash/EmDash'
import StatusTag from 'src/elements/StatusTag/StatusTag'

export type ContactCardContact = {
  contactGuid: string
  name: string
  emailAddress?: string
  phoneNumber?: string
  notificationPreference: NotificationPreferenceType
  primary?: boolean
  archived?: boolean
}

export interface ContactCardProps {
  contact: ContactCardContact
  disabled?: boolean
  onEdit?: (contact: ContactCardContact) => void
  onMakePrimary?: (contact: ContactCardContact) => void
  onArchive?: (contact: ContactCardContact) => void
  onUnarchive?: (contact: ContactCardContact) => void
  onDelete?: (contact: ContactCardContact) => void
}

export const ContactCard = (props: ContactCardProps) => {
  const { contact } = props

  const dropdownMenuItems = useMemo<NonNullable<MenuProps['items']>>(() => {
    const items: MenuProps['items'] = []

    if (props.onEdit) {
      items.push({
        key: `${contact.contactGuid}-edit`,
        label: 'Edit',
        disabled: props.disabled,
      })
    }

    if (!contact.primary && !contact.archived) {
      items.push({
        key: `${contact.contactGuid}-make-primary`,
        label: 'Make Primary',
        disabled: props.disabled,
      })
    }

    if (!contact.archived && props.onArchive) {
      items.push({
        key: `${contact.contactGuid}-archive`,
        label: 'Archive',
        disabled: props.disabled,
      })
    }

    if (contact.archived && props.onUnarchive) {
      items.push({
        key: `${contact.contactGuid}-unarchive`,
        label: 'Unarchive',
        disabled: props.disabled,
      })
    }

    if (props.onDelete) {
      items.push({
        key: `${contact.contactGuid}-delete`,
        label: 'Remove',
        disabled: props.disabled,
      })
    }

    return items
  }, [
    contact.archived,
    contact.contactGuid,
    contact.primary,
    props.disabled,
    props.onArchive,
    props.onDelete,
    props.onEdit,
    props.onUnarchive,
  ])

  const dropdownOnClick = useCallback<NonNullable<MenuProps['onClick']>>(
    ({ key }) => {
      switch (key) {
        case `${contact.contactGuid}-edit`:
          props.onEdit?.(contact)
          break
        case `${contact.contactGuid}-make-primary`:
          props.onMakePrimary?.(contact)
          break
        case `${contact.contactGuid}-archive`:
          props.onArchive?.(contact)
          break
        case `${contact.contactGuid}-unarchive`:
          props.onUnarchive?.(contact)
          break
        case `${contact.contactGuid}-delete`:
          props.onDelete?.(contact)
          break
        default:
          break
      }
    },
    [contact, props],
  )

  return (
    <SectionedCard
      small
      dashed
      sections={[
        {
          verticalPaddingClassName: 'p-3',
          content: (
            <div className="flex flex-row items-center justify-between">
              <span
                className="font-semibold"
                data-testid="contact-card-contact-name"
              >
                {contact.name}
              </span>

              <div className="flex flex-row items-center gap-2">
                {contact.primary && (
                  <StatusTag color="green" text="Primary" border="strong" />
                )}

                {dropdownMenuItems.length > 0 && (
                  <Dropdown
                    menu={{
                      items: dropdownMenuItems,
                      onClick: dropdownOnClick,
                    }}
                  >
                    <FontAwesomeIcon
                      icon={faEllipsis}
                      className="cursor-pointer"
                    />
                  </Dropdown>
                )}
              </div>
            </div>
          ),
        },
        {
          content: (
            <LabeledItemGrid
              items={[
                'Email',
                contact.emailAddress ? contact.emailAddress : <EmDash />,
                'Phone Number',
                contact.phoneNumber ? contact.phoneNumber : <EmDash />,
                'Notifications',
                toNotificationPreferenceTypeDisplayName(
                  contact.notificationPreference,
                ),
              ]}
            />
          ),
        },
      ]}
    />
  )
}
