import {
  Guid,
  INVOICE_V2_FRIENDLY_STATUS_NAMES,
  InvoiceV2Status,
  R,
  usCentsToUsd,
} from '@breezy/shared'
import {
  faChevronRight,
  faDownload,
  faEnvelope,
  faPrint,
} from '@fortawesome/pro-regular-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Button } from 'antd'
import classNames from 'classnames'
import React, { useCallback, useMemo, useState } from 'react'
import { PaymentWorkflowOptionConfig } from 'src/components/PaymentWorkflow/PaymentWorkflowWizard'
import {
  ActionsModalAction,
  ActionsModalContent,
} from '../../../adam-components/OnsiteModal/ActionsModalContent'
import {
  OnsiteModal,
  OnsiteModalContent,
} from '../../../adam-components/OnsiteModal/OnsiteModal'
import {
  CloseConfirmModal,
  useCloseConfirmModal,
} from '../../../adam-components/OnsiteModal/useCloseConfirmModal'
import { BaseLoadingSpinner } from '../../../components/LoadingSpinner'
import PaymentWorkflowWizard, {
  PaymentWorkflowWizardProps,
} from '../../../components/PaymentWorkflow/PaymentWorkflowWizard'
import { trpc } from '../../../hooks/trpc'
import useIsMobile from '../../../hooks/useIsMobile'
import { useIsTechApp } from '../../../providers/AppContextWrapper'
import { useMessage } from '../../../utils/antd-utils'
import {
  useDownloadInvoice,
  useFetchPdfBlob,
  useOpenInvoiceInNewTab,
} from '../../../utils/invoice-utils'
import { useStrictContext } from '../../../utils/react-utils'
import {
  FetchedInvoice,
  INVOICE_V2_STATUS_DISPLAY_INFO,
  InvoiceContext,
  InvoiceDataContext,
  getProcessingPayment,
  hasSpecialInvoiceMarkAsBehavior,
  mapInvoiceToAbidgedInvoiceMetadata,
  useInvoiceStatusUpdater,
  useInvoiceTotals,
} from '../invoiceUtils'
import { ProcessingPaymentsContent } from './ProcessingPaymentsModal'

type StatusChangeButtonProps = {
  currentStatus?: InvoiceV2Status
  status: InvoiceV2Status
  onChange: (status: InvoiceV2Status) => void
}

const StatusChangeButton = React.memo<StatusChangeButtonProps>(
  ({ currentStatus, status, onChange }) => {
    const [showConfirm, setShowConfirm] = useState(false)
    const { invoiceTotals } = useStrictContext(InvoiceDataContext)
    const [updateInvoiceStatus, loading] =
      useInvoiceStatusUpdater(currentStatus)

    const hasSpecialBehavior = useMemo(
      () => hasSpecialInvoiceMarkAsBehavior(status, invoiceTotals),
      [status, invoiceTotals],
    )

    const changeStatus = useCallback(async () => {
      await updateInvoiceStatus(status)
      onChange(status)
    }, [onChange, status, updateInvoiceStatus])

    const onOptionClick = useCallback(() => {
      if (hasSpecialBehavior) {
        onChange(status)
      } else {
        setShowConfirm(R.not)
      }
    }, [onChange, hasSpecialBehavior, status])

    const statusInfo = INVOICE_V2_STATUS_DISPLAY_INFO[status]

    return (
      <div className="flex flex-row">
        <div
          className={classNames(
            'flex min-w-0 flex-1 cursor-pointer flex-row truncate  rounded px-2 py-1',
            statusInfo.colorClassNames,
          )}
          onClick={onOptionClick}
        >
          <div className="ml-2 flex-1 self-center font-semibold">
            {statusInfo.label}
          </div>
          <Button
            type="text"
            icon={<FontAwesomeIcon icon={faChevronRight} />}
          />
        </div>

        <div
          className={classNames(
            'flex flex-row overflow-hidden transition-all *:ml-2',
            showConfirm ? 'max-w-[240px]' : 'max-w-0',
          )}
        >
          <Button
            size="large"
            onClick={() => setShowConfirm(false)}
            loading={loading}
          >
            Cancel
          </Button>
          <Button
            type="primary"
            size="large"
            onClick={changeStatus}
            loading={loading}
          >
            Confirm
          </Button>
        </div>
      </div>
    )
  },
)

export const useSendInvoiceEmail = (invoiceGuid: Guid) => {
  const message = useMessage()

  const emailInvoiceMut = trpc.invoice['invoicing:email-invoice'].useMutation()

  const emailInvoicePDF = useCallback(async () => {
    try {
      await emailInvoiceMut.mutateAsync({
        invoiceGuid,
      })
      message.success('Successfully Emailed Invoice PDF to customer')
    } catch (err) {
      console.error(
        `There was an unexpected error sending the invoice pdf`,
        err,
      )
    }
  }, [emailInvoiceMut, invoiceGuid, message])

  return {
    emailInvoicePDF,
    emailingPdf: emailInvoiceMut.isLoading,
  }
}

type InvoiceActionsModalProps = {
  onCancel: () => void
  markAsOptions?: InvoiceV2Status[]
  invoice: FetchedInvoice
  hiddenPaymentOptions?: PaymentWorkflowWizardProps['hiddenOptions']
  disabledOptions?: PaymentWorkflowOptionConfig['disabledOptions']
}

export const InvoiceActionsModal = React.memo<InvoiceActionsModalProps>(
  ({
    onCancel: externalOnCancel,
    invoice,
    markAsOptions,
    hiddenPaymentOptions,
    disabledOptions,
  }) => {
    const invoiceData = useStrictContext(InvoiceContext)
    const message = useMessage()
    const isMobile = useIsMobile()
    const [mode, setMode] = useState<
      | 'base'
      | 'collect-payment'
      | 'processing-payments'
      | 'void'
      | 'set-uncollectable'
    >('base')

    const paymentInvoice = mapInvoiceToAbidgedInvoiceMetadata(
      invoiceData.invoiceGuid,
      invoice,
    )
    const invoiceTotals = useInvoiceTotals(invoice)
    const [isDirty, setIsDirty] = useState(false)

    const [withConfirmation, closeConfirmProps] = useCloseConfirmModal({
      isDirty,
    })

    const triggerStatusChangeMessage = useCallback(
      (status: InvoiceV2Status) => {
        message.success(
          `Invoice status updated to ${INVOICE_V2_FRIENDLY_STATUS_NAMES[
            status
          ].toLowerCase()}`,
        )
      },
      [message],
    )

    const onCancelWithConfirm = useCallback(() => {
      withConfirmation(externalOnCancel)
    }, [externalOnCancel, withConfirmation])

    const onStatusChange = useCallback(
      (status: InvoiceV2Status) => {
        if (hasSpecialInvoiceMarkAsBehavior(status, invoiceTotals)) {
          switch (status) {
            case 'PAID': {
              if (
                invoiceTotals.dueUsc === 0 &&
                invoiceTotals.processingPaymentsUsc > 0
              ) {
                setMode('processing-payments')
              } else {
                setMode('collect-payment')
              }
              break
            }
            // TODO: https://getbreezyapp.atlassian.net/browse/BZ-3435
            // case 'VOIDED':
            //   setMode('void')
            //   break
            // TODO: https://getbreezyapp.atlassian.net/browse/BZ-3436
            // case 'UNCOLLECTABLE':
            //   setMode('set-uncollectable')
            //   break
          }
        } else {
          triggerStatusChangeMessage(status)
          onCancelWithConfirm()
        }
      },
      [invoiceTotals, onCancelWithConfirm, triggerStatusChangeMessage],
    )

    const { emailInvoicePDF, emailingPdf } = useSendInvoiceEmail(
      invoiceData.invoiceGuid,
    )
    const fetchPdfBlob = useFetchPdfBlob(invoice.companyGuid)
    const { downloadInvoicePDF, invoiceDownloading } = useDownloadInvoice(
      invoice.companyGuid,
      invoiceData.invoiceGuid,
      invoiceData.displayId,
    )

    const { beginOpenInvoice, invoiceGenerating } = useOpenInvoiceInNewTab(
      invoiceData.invoiceGuid,
      fetchPdfBlob,
    )

    const onDownloadPdf = useCallback(async () => {
      await downloadInvoicePDF()

      onCancelWithConfirm()
    }, [downloadInvoicePDF, onCancelWithConfirm])

    const isTechApp = useIsTechApp()

    const onPrintInvoice = useCallback(async () => {
      if (isTechApp) {
        await onDownloadPdf()
      } else {
        await beginOpenInvoice()

        onCancelWithConfirm()
      }
    }, [beginOpenInvoice, isTechApp, onCancelWithConfirm, onDownloadPdf])

    const onSendEmail = useCallback(async () => {
      await emailInvoicePDF()

      onCancelWithConfirm()
    }, [emailInvoicePDF, onCancelWithConfirm])

    const onPaymentSuccess = useCallback(() => {
      triggerStatusChangeMessage('PAID')
      externalOnCancel()
    }, [externalOnCancel, triggerStatusChangeMessage])

    const processingPayment = useMemo(
      () => getProcessingPayment(invoice),
      [invoice],
    )

    const modalContentInfo = useMemo<
      | {
          header: string
          headerBordered: boolean
          content: React.ReactNode
          footer?: React.ReactNode
          onBack?: () => void
          fullOverride?: undefined
        }
      | {
          header?: never
          headerBordered?: never
          content?: never
          footer?: never
          onBack?: never
          fullOverride: JSX.Element
        }
    >(() => {
      switch (mode) {
        case 'collect-payment':
          return {
            fullOverride: (
              <PaymentWorkflowWizard
                meta={{
                  invoice: paymentInvoice,
                  invoiceAmountDueUsd: usCentsToUsd(invoiceTotals.dueUsc),
                  editableAmount: false,
                }}
                onBack={() => setMode('base')}
                onCancel={() => setMode('base')}
                onPaymentSuccess={onPaymentSuccess}
                disabledOptions={disabledOptions}
                setIsDirty={setIsDirty}
                mode="embedded"
                hiddenOptions={hiddenPaymentOptions}
              />
            ),
          }
        case 'processing-payments':
          return {
            header: 'Processing Payment',
            headerBordered: true,
            content: processingPayment ? (
              <ProcessingPaymentsContent {...processingPayment} />
            ) : null,
          }
        case 'base':
        default:
          return {
            header: 'Invoice actions',
            headerBordered: true,
            content: (
              <ActionsModalContent>
                {!isMobile && invoice.status === 'OPEN' && (
                  <ActionsModalAction
                    className={
                      emailingPdf ? 'pointer-events-none cursor-wait' : ''
                    }
                    icon={
                      emailingPdf ? (
                        <BaseLoadingSpinner size={7} />
                      ) : (
                        <FontAwesomeIcon icon={faEnvelope} />
                      )
                    }
                    onClick={onSendEmail}
                  >
                    Send Copy
                  </ActionsModalAction>
                )}
                {invoice && (
                  <ActionsModalAction
                    className={
                      invoiceDownloading
                        ? 'pointer-events-none cursor-wait'
                        : ''
                    }
                    icon={
                      invoiceDownloading ? (
                        <BaseLoadingSpinner size={7} />
                      ) : (
                        <FontAwesomeIcon icon={faDownload} />
                      )
                    }
                    onClick={onDownloadPdf}
                  >
                    Download PDF
                  </ActionsModalAction>
                )}
                {invoice && (
                  <ActionsModalAction
                    className={
                      invoiceGenerating ? 'pointer-events-none cursor-wait' : ''
                    }
                    icon={
                      invoiceGenerating ? (
                        <BaseLoadingSpinner size={7} />
                      ) : (
                        <FontAwesomeIcon icon={faPrint} />
                      )
                    }
                    onClick={onPrintInvoice}
                  >
                    Print
                  </ActionsModalAction>
                )}

                {markAsOptions && markAsOptions.length > 0 && (
                  <div className="border-0 border-t border-solid border-bz-gray-400 py-4">
                    <div className="mb-4 text-base font-semibold">
                      Mark as...
                    </div>
                    <div className="space-y-2">
                      {markAsOptions.map(status => (
                        <StatusChangeButton
                          key={status}
                          currentStatus={invoice.status}
                          status={status}
                          onChange={onStatusChange}
                        />
                      ))}
                    </div>
                  </div>
                )}
              </ActionsModalContent>
            ),
          }
      }
    }, [
      disabledOptions,
      emailingPdf,
      hiddenPaymentOptions,
      invoice,
      invoiceDownloading,
      invoiceGenerating,
      invoiceTotals.dueUsc,
      isMobile,
      markAsOptions,
      mode,
      onDownloadPdf,
      onPaymentSuccess,
      onPrintInvoice,
      onSendEmail,
      onStatusChange,
      paymentInvoice,
      processingPayment,
    ])

    return (
      <>
        <OnsiteModal onClose={onCancelWithConfirm} open>
          {modalContentInfo.fullOverride ?? (
            <OnsiteModalContent
              onClose={onCancelWithConfirm}
              footer={modalContentInfo.footer}
              onBack={modalContentInfo.onBack}
              header={modalContentInfo.header}
              headerBordered={modalContentInfo.headerBordered}
            >
              <div className="text-base">{modalContentInfo.content}</div>
            </OnsiteModalContent>
          )}
        </OnsiteModal>
        <CloseConfirmModal {...closeConfirmProps} />
      </>
    )
  },
)
