import {
  BzDateTime,
  CalculatePaths,
  MaintenancePlanMinimalInfo,
  PayoutEnriched,
  PayoutItemEnriched,
  PayoutItemType,
  TimeZoneId,
  UTC_TIME_ZONE,
  convertPayoutDetailsToCsvRow,
  toTitleCase,
} from '@breezy/shared'
import { faLineColumns } from '@fortawesome/pro-light-svg-icons'
import { Table } from 'antd'
import { ColumnsType } from 'antd/es/table'
import { useCallback, useMemo } from 'react'
import { Link } from 'react-router-dom'
import { QuickbooksViewLinkButton } from '../../components/AccountingIntegration/QuickbooksOnline/QuickbooksViewLinkButton'
import {
  dateColumn,
  invoiceLinkColumn,
  moneyColumnUsc,
  renderDataTableAccountLink,
} from '../../components/datatables/CommonColumnDefinitions'
import TagColumn from '../../components/Page/Columns/TagColumn/TagColumn'
import { BzMonospace } from '../../elements/BzMonospace/BzMonospace'
import { EmDash } from '../../elements/EmDash/EmDash'
import FaIconButton from '../../elements/FaIconButton/FaIconButton'
import { useQuickbooksOnlineEnabled } from '../../providers/CompanyFinancialConfigWrapper'
import { useExpectedCompanyTimeZoneId } from '../../providers/PrincipalUser'
import { downloadDataAsCsv } from '../../utils/export-to-csv'
import { DownloadIcon } from '../../utils/feature-icons'
import { m } from '../../utils/react-utils'

type TablePayoutItem = {
  payoutItemGuid: string
  payoutItemDate: string
  itemAmountUsc: number
  itemType: PayoutItemType
  feesAmountUsc: number
  netAmountUsc: number
  itemDescription: string
  paymentRecordGuid?: string
  paymentRefundGuid?: string

  invoiceGuid?: string
  invoiceDisplayId?: string
  paymentItemGuid?: string
  paymentItemType?: string
  accountDisplayName?: string
  accountGuid?: string
  accountMaintenancePlan?: MaintenancePlanMinimalInfo
  qboLink?: string
}

type TableItemType = TablePayoutItem

const tableColumns = (
  tzId: TimeZoneId,
  showFees: boolean,
  showQuickbooksOnlineColumn: boolean,
): ColumnsType<TablePayoutItem> => {
  const columns: ColumnsType<TablePayoutItem> = [
    dateColumn(tzId, 'Date', 'payoutItemDate'),
    moneyColumnUsc(showFees ? 'Gross' : 'Amount', 'itemAmountUsc'),
  ]

  if (showFees) {
    columns.push(moneyColumnUsc('Fees', 'feesAmountUsc'))
    columns.push(moneyColumnUsc('Net', 'netAmountUsc'))
  }

  columns.push(
    {
      title: 'Type',
      dataIndex: 'itemType',
      key: 'itemType',
      render: (_: string, record: TableItemType) => (
        <BzMonospace text={toTitleCase(record.itemType)} />
      ),
    },
    {
      title: 'Account',
      dataIndex: 'accountDisplayName',
      key: 'accountDisplayName',
      render: (_: string, record: TableItemType) =>
        record.accountGuid &&
        record.accountDisplayName &&
        renderDataTableAccountLink({
          accountGuid: record.accountGuid,
          displayName: record.accountDisplayName,
          maintenancePlan: record.accountMaintenancePlan,
        }),
      ellipsis: {
        showTitle: true,
      },
      width: 320,
    },
    {
      title: 'Payment',
      dataIndex: 'paymentRecordGuid',
      key: 'paymentRecordGuid',
      render: (_: string, record: TableItemType) =>
        record.paymentRecordGuid && (
          <Link to={CalculatePaths.paymentDetails(record)}>View</Link>
        ),
    },
    {
      title: 'Refund',
      dataIndex: 'paymentRefundGuid',
      key: 'paymentRefundGuid',
      render: (_: string, record: TableItemType) =>
        record.paymentRefundGuid ? (
          <Link to={CalculatePaths.paymentDetails(record)}>View</Link>
        ) : (
          <EmDash />
        ),
    },
    invoiceLinkColumn('invoiceGuid', 'invoiceDisplayId'),
    {
      title: 'Item',
      dataIndex: 'paymentItemGuid',
      key: 'paymentItemGuid',
      render: (_: string, record: TableItemType) => {
        if (!record.paymentItemGuid) return null

        if (record.paymentItemType === 'Job') {
          return (
            <Link
              to={CalculatePaths.jobDetails({
                jobGuid: record.paymentItemGuid,
              })}
            >
              {record.paymentItemType}
            </Link>
          )
        }
        if (record.paymentItemType === 'Maintenance Plan') {
          return (
            <Link
              to={CalculatePaths.maintenancePlanDetails({
                maintenancePlanGuid: record.paymentItemGuid,
              })}
            >
              {record.paymentItemType}
            </Link>
          )
        }
      },
    },
    {
      title: 'Description',
      dataIndex: 'itemDescription',
      key: 'itemDescription',
    },
  )

  if (showQuickbooksOnlineColumn) {
    columns.push({
      title: 'QBO',
      dataIndex: 'qboLink',
      key: 'qboLink',
      onCell: () => ({
        className: 'p-1',
      }),
      render: (_: string, record: TableItemType) =>
        record.qboLink && (
          <QuickbooksViewLinkButton
            onClick={() => window.open(record.qboLink, '_blank')}
          />
        ),
    })
  }

  return columns
}

const getPaymentItemType = (item: PayoutItemEnriched) => {
  if (item.jobGuid) return 'Job'
  if (item.maintenancePlanGuid) return 'Maintenance Plan'
  return undefined
}

const getPaymentIemGuid = (item: PayoutItemEnriched) => {
  if (item.jobGuid) return item.jobGuid
  if (item.maintenancePlanGuid) return item.maintenancePlanGuid
  return undefined
}

type PayoutDetailsItemsTableProps = {
  payout: PayoutEnriched
}

export const PayoutDetailsItemsTable = m<PayoutDetailsItemsTableProps>(
  ({ payout }) => {
    const tzId = useExpectedCompanyTimeZoneId()
    const isQuickbooksOnlineEnabled = useQuickbooksOnlineEnabled()

    const downloadAsCsv = useCallback(() => {
      const csv = convertPayoutDetailsToCsvRow(tzId, payout)
      const payoutDate = BzDateTime.fromIsoString(
        payout.paidAt,
        tzId,
      ).toLocalDateString()
      downloadDataAsCsv(csv, `payout-details-${payoutDate}.csv`)
    }, [payout, tzId])

    const tableItems = useMemo(() => {
      const byPayoutItemGuid: Record<string, TablePayoutItem> = {}
      payout.items
        .filter(i => !i.sourcePayoutItemGuid)
        .forEach(item => {
          byPayoutItemGuid[item.payoutItemGuid] = {
            ...item,
            payoutItemDate: item.occurredAt,
            paymentItemType: getPaymentItemType(item),
            paymentItemGuid: getPaymentIemGuid(item),
            feesAmountUsc: 0,
            netAmountUsc: item.itemAmountUsc,
          }
        })

      payout.items
        .filter(i => !!i.sourcePayoutItemGuid)
        .forEach(item => {
          const srcItemGuid = item.sourcePayoutItemGuid ?? ''
          const matchingItem = byPayoutItemGuid[srcItemGuid]
          if (matchingItem) {
            matchingItem.feesAmountUsc += item.itemAmountUsc
            matchingItem.netAmountUsc += item.itemAmountUsc
          } else {
            byPayoutItemGuid[item.payoutItemGuid] = {
              ...item,
              payoutItemDate: item.occurredAt,
              paymentItemType: getPaymentItemType(item),
              paymentItemGuid: getPaymentIemGuid(item),
              feesAmountUsc: item.itemAmountUsc,
              netAmountUsc: 0,
            }
          }
        })

      return Object.values(byPayoutItemGuid)
    }, [payout.items])

    return (
      <TagColumn
        title="Items"
        titleCssClass="grey9"
        icon={faLineColumns}
        headerCssClass={'bg-gradient-to-r to-[#F8F8F8] from-[#F8F8F8]'}
        extra={
          <FaIconButton
            icon={DownloadIcon}
            onClick={downloadAsCsv}
            tooltip="Download - CSV"
          />
        }
      >
        <Table
          rowKey="payoutItemGuid"
          dataSource={tableItems}
          scroll={{ x: true }}
          columns={tableColumns(
            UTC_TIME_ZONE,
            payout.items.some(item => !!item.sourcePayoutItemGuid),
            isQuickbooksOnlineEnabled,
          )}
          pagination={{ pageSize: 100 }}
          size="small"
        />
      </TagColumn>
    )
  },
)
