import { Col, Row } from 'antd'
import React, { useState } from 'react'
import ThinDivider from '../../../elements/ThinDivider'
import { Page } from '../../Page/Page'
import { AccountingCartItemTypeAccountsConfigPanel } from '../AccountingCartItemTypeAccountsConfigPanel'
import AccountingOptionCheckboxes from '../AccountingOptionCheckboxes'
import { AccountingPayoutAccountsConfigPanel } from '../AccountingPayoutAccountsConfigPanel'
import {
  QuickbooksAuthorizeButton,
  useQuickbooksAuthorizeButtonProps,
} from './QuickbooksAuthorizeButton'
import { QuickbooksReauthNeededNonActionBanner } from './QuickbooksReauthNeededBanner'

export const QuickbooksConfigPage = React.memo(() => {
  const qboAuthorizeButtonProps = useQuickbooksAuthorizeButtonProps()

  const [reauthInitiated, setIsReauthInitiated] = useState(false)

  return (
    <Page requiresCompanyUser={true} className="p-0">
      <div className="card overflow-y-scroll">
        <Col>
          <Row className="mb-2">
            <img
              alt="Sync with Quickbooks"
              src="https://d3j5nzmmhjc8v6.cloudfront.net/quickbooks_logo.png"
              className="m-1 h-6 w-6"
            />
            <h2 className="card-title-lg ml1 semibold_20_28 grey9">
              Quickbooks Online
            </h2>
          </Row>
          {!reauthInitiated && <QuickbooksReauthNeededNonActionBanner />}
          <ThinDivider />
          <QuickbooksAuthorizeButton
            {...qboAuthorizeButtonProps}
            onClick={() => setIsReauthInitiated(true)}
          />
          {qboAuthorizeButtonProps.isAuthorized && (
            <>
              <ThinDivider />
              <div className="pb-2 text-lg font-semibold">
                Auto Sync Options
              </div>
              <AccountingOptionCheckboxes />
              <ThinDivider />
              <AccountingCartItemTypeAccountsConfigPanel />
              <ThinDivider />
              <AccountingPayoutAccountsConfigPanel />
            </>
          )}
        </Col>
      </div>
    </Page>
  )
})
