import {
  AppointmentChecklistInstance,
  BzDateFns,
  bzExpect,
  InferredAppointmentStatus,
  isNullish,
  IsoDateString,
  TimeZoneId,
} from '@breezy/shared'
import { faEllipsis } from '@fortawesome/pro-regular-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Dropdown, MenuProps, Typography } from 'antd'
import { useCallback, useMemo } from 'react'
import { LabeledItemGrid } from 'src/adam-components/LabeledItemGrid'
import { SectionedCard } from 'src/adam-components/SectionedCard/SectionedCard'
import { DateTimeWindow } from 'src/elements/DateTimeWindow/DateTimeWindow'
import { EmDash } from 'src/elements/EmDash/EmDash'
import { Link } from 'src/elements/Link/Link'
import { AppointmentStatusTag } from 'src/pages/ScheduleV2Page/AppointmentStatusTag'
import { useIsTechApp } from 'src/providers/AppContextWrapper'

export type VisitCardVisit = {
  appointment: {
    appointmentGuid: string
    appointmentType: string
    appointmentWindowStart: IsoDateString
    appointmentWindowEnd: IsoDateString
    appointmentStatus: InferredAppointmentStatus
  }
  job: {
    jobGuid?: string
    jobTypeName: string
    displayId?: string
    assignedTech?: {
      name: string
      assignmentStart: IsoDateString
      assignmentEnd: IsoDateString
    }
  }
  location: {
    locationGuid?: string
    address: {
      line1: string
    }
  }
  description?: string
  // TODO: Refactor so we only take in the minimal amount of data
  checklists?: AppointmentChecklistInstance[]
}

export interface VisitCardProps {
  visit: VisitCardVisit
  timezoneId: TimeZoneId
  editable?: boolean
  onEdit?: (input: { appointmentGuid: string }) => void
  onCancelVisit?: (input: { appointmentGuid: string }) => void
  onViewDescription?: (description: string) => void
  onViewChecklist?: (checklist: AppointmentChecklistInstance) => void
}

export const VisitCard = ({ editable = true, ...props }: VisitCardProps) => {
  const { visit, timezoneId } = props

  const isTechnicianApp = useIsTechApp()

  const dateAsStr = BzDateFns.parseISO(
    visit.appointment.appointmentWindowStart,
    timezoneId,
  )
    .toDateString()
    .split(' ')
    .splice(1)
    .join(' ')

  const dropdownItems: NonNullable<MenuProps['items']> = useMemo(() => {
    const items: NonNullable<MenuProps['items']> = []

    if (
      editable &&
      props.onEdit &&
      visit.appointment.appointmentStatus !== 'CANCELED'
    ) {
      items.push({
        label: 'Edit',
        key: `${visit.appointment.appointmentGuid}-edit`,
      })
    }

    if (
      editable &&
      props.onCancelVisit &&
      visit.appointment.appointmentStatus !== 'CANCELED'
    ) {
      items.push({
        label: 'Cancel Visit',
        key: `${visit.appointment.appointmentGuid}-cancel-visit`,
      })
    }

    return items
  }, [
    editable,
    props.onCancelVisit,
    props.onEdit,
    visit.appointment.appointmentGuid,
    visit.appointment.appointmentStatus,
  ])

  const onDropdownClick: NonNullable<MenuProps['onClick']> = useCallback(
    ({ key }) => {
      switch (key) {
        case `${visit.appointment.appointmentGuid}-edit`:
          props.onEdit?.({ appointmentGuid: visit.appointment.appointmentGuid })
          break
        case `${visit.appointment.appointmentGuid}-cancel-visit`:
          props.onCancelVisit?.({
            appointmentGuid: visit.appointment.appointmentGuid,
          })
          break
        default:
          break
      }
    },
    [props, visit.appointment.appointmentGuid],
  )

  return (
    <SectionedCard
      dashed
      small
      sections={[
        {
          verticalPaddingClassName: 'p-3',
          content: (
            <div className="flex flex-row items-center justify-between">
              {isTechnicianApp ? (
                <Link
                  to={`/appointments/${visit.appointment.appointmentGuid}`}
                  bold
                  className="text-sm"
                >
                  {visit.appointment.appointmentType}
                </Link>
              ) : (
                <span className="font-semibold">
                  {visit.appointment.appointmentType}
                </span>
              )}

              <div className="flex flex-row items-center gap-2">
                <AppointmentStatusTag
                  status={visit.appointment.appointmentStatus}
                />

                {(!isNullish(props.onEdit) ||
                  !isNullish(props.onCancelVisit)) &&
                  visit.appointment.appointmentStatus !== 'CANCELED' && (
                    <Dropdown
                      menu={{ items: dropdownItems, onClick: onDropdownClick }}
                      trigger={['click']}
                    >
                      <FontAwesomeIcon
                        icon={faEllipsis}
                        className="cursor-pointer"
                      />
                    </Dropdown>
                  )}
              </div>
            </div>
          ),
        },
        {
          content: (
            <LabeledItemGrid
              items={[
                'Job',
                visit.job.jobGuid ? (
                  <Link
                    to={`/jobs/${visit.job.jobGuid}`}
                    className="text-bz-primary no-underline"
                  >
                    {`${visit.job.jobTypeName} ${
                      visit.job.displayId ? '#' + visit.job.displayId : ' '
                    }`.trim()}
                  </Link>
                ) : (
                  `${visit.job.jobTypeName} ${
                    visit.job.displayId ? '#' + visit.job.displayId : ' '
                  }`.trim()
                ),
                'Location',
                visit.location.locationGuid ? (
                  <Link
                    to={`/locations/${visit.location.locationGuid}`}
                    className="text-bz-primary no-underline"
                  >
                    {visit.location.address.line1}
                  </Link>
                ) : (
                  `${visit.location.address.line1}`
                ),
                'Date',
                dateAsStr,
                'Arrival Window',
                <DateTimeWindow
                  includeDate={false}
                  overrideClassName="text-sm"
                  startWindowIsoWithOffsetTimestamp={
                    visit.appointment.appointmentWindowStart
                  }
                  endWindowIsoWithOffsetTimestamp={
                    visit.appointment.appointmentWindowEnd
                  }
                />,
                'Assigned Tech',
                visit.job.assignedTech ? (
                  `${visit.job.assignedTech.name}`
                ) : (
                  <EmDash />
                ),
                'Assigned Time',
                visit.job.assignedTech ? (
                  <DateTimeWindow
                    includeDate={false}
                    overrideClassName="text-sm"
                    startWindowIsoWithOffsetTimestamp={
                      visit.job.assignedTech.assignmentStart
                    }
                    endWindowIsoWithOffsetTimestamp={
                      visit.job.assignedTech.assignmentEnd
                    }
                  />
                ) : (
                  <EmDash />
                ),
                'Description',
                !isNullish(visit.description) &&
                !isNullish(props.onViewDescription) ? (
                  <Typography.Link
                    onClick={event => {
                      event.stopPropagation()
                      props.onViewDescription?.(bzExpect(visit.description))
                    }}
                  >
                    View Description
                  </Typography.Link>
                ) : (
                  <EmDash />
                ),
                ...(!isNullish(visit.checklists) &&
                !isNullish(props.onViewChecklist) &&
                visit.checklists.length > 0
                  ? [
                      'Checklists',
                      <Typography.Link
                        onClick={event => {
                          event.stopPropagation()
                          props.onViewChecklist?.(bzExpect(visit.checklists)[0])
                        }}
                      >
                        View Checklist
                      </Typography.Link>,
                    ]
                  : []),
              ]}
            />
          ),
        },
      ]}
    />
  )
}
