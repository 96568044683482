import React from 'react'

type OnlineBookingSectionHeaderProps = {
  title: string
  description?: string
}

export const OnlineBookingSectionHeader =
  React.memo<OnlineBookingSectionHeaderProps>(({ title, description }) => (
    <div className="flex flex-col gap-[2px]">
      <div className="text-base font-semibold">{title}</div>
      {description && (
        <div className="text-bz-text-secondary">{description}</div>
      )}
    </div>
  ))
