import {
  BzDateFns,
  bzExpect,
  getDefaultTaxRate,
  isNullish,
  nextGuid,
} from '@breezy/shared'
import React, { useEffect, useMemo, useState } from 'react'
import { useParams } from 'react-router-dom'
import { useSearchParam } from 'react-use'
import { useQuery } from 'urql'
import { OnsitePageContainer } from '../../adam-components/OnsitePage/OnsitePageContainer'
import { OnsitePageLoader } from '../../adam-components/OnsitePage/OnsitePageLoader'
import { GET_PRICEBOOK_TAX_RATES } from '../../components/Pricebook/PricebookPickers.gql'
import { trpc } from '../../hooks/trpc'
import {
  getInvoiceCartItemsFromTemplate,
  useInvoiceTemplates,
} from '../../hooks/useInvoiceTemplates'
import { useMaintenancePlanJobOnsiteDiscount } from '../../hooks/useMaintenancePlanJobDiscount'
import { useExpectedCompanyTimeZoneId } from '../../providers/PrincipalUser'
import { InvoiceEditView } from './InvoiceEditView'
import {
  InvoiceBillingAddress,
  InvoiceContext,
  InvoiceEditDataContext,
  InvoiceInfoFormData,
  useRelevantInvoiceData,
  useRelevantInvoiceEditData,
} from './invoiceUtils'

export const InvoiceCreationPage = React.memo(() => {
  const accountGuid = bzExpect(
    useParams().accountGuid,
    'accountGuid',
    'AccountGuid is required',
  )

  const jobGuid = bzExpect(useParams().jobGuid, 'jobGuid', 'Job is required')
  const jobAppointmentGuid = useSearchParam('appt')
  const invoiceTemplateGuid = useSearchParam('template')
  const { templates } = useInvoiceTemplates()

  const invoiceTemplate = useMemo(() => {
    return templates.find(t => t.invoiceTemplateGuid === invoiceTemplateGuid)
  }, [templates, invoiceTemplateGuid])

  const invoiceGuid = useMemo(() => nextGuid(), [])

  const generateDisplayIdMutation =
    trpc.invoice['invoicing:generate-display-id'].useMutation()

  const [displayId, setDisplayId] = useState<number>()

  useEffect(() => {
    if (!displayId && !generateDisplayIdMutation.isLoading) {
      ;(async () => {
        const displayId = await generateDisplayIdMutation.mutateAsync({
          entityType: 'INVOICE',
        })
        setDisplayId(displayId)
      })()
    }
  }, [displayId, generateDisplayIdMutation])

  const {
    companyDefaultTaxRateGuid,
    hasLoaded: relevantDataHasLoaded,
    ...relevantData
  } = useRelevantInvoiceData()

  const {
    data: relevantInvoiceEditData,
    hasLoaded: relevantEditDataHasLoaded,
  } = useRelevantInvoiceEditData(accountGuid, jobGuid)

  const [{ data: taxRates, fetching: fetchingTaxRates }] = useQuery({
    query: GET_PRICEBOOK_TAX_RATES,
  })

  const defaultTaxRate = useMemo(
    () =>
      getDefaultTaxRate(
        taxRates?.pricebookTaxRates ?? [],
        relevantInvoiceEditData?.account.accountType,
        relevantInvoiceEditData?.job?.location.address.zipCode ??
          relevantInvoiceEditData?.account.mailingAddress?.zipCode,
        companyDefaultTaxRateGuid,
      ),
    [
      companyDefaultTaxRateGuid,
      relevantInvoiceEditData?.account.accountType,
      relevantInvoiceEditData?.account.mailingAddress?.zipCode,
      relevantInvoiceEditData?.job?.location.address.zipCode,
      taxRates?.pricebookTaxRates,
    ],
  )

  const tzId = useExpectedCompanyTimeZoneId()

  const mpOnsiteDiscounts = useMaintenancePlanJobOnsiteDiscount({ jobGuid })

  const defaultInfoFormData = useMemo<InvoiceInfoFormData | undefined>(() => {
    if (!relevantInvoiceEditData) {
      return undefined
    }

    const { account, job: maybeJob } = relevantInvoiceEditData

    const job = bzExpect(maybeJob, 'job', 'Job is required')

    let billingAddress: InvoiceBillingAddress = {
      type: 'same_as_service',
    }

    if (account.mailingAddress?.addressGuid) {
      billingAddress = {
        type: 'account_billing_address',
        accountBillingAddressGuid: account.mailingAddress.addressGuid,
      }
    }

    return {
      serviceCompletionDate: job.workCompletedAt
        ? BzDateFns.formatLocalDate(
            BzDateFns.parseISO(job.workCompletedAt, tzId),
          )
        : BzDateFns.getTodayLocalDate(tzId),
      invoiceTerm: relevantData.defaultInvoiceTerm,
      customerPurchaseOrderNumber: job.customerPurchaseOrderNumber ?? '',
      serviceLocationGuid: job.locationGuid,
      billingContactGuid: job.pointOfContact.contactGuid,
      billingAddress,
    }
  }, [relevantData.defaultInvoiceTerm, relevantInvoiceEditData, tzId])

  const isLoading =
    !relevantDataHasLoaded ||
    !relevantEditDataHasLoaded ||
    isNullish(displayId) ||
    fetchingTaxRates

  if (isLoading) {
    return <OnsitePageLoader />
  }

  if (!relevantInvoiceEditData || !defaultInfoFormData) {
    return (
      <OnsitePageContainer title="Error">
        <div className="text-center">Account not found</div>
      </OnsitePageContainer>
    )
  }

  return (
    <InvoiceContext.Provider
      value={{
        invoiceGuid,
        accountGuid,
        jobGuid,
        jobAppointmentGuid: jobAppointmentGuid ?? undefined,
        displayId,
        tzId,
        status: 'DRAFT',
        ...relevantData,
      }}
    >
      <InvoiceEditDataContext.Provider value={relevantInvoiceEditData}>
        <InvoiceEditView
          isNew
          defaultTaxRate={defaultTaxRate}
          defaultInfoFormData={defaultInfoFormData}
          defaultDiscounts={mpOnsiteDiscounts}
          defaultLineItems={
            invoiceTemplate
              ? getInvoiceCartItemsFromTemplate(invoiceTemplate)
              : undefined
          }
          createdFromTemplate={!!invoiceTemplate}
        />
      </InvoiceEditDataContext.Provider>
    </InvoiceContext.Provider>
  )
})

export default InvoiceCreationPage
