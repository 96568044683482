import { BzDateTime, PaymentMethod } from '@breezy/shared'
import { useFormContext } from 'react-hook-form'
import { BzSwitch } from '../../../elements/BzSwitch/BzSwitch'
import { Card } from '../../../elements/Card/Card'
import ThinDivider from '../../../elements/ThinDivider'
import { useExpectedCompanyTimeZoneId } from '../../../providers/PrincipalUser'
import { BillingInfoSectionHeader } from '../ConfigureMaintenancePlanPanel/ConfigureBillingInfoSection'
import { ImportInfoSectionHeader } from '../ConfigureMaintenancePlanPanel/ConfigureImportInfoSection'
import { SupportedPaymentTypesSection } from '../ConfigureMaintenancePlanPanel/SupportedPaymentTypesSection'
import { toPlanBillingLabel } from '../MaintenancePlanV3Utils'
import { ConfigureMaintenancePlanFormData } from '../configureMaintenancePlanFormSchema'

const KeyValueStack = ({ label, value }: { label: string; value: string }) => {
  return (
    <div className="flex flex-col gap-y-1">
      <label className="text-[14px] font-semibold leading-[22px] text-[#262626]">
        {label}
      </label>
      <label className="text-[14px] leading-[22px] text-[#262626]">
        {value}
      </label>
    </div>
  )
}

export interface ReadOnlyBillingInfoSectionProps {
  hiddenPaymentMethods?: PaymentMethod[]
}

export const ReadOnlyBillingInfoSection = (
  props: ReadOnlyBillingInfoSectionProps,
) => {
  const tzId = useExpectedCompanyTimeZoneId()
  const { watch } = useFormContext<ConfigureMaintenancePlanFormData>()
  const billingPaymentInterval = watch('billingPaymentInterval')
  const billingAnchorDate = watch('billingAnchorDate')
  const activationDate = watch('activationDate')
  const isAutoRenewing = watch('isAutoRenewing')
  const isFreePlan = watch('isFreePlan')
  const isImportedPlan = watch('isImportedPlan')

  return (
    <div className="grid grid-cols-1 gap-6 md:grid-cols-2">
      <div className="flex flex-col gap-y-6">
        {isImportedPlan ? (
          <ImportInfoSectionHeader />
        ) : (
          <BillingInfoSectionHeader />
        )}

        {!isImportedPlan && (
          <KeyValueStack
            label="Billing Frequency"
            value={toPlanBillingLabel(
              isFreePlan,
              isAutoRenewing,
              billingPaymentInterval,
            )}
          />
        )}
        {!isFreePlan && !isImportedPlan && billingAnchorDate && (
          <KeyValueStack
            label="Billing Start Date"
            value={BzDateTime.fromDateString(
              billingAnchorDate,
              tzId,
            ).toHumanFriendlyMonthDayYear()}
          />
        )}
        {!isImportedPlan && (
          <KeyValueStack
            label="Plan Activation Date"
            value={BzDateTime.fromDateString(
              activationDate,
              tzId,
            ).toHumanFriendlyMonthDayYear()}
          />
        )}
        <KeyValueStack
          label={isAutoRenewing ? 'Plan Renewal Date' : 'Plan Expiration Date'}
          value={BzDateTime.fromDateString(activationDate, tzId)
            .plusYears(1)
            .toHumanFriendlyMonthDayYear()}
        />
        <KeyValueStack
          label="Auto-renews"
          value={isAutoRenewing ? 'Yes' : 'No'}
        />
      </div>

      {!isImportedPlan && (
        <div>
          <Card
            className="bg-[#fafafa] shadow-none"
            noPadding
            bodyClassName="p-3"
          >
            <SupportedPaymentTypesSection
              isFreePlan={isFreePlan}
              isAutoRenewing={isAutoRenewing}
              isImportedPlan={isImportedPlan}
              hiddenPaymentMethods={props.hiddenPaymentMethods}
            />
            <ThinDivider
              dividerStyle="solid"
              widthPx={1}
              styleOverrides={{ marginTop: '12px', marginBottom: '12px' }}
            />
            <div className="flex flex-row justify-between gap-x-3">
              <div className="flex flex-col gap-y-[2px]">
                <div className="text-md font-semibold">Create as Free Plan</div>
                <div className="letter-spacing-[-0.14px] text-[14px] leading-[22px] text-[#898989]">
                  This will disable and reset billing settings to their default
                  values for non-billed plans.
                </div>
              </div>
              <BzSwitch
                disabled
                checked={isFreePlan}
                data-testid="create-as-free-plan-switch"
              />
            </div>
          </Card>
        </div>
      )}
    </div>
  )
}
