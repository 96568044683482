import { z } from 'zod'

export const DAYS_OF_THE_WEEK = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'] as const

export type DayOfTheWeek = (typeof DAYS_OF_THE_WEEK)[number]

export const DayOfTheWeekSchema = z.enum(DAYS_OF_THE_WEEK)

export const DayOfTheWeekAbbreviation = {
  Sunday: 'Sun',
  Monday: 'Mon',
  Tuesday: 'Tue',
  Wednesday: 'Wed',
  Thursday: 'Thu',
  Friday: 'Fri',
  Saturday: 'Sat',
} as const
