import { useMutation, useQuery } from 'urql'
import BzCheckBox from '../../elements/BzCheckBox/BzCheckBox'
import {
  READ_ACCOUNTING_COMPANY_CONFIG_QUERY,
  UPSERT_ACCOUNTING_COMPANY_CONFIG_AUTO_SYNC_INVOICE_ON_FULLY_PAID_MUTATION,
  UPSERT_ACCOUNTING_COMPANY_CONFIG_AUTO_SYNC_INVOICE_ON_ISSUED_MUTATION,
  UPSERT_ACCOUNTING_COMPANY_CONFIG_AUTO_SYNC_INVOICE_ON_PAYMENT_MUTATION,
  UPSERT_ACCOUNTING_COMPANY_CONFIG_AUTO_SYNC_INVOICE_ON_VOIDED_MUTATION,
} from '../../gql/CompanyAccountingConfig.gql'
import { useAccountingIntegrationType } from '../../providers/CompanyFinancialConfigWrapper'
import { useExpectedCompanyGuid } from '../../providers/PrincipalUser'
import { m } from '../../utils/react-utils'
import GqlQueryLoader from '../GqlQueryLoader/GqlQueryLoader'

const labelIssued = 'Auto Sync Invoices when Issued'
const labelFullyPaid = 'Auto Sync Invoices when Fully Paid'
const labelOnPayment = 'Auto Sync Invoices on Payment'
const labelOnVoided = 'Auto Sync Invoices on Voided'

const ConfigLoader = m(() => {
  const companyGuid = useExpectedCompanyGuid()
  const accountingIntegrationType = useAccountingIntegrationType()
  const query = useQuery({
    query: READ_ACCOUNTING_COMPANY_CONFIG_QUERY,
    variables: { companyGuid },
  })

  const [, executeMutationIssued] = useMutation(
    UPSERT_ACCOUNTING_COMPANY_CONFIG_AUTO_SYNC_INVOICE_ON_ISSUED_MUTATION,
  )
  const [, executeMutationFullyPaid] = useMutation(
    UPSERT_ACCOUNTING_COMPANY_CONFIG_AUTO_SYNC_INVOICE_ON_FULLY_PAID_MUTATION,
  )
  const [, executeMutationOnPayment] = useMutation(
    UPSERT_ACCOUNTING_COMPANY_CONFIG_AUTO_SYNC_INVOICE_ON_PAYMENT_MUTATION,
  )
  const [, executeMutationVoided] = useMutation(
    UPSERT_ACCOUNTING_COMPANY_CONFIG_AUTO_SYNC_INVOICE_ON_VOIDED_MUTATION,
  )

  return (
    <GqlQueryLoader
      query={query}
      render={data => {
        if (!data.accCompanyConfigByPk) return <></>

        return (
          <>
            <BzCheckBox
              label={labelIssued}
              value={
                data.accCompanyConfigByPk?.autosyncInvoiceOnIssued ?? false
              }
              onChange={async e => {
                await executeMutationIssued({
                  object: {
                    companyGuid,
                    accountingIntegrationType,
                    autosyncInvoiceOnIssued: e,
                    updatedAt: new Date().toISOString(),
                  },
                })
              }}
            />
            <BzCheckBox
              label={labelFullyPaid}
              value={
                data.accCompanyConfigByPk?.autosyncInvoiceOnFullyPaid ?? false
              }
              onChange={async e => {
                await executeMutationFullyPaid({
                  object: {
                    companyGuid,
                    accountingIntegrationType,
                    autosyncInvoiceOnFullyPaid: e,
                    updatedAt: new Date().toISOString(),
                  },
                })
              }}
            />
            <BzCheckBox
              label={labelOnPayment}
              value={
                data.accCompanyConfigByPk?.autosyncInvoiceOnPayment ?? false
              }
              onChange={async e => {
                await executeMutationOnPayment({
                  object: {
                    companyGuid,
                    accountingIntegrationType,
                    autosyncInvoiceOnPayment: e,
                    updatedAt: new Date().toISOString(),
                  },
                })
              }}
            />
            <BzCheckBox
              label={labelOnVoided}
              value={
                data.accCompanyConfigByPk?.autosyncInvoiceOnVoided ?? false
              }
              onChange={async e => {
                await executeMutationVoided({
                  object: {
                    companyGuid,
                    accountingIntegrationType,
                    autosyncInvoiceOnVoided: e,
                    updatedAt: new Date().toISOString(),
                  },
                })
              }}
            />
          </>
        )
      }}
    />
  )
})

export default ConfigLoader
