import { faCircleInfo } from '@fortawesome/pro-regular-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Button, Tooltip } from 'antd'
import { useCallback } from 'react'
import { trpc } from '../../../hooks/trpc'
import { useMessage } from '../../../utils/antd-utils'

export const AdminTriggerTestWorkflow = () => {
  const message = useMessage()
  const triggerTestWorkflow =
    trpc.devTools[
      'devtools:customer-data-onboarding:test-workflow'
    ].useMutation()

  const onClick = useCallback(() => {
    triggerTestWorkflow.mutate(undefined, {
      onSuccess: () => {
        message.success('Test workflow triggered successfully')
      },
      onError: error => {
        message.error(`Failed to trigger test workflow: ${error.message}`)
      },
    })
  }, [triggerTestWorkflow, message])

  return (
    <div className="flex flex-col space-y-3">
      <div className="flex items-center space-x-2">
        <b>Trigger Test Hatchet Workflow</b>
        <Tooltip title="Use this to trigger the test Hatchet workflow. View at https://hatchet.getbreezyapp.com">
          <FontAwesomeIcon icon={faCircleInfo} />
        </Tooltip>
      </div>
      <Button
        className="max-w-[100px]"
        loading={triggerTestWorkflow.isLoading}
        onClick={onClick}
      >
        Trigger
      </Button>
    </div>
  )
}
