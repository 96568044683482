import z from 'zod'
import { AsyncFn, phoneUtils } from '../../common'

import { guidSchema } from '../../contracts/_common'
import { bzOptional, Guid } from '../common-schemas'

export const telephoneNumberSchema = z.string().refine(
  val => phoneUtils.isPossibleNumber(val, 'US'),
  val => ({ message: 'Invalid Phone Number', params: { phoneNumber: val } }),
)

export const optionalTelephoneNumberSchema = bzOptional(
  z.string().refine(
    val => !val || phoneUtils.isPossibleNumber(val, 'US'),
    val => ({ message: 'Invalid Phone Number', params: { phoneNumber: val } }),
  ),
)

export type PhoneNumberValue = z.infer<typeof telephoneNumberSchema>

export const PHONE_NUMBER_TYPES = ['MOBILE', 'HOME', 'WORK'] as const
export type PhoneNumberType = (typeof PHONE_NUMBER_TYPES)[number]

export const PhoneNumberTypeSchema = z.enum(PHONE_NUMBER_TYPES)

const PRETTY_PHONE_NUMBER_TYPE_MAP: Record<PhoneNumberType, string> = {
  MOBILE: 'Mobile',
  HOME: 'Home',
  WORK: 'Work',
}

export const prettifyPhoneNumberType = (type: PhoneNumberType): string => PRETTY_PHONE_NUMBER_TYPE_MAP[type]

export const PhoneSchemaV2 = z.object({
  number: telephoneNumberSchema,
  type: PhoneNumberTypeSchema,
})

export type Phone = z.infer<typeof PhoneSchemaV2>

export const toPhone = (p: PhoneNumber): Phone => ({
  ...p,
  number: p.phoneNumber,
})

// When you have a phone number with "type: string" instead of "type: PhoneNumberType" (if it comes out of the DB, for
// instance) then you can use this to cast it easily.
export const castPhoneNumberType = (phoneNumber: Omit<PhoneNumber, 'type'> & { type: string }): PhoneNumber => ({
  ...phoneNumber,
  type: phoneNumber.type as PhoneNumberType,
})

export const PhoneNumberSchema = z.object({
  phoneNumberGuid: guidSchema,
  companyGuid: guidSchema,
  phoneNumber: telephoneNumberSchema,
  type: PhoneNumberTypeSchema,
  unsubscribed: bzOptional(z.boolean()),
})

export type PhoneNumberGuid = Guid

export type PhoneNumber = z.infer<typeof PhoneNumberSchema>

export type IPhoneUpserter = AsyncFn<PhoneNumber>
export type IPhoneDeleter = AsyncFn<PhoneNumber>

export const PHONE_NUMBER_UNSUBSCRIBED_ERROR_MESSAGE =
  'The phone number opted out of messages. Please choose a different contact method.'
