import { z } from 'zod'
import {
  AsyncFn,
  cloneDeep,
  DateTimeFormatter,
  LocalDate,
  LocalDateTime,
  LocalTime,
  LocalTimeString,
  sortBy,
  ZonedDateTime,
  ZoneId,
} from '../../common'
import { guidSchema, makeLocalTimeString } from '../../contracts/_common'
import { BzDuration } from '../DateTime/BzDuration'
import { defaultDurationForJobClass, JobClass } from '../Job'
import { WorkingHours } from '../Scheduling/WorkingHours'

export type LocalTimeArrivalWindowJSON = {
  arrivalWindowStart: LocalTimeString
  arrivalWindowEnd: LocalTimeString
}

type LocalTimeArrivalWindow = {
  arrivalWindowStart: LocalTime
  arrivalWindowEnd: LocalTime
}

type JobClassAppointmentArrivalWindows = {
  companyTimezone: ZoneId
  arrivalWindows: Record<JobClass, LocalTimeArrivalWindow[]>
  workingHours: WorkingHours
}
export type JobClassAppointmentArrivalWindowJSON = {
  companyTimezone: string
  arrivalWindows: Record<JobClass, LocalTimeArrivalWindowJSON[]>
  workingHours: WorkingHours
}

type ArrivalWindow = {
  arrivalWindowStartZonedDateTime: ZonedDateTime
  arrivalWindowEndZonedDateTime: ZonedDateTime
}

export const AppointmentArrivalForCompanyQuerySchema = z.object({
  companyGuid: guidSchema,
})

export type AppointmentArrivalForCompanyQuery = z.infer<typeof AppointmentArrivalForCompanyQuerySchema>

export type IQueryBzCompanySchedulingConfig = AsyncFn<AppointmentArrivalForCompanyQuery, BzCompanySchedulingConfig[]>

export class BzCompanySchedulingConfig {
  static create(json: JobClassAppointmentArrivalWindowJSON) {
    return new BzCompanySchedulingConfig({
      companyTimezone: ZoneId.of(json.companyTimezone),
      arrivalWindows: Object.entries(json.arrivalWindows).reduce((current, [jobClass, windows]) => {
        return {
          ...current,
          [jobClass as JobClass]: windows.map(localTimeArrivalWindowJSON => ({
            arrivalWindowStart: LocalTime.parse(
              localTimeArrivalWindowJSON.arrivalWindowStart,
              DateTimeFormatter.ISO_LOCAL_TIME,
            ),
            arrivalWindowEnd: LocalTime.parse(
              localTimeArrivalWindowJSON.arrivalWindowEnd,
              DateTimeFormatter.ISO_LOCAL_TIME,
            ),
          })),
        }
      }, {} as Record<JobClass, LocalTimeArrivalWindow[]>),
      workingHours: json.workingHours,
    })
  }

  constructor(private readonly data: JobClassAppointmentArrivalWindows) {}

  toJobClassAppointmentArrivalWindows(): JobClassAppointmentArrivalWindowJSON {
    return {
      companyTimezone: this.data.companyTimezone.id(),
      arrivalWindows: Object.entries(this.data.arrivalWindows).reduce(
        (current, [jobClass, localTimeArrivalWindows]) => {
          return {
            ...current,
            [jobClass as JobClass]: localTimeArrivalWindows.map(w => {
              return {
                arrivalWindowStart: w.arrivalWindowStart.format(DateTimeFormatter.ISO_LOCAL_TIME),
                arrivalWindowEnd: w.arrivalWindowEnd.format(DateTimeFormatter.ISO_LOCAL_TIME),
              }
            }),
          }
        },
        {} as Record<JobClass, LocalTimeArrivalWindowJSON[]>,
      ),
      workingHours: this.data.workingHours,
    }
  }

  defaultDurationForJobClass(jobClass: JobClass): BzDuration {
    return defaultDurationForJobClass[jobClass]
  }

  getCompanyWorkingHourStart(): LocalTime {
    return LocalTime.of(this.data.workingHours.startHour)
  }

  getCompanyWorkingHourEnd(): LocalTime {
    return LocalTime.of(this.data.workingHours.endHour)
  }

  getQuantizedAppointmentStartTimes(): LocalTime[] {
    const start = this.getCompanyWorkingHourStart()
    const end = this.getCompanyWorkingHourEnd()

    const times: LocalTime[] = []
    let next = start

    while (next.isBefore(end)) {
      times.push(next)
      next = next.plusMinutes(15)
    }

    return times
  }

  getQuantizedAppointmentEndTimes(): LocalTime[] {
    const start = this.getCompanyWorkingHourStart()
    const end = this.getCompanyWorkingHourEnd()

    const times: LocalTime[] = []
    let next = start.plusMinutes(15)

    while (next.isBefore(end) || next.equals(end)) {
      times.push(next)
      next = next.plusMinutes(15)
    }

    return times
  }

  getQuantizedAppointmentStartTimesForDate(date: LocalDate): ZonedDateTime[] {
    return this.getQuantizedAppointmentStartTimes().map(localTime => {
      return ZonedDateTime.of(LocalDateTime.of(date, localTime), this.data.companyTimezone)
    })
  }

  getQuantizedAppointmentEndTimesForDate(date: LocalDate): ZonedDateTime[] {
    return this.getQuantizedAppointmentEndTimes().map(localTime => {
      return ZonedDateTime.of(LocalDateTime.of(date, localTime), this.data.companyTimezone)
    })
  }

  getArrivalWindows({
    jobClass,
    localDate,
    zoneIdOverride,
  }: {
    jobClass: JobClass
    localDate: LocalDate
    zoneIdOverride?: ZoneId
  }): ArrivalWindow[] {
    const arrivalWindowsForJobClass = this.data.arrivalWindows[jobClass] ?? []

    const windows = arrivalWindowsForJobClass.map(({ arrivalWindowStart, arrivalWindowEnd }) => {
      // These are local times, but they are inherently expressed at the timezone of company since when
      // when a company configures their windows they are expressing those windows relative to their company timezone
      let arrivalWindowStartZonedDateTime = ZonedDateTime.of(
        LocalDateTime.of(localDate, arrivalWindowStart),
        this.data.companyTimezone,
      )

      let arrivalWindowEndZonedDateTime = ZonedDateTime.of(
        LocalDateTime.of(localDate, arrivalWindowEnd),
        this.data.companyTimezone,
      )

      // Maybe useful if the timezone of the service-location or the end consumer viewing the time is in a different
      // timezone than that of the company
      if (zoneIdOverride) {
        arrivalWindowStartZonedDateTime = arrivalWindowStartZonedDateTime.withZoneSameInstant(zoneIdOverride)
        arrivalWindowEndZonedDateTime = arrivalWindowEndZonedDateTime.withZoneSameInstant(zoneIdOverride)
      }

      return {
        arrivalWindowStartZonedDateTime,
        arrivalWindowEndZonedDateTime,
      }
    })

    const sortedWindows = sortBy(windows, window => {
      return (
        window.arrivalWindowStartZonedDateTime.toLocalTime().hour() +
        window.arrivalWindowStartZonedDateTime.toLocalTime().minute() / 60
      )
    })

    return sortedWindows
  }
}

export const DEFAULT_COMPANY_ARRIVAL_WINDOWS = [
  {
    arrivalWindowStart: makeLocalTimeString('08:00:00'),
    arrivalWindowEnd: makeLocalTimeString('12:00:00'),
  },
  {
    arrivalWindowStart: makeLocalTimeString('13:00:00'),
    arrivalWindowEnd: makeLocalTimeString('17:00:00'),
  },
] satisfies LocalTimeArrivalWindowJSON[]

export const addJobClassToArrivalWindowMappings = (
  map: Record<JobClass, LocalTimeArrivalWindowJSON[]>,
  jobClass: JobClass,
  arrivalWindows: LocalTimeArrivalWindowJSON[],
): Record<JobClass, LocalTimeArrivalWindowJSON[]> => ({
  ...map,
  [jobClass]: cloneDeep(arrivalWindows),
})

export const DEFAULT_JOB_CLASS_TO_ARRIVAL_WINDOW_MAPPING = () =>
  Object.values(JobClass).reduce(
    (map, jobClass) => ({
      ...addJobClassToArrivalWindowMappings(map, jobClass, DEFAULT_COMPANY_ARRIVAL_WINDOWS),
    }),
    {} as Record<JobClass, LocalTimeArrivalWindowJSON[]>,
  )
