import {
  BzDateFns,
  copyToClipboard,
  IsoDateString,
  nextGuid,
  OnlineBookingServiceType,
  OnlineBookingServiceTypeDisplayNames,
  OnlineBookingServiceTypeMetadata,
  OnlineBookingType,
  OnlineBookingTypeDisplayNames,
} from '@breezy/shared'
import { faArrowUpRight } from '@fortawesome/pro-light-svg-icons'
import { faEdit, faShuffle } from '@fortawesome/pro-regular-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { zodResolver } from '@hookform/resolvers/zod'
import { Button, Form, Input, Skeleton } from 'antd'
import classNames from 'classnames'
import React, { useCallback, useMemo } from 'react'
import { useForm } from 'react-hook-form'
import { useMutation } from 'urql'
import { CloseConfirmModal } from '../../adam-components/OnsiteModal/useCloseConfirmModal'
import { useGoBack } from '../../adam-components/OnsitePage/onsitePageUtils'
import { CategorySection } from '../../components/Settings/SettingsBuilderComponents'
import { ColorPickerField } from '../../elements/Forms/ColorPickerField'
import { FormDescription } from '../../elements/Forms/FormDescription'
import { ReactHookFormItem } from '../../elements/Forms/ReactHookFormItem'
import { RichTextAreaField } from '../../elements/Forms/RichTextAreaField'
import { SelectField } from '../../elements/Forms/SelectField'
import { SwitchField } from '../../elements/Forms/SwitchField'
import { useDirtyingSetValue } from '../../elements/Forms/useDirtyingSetValue'
import { useReactHookFormSubmit } from '../../elements/Forms/useReactHookFormSubmit'
import ThinDivider from '../../elements/ThinDivider'
import useAppNav from '../../hooks/useAppNav'
import { BlockerFunction, useBlocker } from '../../providers/BlockerWrapper'
import {
  useExpectedCompanyGuid,
  useExpectedCompanyTimeZoneId,
} from '../../providers/PrincipalUser'
import { useMessage } from '../../utils/antd-utils'
import { OnlineBookingSectionHeader } from './OnlineBookingSectionHeader'
import { ONLINE_BOOKING_COMPANY_CONFIG_UPSERT } from './OnlineBookingSettingsPage.gql'
import {
  OnlineBookingCompanyConfigFormData,
  OnlineBookingCompanyConfigFormSchema,
  UniqueZipCodes,
  useMinifiedBookingUrl,
} from './utils'
import { WithOnlineBookingCompanyConfig } from './WithOnlineBookingCompanyConfig'

type ServiceTypeItemSectionProps = React.PropsWithChildren<{
  className?: string
  label?: string
}>

const ServiceTypeItemSection = React.memo<ServiceTypeItemSectionProps>(
  ({ className, label, children }) => (
    <div className={classNames('flex flex-1 flex-col gap-2', className)}>
      <div className="text-base font-semibold">{children}</div>
      {label && <div className="text-bz-gray-700">{label}</div>}
    </div>
  ),
)

type ServiceTypeItemProps = {
  serviceTypeConfigGuid: string
  type: OnlineBookingType
  serviceType: OnlineBookingServiceType
  updatedAt: IsoDateString
}

const ServiceTypeItem = React.memo<ServiceTypeItemProps>(
  ({ serviceTypeConfigGuid, serviceType, type, updatedAt }) => {
    const tzId = useExpectedCompanyTimeZoneId()
    const formattedUpdatedAt = useMemo(
      () => BzDateFns.format(BzDateFns.parseISO(updatedAt, tzId), 'MM/dd/yy'),
      [updatedAt, tzId],
    )
    const appNav = useAppNav()

    return (
      <div className="flex flex-row items-center space-x-6 rounded-lg border border-solid border-bz-gray-500 px-6 py-4">
        <div className="mr-4 flex items-center rounded-full bg-daybreak-blue-100 p-2">
          <FontAwesomeIcon
            // TODO: update this to the service type
            icon={faShuffle}
            className="h-5 w-5 text-daybreak-blue-900"
          />
        </div>

        <ServiceTypeItemSection className="flex-[5]">
          {OnlineBookingServiceTypeDisplayNames[serviceType]}
        </ServiceTypeItemSection>

        <ServiceTypeItemSection label="Booking Type">
          {OnlineBookingTypeDisplayNames[type]}
        </ServiceTypeItemSection>

        <ServiceTypeItemSection label="Last updated">
          {formattedUpdatedAt}
        </ServiceTypeItemSection>

        <div className="ml-4 flex flex-row gap-2">
          <Button
            icon={<FontAwesomeIcon icon={faEdit} />}
            onClick={() => {
              appNav.navigateToOnlineBookingEditServiceTypePage(
                serviceTypeConfigGuid,
              )
            }}
          >
            Edit
          </Button>
        </div>
      </div>
    )
  },
)

type ServiceTypeItemListProps = {
  onlineBookingServiceTypes: OnlineBookingServiceTypeMetadata[]
}

const ServiceTypeItemList = React.memo<ServiceTypeItemListProps>(
  ({ onlineBookingServiceTypes }) => {
    const sortedServiceTypes = useMemo(() => {
      const order: OnlineBookingServiceType[] = [
        'MAINTENANCE',
        'SERVICE',
        'ESTIMATE',
      ] as const
      return [...onlineBookingServiceTypes].sort(
        (a, b) => order.indexOf(a.serviceType) - order.indexOf(b.serviceType),
      )
    }, [onlineBookingServiceTypes])

    return (
      <>
        {sortedServiceTypes.map(serviceType => (
          <ServiceTypeItem
            key={serviceType.onlineBookingServiceTypeGuid}
            type={serviceType.bookingType}
            serviceTypeConfigGuid={serviceType.onlineBookingServiceTypeGuid}
            serviceType={serviceType.serviceType}
            updatedAt={serviceType.updatedAt}
          />
        ))}
      </>
    )
  },
)

const OnlineBookingBookingPageLink = React.memo(() => {
  const companyGuid = useExpectedCompanyGuid()
  const { minifiedUrl, isLoading } = useMinifiedBookingUrl(companyGuid)
  const message = useMessage()

  const onCopy = useCallback(async () => {
    await copyToClipboard(minifiedUrl)
    message.success('Copied to clipboard')
  }, [minifiedUrl, message])
  return (
    <Form layout="vertical">
      <Form.Item required label="Booking Page Link">
        <div className="flex flex-row items-center gap-3">
          {isLoading ? (
            <Skeleton.Input className="w-full rounded-md" active />
          ) : (
            <Input disabled value={minifiedUrl} />
          )}
          <Button disabled={isLoading} onClick={onCopy}>
            Copy
          </Button>
          <Button
            disabled={isLoading}
            className="flex items-center gap-2"
            onClick={() => {
              window.open(minifiedUrl, '_blank')
            }}
          >
            View <FontAwesomeIcon icon={faArrowUpRight} />
          </Button>
        </div>
      </Form.Item>
    </Form>
  )
})

type WithRefetch<T> = T & {
  refetch: () => void
}

const OnlineBookingConfiguration = React.memo<
  WithRefetch<
    OnlineBookingCompanyConfigFormData & {
      onlineBookingServiceTypes: OnlineBookingServiceTypeMetadata[]
    } & UniqueZipCodes
  >
>(
  ({
    limitBookingsToServiceAreaEnabled = false,
    brandingColorHex = '#1677FF',
    afterHoursBannerHtml = '',
    serviceAreaZipCodes = [],
    zipCodes = [],
    onlineBookingServiceTypes = [],
    refetch,
  }) => {
    const companyGuid = useExpectedCompanyGuid()
    const [{ fetching: submitting }, executeMutation] = useMutation(
      ONLINE_BOOKING_COMPANY_CONFIG_UPSERT,
    )
    const message = useMessage()
    const {
      control,
      handleSubmit,
      watch,
      setValue: rawSetValue,
      formState: { isDirty, errors },
      reset,
    } = useForm({
      resolver: zodResolver(OnlineBookingCompanyConfigFormSchema),
      defaultValues: {
        limitBookingsToServiceAreaEnabled,
        brandingColorHex,
        afterHoursBannerHtml,
        serviceAreaZipCodes,
      },
    })
    const setValue = useDirtyingSetValue(rawSetValue)

    const [submitElement, triggerSubmit] = useReactHookFormSubmit()
    const goBack = useGoBack()

    const onSubmit = useCallback(
      async (data: OnlineBookingCompanyConfigFormData) => {
        try {
          await executeMutation({
            companyGuid: companyGuid,
            config: {
              companyGuid,
              limitBookingsToServiceAreaEnabled:
                data.limitBookingsToServiceAreaEnabled,
              brandingColorHex: data.brandingColorHex,
              afterHoursBannerHtml: data.afterHoursBannerHtml,
              serviceAreaZipCodes: {
                data: data.serviceAreaZipCodes.map(zipCode => ({
                  serviceAreaZipCodeGuid: nextGuid(),
                  zipCode,
                })),
              },
            },
            onConflict: {
              constraint: 'online_booking_company_config_pkey',
              updateColumns: [
                'limitBookingsToServiceAreaEnabled',
                'brandingColorHex',
                'afterHoursBannerHtml',
              ],
            },
          })
          message.success('Online Booking configuration saved')
          reset(data)
        } catch (e) {
          console.error(e)
          message.error('Error saving Online Booking configuration')
        }
      },
      [companyGuid, executeMutation, message, reset],
    )

    const isLimitBookingsToServiceAreaEnabled = watch(
      'limitBookingsToServiceAreaEnabled',
    )

    const shouldBlock = useCallback<BlockerFunction>(() => {
      return isDirty
    }, [isDirty])

    const blocker = useBlocker('OnlineBookingSettingsPage', shouldBlock)

    return (
      <div className="flex min-h-0 flex-col gap-y-6">
        <div className="flex items-center justify-between gap-3">
          <div className="flex-1 text-bz-text-secondary">
            Configure services, assign technicians, and set availability to
            streamline scheduling options for homeowners.
          </div>
          <div className="flex justify-end gap-2">
            <Button disabled={!isDirty || submitting} onClick={goBack}>
              Cancel
            </Button>
            <Button
              type="primary"
              disabled={!isDirty || submitting}
              onClick={triggerSubmit}
            >
              Save Changes
            </Button>
          </div>
        </div>
        <div className="flex flex-col gap-1">
          <OnlineBookingSectionHeader
            title="Service Types"
            description="Configure the services your company offers for online booking. Define the types of jobs your homeowners can request, such as maintenance, repairs, or equipment issues."
          />
        </div>
        <ServiceTypeItemList
          onlineBookingServiceTypes={onlineBookingServiceTypes}
        />
        <ThinDivider
          widthPx={1}
          styleOverrides={{ marginTop: 0, marginBottom: 0 }}
        />
        <CategorySection
          title="Sharing"
          subtitle="Use these options to share your booking page with homeowners."
        >
          <div className="flex flex-col gap-6">
            <OnlineBookingSectionHeader
              title="Confirmation Page"
              description="Customize the confirmation page that appears once homeowners submit a booking."
            />
            <OnlineBookingBookingPageLink />
          </div>
        </CategorySection>
        <ThinDivider
          widthPx={1}
          styleOverrides={{ marginTop: 0, marginBottom: 0 }}
        />
        <CategorySection
          title="Settings"
          subtitle="Control key settings for your online booking system, including the service area, branding, and emergency messaging."
        >
          <Form
            layout="vertical"
            className="flex flex-col gap-6"
            disabled={submitting}
            onSubmitCapture={handleSubmit(onSubmit)}
          >
            <ReactHookFormItem
              control={control}
              name="limitBookingsToServiceAreaEnabled"
              noBottomMargin
              errors={errors}
              render={({ field }) => (
                <div className="flex flex-row gap-3">
                  <OnlineBookingSectionHeader
                    title="Limit Bookings to Service Area"
                    description="Specify the geographic area where your company provides services. Only homeowners in the designated zip codes will be able to request jobs."
                  />
                  <SwitchField
                    withIcons
                    loading={submitting}
                    {...field}
                    onChange={checked => {
                      if (!checked) {
                        setValue('serviceAreaZipCodes', [])
                      }
                      field.onChange(checked)
                    }}
                  />
                </div>
              )}
            />
            {isLimitBookingsToServiceAreaEnabled && (
              <ReactHookFormItem
                control={control}
                name="serviceAreaZipCodes"
                required
                noBottomMargin
                errors={errors}
                label="Zip Codes"
                render={({ field }) => (
                  <SelectField
                    {...field}
                    loading={submitting}
                    options={zipCodes.map(zipCode => ({
                      label: zipCode,
                      value: zipCode,
                    }))}
                    values={field.value}
                    mode="multiple"
                    title="Zip Codes"
                  />
                )}
              />
            )}
            <ThinDivider
              widthPx={1}
              dividerStyle="dashed"
              styleOverrides={{ marginTop: 0, marginBottom: 0 }}
            />
            <OnlineBookingSectionHeader
              title="Branding"
              description="Customize the brand color of your booking page."
            />
            <ReactHookFormItem
              control={control}
              name="brandingColorHex"
              noBottomMargin
              label="Button Color"
              required
              errors={errors}
              render={({ field }) => (
                <>
                  <ColorPickerField
                    defaultFormat="hex"
                    disabledAlpha
                    disabled={submitting}
                    showText
                    size="middle"
                    className="w-fit"
                    {...field}
                  />
                  <FormDescription>
                    Set this to your primary brand color.
                  </FormDescription>
                </>
              )}
            />
            <ThinDivider
              widthPx={1}
              dividerStyle="dashed"
              styleOverrides={{ marginTop: 0, marginBottom: 0 }}
            />
            <OnlineBookingSectionHeader
              title="Confirmation Page"
              description="Customize the confirmation page that appears once homeowners submit a booking."
            />
            <ReactHookFormItem
              control={control}
              name="afterHoursBannerHtml"
              label="After Hours Banner Message"
              errors={errors}
              render={({ field }) => (
                <>
                  <RichTextAreaField disabled={submitting} {...field} />
                  <FormDescription>
                    This message is displayed to customers when they finish
                    filling out the web job request form and gives them
                    information for how to get in touch with you if it's an
                    emergency.
                  </FormDescription>
                </>
              )}
            />
            {submitElement}
          </Form>
        </CategorySection>
        {blocker.state === 'blocked' && (
          <CloseConfirmModal
            open
            onCancel={blocker.reset}
            onConfirm={blocker.proceed}
          />
        )}
      </div>
    )
  },
)

export const OnlineBookingSettingsPage = React.memo(() => {
  const companyGuid = useExpectedCompanyGuid()
  return (
    <WithOnlineBookingCompanyConfig
      companyGuid={companyGuid}
      render={(onlineBookingCompanyConfig, refetch) => (
        <OnlineBookingConfiguration
          {...onlineBookingCompanyConfig}
          refetch={refetch}
        />
      )}
    />
  )
})
