import { PageHeader } from '@ant-design/pro-components'
import { faGlobePointer } from '@fortawesome/pro-light-svg-icons'
import { Card } from 'antd'
import React from 'react'
import { Outlet } from 'react-router-dom'
import PageTitle from '../../elements/PageTitle/PageTitle'

export const OnlineBookingSettingsLayout = React.memo(() => {
  return (
    <div className="flex w-full min-w-[700px] flex-col">
      <PageHeader
        title={
          <PageTitle
            title={
              <div className="flex items-center space-x-2">
                <span>Online Booking</span>
              </div>
            }
            icon={faGlobePointer}
          />
        }
      />
      <Card className="min-h-0 w-full overflow-auto p-2">
        <Outlet />
      </Card>
    </div>
  )
})
