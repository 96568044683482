import {
  AccountContact,
  AccountLocation,
  AddressGuid,
  BzAddress,
  BzContact,
  BzDateFns,
  bzExpect,
  calculateInferredAppointmentStatus,
  formatName,
  getDisplayNameForAccountType,
  InstalledEquipmentSummary,
  InstalledHvacSystem,
  isNullish,
  JobClass,
  LoanRecord,
  LocalDate,
  Location,
  PaymentStatus,
  PhotoRecordWithLinks,
  PrequalRecord,
  R,
  ZoningInfo,
} from '@breezy/shared'
import {
  faEdit,
  faEllipsis,
  faHistory,
} from '@fortawesome/pro-regular-svg-icons'
import { faExclamationTriangle } from '@fortawesome/pro-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Button, Col, Divider, Dropdown, Modal, Row } from 'antd'
import { default as cn } from 'classnames'
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { useParams } from 'react-router-dom'
import { SectionedCard } from 'src/adam-components/SectionedCard/SectionedCard'
import { SET_ACCOUNT_CONTACT_ARCHIVED_MUTATION } from 'src/components/collapsibles/AccountContactsCollapsible/AccountContactsCollapsible.gql'
import {
  ContactsCollapsible,
  ContactsCollapsibleProps,
} from 'src/components/collapsibles/ContactsCollapsible/ContactsCollapsible'
import { EstimatesCollapsibleV2 } from 'src/components/collapsibles/EstimatesCollapsible/EstimatesCollapsibleV2'
import { EquipmentCollapsible } from 'src/components/collapsibles/InstalledEquipmentCollapsible/EquipmentCollapsible'
import { HvacSystemsCollapsible } from 'src/components/collapsibles/InstalledHvacSystemsCollapsible/HvacSystemsCollapsible'
import { InvoicesV2CollapsibleV2 } from 'src/components/collapsibles/InvoicesCollapsible/InvoicesV2CollapsibleV2'
import {
  JobsCollapsible,
  JobsCollapsibleProps,
} from 'src/components/collapsibles/JobsCollapsible/JobsCollapsible'
import {
  LocationsCollapsible,
  LocationsCollapsibleProps,
} from 'src/components/collapsibles/LocationsCollapsible/LocationsCollapsible'
import { PaymentsCollapsible } from 'src/components/collapsibles/PaymentsCollapsible/PaymentsCollapsible'
import {
  VisitsCollapsible,
  VisitsCollapsibleProps,
} from 'src/components/collapsibles/VisitsCollapsible/VisitsCollapsible'
import { WisetackFinancingCollapsibleV2 } from 'src/components/collapsibles/WisetackFinancingCollapsible/WisetackFinancingCollapsibleV2'
import { CreateOrEditNewContactForm } from 'src/components/Contacts/CreateOrEditNewContactForm/CreateOrEditNewContactForm'
import CreateOrEditNewAccountLocationForm from 'src/components/CreateOrEditNewAccountLocationForm/CreateOrEditNewAccountLocationForm'
import ProgressiveJobCreationModal from 'src/components/ProgressiveJobCreationModal/ProgressiveJobCreationModal'
import { useMutation, useQuery, useSubscription } from 'urql'
import { QuickbooksSyncAccountButton } from '../../components/AccountingIntegration/QuickbooksOnline/QuickbooksSyncButton'
import AccountReminders from '../../components/AccountReminders/AccountReminders'
import {
  ActivityFeed,
  ActivityFeedContext,
} from '../../components/ActivityFeed/ActivityFeed'
import AddressMultiLineView from '../../components/Addresses/AddressMultiLineView/AddressMultiLineView'
import { CardOnFileCollapsibleLoader } from '../../components/collapsibles/CardOnFileCollapsible/CardOnFileCollapsible'
import MaintenancePlansCollapsible from '../../components/collapsibles/MaintenancePlansCollapsible/MaintenancePlansCollapsible'
import EquipmentUpsertDrawer from '../../components/EquipmentUpsertDrawer/EquipmentUpsertDrawer'
import FileElements from '../../components/Files/FileElements'
import { useFinancingWizard } from '../../components/Financing/hooks/useFinancingWizard'
import InstalledHvacSystemUpsertDrawer from '../../components/InstalledHvacSystemUpsertDrawer/InstalledHvacSystemUpsertDrawer'
import { LayoutPage } from '../../components/Layout/LayoutPage'
import { LayoutSection } from '../../components/Layout/LayoutSection'
import { LinkedCallsListView } from '../../components/LinkedCallsListView/LinkedCallsListView'
import { LoadingSpinner } from '../../components/LoadingSpinner'
import MaintenancePlanVipIcon from '../../components/MaintenancePlanVipIcon/MaintenancePlanVipIcon'
import { MigrationJunk } from '../../components/MigrationJunk/MigrationJunk'
import { MigrationJunkView } from '../../components/MigrationJunk/MigrationJunkView'
import UpsertAppointmentDrawer from '../../components/NewAppointmentModal/UpsertAppointmentDrawer'
import { CreateAppointmentFormProps } from '../../components/NewAppointmentModal/UpsertAppointmentForm/UpsertAppointmentForm'
import NotesListCard from '../../components/Notes/NotesListCard/NotesListCard'
import BzTabs, {
  BzTab,
  useURLSettableTabIndex,
} from '../../components/Page/BzTabs/BzTabs'
import VerticalKeyValue from '../../components/Page/Columns/TagColumn/VerticalKeyValue'
import { Page } from '../../components/Page/Page'
import { PaymentsCollapsiblePayment } from '../../components/Payments/PaymentsCollapsible/PaymentsCollapsible'
import { PhotoDetailDrawer } from '../../components/PhotoDetailDrawer/PhotoDetailDrawer'
import CreateNewAccountForm from '../../components/ProgressiveJobCreationModal/CreateOrEditAccountForm/CreateOrEditAccountForm'
import { RichCompanyLeadSourceView } from '../../components/RichCompanyLeadSourceView/RichCompanyLeadSourceView'
import { TagList } from '../../components/Tags'
import { ServiceHistoryModal } from '../../components/TechnicianApp/MyAppointmentsPage/AppointmentDetail/ServiceHistory/ServiceHistory'
import { TrpcMultiQueryLoader } from '../../components/TrpcQueryLoader'
import { AsyncFileUpload } from '../../components/Upload/AsyncFileUpload'
import { AsyncPhotoUpload } from '../../components/Upload/AsyncPhotoUpload'
import { useSynchronousUpload } from '../../components/Upload/Upload'
import BzColumn from '../../elements/BzColumn/BzColumn'
import BzDrawer from '../../elements/BzDrawer/BzDrawer'
import { EmDash } from '../../elements/EmDash/EmDash'
import { AccountsDetailsQuery } from '../../generated/user/graphql'
import { TAGS_MINIMAL_FOR_COMPANY_QUERY } from '../../gql/queries/Tags.gql'
import { useCanCreateJobs } from '../../hooks/permission/useCanCreateJob'
import { useCanManageAccount } from '../../hooks/permission/useCanManageAccount'
import { useCanUseIntegratedPhone } from '../../hooks/permission/useCanUseIntegratedPhone'
import { useCanViewAccount } from '../../hooks/permission/useCanViewAccount'
import { trpc } from '../../hooks/trpc'
import { useIntercom } from '../../hooks/useIntercom'
import { useAddRecentRecord } from '../../hooks/useRecentRecord'
import {
  useQuickbooksOnlineEnabled,
  useWisetackEnabled,
} from '../../providers/CompanyFinancialConfigWrapper'
import {
  useExpectedCompanyTimeZoneId,
  useExpectedPrincipal,
} from '../../providers/PrincipalUser'
import { useMessage } from '../../utils/antd-utils'
import {
  AccountIcon,
  ContactIcon,
  LocationIcon,
} from '../../utils/feature-icons'
import { Placeholder } from '../../utils/Placeholder'
import { useModalState, useQueryParamFlag } from '../../utils/react-utils'
import { UpsertOp } from '../../utils/upsert-utils'
import {
  MaintenancePlanWizard,
  useMaintenancePlanWizardFlags,
} from '../CreateOrEditMaintenancePlanPage/MaintenancePlanWizard'
import {
  ACCOUNT_DETAILS_QUERY,
  COMPANY_CONFIG_QUERY,
  LINKED_CALLS_FOR_ACCOUNT_SUBSCRIPTION,
  UPDATE_VIEWED_AT_TIME_FOR_USER,
} from './AccountDetailsPage.gql'
import { useMaintenancePlanCollapsibleData } from './accountDetailsUtils'
import {
  ArchiveAccountModal,
  useArchiveAccountModal,
} from './ArchiveAccountModal'

export const ACCOUNT_DETAILS_TAB_KEY = 'tab'

type AccountDetails = NonNullable<AccountsDetailsQuery['accountsByPk']>

const AccountDetailsPageAuthWrapper = () => {
  const accountGuid = bzExpect(
    useParams().accountGuid,
    'accountGuid',
    'Account Guid is required',
  )
  const { canView, isLoading } = useCanViewAccount(accountGuid)

  const [accountDetailsQuery, refetch] = useQuery({
    query: ACCOUNT_DETAILS_QUERY,
    variables: { accountGuid },
  })

  const fetchMigrationJunkQuery = trpc.migrationJunk[
    'migration-junk:query'
  ].useQuery({
    accountGuid,
  })

  const queries = useMemo(
    () => [fetchMigrationJunkQuery] as const,
    [fetchMigrationJunkQuery],
  )

  const account = accountDetailsQuery.data?.accountsByPk

  return (
    <Page requiresCompanyUser={true} disableLayout>
      {isLoading || accountDetailsQuery.fetching ? (
        <div className="flex h-full w-full items-center justify-center">
          <LoadingSpinner />
        </div>
      ) : !account ? (
        <div>Account not found</div>
      ) : canView ? (
        <TrpcMultiQueryLoader
          queries={queries}
          render={([migrationJunk]) => {
            return (
              <AccountDetailsPage
                refetch={refetch}
                account={account}
                migrationJunk={migrationJunk}
              />
            )
          }}
        />
      ) : (
        <div className="flex h-full w-full items-center justify-center">
          <Placeholder>Unauthorized to view this account</Placeholder>
        </div>
      )}
    </Page>
  )
}

type AccountWrapper = {
  account: AccountDetails
  refetch: () => void
}

type MigrationJunkWrapper = {
  migrationJunk?: MigrationJunk | null
}

type AccountDetailsPageProps = AccountWrapper & MigrationJunkWrapper
type Editable<T> = T & {
  editable?: boolean
}

const AccountDetailsPage = React.memo<AccountDetailsPageProps>(
  ({ account, refetch, migrationJunk }) => {
    const { userGuid } = useExpectedPrincipal()

    const { accountGuid } = account

    const [isEditingAccount, setIsEditingAccount] = useState(false)
    const { canManage } = useCanManageAccount(accountGuid)
    useAddRecentRecord('accounts', accountGuid)

    const [, updateViewedAtTimeForUserMutation] = useMutation(
      UPDATE_VIEWED_AT_TIME_FOR_USER,
    )

    useEffect(() => {
      updateViewedAtTimeForUserMutation({
        accountGuid,
        userGuid,
        viewedAt: BzDateFns.nowISOString(),
      })
    }, [account, accountGuid, updateViewedAtTimeForUserMutation, userGuid])

    return (
      <>
        <LayoutPage padded>
          <LayoutSection size={{ xs: 12, md: 6, xl: 12 * 0.3 }}>
            <LeftSideAccountPane
              account={account}
              refetch={refetch}
              editable={canManage}
            />
          </LayoutSection>
          <LayoutSection
            size={{ xs: 12, md: 12, xl: 12 * 0.4 }}
            order={{ xs: 100, xl: 'DEFAULT' }}
            card
          >
            <MiddleAccountPane
              account={account}
              refetch={refetch}
              migrationJunk={migrationJunk}
              editable={canManage}
            />
          </LayoutSection>
          <LayoutSection size={{ xs: 12, md: 6, xl: 12 * 0.3 }} card>
            <RightSideAccountPane
              account={account}
              refetch={refetch}
              editable={canManage}
            />
          </LayoutSection>
        </LayoutPage>
        <EditAccountDrawer
          account={account}
          refetch={refetch}
          open={isEditingAccount}
          onClose={() => setIsEditingAccount(false)}
        />
      </>
    )
  },
)

type EditAccountDrawerProps = {
  account: AccountDetails
  refetch: () => void
  onClose: (didUpdateAccount: boolean) => void
  open: boolean
}

const EditAccountDrawer = React.memo<EditAccountDrawerProps>(
  ({ account, onClose, refetch, open }) => {
    const {
      companyGuid,
      accountGuid,
      accountType,
      accountDisplayName,
      accountManagerUserGuid,
      accountLeadSource,
      tags,
    } = account
    const message = useMessage()
    const [tagsQuery] = useQuery({
      query: TAGS_MINIMAL_FOR_COMPANY_QUERY,
      variables: { companyGuid },
    })

    return (
      <BzDrawer
        title="Edit Account"
        icon={AccountIcon}
        preferredWidth={720}
        item={open ? { onCancel: () => onClose(false) } : undefined}
        destroyOnClose
      >
        <CreateNewAccountForm
          mode="master-edit"
          onCancel={() => onClose(false)}
          onFinish={() => {
            onClose(true)
            refetch()
            message.success('Successfully updated Account')
          }}
          accountGuid={accountGuid}
          companyGuid={companyGuid}
          accountTags={tagsQuery.data?.tags ?? []}
          initialValues={{
            accountType,
            accountDisplayName,
            accountManagerUserGuid,
            leadSourceGuid: accountLeadSource[0]?.companyLeadSourceGuid,
            leadSourceReferringContactGuid:
              accountLeadSource[0]?.referringContactGuid,
            leadSourceAttributionDescription:
              accountLeadSource[0]?.attributionDescription,
            accountTagGuids: R.pluck('tagGuid', tags),
          }}
        />
      </BzDrawer>
    )
  },
)

type MiddleAccountPaneProps = Editable<AccountWrapper & MigrationJunkWrapper>

const MiddleAccountPane = React.memo<MiddleAccountPaneProps>(
  ({ account, refetch, migrationJunk, editable = true }) => {
    const {
      accountGuid,
      companyGuid,
      accountLocations,
      jobs,
      photoLinks,
      fileLinks,
    } = account
    const [editingPhoto, setEditingPhoto] =
      useState<PhotoRecordWithLinks | null>(null)

    const canUseIntegratedPhone = useCanUseIntegratedPhone()

    const [{ data: callsData, fetching: callsFetching }] = useSubscription({
      query: LINKED_CALLS_FOR_ACCOUNT_SUBSCRIPTION,
      variables: {
        accountGuid,
        companyGuid,
      },
      pause: !canUseIntegratedPhone,
    })

    const photos = useMemo(() => {
      // The type assertion is because of a quirk of `R.flatten`. It's making the type a big ([photo link type] | [photo
      // link type] | [photo link type] | ...) even though all those `[photo link type]`s are the same. By basically
      // saying "this is an array of the first type", it's verifying that all those possible options match, then giving
      // us a much cleaner type preview.
      const unsortedPhotos: (typeof photoLinks)[number]['photo'][] = R.uniqBy(
        R.prop('photoGuid'),
        R.pluck(
          'photo',
          R.flatten([
            photoLinks,
            R.pluck('photoLinks', accountLocations),
            jobs.map(j => [
              j.photoLinks,
              j.appointments.map(a => [
                a.photoLinks,
                R.pluck('photoLinks', a.assignments),
              ]),
            ]),
          ]),
        ),
      )
      const photoRecords = R.sortWith(
        [R.descend(R.prop('createdAt')), R.ascend(R.prop('photoGuid'))],
        unsortedPhotos,
      )
      return photoRecords
    }, [accountLocations, jobs, photoLinks])

    const files = useMemo(() => {
      // The type assertion is the same story as photos above
      const unsortedFiles: (typeof fileLinks)[number]['file'][] = R.uniqBy(
        R.prop('fileGuid'),
        R.pluck(
          'file',
          R.flatten([
            fileLinks,
            R.pluck('fileLinks', accountLocations),
            jobs.map(j => [
              j.fileLinks,
              j.appointments.map(a => [
                a.fileLinks,
                R.pluck('fileLinks', a.assignments),
              ]),
            ]),
          ]),
        ),
      )
      const fileRecords = R.sortWith(
        [R.descend(R.prop('createdAt')), R.ascend(R.prop('fileName'))],
        unsortedFiles,
      )
      return fileRecords.map(({ createdByUserGuid, ...rest }) => ({
        userGuid: createdByUserGuid,
        companyGuid,
        ...rest,
      }))
    }, [accountLocations, companyGuid, fileLinks, jobs])

    const photoElements = useMemo(() => {
      return photos.map(photo => (
        <div
          className={cn(
            'mb-2 flex flex-col items-center',
            editable ? 'hover:cursor-pointer' : '',
          )}
          key={photo.photoGuid}
          onClick={
            editable
              ? () => {
                  setEditingPhoto(photo)
                }
              : undefined
          }
        >
          <img src={photo.cdnUrl} alt="belongs to account" />
        </div>
      ))
    }, [editable, photos])

    const links = useMemo(() => ({ accountGuid }), [accountGuid])
    const onUploadChange = useSynchronousUpload(refetch)

    const tabs = useMemo<BzTab[]>(() => {
      const t: BzTab[] = []

      t.push({
        title: 'Reminders',
        content: <AccountReminders accountGuid={accountGuid} />,
      })

      if (canUseIntegratedPhone && callsData && callsData.accounts.length > 0) {
        t.push({
          title: 'Calls',
          content: (
            <LinkedCallsListView
              calls={callsData.accounts[0].integratedPhoneCalls}
              isLoading={callsFetching}
            />
          ),
        })
      }

      t.push(
        {
          title: 'Notes',
          content: (
            <NotesListCard
              linkData={{
                primaryNoteType: 'ACCOUNT',
                accountGuid,
              }}
              editable={editable}
            />
          ),
        },
        {
          title: 'Photos',
          content: (
            <div className="w-full">
              {editable && (
                <AsyncPhotoUpload
                  links={links}
                  onPhotoUploadChange={onUploadChange}
                />
              )}
              <div className="my-3 w-full columns-2 gap-2">{photoElements}</div>
            </div>
          ),
        },
        {
          title: 'Attachments',
          content: (
            <div className="w-full">
              {editable && (
                <div className="mb-3">
                  <AsyncFileUpload
                    links={links}
                    onFileUploadChange={onUploadChange}
                  />
                </div>
              )}
              <div className="w-full">
                <FileElements
                  onMutate={refetch}
                  files={files}
                  editable={editable}
                />
              </div>
            </div>
          ),
        },
        {
          title: 'Activity',
          content: (
            <ActivityFeed
              context={ActivityFeedContext.Account}
              accountGuid={accountGuid}
            />
          ),
        },
      )

      if (migrationJunk && Object.keys(migrationJunk.junk).length > 0) {
        t.push({
          title: migrationJunk.uiLabel ?? 'Legacy Migration Data',
          content: <MigrationJunkView migrationJunk={migrationJunk} />,
        })
      }

      return t
    }, [
      accountGuid,
      editable,
      links,
      onUploadChange,
      photoElements,
      refetch,
      files,
      migrationJunk,
      canUseIntegratedPhone,
      callsData,
      callsFetching,
    ])

    const [activeTabIndex, setActiveTabIndex] = useURLSettableTabIndex(
      tabs,
      0,
      ACCOUNT_DETAILS_TAB_KEY,
    )

    const activeTab = tabs[activeTabIndex]

    useIntercom({ isLauncherVisible: isNullish(editingPhoto) })

    return (
      <div className="flex flex-col space-y-3 p-6">
        <BzTabs
          tabs={tabs}
          activeTabIndex={activeTabIndex}
          setActiveTabIndex={setActiveTabIndex}
        />
        {activeTab.content}
        {editable && editingPhoto && (
          <PhotoDetailDrawer
            photo={editingPhoto}
            onClose={() => {
              setEditingPhoto(null)
            }}
            onDelete={() => {
              setEditingPhoto(null)
              refetch()
            }}
            editable={editable}
          />
        )}
      </div>
    )
  },
)

const RightSideAccountPane = ({
  account,
  refetch,
  editable = true,
}: Editable<AccountWrapper>) => {
  const {
    accountGuid,
    jobs,
    maintenancePlans,
    wisetackLoanRecords,
    wisetackPrequalRecords,
    paymentRecords,
  } = account

  const timezoneId = useExpectedCompanyTimeZoneId()
  const wisetackEnabled = useWisetackEnabled()

  const canCreateNewJobs = useCanCreateJobs()

  const [createApptModalOpen, setCreateApptModalOpen] = useState(false)

  const apptDrawerJobs: NonNullable<CreateAppointmentFormProps['jobList']> =
    useMemo(
      () =>
        jobs.map(job => ({
          jobGuid: job.jobGuid,
          displayId: job.displayId,
          jobType: job.jobType.name,
          jobClass: job.jobType.jobClass,
          address: {
            line1: job.location.address.line1,
            line2: job.location.address.line2,
            city: job.location.address.city,
            stateAbbreviation: job.location.address.stateAbbreviation,
          },
        })),
      [jobs],
    )

  const [
    maintenancePlanWizardOpen,
    openMaintenancePlanWizard,
    closeMaintenancePlanWizard,
  ] = useMaintenancePlanWizardFlags('mpw', 'account-details')
  const maintenancePlansCollapsibleData =
    useMaintenancePlanCollapsibleData(maintenancePlans)

  const jobsCollapsibleJobs = useMemo<JobsCollapsibleProps['jobs']>(
    () =>
      jobs
        .map(
          job =>
            ({
              jobGuid: job.jobGuid,
              jobTypeName: job.jobType.name,
              displayId: job.displayId.toString(),
              createdAt: job.createdAt,
              completedAt: job.workCompletedAt,
              jobLifecycleStatus: {
                name: job.jobLifecycleStatus.name,
                color: job.jobLifecycleStatus.color,
              },
              location: {
                locationGuid: job.location.locationGuid,
                address: { line1: job.location.address.line1 },
              },
              tags: [],
              appointments: job.appointments.map(appt => ({
                appointmentGuid: appt.jobAppointmentGuid,
                appointmentType: appt.appointmentType,
                appointmentDate: appt.appointmentWindowStart,
              })),
            } satisfies JobsCollapsibleProps['jobs'][number]),
        )
        .sort((a, b) => {
          // Sort in descending order
          const aDate = BzDateFns.parseISO(a.createdAt, timezoneId)
          const bDate = BzDateFns.parseISO(b.createdAt, timezoneId)

          if (BzDateFns.isBefore(aDate, bDate)) {
            return 1
          }

          return -1
        }),
    [jobs, timezoneId],
  )

  const appointmentsCollapsibleAppointments = useMemo<
    VisitsCollapsibleProps['visits']
  >(
    () =>
      jobs
        .map(job =>
          job.appointments.map(
            appt =>
              ({
                description: appt.description,
                appointment: {
                  appointmentGuid: appt.jobAppointmentGuid,
                  appointmentStatus: calculateInferredAppointmentStatus(
                    appt.cancellationStatus?.canceled || false,
                    appt.assignments.map(assignment => ({
                      assignmentStatus:
                        assignment.assignmentStatus
                          ?.jobAppointmentAssignmentStatusType || 'TO_DO',
                    })),
                  ),
                  appointmentType: appt.appointmentType,
                  appointmentWindowStart: appt.appointmentWindowStart,
                  appointmentWindowEnd: appt.appointmentWindowEnd,
                },
                job: {
                  jobGuid: job.jobGuid,
                  jobTypeName: job.jobType.name,
                  displayId: job.displayId.toString(),
                  assignedTech: !isNullish(appt.assignments[0])
                    ? {
                        name:
                          appt.assignments[0].technician.firstName +
                          ' ' +
                          appt.assignments[0].technician.lastName,
                        assignmentStart: appt.assignments[0].assignmentStart,
                        assignmentEnd: appt.assignments[0].assignmentEnd,
                      }
                    : undefined,
                },
                location: {
                  locationGuid: job.location.locationGuid,
                  address: {
                    line1: job.location.address.line1,
                  },
                },
              } satisfies VisitsCollapsibleProps['visits'][number]),
          ),
        )
        .flat(),
    [jobs],
  )

  const loanRecords = useMemo<LoanRecord[]>(
    () =>
      wisetackLoanRecords.map(
        ({
          wisetackLoanRecordGuid,
          wisetackMerchantGuid,
          wisetackLoanStatuses,
          wisetackTransactionGuid,
          contact,
          createdAt,
          updatedAt,
          ...rest
        }) => {
          const loanRecordStatuses = wisetackLoanStatuses.map(
            ({ createdAt, ...rest }) => ({
              // Note that though this field is `nullable`, there are no records in prod where it's `null` and we don't
              // know why it's nullable.
              createdAt: createdAt ?? BzDateFns.nowISOString(),
              ...rest,
            }),
          )
          return {
            loanRecordGuid: wisetackLoanRecordGuid,
            merchantGuid: wisetackMerchantGuid,
            externalTransactionGuid: wisetackTransactionGuid,
            loanRecordStatuses,
            // Note that in the query `wisetackLoanStatuses` is sorted by `createdAt` in descending order
            latestLoanRecordStatus: loanRecordStatuses[0],
            contactName: contact.fullName ?? '',
            // Note that though `createdAt` and `updatedAt` are nullable on this table, we don't know why and there are no
            // records in prod where they are `null`.
            createdAt: createdAt ?? BzDateFns.nowISOString(),
            updatedAt: updatedAt ?? BzDateFns.nowISOString(),
            ...rest,
          }
        },
      ),
    [wisetackLoanRecords],
  )

  const prequalRecords = useMemo<PrequalRecord[]>(
    // TODO: I don't to do this now since the query uses a fragment that's used in other places, but we can alias the
    // names right in the gql query so most of this isn't necessary.
    () =>
      wisetackPrequalRecords.map(
        ({
          wisetackPrequalRecordGuid,
          wisetackMerchantGuid,
          wisetackPrequalGuid,
          createdAt,
          updatedAt,
          ...rest
        }) => ({
          prequalRecordGuid: wisetackPrequalRecordGuid,
          merchantGuid: wisetackMerchantGuid,
          externalPrequalGuid: wisetackPrequalGuid,
          // As with the loan records, these are nullable for no discernable reason.
          createdAt: createdAt ?? BzDateFns.nowISOString(),
          updatedAt: updatedAt ?? BzDateFns.nowISOString(),
          ...rest,
        }),
      ),
    [wisetackPrequalRecords],
  )

  const payments = useMemo<PaymentsCollapsiblePayment[]>(() => {
    const loanRecordMap = R.indexBy(R.prop('loanRecordGuid'), loanRecords)
    return paymentRecords.map(
      ({
        paymentRecordGuid,
        paymentStatuses,
        paymentReferenceNumber,
        paymentLink,
        ...rest
      }) => ({
        guid: paymentRecordGuid,
        referenceNumber: paymentReferenceNumber,
        status: paymentStatuses[0]?.paymentStatus ?? PaymentStatus.SUBMITTING,
        loanRecord: loanRecordMap[paymentLink?.wisetackLoanRecordGuid ?? ''],
        ...rest,
      }),
    )
  }, [loanRecords, paymentRecords])

  const [isCreatingNewJob, setIsCreatingNewJob] = useState(false)
  const shouldCreateJobQueryParam = useQueryParamFlag('shouldCreateJob')

  const [appointmentToCancel, setAppointmentToCancel] = useState<
    VisitsCollapsibleProps['visits'][number] | undefined
  >(undefined)

  const cancelAppointmentMutation = trpc.appointments[
    'appointments:cancel'
  ].useMutation({
    onSuccess: () => {
      refetch()
    },
  })

  return (
    <BzColumn noPaddingMobile>
      <MaintenancePlansCollapsible
        plans={maintenancePlansCollapsibleData}
        createNew={() => {
          openMaintenancePlanWizard()
        }}
      />
      {maintenancePlanWizardOpen && (
        <MaintenancePlanWizard
          onRamp="account-details"
          accountGuid={accountGuid}
          onClose={closeMaintenancePlanWizard}
        />
      )}

      <JobsCollapsible
        timezoneId={timezoneId}
        jobs={jobsCollapsibleJobs}
        canManage={editable && canCreateNewJobs}
        onAddJob={() => setIsCreatingNewJob(true)}
      />

      <VisitsCollapsible
        timezoneId={timezoneId}
        visits={appointmentsCollapsibleAppointments}
        editable={editable}
        onCreateVisit={() => setCreateApptModalOpen(true)}
        onCancelVisit={({ appointmentGuid }) => {
          const appt = appointmentsCollapsibleAppointments.find(
            appt => appt.appointment.appointmentGuid === appointmentGuid,
          )
          if (isNullish(appt)) {
            return
          }

          setAppointmentToCancel(appt)
        }}
      />

      {wisetackEnabled && (
        <WisetackFinancingCollapsibleV2
          accountGuid={accountGuid}
          loanRecords={loanRecords}
          prequalRecords={prequalRecords}
        />
      )}

      <PaymentsCollapsible
        payments={payments.map(payment => ({
          paymentRecordGuid: payment.guid,
          paymentMethod: payment.paymentMethod,
          occuredAt: payment.occurredAt,
          amountUsd: payment.amountUsd,
          referenceNumber: payment.referenceNumber,
          status: payment.status,
          displayId: payment.displayId,
          invoiceGuid: payment.invoiceGuid,
          jobGuid: payment.jobGuid,
          loanRecord: payment.loanRecord,
        }))}
      />

      <InvoicesV2CollapsibleV2
        links={{
          accountGuid: accountGuid,
        }}
        allowExternalCreate={false}
      />

      <EstimatesCollapsibleV2
        links={{ accountGuid }}
        allowExternalCreate={false}
      />

      <UpsertAppointmentDrawer
        mode="create"
        jobGuid={apptDrawerJobs[0]?.jobGuid ?? ''}
        jobClass={apptDrawerJobs[0]?.jobClass ?? JobClass.INSTALL}
        open={createApptModalOpen}
        onCancel={() => setCreateApptModalOpen(false)}
        onAppointmentCreated={() => {
          setCreateApptModalOpen(false)
          refetch()
        }}
        jobList={apptDrawerJobs}
        labelClassName="semibold_14_22 grey9"
      />

      {isCreatingNewJob && (
        <ProgressiveJobCreationModal
          isOpen={isCreatingNewJob || shouldCreateJobQueryParam}
          setIsOpen={setIsCreatingNewJob}
          selectedAccountGuid={accountGuid}
        />
      )}

      <Modal
        title="Are you sure?"
        open={!!appointmentToCancel}
        onOk={() => {
          appointmentToCancel &&
            cancelAppointmentMutation.mutate({
              jobAppointmentGuid:
                appointmentToCancel.appointment.appointmentGuid,
            })
          setAppointmentToCancel(undefined)
        }}
        onCancel={() => setAppointmentToCancel(undefined)}
        okButtonProps={{ danger: true }}
      >
        <div className="column">
          <FontAwesomeIcon
            icon={faExclamationTriangle}
            size="4x"
            color="#ffcc33"
            style={{ marginBottom: 12 }}
          />
          <div className="text-center">
            Once you cancel this appointment, you will not be able to un-cancel
            it.
          </div>
        </div>
      </Modal>
    </BzColumn>
  )
}

export const LeftSideAccountPane = React.memo<Editable<AccountWrapper>>(
  ({ account, refetch: providedRefetch, editable = true }) => {
    const {
      accountGuid,
      companyGuid,
      accountContacts,
      accountLocations: rawAccountLocations,
      accountDisplayName,
      maintenancePlans,
      accountType,
      accountCreatedAt,
      accountManager,
      accountLeadSource,
      mailingAddress,
      tags: rawTags,
      archived,
    } = account
    const tzId = useExpectedCompanyTimeZoneId()
    const message = useMessage()
    const wisetackEnabled = useWisetackEnabled()
    const isQuickbooksOnlineEnabled = useQuickbooksOnlineEnabled()

    const qboStaleAccountQuery = trpc.qbo[
      'finance-app:get-stale-accounts'
    ].useQuery(
      {
        accountGuid: accountGuid,
      },
      {
        enabled: isQuickbooksOnlineEnabled,
      },
    )

    const [companyConfigQuery] = useQuery({
      query: COMPANY_CONFIG_QUERY,
      variables: { companyGuid },
    })

    const refetch = useCallback(() => {
      providedRefetch()

      if (isQuickbooksOnlineEnabled) {
        qboStaleAccountQuery.refetch()
      }
    }, [providedRefetch, qboStaleAccountQuery, isQuickbooksOnlineEnabled])

    const [editAccountDrawerOpen, startEditAccount, stopEditAccount] =
      useQueryParamFlag('edit')
    const { showFinancingWizard, financingWizard } = useFinancingWizard({
      accountGuid,
      onCancel: refetch,
    })

    const [accountLocations, installedEquipment, hvacSystems] = useMemo<
      [AccountLocation[], InstalledEquipmentSummary[], InstalledHvacSystem[]]
    >(() => {
      const EQUIPMENT_DATE_FIELDS = [
        'installationDate',
        'estimatedEndOfLifeDate',
        'manufacturerWarrantyStartDate',
        'manufacturerWarrantyEndDate',
        'laborWarrantyStartDate',
        'laborWarrantyEndDate',
        'manufacturingDate',
      ] as const

      const parseEquipment = (
        equipment: (typeof rawAccountLocations)[number]['location']['installedEquipment'][number],
      ): Omit<InstalledEquipmentSummary, 'locationGuid'> => {
        const dateFields = R.pick(EQUIPMENT_DATE_FIELDS, equipment)
        return {
          ...R.mapObjIndexed(
            value => (value ? LocalDate.parse(value) : undefined),
            dateFields,
          ),
          ...R.omit(EQUIPMENT_DATE_FIELDS, equipment),
        }
      }

      const installedEquipment: InstalledEquipmentSummary[] = []
      const hvacSystems: InstalledHvacSystem[] = []

      const accountLocations = rawAccountLocations.map(
        ({ location, ...rest }) => {
          const myInstalledEquipment: InstalledEquipmentSummary[] = (
            location.installedEquipment ?? []
          ).map(equipment => ({
            locationGuid: location.locationGuid,
            ...parseEquipment(equipment),
          }))

          const myHvacSystems: InstalledHvacSystem[] = (
            location.installedHvacSystems ?? []
          ).map(({ installedEquipment, zoningInfo, ...rest }) => ({
            locationGuid: location.locationGuid,
            installedEquipment: installedEquipment.map(equipment => ({
              locationGuid: location.locationGuid,
              ...parseEquipment(equipment),
            })),
            zoningInfo: zoningInfo as ZoningInfo,
            ...rest,
          }))

          installedEquipment.push(...myInstalledEquipment)
          hvacSystems.push(...myHvacSystems)

          const { maintenancePlans, estimatedBuildDate, ...restLocation } =
            location

          return {
            maintenancePlanGuids: R.pluck(
              'maintenancePlanGuid',
              location.maintenancePlans,
            ),
            location: {
              ...restLocation,
              estimatedBuildDate: estimatedBuildDate
                ? LocalDate.parse(estimatedBuildDate)
                : undefined,
              installedEquipment: myInstalledEquipment,
              installedHvacSystems: myHvacSystems,
            },
            ...rest,
          }
        },
      )

      return [accountLocations, installedEquipment, hvacSystems]
    }, [rawAccountLocations])

    const [installedEquipmentMutState, setInstalledEquipmentMutState] =
      useState<UpsertOp<InstalledEquipmentSummary>>()

    const closeEquipmentEdit = useCallback(
      () => setInstalledEquipmentMutState(undefined),
      [],
    )
    const onEquipmentEdit = useCallback(() => {
      refetch()
      closeEquipmentEdit()
    }, [closeEquipmentEdit, refetch])

    const [hvacSystemMutState, setHvacSystemMutState] =
      useState<UpsertOp<InstalledHvacSystem>>()

    const closeHvacSystemEdit = useCallback(
      () => setHvacSystemMutState(undefined),
      [],
    )
    const onHvacSystemEdit = useCallback(() => {
      refetch()
      closeHvacSystemEdit()
    }, [closeHvacSystemEdit, refetch])

    const qboParams = useMemo(() => ({ accountGuid }), [accountGuid])

    const billingAddressUpsertMutation =
      trpc.accounts['account-billing-address:upsert'].useMutation()

    const onBillingAddressSet = useCallback(
      (addressGuid: AddressGuid) => {
        billingAddressUpsertMutation.mutate(
          {
            accountGuid,
            mailingAddressGuid: addressGuid,
          },
          {
            onSuccess() {
              refetch()
              message.success('Successfully updated billing address')
            },
          },
        )
      },
      [billingAddressUpsertMutation, accountGuid, refetch, message],
    )

    useIntercom({
      isLauncherVisible:
        isNullish(installedEquipmentMutState) && isNullish(hvacSystemMutState),
    })

    const maintenancePlan = useMemo(() => {
      if (!maintenancePlans.length) {
        return undefined
      }
      return {
        status: maintenancePlans[0].status,
        planTypeName:
          maintenancePlans[0].maintenancePlanDefinition?.marketingInfo?.name ??
          '',
      }
    }, [maintenancePlans])

    const leadSource = useMemo(() => {
      if (!accountLeadSource.length) {
        return undefined
      }
      const leadSource = accountLeadSource[0]
      return {
        ...leadSource,
        leadSource: {
          name:
            leadSource.companyLeadSource?.canonicalLeadSourceNameOverride ??
            leadSource.companyLeadSource?.canonicalLeadSourceName ??
            'Unknown Lead Source',
        },
      }
    }, [accountLeadSource])

    const tags = useMemo(
      () => rawTags.map(({ tagGuid, tag }) => ({ tagGuid, ...tag })),
      [rawTags],
    )

    const { archiveAccount, closeConfirmProps } = useArchiveAccountModal(
      accountGuid,
      archived,
    )

    const [
      serviceHistoryModalOpen,
      openServiceHistoryModal,
      closeServiceHistoryModal,
    ] = useModalState()

    const contacts = useMemo<ContactsCollapsibleProps['contacts']>(() => {
      return accountContacts
        .map(contact => ({
          contactGuid: contact.contact.contactGuid,
          name: `${contact.contact.firstName} ${contact.contact.lastName}`,
          notificationPreference: contact.contact.notificationPreferenceType,
          emailAddress: contact.contact.primaryEmailAddress?.emailAddress,
          phoneNumber: contact.contact.primaryPhoneNumber?.phoneNumber,
          primary: contact.primary,
          archived: contact.archived,
        }))
        .sort((a, b) => {
          return a.primary ? -1 : b.primary ? 1 : a.archived ? 1 : -1
        })
    }, [accountContacts])

    const locations = useMemo<LocationsCollapsibleProps['locations']>(() => {
      return accountLocations.map(location => location.location)
    }, [accountLocations])

    const [editingContact, setEditingContact] = useState<AccountContact | null>(
      null,
    )

    const [upsertContactDrawerIsOpen, setUpsertContactDrawerIsOpen] =
      useState(false)

    const onUpsertContactDrawerClosed = useCallback(() => {
      setUpsertContactDrawerIsOpen(false)
      setEditingContact(null)
    }, [setUpsertContactDrawerIsOpen])

    const onAccountContactUpserted = useCallback(() => {
      setUpsertContactDrawerIsOpen(false)
      if (!editingContact) {
        message.success('Successfully added new Contact')
      } else {
        message.success('Successfully updated Contact')
      }
      setEditingContact(null)
      refetch()
    }, [editingContact, message, refetch])

    const [
      { fetching: mutatingAccountContactArchived },
      executeAccountContactArchivedMutation,
    ] = useMutation(SET_ACCOUNT_CONTACT_ARCHIVED_MUTATION)

    const archiveAccountContact = useCallback(
      async (accountContact: AccountContact) => {
        const bzContact = BzContact.create(accountContact.contact)

        if (accountContact.primary) {
          message.error(
            `Primary contacts cannot be archived. To archive this contact you must first change the primary contact on the account`,
          )
          return
        }

        if (accountContacts.filter(ac => !ac.archived).length <= 1) {
          message.error(
            "Cannot archive a contact when it is the account's only active contact",
          )
          return
        }

        if (accountContact.archived) {
          message.error(
            `Cannot archive contact ${bzContact.fullName} because it is already archived`,
          )
          return
        }

        try {
          await executeAccountContactArchivedMutation({
            accountContactGuid: accountContact.accountContactGuid,
            archived: true,
          })

          refetch()

          message.success(`Archived contact ${bzContact.fullName}`)
        } catch (e) {
          message.error(`Failed to archive contact ${bzContact.fullName}`)
        }
      },
      [
        accountContacts,
        executeAccountContactArchivedMutation,
        message,
        refetch,
      ],
    )

    const unarchiveAccountContact = useCallback(
      async (accountContact: AccountContact) => {
        const bzContact = BzContact.create(accountContact.contact)

        if (!accountContact.archived) {
          message.warning(
            `Cannot unarchive contact ${bzContact.fullName} because it is already unarchived`,
          )
          return
        }

        try {
          await executeAccountContactArchivedMutation({
            accountContactGuid: accountContact.accountContactGuid,
            archived: false,
          })

          refetch?.()

          message.success(`Unarchived contact ${bzContact.fullName}`)
        } catch (e) {
          message.error(`Failed to unarchive contact ${bzContact.fullName}`)
        }
      },
      [executeAccountContactArchivedMutation, message, refetch],
    )

    const updatePrimaryAccountContactMutation =
      trpc.accountContacts['account-contacts:update-primary'].useMutation()

    const [editingLocation, setEditingLocation] = useState<Location | null>(
      null,
    )

    const [upsertLocationDrawerOpen, setUpsertLocationDrawerOpen] =
      useState(false)

    return (
      <SectionedCard
        accentBarColor={account.archived ? '#8C8C8C' : '#0958D9'}
        accentBarBody={
          account.archived ? (
            <span className="self-center text-white">Archived</span>
          ) : undefined
        }
        sections={[
          {
            content: (
              <>
                <BzColumn noPadding>
                  <div className="flex justify-between">
                    <div className="account-display-name card-title-md w-full">
                      {accountDisplayName}
                    </div>

                    <div className="row flex items-center space-x-2">
                      <MaintenancePlanVipIcon
                        maintenancePlan={maintenancePlan}
                      />

                      {editable && isQuickbooksOnlineEnabled && (
                        <QuickbooksSyncAccountButton
                          loading={qboStaleAccountQuery.isLoading}
                          staleInfo={qboStaleAccountQuery.data?.[accountGuid]}
                          params={qboParams}
                          className="ml-2"
                          onSuccess={qboStaleAccountQuery.refetch}
                        />
                      )}
                    </div>
                  </div>
                  <div className="mt-3 flex flex-row flex-wrap space-x-2">
                    {editable && (
                      <Button
                        type="primary"
                        icon={<FontAwesomeIcon icon={faEdit} />}
                        onClick={startEditAccount}
                        data-testid="edit-account-button"
                      >
                        Edit
                      </Button>
                    )}

                    <Button
                      type="default"
                      icon={<FontAwesomeIcon icon={faHistory} />}
                      onClick={openServiceHistoryModal}
                      data-dd-action-name="bz-service-history-account-details"
                    >
                      Service History
                    </Button>

                    {editable && (
                      <Dropdown
                        trigger={['click']}
                        menu={{
                          items: [
                            ...(wisetackEnabled
                              ? [
                                  {
                                    key: 'Send Financing',
                                    label: 'Send Financing',
                                    onClick: () => showFinancingWizard(),
                                  },
                                ]
                              : []),
                            {
                              key: 'Archive Account',
                              label: archived
                                ? 'Unarchive Account'
                                : 'Archive Account',
                              danger: !archived,
                              onClick: archiveAccount,
                            },
                          ],
                        }}
                      >
                        <Button icon={<FontAwesomeIcon icon={faEllipsis} />} />
                      </Dropdown>
                    )}
                  </div>
                  <div className="flex justify-between space-x-1">
                    <VerticalKeyValue
                      pair={{
                        key: 'Account Type',
                        value: getDisplayNameForAccountType(accountType),
                      }}
                    />
                    {companyConfigQuery.data?.companyConfigByPk
                      ?.accountManagerEnabled && accountManager ? (
                      <VerticalKeyValue
                        pair={{
                          key: 'Account Manager',
                          value: formatName(accountManager),
                        }}
                      />
                    ) : null}
                    <VerticalKeyValue
                      pair={{
                        key: 'Created On',
                        value: BzDateFns.formatFromISO(
                          accountCreatedAt,
                          'MMM d, yyyy',
                          tzId,
                        ),
                      }}
                    />
                    <VerticalKeyValue
                      pair={{
                        key: 'Lead Source',
                        value: leadSource ? (
                          <RichCompanyLeadSourceView
                            accountGuid={accountGuid}
                            leadSource={leadSource}
                          />
                        ) : (
                          <EmDash />
                        ),
                      }}
                    />
                  </div>
                  {mailingAddress && (
                    <Row>
                      <Col span={24}>
                        <div className="mt-5">
                          <h4 className="gray9">Billing Address</h4>
                          <AddressMultiLineView
                            address={BzAddress.create(mailingAddress)}
                            labelClassName="semibold_14_22"
                          />
                        </div>
                      </Col>
                    </Row>
                  )}
                  <div className="flex flex-col space-y-3">
                    <span className="font-semibold text-bz-gray-900">Tags</span>
                    <TagList tags={tags} spacingY={2} />
                  </div>

                  <Divider />

                  <ContactsCollapsible
                    title="Contacts"
                    contacts={contacts}
                    editable={editable}
                    disabled={
                      upsertContactDrawerIsOpen ||
                      mutatingAccountContactArchived
                    }
                    onContactAdd={() => setUpsertContactDrawerIsOpen(true)}
                    onContactEdit={contact => {
                      const c = accountContacts.find(
                        curr =>
                          curr.contact.contactGuid === contact.contactGuid,
                      )

                      if (isNullish(c)) {
                        return
                      }

                      setEditingContact(c)
                      setUpsertContactDrawerIsOpen(true)
                    }}
                    onContactMakePrimary={contact => {
                      const c = accountContacts.find(
                        curr =>
                          curr.contact.contactGuid === contact.contactGuid,
                      )

                      if (isNullish(c)) {
                        return
                      }

                      updatePrimaryAccountContactMutation.mutate(
                        {
                          accountContactGuid: c.accountContactGuid,
                          accountGuid: c.accountGuid,
                        },
                        {
                          onSuccess() {
                            message.success('Successfully updated contact')
                            refetch()
                          },
                        },
                      )
                    }}
                    onContactArchive={contact => {
                      const c = accountContacts.find(
                        curr =>
                          curr.contact.contactGuid === contact.contactGuid,
                      )

                      if (isNullish(c)) {
                        return
                      }

                      archiveAccountContact(c)
                    }}
                    onContactUnarchive={contact => {
                      const c = accountContacts.find(
                        curr =>
                          curr.contact.contactGuid === contact.contactGuid,
                      )

                      if (isNullish(c)) {
                        return
                      }

                      unarchiveAccountContact(c)
                    }}
                  />

                  <LocationsCollapsible
                    locations={locations}
                    onLocationAdd={() => setUpsertLocationDrawerOpen(true)}
                    onLocationMakeBillingAddress={location =>
                      onBillingAddressSet(location.address.addressGuid)
                    }
                    onEdit={location => {
                      setUpsertLocationDrawerOpen(true)
                      setEditingLocation(location)
                    }}
                  />

                  <CardOnFileCollapsibleLoader
                    accountGuid={accountGuid}
                    editable={editable}
                  />

                  <EquipmentCollapsible
                    equipment={installedEquipment}
                    onAddEquipment={() =>
                      setInstalledEquipmentMutState('create new')
                    }
                    onEquipmentEdit={setInstalledEquipmentMutState}
                    canManage={editable}
                  />

                  <HvacSystemsCollapsible
                    canManage={editable}
                    hvacSystems={hvacSystems}
                    onHvacSystemAdd={() => setHvacSystemMutState('create new')}
                    onHvacSystemEdit={hvacSystem =>
                      setHvacSystemMutState(hvacSystem)
                    }
                    onEquipmentEdit={setInstalledEquipmentMutState}
                  />
                </BzColumn>

                <EditAccountDrawer
                  account={account}
                  open={editAccountDrawerOpen}
                  onClose={stopEditAccount}
                  refetch={refetch}
                />
                {serviceHistoryModalOpen && (
                  <ServiceHistoryModal
                    locationGuid={
                      account.accountLocations[0]?.location.locationGuid ?? ''
                    }
                    onClose={closeServiceHistoryModal}
                  />
                )}

                {installedEquipmentMutState === 'create new' && (
                  <EquipmentUpsertDrawer
                    mode="create-for-account"
                    availableLocations={R.pluck('location', accountLocations)}
                    isOpen={!!installedEquipmentMutState}
                    onCancel={closeEquipmentEdit}
                    onMutate={onEquipmentEdit}
                  />
                )}

                {installedEquipmentMutState &&
                  installedEquipmentMutState !== 'create new' && (
                    <EquipmentUpsertDrawer
                      mode="update"
                      location={bzExpect(
                        accountLocations
                          .map(al => al.location)
                          .find(
                            l =>
                              l.locationGuid ===
                              installedEquipmentMutState.locationGuid,
                          ),
                      )}
                      initialValues={installedEquipmentMutState}
                      isOpen={!!installedEquipmentMutState}
                      onCancel={closeEquipmentEdit}
                      onMutate={onEquipmentEdit}
                    />
                  )}

                {hvacSystemMutState === 'create new' && (
                  <InstalledHvacSystemUpsertDrawer
                    mode="create-for-account"
                    availableLocations={accountLocations.map(al => al.location)}
                    isOpen={!!hvacSystemMutState}
                    onCancel={closeHvacSystemEdit}
                    onMutate={onHvacSystemEdit}
                  />
                )}

                {hvacSystemMutState && hvacSystemMutState !== 'create new' && (
                  <InstalledHvacSystemUpsertDrawer
                    mode="update"
                    location={bzExpect(
                      accountLocations
                        .map(al => al.location)
                        .find(
                          l =>
                            l.locationGuid === hvacSystemMutState.locationGuid,
                        ),
                    )}
                    initialValues={hvacSystemMutState}
                    isOpen={!!hvacSystemMutState}
                    onCancel={closeHvacSystemEdit}
                    onMutate={onHvacSystemEdit}
                  />
                )}

                {financingWizard}

                <ArchiveAccountModal
                  {...closeConfirmProps}
                  archived={archived}
                />

                {editable && (
                  <BzDrawer
                    title={
                      !isNullish(editingContact)
                        ? 'Edit Contact'
                        : 'Add Contact'
                    }
                    icon={ContactIcon}
                    preferredWidth={720}
                    item={
                      upsertContactDrawerIsOpen
                        ? {
                            onCancel: onUpsertContactDrawerClosed,
                          }
                        : undefined
                    }
                    destroyOnClose
                  >
                    <CreateOrEditNewContactForm
                      onCancelButtonPressed={onUpsertContactDrawerClosed}
                      accountGuid={accountGuid}
                      editingAccountContact={editingContact ?? undefined}
                      onAccountContactAdded={onAccountContactUpserted}
                      onAccountContactUpdated={onAccountContactUpserted}
                    />
                  </BzDrawer>
                )}

                <BzDrawer
                  title={
                    !isNullish(editingLocation)
                      ? 'Edit Location'
                      : 'New Location'
                  }
                  icon={LocationIcon}
                  item={
                    upsertLocationDrawerOpen
                      ? {
                          onCancel: () => setUpsertLocationDrawerOpen(false),
                        }
                      : undefined
                  }
                  destroyOnClose
                  preferredWidth={720}
                >
                  <CreateOrEditNewAccountLocationForm
                    flexRowSpaceX="space-x"
                    labelClassName="semibold_14_22 grey9"
                    showDivider
                    showCancelSubmitButtons
                    accountGuid={accountGuid}
                    editingLocation={editingLocation ?? undefined}
                    onLocationUpdated={() => {
                      setUpsertLocationDrawerOpen(false)
                      setEditingLocation(null)
                      refetch()
                      message.success('Successfully updated location')
                    }}
                    onCancelButtonPressed={() =>
                      setUpsertLocationDrawerOpen(false)
                    }
                  />
                </BzDrawer>
              </>
            ),
          },
        ]}
      />
    )
  },
)

export default AccountDetailsPageAuthWrapper
