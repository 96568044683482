import {
  CartItem,
  cartItemToCartItemUsc,
  HtmlString,
  isNullish,
  nextGuid,
  usCentsToUsd,
  usdToUsCents,
} from '@breezy/shared'
import { faCopy, faPlus } from '@fortawesome/pro-light-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Button, Divider, Form, Input } from 'antd'
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { useMutation } from 'urql'
import { PricebookItemEditModal } from '../../components/Pricebook/PricebookItemEditModal'
import { PricebookItemPicker } from '../../components/Pricebook/PricebookItemPicker'
import BzDrawer from '../../elements/BzDrawer/BzDrawer'
import { InvoiceTemplateCartItemsInsertInput } from '../../generated/user/graphql'
import { useExpectedCompanyGuid } from '../../providers/PrincipalUser'
import { LineItemList } from '../Invoices/components/LineItemList'
import { UPSERT_INVOICE_TEMPLATE_MUTATION } from './InvoiceTemplates.gql'
import { InvoiceTemplateItemData } from './InvoiceTemplatesPage'

const VALIDATE_MESSAGES = {
  // This is the format antd wants
  // eslint-disable-next-line no-template-curly-in-string
  required: '${label} is required.',
}

type InvoiceTemplateForm = {
  name: string
  lineItems: CartItem[]
}

type InvoiceTemplateUpsertDrawerProps = {
  isOpen: boolean
  editTemplate?: InvoiceTemplateItemData
  onMutate: () => void
  onClose: () => void
}

const mapGqlCartItemToCartItem = (
  gqlCartItemParent: InvoiceTemplateItemData['invoiceTemplateCartItems'][0],
): CartItem => {
  const gqlCartItem = gqlCartItemParent.cartItem
  const mappedItem: CartItem = {
    itemGuid: gqlCartItem.cartItemGuid,
    name: gqlCartItem.name,
    description: gqlCartItem.description
      ? (gqlCartItem.description as HtmlString)
      : ('' as HtmlString),
    quantity: gqlCartItem.quantity,
    isTaxable: gqlCartItem.isTaxable,
    isDiscountable: gqlCartItem.isDiscountable,
    itemType: gqlCartItem.cartItemType,
    photoGuid: gqlCartItem.photoGuid,
    photoCdnUrl: gqlCartItem.photo?.cdnUrl,
    originalPricebookItemGuid: gqlCartItem.originalPricebookItemGuid,
    unitPriceUsd: usCentsToUsd(gqlCartItem.unitPriceUsc),
  }
  return mappedItem
}

export const InvoiceTemplateUpsertDrawer =
  React.memo<InvoiceTemplateUpsertDrawerProps>(
    ({ isOpen, editTemplate, onClose, onMutate }) => {
      const companyGuid = useExpectedCompanyGuid()
      const [form] = Form.useForm<InvoiceTemplateForm>()
      const [isItemSelectionOpen, setIsItemSelectionOpen] = useState(false)
      const [lineItems, setLineItems] = useState<CartItem[]>([])
      const [lineItemErrors, setLineItemErrors] = useState<string[]>([])
      const [isSaveDisabled, setIsSaveDisabled] = useState(true)
      const [openedLineItemIndex, setOpenedLineItemIndex] = useState<number>()
      const initialLineItems = useMemo(() => {
        return editTemplate
          ? editTemplate.invoiceTemplateCartItems.map(mapGqlCartItemToCartItem)
          : []
      }, [editTemplate])

      const fieldName = Form.useWatch('name', form)

      const clearForm = useCallback(() => {
        form.setFieldsValue({
          name: '',
          lineItems: [],
        })
        setLineItems([])
        setLineItemErrors([])
      }, [form])

      useEffect(() => {
        if (isOpen) {
          setLineItems(initialLineItems)
          form.setFieldsValue({
            name: editTemplate?.templateName ?? '',
            lineItems: initialLineItems,
          })
        } else {
          clearForm()
        }
      }, [isOpen, editTemplate, initialLineItems, form, clearForm])

      useEffect(() => {
        const hasChanges =
          fieldName !== editTemplate?.templateName ||
          JSON.stringify(lineItems) !==
            JSON.stringify(
              editTemplate?.invoiceTemplateCartItems.map(
                mapGqlCartItemToCartItem,
              ),
            )
        setIsSaveDisabled(!hasChanges)
      }, [fieldName, lineItems, editTemplate])

      const onCancel = useCallback(() => {
        clearForm()
        onClose()
      }, [clearForm, onClose])

      const drawerItem = React.useMemo(
        () => ({ onCancel: onCancel }),
        [onCancel],
      )

      const [{ fetching: upserting }, upsertTemplateMutation] = useMutation(
        UPSERT_INVOICE_TEMPLATE_MUTATION,
      )

      const onSave = useCallback(async () => {
        try {
          await form.validateFields()
          if (lineItems.length === 0) {
            setLineItemErrors(['At least one line item is required'])
            return
          }
          setLineItemErrors([])
          const invoiceTemplateGuid =
            editTemplate?.invoiceTemplateGuid ?? nextGuid()
          const paramLineItems: InvoiceTemplateCartItemsInsertInput[] =
            lineItems.map((item, index) => ({
              seq: index,
              cartItem: {
                data: {
                  companyGuid,
                  cartItemGuid: nextGuid(),
                  cartItemType: item.itemType,
                  name: item.name,
                  description: item.description,
                  quantity: item.quantity,
                  unitPriceUsc: usdToUsCents(item.unitPriceUsd),
                  isTaxable: item.isTaxable,
                  isDiscountable: item.isDiscountable,
                  photoGuid: item.photoGuid,
                  originalPricebookItemGuid: item.originalPricebookItemGuid,
                  createdAt: new Date().toISOString(),
                  updatedAt: new Date().toISOString(),
                },
              },
            }))
          const validCartItemGuids = paramLineItems.map(
            item => item.cartItem?.data.cartItemGuid ?? '',
          )

          const upsertBody = {
            invoiceTemplateGuid,
            validCartItemGuids,
            template: {
              invoiceTemplateGuid,
              templateName: form.getFieldValue('name'),
              companyGuid,
              createdAt: new Date().toISOString(),
              updatedAt: new Date().toISOString(),
              isDeleted: false,
              invoiceTemplateCartItems: {
                data: paramLineItems,
              },
            },
          }

          await upsertTemplateMutation(upsertBody)
          clearForm()
          onMutate()
          onClose()
        } catch (err) {
          // Form validation error, no need to show message... Form shows errors per field
        }
      }, [
        companyGuid,
        editTemplate,
        form,
        lineItems,
        upsertTemplateMutation,
        clearForm,
        onClose,
        onMutate,
      ])

      const onAddLineItems = useCallback(
        (items: CartItem[]) => {
          setLineItems(prev => [...prev, ...items])
          form.setFieldValue('lineItems', [...lineItems, ...items])
          setIsItemSelectionOpen(false)
        },
        [form, lineItems],
      )

      const onLineItemActionsCancel = useCallback(() => {
        setOpenedLineItemIndex(undefined)
      }, [])

      const onLineItemRemove = useCallback(() => {
        if (!isNullish(openedLineItemIndex)) {
          setLineItems(prev => prev.filter((_, i) => i !== openedLineItemIndex))
          onLineItemActionsCancel()
        }
      }, [openedLineItemIndex, onLineItemActionsCancel])

      const onLineItemEdit = useCallback(
        (item: CartItem) => {
          if (!isNullish(openedLineItemIndex)) {
            if (item.quantity === 0) {
              setLineItems(prev =>
                prev.filter((_, i) => i !== openedLineItemIndex),
              )
            } else {
              setLineItems(prev => {
                const newItems = [...prev]
                newItems[openedLineItemIndex] = item
                return newItems
              })
            }
          }
          onLineItemActionsCancel()
        },
        [openedLineItemIndex, onLineItemActionsCancel],
      )

      const editingLineItem = useMemo(
        () =>
          isNullish(openedLineItemIndex)
            ? undefined
            : lineItems[openedLineItemIndex],
        [openedLineItemIndex, lineItems],
      )

      return (
        <BzDrawer
          title="Add Invoice Template"
          icon={faCopy}
          item={isOpen ? drawerItem : undefined}
          preferredWidth={560}
          footer={
            <div className="flex flex-row justify-between py-4">
              <span />
              <div className="flex flex-row space-x-2">
                <Button size="large" onClick={onCancel} loading={upserting}>
                  Cancel
                </Button>
                <Button
                  type="primary"
                  size="large"
                  onClick={onSave}
                  loading={upserting}
                  disabled={isSaveDisabled}
                >
                  Save
                </Button>
              </div>
            </div>
          }
        >
          <Form
            form={form}
            layout="vertical"
            requiredMark="optional"
            validateMessages={VALIDATE_MESSAGES}
            disabled={false}
            initialValues={{
              name: editTemplate?.templateName ?? '',
            }}
          >
            <Form.Item
              label="Template Name"
              name="name"
              required
              rules={[{ required: true }]}
            >
              <Input placeholder="Enter template name" />
            </Form.Item>
            <Divider />

            <div className="mb-4">
              <div className="text-base font-semibold leading-6 text-[#1F1F1F]">
                Line Items
              </div>
              <div className="min-h-[100px] rounded-md border border-gray-200 py-4">
                {lineItems.length === 0 ? (
                  <>
                    <div
                      className="mb-4 flex h-full flex-col items-center justify-center gap-4 self-stretch rounded-lg bg-[#F5f5f5] p-[20px_16px]"
                      onClick={() => setIsItemSelectionOpen(true)}
                    >
                      <div className="text-center text-[#595959]">
                        Line items will be displayed here. Click to add one now.
                      </div>
                    </div>
                    {lineItemErrors.length > 0 && (
                      <div className="text-sm text-red-500">
                        {lineItemErrors[0]}
                      </div>
                    )}
                  </>
                ) : (
                  <div className="rounded-lg border border-solid border-[#D9D9D9] px-4 py-3">
                    <LineItemList
                      lineItems={lineItems.map((item, idx) => {
                        const itemUsc = cartItemToCartItemUsc(item)
                        return {
                          ...itemUsc,
                          seq: idx,
                          cartItemGuid: item.itemGuid
                            ? item.itemGuid
                            : nextGuid(),
                        }
                      })}
                      onItemClick={setOpenedLineItemIndex}
                    />
                  </div>
                )}
              </div>

              <Button
                type="primary"
                onClick={() => setIsItemSelectionOpen(true)}
                className="h-10"
              >
                <FontAwesomeIcon icon={faPlus} className="mr-2" />
                Add Line Item
              </Button>
            </div>
            {isItemSelectionOpen && (
              <PricebookItemPicker
                isSubmitting={upserting}
                canSelectMultiple
                onCancel={() => setIsItemSelectionOpen(false)}
                onSubmit={onAddLineItems}
              />
            )}
            {editingLineItem && (
              <PricebookItemEditModal
                item={editingLineItem}
                onSubmit={onLineItemEdit}
                onCancel={onLineItemActionsCancel}
                onRemove={onLineItemRemove}
              />
            )}
          </Form>
        </BzDrawer>
      )
    },
  )
