import { faCircle } from '@fortawesome/pro-regular-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Button, Divider } from 'antd'
import { useCallback } from 'react'
import { OnsiteBasicModal } from '../../../adam-components/OnsiteModal/OnsiteModal'
import useAppNav from '../../../hooks/useAppNav'
import { InvoiceTemplateItems } from '../../../hooks/useInvoiceTemplates'

type InvoiceTemplatePickerProps = {
  accountGuid: string
  jobGuid: string
  jobAppointmentGuid?: string
  templates: InvoiceTemplateItems
  onCancel: () => void
}

const InvoiceTemplatePicker = ({
  accountGuid,
  jobGuid,
  templates,
  jobAppointmentGuid,
  onCancel,
}: InvoiceTemplatePickerProps) => {
  const appNav = useAppNav()
  const templatesPlusBlank = [
    { invoiceTemplateGuid: 'blank', templateName: 'Create Blank Invoice' },
    ...templates,
  ]

  const createInvoice = useCallback(
    (invoiceTemplateGuid: string | 'blank') =>
      appNav.navigateToCreateNewInvoiceV2(accountGuid, jobGuid, {
        jobAppointmentGuid,
        invoiceTemplateGuid:
          invoiceTemplateGuid === 'blank' ? undefined : invoiceTemplateGuid,
      }),
    [appNav, accountGuid, jobGuid, jobAppointmentGuid],
  )

  return (
    <OnsiteBasicModal open={true} onClose={onCancel} size="default">
      <h2 className="mb-4 text-[30px] font-semibold leading-[38px] text-[#1F1F1F]">
        Select invoice template
      </h2>
      <Divider className="m-0" />
      <div className="flex flex-col gap-0">
        {templatesPlusBlank.map(template => (
          <>
            <Button
              className="mb-0 flex min-h-[64px] w-full items-center justify-between rounded-none border-none bg-[#ffffff] px-4 py-0 shadow-none hover:bg-[#F5F5F5]"
              key={template.invoiceTemplateGuid}
              onClick={() => createInvoice(template.invoiceTemplateGuid)}
            >
              <div className="min-h-[24px] text-base font-semibold leading-6 text-[#1F1F1F]">
                {template.templateName}
              </div>
              <FontAwesomeIcon
                icon={faCircle}
                className="text-[24px] text-[#818181] hover:text-[#166cff]"
              />
            </Button>
            <Divider className="m-0" />
          </>
        ))}
      </div>
    </OnsiteBasicModal>
  )
}

export default InvoiceTemplatePicker
