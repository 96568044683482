import { Divider } from 'antd'
import { ReactNode } from 'react'
import { Card } from '../Card/Card'

const ObjectCardDivider = () => {
  return <Divider className="my-[4px] border-bz-border" dashed />
}

interface ObjectCardInfoItemTitleProps {
  text: string
  size?: 'sm' | 'base'
}

const ObjectCardInfoItemTitle = (props: ObjectCardInfoItemTitleProps) => {
  const fontSize = props.size ?? 'base'

  return (
    <h2 className={`m-0 p-0 text-${fontSize} font-semibold`}>{props.text}</h2>
  )
}

interface ObjectCardInfoItemProps {
  title: ReactNode
  info: ReactNode
}

const ObjectCardInfoItem = (props: ObjectCardInfoItemProps) => {
  return (
    <div className="flex w-full flex-row items-center justify-between">
      <div>{props.title}</div>

      <div>{props.info}</div>
    </div>
  )
}

ObjectCardInfoItem.Title = ObjectCardInfoItemTitle

export interface ObjectCardProps {
  children?: ReactNode
  paddingClassName?: string
}

export const ObjectCard = (props: ObjectCardProps) => {
  return (
    <Card paddingClassName={props.paddingClassName}>
      <div className="flex flex-col gap-[6px]">{props.children}</div>
    </Card>
  )
}

ObjectCard.Divider = ObjectCardDivider
ObjectCard.InfoItem = ObjectCardInfoItem
