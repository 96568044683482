import { TimeZoneId } from '@breezy/shared'
import { OnsitePageCollapsibleSection } from 'src/adam-components/OnsitePage/OnsitePageCollapsibleSection'
import { JobCard, JobCardJob } from 'src/components/Cards/JobCard'

export interface JobsCollapsibleProps {
  jobs: JobCardJob[]
  timezoneId: TimeZoneId
  canManage?: boolean
  onAddJob?: () => void
}

export const JobsCollapsible = (props: JobsCollapsibleProps) => {
  const { jobs } = props

  return (
    <OnsitePageCollapsibleSection
      smallTitle
      title="Jobs"
      count={jobs.length}
      defaultCollapsed={jobs.length === 0}
      onAdd={props.canManage ? props.onAddJob : undefined}
      addButtonTestId="jobs-collapsible-add-button"
    >
      <div className="flex flex-col gap-2">
        {jobs.map(job => (
          <JobCard key={job.jobGuid} job={job} timezoneId={props.timezoneId} />
        ))}
      </div>
    </OnsitePageCollapsibleSection>
  )
}
