import {
  AbridgedInvoiceMetadata,
  AccountGuid,
  CompanyGuid,
  LocalDateString,
  MaintenancePlanGuid,
  MaintenancePlanPaymentInterval,
  PaymentMethod,
  PaymentWorkflowOption,
  PricebookTaxRateGuid,
  bzExpect,
  getPaymentWorkflowOptionToPaymentMethod,
  isNullish,
  noOp,
  usCentsToUsd,
  usdToUsCents,
} from '@breezy/shared'
import { IconProp } from '@fortawesome/fontawesome-svg-core'
import {
  faBuildingColumns,
  faCreditCard,
  faMoneyCheckDollarPen,
  faPaperPlane,
  faPiggyBank,
  faWallet,
} from '@fortawesome/pro-light-svg-icons'
import React, { useCallback, useMemo, useState } from 'react'
import {
  OnsiteModal,
  OnsiteModalContent,
} from '../../adam-components/OnsiteModal/OnsiteModal'
import {
  CloseConfirmModal,
  useCloseConfirmModal,
} from '../../adam-components/OnsiteModal/useCloseConfirmModal'
import Switch from '../../elements/Switch/Switch'
import { useUnsavedChangesWarning } from '../../hooks/useUnsavedChangesWarning'
import { useTilledMerchantId } from '../../providers/CompanyFinancialConfigWrapper'
import OtherPaymentWorkflow from './components/OtherPaymentWorkflow/OtherPaymentWorkflow'
import PaymentWorkflowConfigForm, {
  PaymentWorkflowConfigSubmitFormData,
} from './components/PaymentWorkflowConfigForm/PaymentWorkflowConfigForm'
import TilledPaymentSubscriptionWorkflow from './components/TilledPaymentSubscriptionWorkflow/TilledPaymentSubscriptionWorkflow'
import TilledPaymentWorkflow from './components/TilledPaymentWorkflow/TilledPaymentWorkflow'
import { useSelfServePaymentWorkflow } from './hooks/useSelfServePaymentWorkflow'
import { usePaymentStatusModalContext } from './providers/PaymentStatusModalProvider'
import { usePaymentStatusContext } from './providers/PaymentStatusProvider'

export type PaymentWorkflowFormStyle = 'inline' | 'modal'

export type PaymentAmountUsc = number

export type MaintenancePlanActivationInput = {
  maintenancePlanGuid: MaintenancePlanGuid
  accountGuid: AccountGuid
  companyGuid: CompanyGuid
  paymentInterval: MaintenancePlanPaymentInterval
  totalPriceUsc: number
  customBillingStartDate?: LocalDateString
  activationDate: LocalDateString
  taxRateGuid: PricebookTaxRateGuid | null
}

export type PaymentWorkflowMetadata = {
  /* If true, the amount in the payment workflow config step is editable */
  editableAmount?: boolean
} & (
  | {
      invoice: AbridgedInvoiceMetadata

      /* Required for SelfServe Payment workflow */
      invoiceAmountDueUsd?: number

      maintenancePlan?: never
    }
  | {
      invoice?: never
      invoiceAmountDueUsd?: never
      maintenancePlan: MaintenancePlanActivationInput
    }
)

export const defaultPaymentWorkflowFooterPaddingStyles =
  'p-0 -mx-4 px-4 md:-mx-6 md:px-6 pt-4'

export const defaultPaymentWorkflowFooterStyles =
  'gap-3 border-0 border-t-4 border-solid border-t-bz-gray-400'

export const PAYMENT_WORKFLOW_OPTIONS: {
  label: PaymentWorkflowOption
  method?: PaymentMethod
  icon: IconProp
}[] = [
  {
    label: 'Payment Link',
    icon: faPaperPlane,
  },
  {
    label: 'Credit Card',
    method: PaymentMethod.CARD,
    icon: faCreditCard,
  },
  {
    label: 'ACH',
    method: PaymentMethod.ACH,
    icon: faBuildingColumns,
  },
  {
    label: 'Check',
    method: PaymentMethod.CHECK,
    icon: faMoneyCheckDollarPen,
  },
  {
    label: 'Cash',
    method: PaymentMethod.CASH,
    icon: faWallet,
  },
  {
    label: 'Other',
    method: PaymentMethod.OTHER,
    icon: faPiggyBank,
  },
]

export type PaymentSuccessMethod = PaymentMethod | 'PAYMENT_LINK'

export type PaymentSuccessHandler = (
  paymentMethod: PaymentSuccessMethod,
  paymentAmountUsc: number,
) => void

type PaymentWorkflowContextType = {
  mode?: PaymentWorkflowMode
  setIsProcessingPayment: (isProcessingPayment: boolean) => void
  onPaymentSuccess?: (
    paymentMethod: PaymentSuccessMethod,
    paymentAmountUsc: number,
  ) => void
  onPaymentError?: () => void
}

const PaymentWorkflowContext = React.createContext<PaymentWorkflowContextType>({
  mode: 'base',
  setIsProcessingPayment: () => {},
  onPaymentSuccess: () => {},
  onPaymentError: () => {},
})

export const usePaymentWorkflowContext = () => {
  return React.useContext(PaymentWorkflowContext)
}

type PaymentWorkflowOptionWithInfo = {
  option: PaymentWorkflowOption
  info?: string
  hide?: boolean
}

export type PaymentWorkflowOptionConfig = {
  /** Consumers can pass in a react component that will be rendered under the payment amount input  */
  paymentInfo?: React.ReactNode
  /* Allows consumer to specify which options they'd like to hide */
  hiddenOptions?: PaymentWorkflowOption[]
  /* Allows consumer to specify which options they'd like to disable */
  disabledOptions?: PaymentWorkflowOptionWithInfo[]
}

export type PaymentWorkflowMode = 'base' | 'embedded'
export type PaymentWorkflowWizardProps = {
  meta: PaymentWorkflowMetadata
  mode?: PaymentWorkflowMode
  onCancel?: () => void
  onBack?: () => void
  onPaymentSuccess?: PaymentSuccessHandler
  onPaymentError?: () => void
  setIsDirty?: (isDirty: boolean) => void
  hideCloseButton?: boolean
} & PaymentWorkflowOptionConfig

const tilledDisabledOptions = [
  'Payment Link',
  'ACH',
  'Credit Card',
] satisfies PaymentWorkflowOption[]

export const PaymentWorkflowWizard = React.memo<PaymentWorkflowWizardProps>(
  ({
    meta,
    paymentInfo,
    onCancel: externalOnCancel,
    onBack: externalOnBack,
    disabledOptions,
    hiddenOptions,
    mode = 'base',
    setIsDirty: externalSetIsDirty,
    onPaymentSuccess,
    onPaymentError,
    hideCloseButton = false,
  }) => {
    const tilledMerchantId = useTilledMerchantId()
    const { isProcessingPayment } = usePaymentStatusContext()
    const { showPaymentStatusModal } = usePaymentStatusModalContext()

    const accountGuid = useMemo(
      () =>
        bzExpect(
          meta.invoice?.accountGuid ?? meta.maintenancePlan?.accountGuid,
          'accountGuid',
          'Account is required to collect payment',
        ),
      [meta],
    )

    const currentDisabledOptions = useMemo<
      PaymentWorkflowOptionWithInfo[]
    >(() => {
      const existingDisabledOptions = disabledOptions ?? []
      return tilledMerchantId
        ? existingDisabledOptions
        : [
            ...existingDisabledOptions,
            ...tilledDisabledOptions.map(option => ({
              option,
              info: 'Only available for Tilled Merchants',
            })),
          ].reduce<PaymentWorkflowOptionWithInfo[]>((acc, current) => {
            if (!acc.some(item => item.option === current.option)) {
              acc.push(current)
            }
            return acc
          }, [])
    }, [disabledOptions, tilledMerchantId])

    const [currentStep, setCurrentStep] = useState(0)

    const [paymentOption, setPaymentOption] = useState<
      PaymentWorkflowOption | undefined
    >(undefined)

    const setIsProcessingPayment = useCallback(
      (isProcessingPayment: boolean) => {
        if (isProcessingPayment) {
          showPaymentStatusModal({
            mode,
            type: 'payment-processing',
            data: {
              paymentMethod:
                getPaymentWorkflowOptionToPaymentMethod(paymentOption),
            },
          })
        }
      },
      [mode, paymentOption, showPaymentStatusModal],
    )

    const defaultPaymentAmountUsc = useMemo(() => {
      if (meta.invoiceAmountDueUsd) {
        return usdToUsCents(meta.invoiceAmountDueUsd)
      } else if (meta.maintenancePlan?.totalPriceUsc) {
        return meta.maintenancePlan.totalPriceUsc
      } else {
        return 0
      }
    }, [meta.invoiceAmountDueUsd, meta.maintenancePlan?.totalPriceUsc])
    const [paymentAmountUsc, setPaymentAmountUsc] = useState<
      number | undefined
    >(defaultPaymentAmountUsc)

    const canGoBack = useMemo(
      () => !!externalOnBack || currentStep > 0,
      [currentStep, externalOnBack],
    )

    const moveForward = useCallback(
      () => setCurrentStep(currentStep => currentStep + 1),
      [setCurrentStep],
    )
    const moveBack = useCallback(() => {
      if (externalOnBack && currentStep === 0) {
        externalOnBack()
      } else {
        setCurrentStep(currentStep => currentStep - 1)
      }
    }, [setCurrentStep, externalOnBack, currentStep])

    const [isInternallyDirty, setIsInternallyDirty] = useState(false)

    const setIsDirty = useCallback(
      (isDirty: boolean) => {
        setIsInternallyDirty(isDirty)
        externalSetIsDirty?.(isDirty)
      },
      [externalSetIsDirty],
    )

    const [withConfirmation, closeConfirmProps] = useCloseConfirmModal({
      isDirty: isInternallyDirty,
    })

    const openProcessingPaymentModal = useCallback(() => {
      showPaymentStatusModal({
        mode,
        type: 'payment-processing',
        data: {
          paymentMethod: getPaymentWorkflowOptionToPaymentMethod(paymentOption),
        },
      })
    }, [mode, paymentOption, showPaymentStatusModal])

    const onCancel = useCallback(
      (force?: boolean) => {
        // Just in case something is lying to TypeScript and sneakily passing an click event object or something
        if (force === true) {
          externalOnCancel?.()
          return
        }
        if (isProcessingPayment) {
          return
        }
        withConfirmation(externalOnCancel ?? (() => {}))
      },
      [isProcessingPayment, withConfirmation, externalOnCancel],
    )

    const {
      title: SelfServePaymentTitle,
      SelfServePaymentForm,
      onSelfServePaymentBack,
    } = useSelfServePaymentWorkflow({
      accountGuid,
      defaultPaymentAmountUsc,
      paymentAmountUsc,
      invoice: meta.invoice,
      onCancel,
      onBack: moveBack,
      setIsDirty: setIsDirty,
      onPaymentSuccess,
    })

    const onBack = useMemo(() => {
      if (!canGoBack) {
        return undefined
      }

      return () => {
        if (isProcessingPayment) {
          openProcessingPaymentModal()
          return
        }

        if (paymentOption === 'Payment Link') {
          onSelfServePaymentBack()
        } else {
          withConfirmation(moveBack)
        }
      }
    }, [
      canGoBack,
      isProcessingPayment,
      paymentOption,
      openProcessingPaymentModal,
      onSelfServePaymentBack,
      withConfirmation,
      moveBack,
    ])

    useUnsavedChangesWarning(isProcessingPayment)

    const onPaymentWorkflowConfigSubmit = useCallback(
      (data: PaymentWorkflowConfigSubmitFormData) => {
        setPaymentOption(data.paymentWorkflowOption)
        setPaymentAmountUsc(usdToUsCents(data.paymentAmountUsd))
        moveForward()
      },
      [moveForward],
    )

    const title = useMemo(() => {
      switch (currentStep) {
        case 0:
          return 'Collect Payment'
        case 1: {
          const paymentOptionConfig = PAYMENT_WORKFLOW_OPTIONS.find(
            config => config.label === paymentOption,
          )
          return paymentOption === 'Payment Link'
            ? SelfServePaymentTitle
            : paymentOptionConfig?.label
        }

        default:
          return undefined
      }
    }, [currentStep, paymentOption, SelfServePaymentTitle])

    const AchWorkflow = useMemo(() => {
      if (isNullish(paymentAmountUsc)) {
        return null
      }

      if (!isNullish(meta.invoice)) {
        return (
          <TilledPaymentWorkflow
            invoice={meta.invoice}
            paymentAmountUsc={paymentAmountUsc ?? 0}
            onCancel={onCancel}
            paymentMethod={PaymentMethod.ACH}
            setIsDirty={setIsDirty}
          />
        )
      }

      if (!isNullish(meta.maintenancePlan)) {
        return (
          <TilledPaymentSubscriptionWorkflow
            onCancel={externalOnCancel ? onCancel : undefined}
            maintenancePlan={meta.maintenancePlan}
            paymentMethod={PaymentMethod.ACH}
            setIsDirty={setIsDirty}
          />
        )
      }

      return null
    }, [
      externalOnCancel,
      meta.invoice,
      meta.maintenancePlan,
      onCancel,
      paymentAmountUsc,
      setIsDirty,
    ])

    const CreditCardWorkflow = useMemo(() => {
      if (isNullish(paymentAmountUsc)) {
        return null
      }

      if (!isNullish(meta.invoice)) {
        return (
          <TilledPaymentWorkflow
            invoice={meta.invoice}
            paymentAmountUsc={paymentAmountUsc ?? 0}
            onCancel={onCancel}
            paymentMethod={PaymentMethod.CARD}
            setIsDirty={setIsDirty}
          />
        )
      }

      if (!isNullish(meta.maintenancePlan)) {
        return (
          <TilledPaymentSubscriptionWorkflow
            onCancel={externalOnCancel ? onCancel : undefined}
            maintenancePlan={meta.maintenancePlan}
            paymentMethod={PaymentMethod.CARD}
            setIsDirty={setIsDirty}
          />
        )
      }

      return null
    }, [
      externalOnCancel,
      meta.invoice,
      meta.maintenancePlan,
      onCancel,
      paymentAmountUsc,
      setIsDirty,
    ])

    const content = useMemo(
      () => (
        <PaymentWorkflowContext.Provider
          value={{
            mode,
            setIsProcessingPayment,
            onPaymentSuccess,
            onPaymentError,
          }}
        >
          <OnsiteModalContent
            onClose={hideCloseButton && currentStep < 1 ? noOp : onCancel}
            onBack={onBack}
            disabled={
              isProcessingPayment || (hideCloseButton && currentStep < 1)
            }
            header={title}
          >
            <Switch value={currentStep}>
              {{
                0: () => (
                  <PaymentWorkflowConfigForm
                    paymentInfo={paymentInfo}
                    defaultPaymentAmountUsc={
                      paymentAmountUsc ?? defaultPaymentAmountUsc
                    }
                    amountUsd={usCentsToUsd(defaultPaymentAmountUsc ?? 0)}
                    disabledOptions={currentDisabledOptions}
                    hiddenOptions={hiddenOptions}
                    onSubmit={onPaymentWorkflowConfigSubmit}
                    editableAmount={meta.editableAmount}
                    paymentAmountLabel={
                      meta.maintenancePlan
                        ? 'Recurring payment amount'
                        : undefined
                    }
                  />
                ),
                1: () => (
                  <Switch value={paymentOption}>
                    {{
                      'Payment Link': () => SelfServePaymentForm,
                      'Credit Card': () => CreditCardWorkflow,
                      ACH: () => AchWorkflow,
                      Check: () =>
                        paymentAmountUsc && meta.invoice ? (
                          <OtherPaymentWorkflow
                            paymentAmountUsc={paymentAmountUsc ?? 0}
                            invoice={meta.invoice}
                            onCancel={onCancel}
                            paymentMethod={PaymentMethod.CHECK}
                            setIsDirty={setIsDirty}
                          />
                        ) : null,
                      Cash: () =>
                        paymentAmountUsc && meta.invoice ? (
                          <OtherPaymentWorkflow
                            paymentAmountUsc={paymentAmountUsc ?? 0}
                            invoice={meta.invoice}
                            onCancel={onCancel}
                            paymentMethod={PaymentMethod.CASH}
                            setIsDirty={setIsDirty}
                          />
                        ) : null,
                      Other: () =>
                        paymentAmountUsc && meta.invoice ? (
                          <OtherPaymentWorkflow
                            paymentAmountUsc={paymentAmountUsc ?? 0}
                            invoice={meta.invoice}
                            onCancel={onCancel}
                            paymentMethod={PaymentMethod.OTHER}
                            setIsDirty={setIsDirty}
                          />
                        ) : null,

                      default: () => null,
                    }}
                  </Switch>
                ),
                default: () => null,
              }}
            </Switch>
          </OnsiteModalContent>
        </PaymentWorkflowContext.Provider>
      ),
      [
        mode,
        setIsProcessingPayment,
        onPaymentSuccess,
        onPaymentError,
        hideCloseButton,
        currentStep,
        onCancel,
        onBack,
        isProcessingPayment,
        title,
        paymentInfo,
        paymentAmountUsc,
        defaultPaymentAmountUsc,
        currentDisabledOptions,
        hiddenOptions,
        onPaymentWorkflowConfigSubmit,
        meta.editableAmount,
        meta.maintenancePlan,
        meta.invoice,
        paymentOption,
        SelfServePaymentForm,
        CreditCardWorkflow,
        AchWorkflow,
        setIsDirty,
      ],
    )

    if (mode === 'embedded') {
      return (
        <>
          {content}
          <CloseConfirmModal {...closeConfirmProps} />
        </>
      )
    }

    return (
      <>
        <OnsiteModal onClose={onCancel} size="large">
          {content}
        </OnsiteModal>
        <CloseConfirmModal {...closeConfirmProps} />
      </>
    )
  },
)

export default PaymentWorkflowWizard
