import {
  faEllipsis,
  faEllipsisVertical,
} from '@fortawesome/pro-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Button, Popover } from 'antd'
import { useState } from 'react'
import ActionItems, { ActionItem } from './ActionItems'
import './ActionItems.less'

type ActionItemsWithEllipsisProps = {
  readonly direction?: 'horizontal' | 'vertical'
  readonly actionItems: ActionItem[]
  asButton?: boolean
  buttonSize?: 'small' | 'medium' | 'large'
}

const ActionItemsWithEllipsis = ({
  direction = 'horizontal',
  actionItems,
  asButton,
  buttonSize = 'large',
}: ActionItemsWithEllipsisProps) => {
  const [popoverVisible, setPopoverVisible] = useState(false)

  return (
    <Popover
      content={
        <ActionItems
          actionItems={actionItems.map(ac => ({
            ...ac,
            onClick: () => {
              setPopoverVisible(false)
              ac.onClick()
            },
          }))}
        />
      }
      placement="bottom"
      overlayClassName="action-items-with-ellipsis"
      showArrow={false}
      trigger="click"
      onOpenChange={setPopoverVisible}
      open={popoverVisible}
    >
      {asButton ? (
        <Button
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          size={buttonSize as any}
          icon={
            <FontAwesomeIcon
              icon={
                direction === 'horizontal' ? faEllipsis : faEllipsisVertical
              }
              className="h-4 w-4 rounded-full p-1 text-bz-gray-800 hover:cursor-pointer hover:bg-gray-300 hover:text-white"
            />
          }
        />
      ) : (
        <FontAwesomeIcon
          icon={direction === 'horizontal' ? faEllipsis : faEllipsisVertical}
          className="h-4 w-4 rounded-full p-1 text-bz-gray-800 hover:cursor-pointer hover:bg-gray-300 hover:text-white"
        />
      )}
    </Popover>
  )
}

export default ActionItemsWithEllipsis
