import { InvoiceTerm, isNullish } from '@breezy/shared'
import { useCallback, useMemo, useState } from 'react'
import { OnsitePageCollapsibleSection } from 'src/adam-components/OnsitePage/OnsitePageCollapsibleSection'
import {
  InvoiceCard,
  InvoiceCardInvoice,
} from 'src/components/Cards/InvoiceCard'
import GqlQueryLoader from 'src/components/GqlQueryLoader/GqlQueryLoader'
import { LoadingSpinner } from 'src/components/LoadingSpinner'
import { InvoicesBoolExp } from 'src/generated/user/graphql'
import useAppNavigation from 'src/hooks/useAppNav'
import { useInvoiceTemplates } from 'src/hooks/useInvoiceTemplates'
import { useIsOfficeApp } from 'src/hooks/useIsOfficeApp'
import { useExpectedCompanyTimeZoneId } from 'src/providers/PrincipalUser'
import { useSubscription } from 'urql'
import { INVOICE_COLLAPSIBLE_DATA_SUBSCRIPTION } from './InvoicesCollapsible.gql'
import InvoiceTemplatePicker from './InvoiceTemplatePicker'

export interface InvoicesV2CollapsibleV2Props {
  links: {
    accountGuid: string
    jobGuid?: string
    maintenancePlanGuid?: string
    maintenancePlanVisitLinkedJobGuids?: string[]
    locationGuid?: string
    jobAppointmentGuid?: string
    technicianAppStyle?: boolean
  }
  allowExternalCreate?: boolean
}

export const InvoicesV2CollapsibleV2 = (
  props: InvoicesV2CollapsibleV2Props,
) => {
  const timezoneId = useExpectedCompanyTimeZoneId()
  const isOfficeApp = useIsOfficeApp()
  const { templates } = useInvoiceTemplates()
  const allowCreate =
    props.allowExternalCreate && !isNullish(props.links.jobGuid)
  const shouldShowTemplatePicker = isOfficeApp && templates.length > 0
  const [isCreatingInvoice, setIsCreatingInvoice] = useState(false)

  const whereClause = useMemo(() => {
    const whereClause: InvoicesBoolExp = {
      accountGuid: { _eq: props.links.accountGuid },
    }
    if (props.links.jobGuid) {
      whereClause.jobLink = { jobGuid: { _eq: props.links.jobGuid } }
    }
    if (props.links.maintenancePlanGuid) {
      const maintenancePlanPredicates: InvoicesBoolExp[] = [
        {
          maintenancePlanLink: {
            maintenancePlanGuid: { _eq: props.links.maintenancePlanGuid },
          },
        },
      ]

      if (props.links.maintenancePlanVisitLinkedJobGuids?.length) {
        maintenancePlanPredicates.push({
          jobLink: {
            jobGuid: { _in: props.links.maintenancePlanVisitLinkedJobGuids },
          },
        })
      }
      whereClause._or = maintenancePlanPredicates
    }

    if (props.links.locationGuid) {
      whereClause.locationLink = {
        locationGuid: { _eq: props.links.locationGuid },
      }
    }
    if (props.links.jobAppointmentGuid) {
      whereClause.jobAppointmentLink = {
        jobAppointmentGuid: { _eq: props.links.jobAppointmentGuid },
      }
    }

    return whereClause
  }, [
    props.links.accountGuid,
    props.links.jobAppointmentGuid,
    props.links.jobGuid,
    props.links.locationGuid,
    props.links.maintenancePlanGuid,
    props.links.maintenancePlanVisitLinkedJobGuids,
  ])

  const invoicesQuery = useSubscription({
    query: INVOICE_COLLAPSIBLE_DATA_SUBSCRIPTION,
    variables: {
      where: whereClause,
    },
  })

  const invoices = useMemo<InvoiceCardInvoice[]>(() => {
    if (!invoicesQuery[0].data) {
      return []
    }

    const invoices = props.links.jobGuid
      ? invoicesQuery[0].data.invoices.filter(
          invoice => invoice.jobLink?.jobGuid === props.links.jobGuid,
        )
      : invoicesQuery[0].data.invoices

    return invoices.map(invoice => ({
      invoiceGuid: invoice.invoiceGuid,
      displayId: invoice.displayId.toString(),
      issuedAt: invoice.issuedAt,
      status: invoice.status,
      totalUsc: invoice.totalUsc,
      invoiceTerm: invoice.invoiceTerm as unknown as InvoiceTerm,
      job: invoice.jobLink
        ? {
            jobGuid: invoice.jobLink.jobGuid,
            displayId: invoice.jobLink.job.displayId.toString(),
            jobTypeName: invoice.jobLink.job.jobType.name,
          }
        : undefined,
      maintenancePlan: invoice.maintenancePlanLink
        ? {
            maintenancePlanGuid:
              invoice.maintenancePlanLink.maintenancePlanGuid,
          }
        : undefined,
    }))
  }, [invoicesQuery, props.links.jobGuid])

  const appNav = useAppNavigation()

  const onCreateInvoice = useCallback(() => {
    if (!allowCreate || !props.links.jobGuid) {
      return
    }

    if (shouldShowTemplatePicker) {
      setIsCreatingInvoice(true)
    } else {
      appNav.navigateToCreateNewInvoiceV2(
        props.links.accountGuid,
        props.links.jobGuid,
        {
          jobAppointmentGuid: props.links.jobAppointmentGuid,
        },
      )
    }
  }, [
    shouldShowTemplatePicker,
    allowCreate,
    appNav,
    props.links.accountGuid,
    props.links.jobAppointmentGuid,
    props.links.jobGuid,
  ])

  return (
    <>
      <GqlQueryLoader
        query={invoicesQuery}
        render={() => (
          <OnsitePageCollapsibleSection
            smallTitle
            title="Invoices"
            count={invoices.length}
            defaultCollapsed={invoices.length === 0}
            onAdd={allowCreate ? onCreateInvoice : undefined}
            testId="invoices-collapsible"
            addButtonTestId="invoices-collapsible-add-button"
          >
            <div className="flex flex-col gap-2">
              {invoices.map(invoice => (
                <InvoiceCard
                  key={invoice.invoiceGuid}
                  invoice={invoice}
                  timezoneId={timezoneId}
                />
              ))}
            </div>
          </OnsitePageCollapsibleSection>
        )}
        loadingComponent={
          <OnsitePageCollapsibleSection title="Invoices" smallTitle>
            <LoadingSpinner />
          </OnsitePageCollapsibleSection>
        }
        errorComponent={
          <OnsitePageCollapsibleSection title="Invoices" smallTitle>
            <span>Failed to load invoices</span>
          </OnsitePageCollapsibleSection>
        }
      />
      {props.links.jobGuid && isCreatingInvoice && (
        <InvoiceTemplatePicker
          onCancel={() => setIsCreatingInvoice(false)}
          accountGuid={props.links.accountGuid}
          jobGuid={props.links.jobGuid}
          templates={templates}
          jobAppointmentGuid={props.links.jobAppointmentGuid}
        />
      )}
    </>
  )
}
