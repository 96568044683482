import {
  ComprehensiveAppointmentDetails,
  DateTimeFormatter,
  ENGLISH_LOCALE,
  MatchToShape,
  ZoneId,
  truncate,
} from '@breezy/shared'
import {
  faCalendar,
  faExclamationTriangle,
} from '@fortawesome/pro-light-svg-icons'
import { faCircleExclamation } from '@fortawesome/pro-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Modal, Tooltip, Typography } from 'antd'
import React, { useCallback, useMemo, useState } from 'react'
import { ActionItem } from '../../../elements/ActionItems/ActionItems'
import { EmDash } from '../../../elements/EmDash/EmDash'
import { MaybeDataPoint } from '../../../elements/MaybeDataPoint/MaybeDataPoint'
import { useCanManageSchedule } from '../../../hooks/permission/useCanManageSchedule'
import { trpc } from '../../../hooks/trpc'
import { useIsOfficeApp } from '../../../hooks/useIsOfficeApp'
import { useIsTechnicianApp } from '../../../hooks/useIsTechnicianApp'
import { AppointmentStatusTag } from '../../../pages/ScheduleV2Page/AppointmentStatusTag'
import { useExpectedCompany } from '../../../providers/PrincipalUser'
import { alertOrange } from '../../../themes/theme'
import { useMessage } from '../../../utils/antd-utils'
import { useQueryParamState } from '../../../utils/react-utils'
import { DateFormat, DateTimeWindow } from '../../Dates'
import UpsertAppointmentDrawer from '../../NewAppointmentModal/UpsertAppointmentDrawer'
import BzCollapsible from '../../Page/BzCollapsible/BzCollapsible'
import CollapsibleItem from '../../Page/BzCollapsible/CollapsibleItem/CollapsibleItem'
import { ChecklistsDrawer } from './ChecklistsDrawer'

export type AppointmentsCollapsibleAppointment = MatchToShape<
  ComprehensiveAppointmentDetails,
  {
    appointmentGuid: true
    timeWindow: true
    canceled: true
    appointmentStatus: true
    appointmentType: true
    appointmentReferenceNumber: true
    address: true
    description: true
    jobType: {
      jobClass: true
      name: true
    }
    assignments: {
      assignmentGuid: true
      technicianUserGuid: true
      timeWindow: true
      technician: {
        user: {
          firstName: true
          lastName: true
        }
      }
    }
    appointmentChecklistInstances: true
    endOfAppointmentNextSteps: true
    sendConfirmationEnabled: true
    notificationType: true
    sendConfirmationTo: true
    sendReminderEnabled: true
    locationGuid: true
    displayId: true
    jobGuid: true
  }
>

type AppointmentsCollapsibleProps = {
  appointments: AppointmentsCollapsibleAppointment[]
  jobGuid?: string
  onAppointmentEdited?: () => void
  onPlus?: () => void
  editable?: boolean
}

export const AppointmentsCollapsible = React.memo<AppointmentsCollapsibleProps>(
  ({ appointments, jobGuid, onAppointmentEdited, onPlus, editable = true }) => {
    const message = useMessage()

    const isOfficeApp = useIsOfficeApp()
    const isTechnicianApp = useIsTechnicianApp()

    const [upsertAppointmentDrawerIsOpen, setUpsertAppointmentDrawerIsOpen] =
      useState(false)
    const [appointmentToCancel, setAppointmentToCancel] = useState<
      AppointmentsCollapsibleAppointment | undefined
    >(undefined)
    const [selectedAppointment, setSelectedAppointment] =
      useState<AppointmentsCollapsibleAppointment>()

    const canManageSchedule = useCanManageSchedule()

    const canManageAppointments = useMemo(() => {
      // BZ-516: use the tech permissions here
      if (isTechnicianApp) return true

      if (isOfficeApp) {
        return canManageSchedule
      }

      return true
    }, [isOfficeApp, isTechnicianApp, canManageSchedule])

    const company = useExpectedCompany()

    const timezone = ZoneId.of(company.timezone)

    const cancelAppointmentMutation = trpc.appointments[
      'appointments:cancel'
    ].useMutation({
      onSuccess: () => {
        message.success(`Appointment canceled`)
        editable && onAppointmentEdited && onAppointmentEdited()
      },
    })

    const [checklistAppointmentGuid, setChecklistAppointmentGuid] =
      useQueryParamState('checklistsAppointment', '')

    const selectedChecklistAppointment = useMemo(
      () =>
        appointments.find(
          appointment =>
            appointment.appointmentGuid === checklistAppointmentGuid,
        ),
      [appointments, checklistAppointmentGuid],
    )

    const appointmentsSorted = useMemo(() => {
      const sorted = [...appointments]
      sorted.sort((a, b) => {
        if (b.timeWindow.start.isBefore(a.timeWindow.start)) {
          return -1
        } else if (b.timeWindow.start.isEqual(a.timeWindow.start)) {
          return 0
        } else {
          return 1
        }
      })
      return sorted
    }, [appointments])

    const onChecklistDrawerClose = useCallback(
      () => setChecklistAppointmentGuid(),
      [setChecklistAppointmentGuid],
    )

    return (
      <div>
        <BzCollapsible
          title="Appointments"
          titleIcon={faCalendar}
          onPlus={editable ? onPlus : undefined}
        >
          {appointmentsSorted.map((appointment, appointmentIndex) => {
            const actionItems: ActionItem[] = []
            const onEdit =
              editable &&
              jobGuid &&
              !appointment.canceled &&
              onAppointmentEdited &&
              canManageAppointments
                ? () => {
                    setSelectedAppointment(appointment)
                    setUpsertAppointmentDrawerIsOpen(true)
                  }
                : undefined

            if (
              editable &&
              !appointment.canceled &&
              canManageAppointments &&
              appointment.appointmentStatus !== 'COMPLETED'
            ) {
              actionItems.push({
                title: 'Cancel Visit',
                onClick: () => {
                  setAppointmentToCancel(appointment)
                },
              })
            }

            const startDate = appointment.timeWindow.start
              .withZoneSameInstant(timezone)
              .format(
                DateTimeFormatter.ofPattern(
                  DateFormat['MMM d, yyyy'],
                ).withLocale(ENGLISH_LOCALE),
              )

            const contentList = [
              {
                key: 'Arrival Window:',
                value: (
                  <DateTimeWindow
                    includeDate={false}
                    overrideClassName="regular_14_22 grey9"
                    startWindowIsoWithOffsetTimestamp={
                      appointment.timeWindow.toDto().start
                    }
                    endWindowIsoWithOffsetTimestamp={
                      appointment.timeWindow.toDto().end
                    }
                  />
                ),
              },
              {
                key: 'Address:',
                value: appointment.address.streetAddressLine1And2Condensed(),
              },
              {
                key: 'Assigned Tech(s):',
                value:
                  appointment.assignments.length > 0
                    ? appointment.assignments
                        .map(
                          assignment =>
                            `${assignment.technician.user.firstName} ${assignment.technician.user.lastName}`,
                        )
                        .join(', ')
                    : '—',
              },
              {
                key: 'Description:',
                value: (
                  <MaybeDataPoint>{appointment.description}</MaybeDataPoint>
                ),
              },
            ]
            if (appointment.appointmentChecklistInstances?.length) {
              contentList.push({
                key: 'Checklists:',
                value: (
                  <Typography.Link
                    onClick={e => {
                      e.stopPropagation()
                      setChecklistAppointmentGuid(appointment.appointmentGuid)
                    }}
                  >
                    View
                  </Typography.Link>
                ),
              })
            }
            if (appointment.endOfAppointmentNextSteps) {
              contentList.push({
                key: 'Onsite Work Complete:',
                value: (
                  <>
                    {appointment.endOfAppointmentNextSteps.data
                      .allOnsiteWorkCompleted ? (
                      <>Yes</>
                    ) : (
                      <Tooltip
                        title={
                          appointment.endOfAppointmentNextSteps.data
                            .allOnsiteWorkNotCompletedDetails
                        }
                      >
                        {truncate(
                          [
                            'No',
                            appointment.endOfAppointmentNextSteps.data
                              .allOnsiteWorkNotCompletedDetails,
                          ].join(' - '),
                          70,
                          '...',
                        )}
                      </Tooltip>
                    )}
                  </>
                ),
              })

              contentList.push({
                key: 'Dispatch Note:',
                value: (
                  <>
                    {appointment.endOfAppointmentNextSteps.data
                      .dispatcherNote ? (
                      <Tooltip
                        title={
                          appointment.endOfAppointmentNextSteps.data
                            .dispatcherNote
                        }
                      >
                        {truncate(
                          appointment.endOfAppointmentNextSteps.data
                            .dispatcherNote,
                          70,
                          '...',
                        )}
                      </Tooltip>
                    ) : (
                      <EmDash />
                    )}
                  </>
                ),
              })
            }

            return (
              <CollapsibleItem
                key={appointment.appointmentGuid}
                statusIndicator={
                  appointment.endOfAppointmentNextSteps?.data
                    .allOnsiteWorkCompleted === false ? (
                    <Tooltip title="Onsite work incomplete">
                      <FontAwesomeIcon
                        icon={faCircleExclamation}
                        color={alertOrange}
                        fontSize={16}
                        style={{ backgroundColor: '#fff', borderRadius: '50%' }}
                      />
                    </Tooltip>
                  ) : undefined
                }
                title={
                  <div>
                    <span className="mr-2 whitespace-nowrap">
                      {appointment.appointmentType}, {startDate}
                    </span>
                    <AppointmentStatusTag
                      status={appointment.appointmentStatus}
                    />
                  </div>
                }
                refId={appointment.appointmentReferenceNumber}
                defaultOpen={appointmentIndex < 3}
                onEdit={onEdit}
                contentList={contentList}
                actionItems={actionItems}
              />
            )
          })}
        </BzCollapsible>
        {jobGuid && editable && onAppointmentEdited && selectedAppointment && (
          <UpsertAppointmentDrawer
            mode="edit"
            open={upsertAppointmentDrawerIsOpen}
            onCancel={() => setUpsertAppointmentDrawerIsOpen(false)}
            onAppointmentEdited={() => {
              onAppointmentEdited()
              setUpsertAppointmentDrawerIsOpen(false)
            }}
            jobGuid={jobGuid}
            jobClass={selectedAppointment.jobType.jobClass}
            comprehensiveAppointment={selectedAppointment}
            appointmentGuid={selectedAppointment.appointmentGuid}
          />
        )}
        <Modal
          title="Are you sure?"
          open={!!appointmentToCancel}
          onOk={() => {
            appointmentToCancel &&
              cancelAppointmentMutation.mutate({
                jobAppointmentGuid: appointmentToCancel.appointmentGuid,
              })
            setAppointmentToCancel(undefined)
          }}
          onCancel={() => setAppointmentToCancel(undefined)}
          okButtonProps={{ danger: true }}
        >
          <div className="column">
            <FontAwesomeIcon
              icon={faExclamationTriangle}
              size="4x"
              color="#ffcc33"
              style={{ marginBottom: 12 }}
            />
            <div className="text-center">
              Once you cancel this appointment, you will not be able to
              un-cancel it.
            </div>
          </div>
        </Modal>
        {selectedChecklistAppointment?.appointmentChecklistInstances?.length ? (
          <ChecklistsDrawer
            appointment={selectedChecklistAppointment}
            onClose={onChecklistDrawerClose}
          />
        ) : null}
      </div>
    )
  },
)
