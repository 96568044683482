import { gql } from '../../generated'

export const UPSERT_INVOICE_TEMPLATE_MUTATION = gql(/* GraphQL */ `
  mutation UpsertInvoiceTemplate(
    $invoiceTemplateGuid: uuid!
    $template: InvoiceTemplatesInsertInput!
    $validCartItemGuids: [uuid!]!
  ) {
    insertInvoiceTemplatesOne(
      object: $template
      onConflict: {
        constraint: invoice_templates_pkey
        updateColumns: [updatedAt, templateName, isDeleted]
      }
    ) {
      __typename
    }
    deleteCartItems(
      where: {
        invoiceTemplateCartItems: {
          invoiceTemplateGuid: { _eq: $invoiceTemplateGuid }
          cartItemGuid: { _nin: $validCartItemGuids }
        }
      }
    ) {
      __typename
    }
  }
`)

export const DELETE_INVOICE_TEMPLATE_MUTATION = gql(/* GraphQL */ `
  mutation MarkInvoiceTemplateDeleted($invoiceTemplateGuid: uuid!) {
    updateInvoiceTemplatesByPk(
      pkColumns: { invoiceTemplateGuid: $invoiceTemplateGuid }
      _set: { isDeleted: true }
    ) {
      isDeleted
    }
  }
`)

export const READ_INVOICE_TEMPLATES_QUERY = gql(/* GraphQL */ `
  query ReadInvoiceTemplates($companyGuid: uuid!) {
    invoiceTemplates(
      where: {
        companyGuid: { _eq: $companyGuid }
        _and: { isDeleted: { _eq: false } }
      }
    ) {
      invoiceTemplateGuid
      templateName
      updatedAt
      invoiceTemplateCartItems {
        cartItem {
          cartItemGuid
          cartItemType
          companyGuid
          createdAt
          description
          isDiscountable
          isTaxable
          name
          originalPricebookItemGuid
          photoGuid
          quantity
          unitPriceUsc
          updatedAt
          photo {
            cdnUrl
            photoGuid
          }
        }
      }
    }
  }
`)
