import { useMemo } from 'react'
import { OnsitePageCollapsibleSection } from 'src/adam-components/OnsitePage/OnsitePageCollapsibleSection'
import { useCanManageTechnicianPerformance } from 'src/hooks/permission/useCanManageTechnicianPerformance'
import { useCanViewTechnicianPerformance } from 'src/hooks/permission/useCanViewTechnicianPerformance'
import { TechnicianRevenueAttributionCard } from './TechnicianRevenueAttributionCard'

export type TechnicianPerformanceInfo = {
  technician: {
    userGuid: string
    name: string
  }
  totalEarnedRevenueUsc: number
  totalSoldRevenueUsc: number
  totalDeductionsUsc: number
  commissionableBaseUsc?: number
  commissionUsc?: number
  commissionRate?: number
  bonusUsc?: number
}

const DEFAULT_TECHNICIAN_PERFORMANCE_INFO: TechnicianPerformanceInfo = {
  totalDeductionsUsc: 0,
  totalEarnedRevenueUsc: 0,
  totalSoldRevenueUsc: 0,
  technician: { name: '', userGuid: '' },
}

export interface TechnicianRevenueAttributionSectionProps {
  technicianEarnedRevenueSummaries: {
    technician: {
      userGuid: string
      name: string
    }
    totalEarnedRevenueUsc?: number
  }[]
  technicianSalesCommissionsAndBonuses: {
    technician: {
      userGuid: string
      name: string
    }
    totalDeductionsUsc?: number
    totalSoldRevenueUsc?: number
    commissionableBaseUsc?: number
    commissionUsc?: number
    commissionRate?: number
    bonusUsc?: number
  }[]
  onEdit?: () => void
}

export const TechnicianRevenueAttributionSection = (
  props: TechnicianRevenueAttributionSectionProps,
) => {
  const canManageTechnicianPerformance = useCanManageTechnicianPerformance()

  const canViewTechnicianPerformance = useCanViewTechnicianPerformance()

  const technicianPerformances: TechnicianPerformanceInfo[] = useMemo(() => {
    const performances: Record<string, TechnicianPerformanceInfo> = {}

    for (const earnedRevenueSummary of props.technicianEarnedRevenueSummaries) {
      performances[earnedRevenueSummary.technician.userGuid] = {
        ...DEFAULT_TECHNICIAN_PERFORMANCE_INFO,
        technician: {
          userGuid: earnedRevenueSummary.technician.userGuid,
          name: earnedRevenueSummary.technician.name,
        },
        totalEarnedRevenueUsc:
          earnedRevenueSummary.totalEarnedRevenueUsc ??
          DEFAULT_TECHNICIAN_PERFORMANCE_INFO.totalEarnedRevenueUsc,
      }
    }

    for (const commissionAndBonus of props.technicianSalesCommissionsAndBonuses) {
      const existingPerformance = performances[
        commissionAndBonus.technician.userGuid
      ] ?? {
        ...DEFAULT_TECHNICIAN_PERFORMANCE_INFO,
        technician: {
          userGuid: commissionAndBonus.technician.userGuid,
          name: commissionAndBonus.technician.name,
        },
      }

      performances[commissionAndBonus.technician.userGuid] = {
        ...existingPerformance,
        totalDeductionsUsc:
          commissionAndBonus.totalDeductionsUsc ??
          DEFAULT_TECHNICIAN_PERFORMANCE_INFO.totalDeductionsUsc,
        totalSoldRevenueUsc:
          commissionAndBonus.totalSoldRevenueUsc ??
          DEFAULT_TECHNICIAN_PERFORMANCE_INFO.totalSoldRevenueUsc,
        commissionableBaseUsc: commissionAndBonus.commissionableBaseUsc,
        commissionUsc: commissionAndBonus.commissionUsc,
        commissionRate: commissionAndBonus.commissionRate,
        bonusUsc: commissionAndBonus.bonusUsc,
      }
    }

    return Object.values(performances)
  }, [
    props.technicianEarnedRevenueSummaries,
    props.technicianSalesCommissionsAndBonuses,
  ])

  if (!canViewTechnicianPerformance) {
    return null
  }

  return (
    <OnsitePageCollapsibleSection
      title={
        <span className="text-base font-semibold">Revenue Attribution</span>
      }
      count={technicianPerformances.length}
      defaultCollapsed={technicianPerformances.length === 0}
    >
      <div className="flex flex-col gap-2">
        {technicianPerformances.map(performance => (
          <TechnicianRevenueAttributionCard
            key={performance.technician.userGuid}
            technician={{
              name: performance.technician.name,
              revenueAttribution: {
                soldRevenueUsc: performance.totalSoldRevenueUsc,
                deductionsUsc: performance.totalDeductionsUsc,
                commissionableBaseUsc: performance.commissionableBaseUsc,
                commissionRate: performance.commissionRate,
                earnedRevenueUsc: performance.totalEarnedRevenueUsc,
                commissionUsc: performance.commissionUsc,
                bonusUsc: performance.bonusUsc,
              },
            }}
            canManageTechnicianPerformance={canManageTechnicianPerformance}
            onEdit={props.onEdit}
          />
        ))}
      </div>
    </OnsitePageCollapsibleSection>
  )
}
