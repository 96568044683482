import {
  BzDateTime,
  formatLoanAmount,
  formatPrequalAmount,
  LoanRecord,
  LoanStatusDisplayNames,
  PrequalRecord,
  PrequalStatusDisplayNames,
  TimeZoneId,
} from '@breezy/shared'
import { useMemo } from 'react'
import { LabeledItemGrid } from 'src/adam-components/LabeledItemGrid'
import { SectionedCard } from 'src/adam-components/SectionedCard/SectionedCard'
import { EmDash } from 'src/elements/EmDash/EmDash'
import { gql } from 'src/generated'
import { useQuery } from 'urql'
import LoanDetailsLink from '../Financing/LoanDetailsLink/LoanDetailsLink'
import LoanStatusTag from '../Financing/LoanStatusTag/LoanStatusTag'
import GqlQueryLoader from '../GqlQueryLoader/GqlQueryLoader'
import { LoadingSpinner } from '../LoadingSpinner'
import PrequalStatusTag from '../PrequalStatusTag/PrequalStatusTag'

export interface WisetackFinancingLoanRecordCardProps {
  // TODO: Refactor this to only take in the minimal amount of data for a
  // Loan Record
  loanRecord: LoanRecord
  timezoneId: TimeZoneId
}

export const WisetackFinancingLoanRecordCard = (
  props: WisetackFinancingLoanRecordCardProps,
) => {
  const { loanRecord } = props

  return (
    <SectionedCard
      small
      dashed
      sections={[
        {
          verticalPaddingClassName: 'p-3',
          content: (
            <div className="flex flex-row items-center justify-between">
              <LoanDetailsLink
                record={loanRecord}
                text="Loan Application"
                className="font-semibold"
              />

              <LoanStatusTag
                status={loanRecord.latestLoanRecordStatus.loanStatus}
              />
            </div>
          ),
        },
        {
          content: (
            <LabeledItemGrid
              items={[
                'Name',
                loanRecord.contactName,
                'Last Update',
                BzDateTime.fromIsoString(
                  loanRecord.updatedAt,
                  props.timezoneId,
                ).toDateFormat('MMMM dd, yyyy'),
                'Status',
                LoanStatusDisplayNames[
                  loanRecord.latestLoanRecordStatus.loanStatus
                ],
                'Amount',
                formatLoanAmount(loanRecord),
              ]}
            />
          ),
        },
      ]}
    />
  )
}

export const PREQUAL_CONTACT_NAME_QUERY = gql(/* GraphQL */ `
  query WisetackFinancingPrequalRecordCardQuery(
    $where: WisetackPrequalRecordsBoolExp!
  ) {
    wisetackPrequalRecords(where: $where) {
      contact {
        fullName
      }
    }
  }
`)

export interface WisetackFinancingPrequalRecordCardProps {
  // TODO: Refactor this to only take in the minimal amount of data for a
  // Prequal Record
  prequalRecord: PrequalRecord
  timezoneId: TimeZoneId
}

export const WisetackFinancingPrequalRecordCard = (
  props: WisetackFinancingPrequalRecordCardProps,
) => {
  const { prequalRecord } = props

  const prequalContactNameQuery = useQuery({
    query: PREQUAL_CONTACT_NAME_QUERY,
    variables: {
      where: {
        wisetackPrequalRecordGuid: { _eq: prequalRecord.prequalRecordGuid },
      },
    },
  })

  const contactInfo = useMemo(() => {
    if (
      !prequalContactNameQuery[0].data ||
      !prequalContactNameQuery[0].data.wisetackPrequalRecords[0]
    ) {
      return null
    }

    return {
      name: prequalContactNameQuery[0].data.wisetackPrequalRecords[0].contact
        .fullName,
    }
  }, [prequalContactNameQuery])

  return (
    <GqlQueryLoader
      query={prequalContactNameQuery}
      render={() => (
        <SectionedCard
          small
          dashed
          sections={[
            {
              verticalPaddingClassName: 'p-3',
              content: (
                <div className="flex flex-row items-center justify-between">
                  <LoanDetailsLink
                    record={prequalRecord}
                    text="Pre-qualification"
                    className="font-semibold"
                  />

                  <PrequalStatusTag status={prequalRecord.status} />
                </div>
              ),
            },
            {
              content: (
                <LabeledItemGrid
                  items={[
                    'Name',
                    contactInfo ? contactInfo.name : <EmDash />,
                    'Last Update',
                    BzDateTime.fromIsoString(
                      prequalRecord.updatedAt,
                      props.timezoneId,
                    ).toDateFormat('MMMM dd, yyyy'),
                    'Status',
                    PrequalStatusDisplayNames[prequalRecord.status],
                    'Amount',
                    formatPrequalAmount(prequalRecord),
                  ]}
                />
              ),
            },
          ]}
        />
      )}
      loadingComponent={
        <SectionedCard
          small
          dashed
          sections={[{ content: <LoadingSpinner /> }]}
        />
      }
      errorComponent={
        <SectionedCard
          small
          dashed
          sections={[
            { content: <span>Failed to load pre-qualification info</span> },
          ]}
        />
      }
    />
  )
}

export const WisetackFinancingCard = {
  LoanRecordCard: WisetackFinancingLoanRecordCard,
  PrequalRecordCard: WisetackFinancingPrequalRecordCard,
}
