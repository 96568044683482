import { LinkedNoteViewModel, NoteLinkData, NoteLinks } from '@breezy/shared'
import React, { useMemo } from 'react'
import { useSearchParam } from 'react-use'
import { trpc } from '../../../hooks/trpc'
import { useRequery } from '../../../hooks/useRequery'
import TrpcQueryLoader from '../../TrpcQueryLoader'
import { NoteRowItem } from '../NoteRowItem/NoteRowItem'

export const URL_HIGHLIGHT_NOTE_KEY = 'highlightNoteId'

export type LinkedNotesViewProps = {
  readonly noteLinks: NoteLinks
  // TECH DEBT: Why the **** do we have Note Links and Note Link Data...? Ugh!
  readonly linkData: NoteLinkData
  readonly displayCondition?: (n: LinkedNoteViewModel) => boolean
  readonly maxNotes?: number
  readonly version?: number
  readonly noNotesComponent?: JSX.Element
  readonly onMutate?: () => void
  editable?: boolean

  /**
   * A callback that is executed when the notes have been fetched
   *
   * TODO: Refactor LinkedNotesListLoader so that we extract the actual rendering
   * of the notes into a reusable component that can be plugged in anywhere.
   */
  onNotesLoaded?: (notes: LinkedNoteViewModel[]) => void
}

const LinkedNotesListLoader = React.memo<LinkedNotesViewProps>(
  ({
    noteLinks,
    linkData,
    displayCondition = () => true,
    maxNotes = 1000,
    version = 0,
    noNotesComponent,
    onMutate,
    editable = true,
    onNotesLoaded,
  }) => {
    const query = trpc.notes['notes:get-linked'].useQuery(
      { ...noteLinks },
      {
        onSuccess: data => {
          onNotesLoaded?.(data)
        },
      },
    )
    useRequery({ query, version })

    const usersQuery = trpc.user['users:get'].useQuery()
    useRequery({ query: usersQuery, version })

    const userMap = useMemo(
      () =>
        (usersQuery.data?.users || []).reduce(
          (map, user) => ({
            ...map,
            [user.userGuid]: user,
          }),
          {} as Record<
            string,
            NonNullable<(typeof usersQuery)['data']>['users'][number]
          >,
        ),
      [usersQuery.data?.users],
    )

    const urlHighlightedNote = useSearchParam(URL_HIGHLIGHT_NOTE_KEY)

    return (
      <TrpcQueryLoader
        query={query}
        render={data => {
          const filtered = data.filter(displayCondition)
          return (
            <>
              {filtered.length === 0 &&
                (noNotesComponent || (
                  <div className="center-children-vh h-16 w-full">
                    No notes found.
                  </div>
                ))}
              {filtered.length > 0 && (
                <div>
                  {filtered.slice(0, maxNotes).map(note => (
                    <NoteRowItem
                      key={note.noteGuid}
                      note={note}
                      userMap={userMap}
                      highlighted={urlHighlightedNote === note.noteGuid}
                      onMutate={onMutate || query.refetch}
                      linkData={linkData}
                      editable={editable}
                    />
                  ))}
                </div>
              )}
              {filtered.length > maxNotes && <h3 className="w-full">...</h3>}
            </>
          )
        }}
      />
    )
  },
)

export default LinkedNotesListLoader
