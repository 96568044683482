import { ComprehensiveAccountDetails } from '@breezy/backend/src/application-types'
import {
  Account,
  AccountJob,
  BzDateTime,
  ComprehensiveMaintenancePlanConfigViewModel,
  LocalDateString,
  MaintenancePlanConfiguredEventData,
  MaintenancePlanCreationRequest,
  MaintenancePlanDefinition,
  MaintenancePlanDetailsViewModel,
  PaymentMethod,
  TaxRateSetEventData,
  VisitEquipmentViewModel,
  VisitJobViewModel,
  VisitStatus,
  VisitViewModel,
  isNullish,
  nextGuid,
  usCentsToUsd,
} from '@breezy/shared'
import { zodResolver } from '@hookform/resolvers/zod'
import { Button } from 'antd'
import classNames from 'classnames'
import React, { memo, useCallback, useMemo, useRef, useState } from 'react'
import ReactDOM from 'react-dom'
import { FormProvider, useForm } from 'react-hook-form'
import { OnsitePageContainer } from '../../adam-components/OnsitePage/OnsitePageContainer'
import { BlockerFunction, useBlocker } from '../../providers/BlockerWrapper'

import { BzDateFns } from '@breezy/shared'
import { datadogRum } from '@datadog/browser-rum'
import { useCompanyHiddenPaymentMethods } from 'src/hooks/useCompanyHiddenPaymentMethods'
import {
  OnsiteConfirmModal,
  OnsiteModal,
} from '../../adam-components/OnsiteModal/OnsiteModal'
import FullScreenModal from '../../components/FullScreenModal/FullScreenModal'
import { LoadingSpinner } from '../../components/LoadingSpinner'
import {
  MaintenancePlanActivationInput,
  PaymentWorkflowWizard,
} from '../../components/PaymentWorkflow/PaymentWorkflowWizard'
import { usePaymentStatusModalContext } from '../../components/PaymentWorkflow/providers/PaymentStatusModalProvider'
import AccountSearchAutocomplete from '../../components/ProgressiveJobCreationModal/AccountSearchAutocomplete/AccountSearchAutocomplete'
import CreationStepHeading from '../../components/ProgressiveJobCreationModal/JobCreationSteps/CreationStepHeading'
import TrpcQueryLoader from '../../components/TrpcQueryLoader'
import { UploadingStatusContent } from '../../components/UploadingStatusContent/UploadingStatusContent'
import FatDivider from '../../elements/FatDivider/FatDivider'
import { useFetchAbridgedInvoiceByGuid } from '../../hooks/fetch/useFetchAbridgedInvoiceByGuid'
import { useFetchAccountByAccountGuidQuery } from '../../hooks/fetch/useFetchAccountByGuid'
import { useFetchComprehensiveAccountDetails } from '../../hooks/fetch/useFetchComprehensiveAccountDetails'
import { trpc } from '../../hooks/trpc'
import { useCompanyMaintenancePlanRenewalType } from '../../hooks/useCompanyMaintenancePlanRenewalType'
import { SetIsDirty } from '../../hooks/useExternalIsDirty'
import useIsMobile from '../../hooks/useIsMobile'
import {
  useExpectedCompanyGuid,
  useExpectedCompanyTimeZoneId,
} from '../../providers/PrincipalUser'
import { useMessage } from '../../utils/antd-utils'
import {
  LOAD_PREVIOUS_QUERY_STRING_ON_MOUNT_KEY_PREFIX,
  useModalState,
  useQueryParamFlag,
} from '../../utils/react-utils'
import { ConfigureMaintenancePlanPanel } from './ConfigureMaintenancePlanPanel/ConfigureMaintenancePlanPanel'
import {
  ActivatedMaintenancePlanData,
  MaintenancePlanActivatedModal,
} from './MaintenancePlanActivatedModal'
import {
  calcMaintenancePlanPricing,
  isZeroTaxRate,
  zeroTaxRate,
} from './MaintenancePlanV3Utils'
import { MaintenancePlanWizardPageSection } from './MaintenancePlanWizardPageSection'
import { DelayedPaymentBanner } from './ReviewMaintenancePlanPanel/FinalPricingSection'
import { ReviewMaintenancePlanPanel } from './ReviewMaintenancePlanPanel/ReviewMaintenancePlanPanel'
import { SelectAccountPanel } from './SelectAccountPanel'
import { SelectLocationPanel } from './SelectLocationPanel'
import { SelectMaintenancePlanTypePanel } from './SelectMaintenancePlanTypePanel'
import { SelectedAccountAndLocationPanel } from './SelectedAccountAndLocationPanel'
import { MaintenancePlanTermsAndConditions } from './components/MaintenancePlanTermsAndConditions'
import { createDefaultValuesForCreateMaintenancePlanForm } from './configureMaintenancePlanFormDefaultValues'
import {
  ConfigureMaintenancePlanFormData,
  configureMaintenancePlanFormSchema,
} from './configureMaintenancePlanFormSchema'
import { StepDefinition } from './create-or-edit-maintenance-plan-page-types'
import { useFormLogicWatcher } from './hooks/useFormLogicWatcher'
import { useShouldShowDelayedPaymentBanner } from './hooks/useShouldShowDelayedPaymentBanner'

export type MaintenancePlanTypeOption = {
  readonly plan: MaintenancePlanDefinition
  readonly subtotalAnnualPriceUsd: number
  readonly nonPlanSubtotalAnnualPriceUsd: number
}

const accountJobToVisitJobViewModel = (job: AccountJob): VisitJobViewModel => ({
  jobGuid: job.jobGuid,
  jobDisplayId: job.displayId,
  jobTypeName: job.jobType.name,
})

const accountJobToVisitStatus = (job: AccountJob): VisitStatus => {
  if (job.jobLifecycleStatus.specialStatus === 'Completed') return 'COMPLETED'
  return job.appointments.length > 0 ? 'SCHEDULED' : 'UNSCHEDULED'
}

const stepSelectAccount: StepDefinition = {
  id: 1,
  stepName: 'Account',
  heading: 'Add Maintenance Plan',
  subheading: "Add a maintenance plan to a customer's account",
  hideProgressButtons: true,
  hideStepper: true,
  preRequiredData: [],
  proceedRequiredData: 'Account',
}

const stepSelectLocation: StepDefinition = {
  id: 2,
  stepName: 'Location',
  heading: 'Add Maintenance Plan',
  subheading: "Add a maintenance plan to a customer's account",
  hideProgressButtons: true,
  hideStepper: true,
  preRequiredData: ['Account'],
  proceedRequiredData: 'Location',
}

const stepSelectPlanType: StepDefinition = {
  id: 3,
  stepName: 'Plan Type',
  heading: 'Add Maintenance Plan',
  subheading: "Add a maintenance plan to a customer's account",
  hideProgressButtons: true,
  hideStepper: true,
  preRequiredData: ['Account', 'Location'],
  proceedRequiredData: 'Plan Type',
}

const stepConfigurePlan: StepDefinition = {
  id: 4,
  stepName: 'Configure',
  heading: 'Add Maintenance Plan',
  subheading: "Add a maintenance plan to a customer's account",
  hideProgressButtons: true,
  hideStepper: true,
  preRequiredData: ['Account', 'Location', 'Plan Type'],
}

const stepFinalReview: StepDefinition = {
  id: 5,
  stepName: 'Review',
  heading: 'Add Maintenance Plan',
  subheading: "Add a maintenance plan to a customer's account",
  hideProgressButtons: true,
  hideStepper: true,
  preRequiredData: ['Account', 'Location', 'Plan Type', 'Configure'],
}

type CreateMaintenancePlanProps = {
  existingPlan?: MaintenancePlanDetailsViewModel
  mode?: 'new-from-scratch' | 'new-from-existing' | 'renew-existing'
  onRamp?: MaintenancePlanWizardOnramps
  accountGuid?: string
  locationGuid?: string
  jobGuid?: string
  isImport?: boolean
}

type MaintenancePlanWizardProps = CreateMaintenancePlanProps & {
  onClose: () => void
}

export type MaintenancePlanWizardOnramps =
  | 'office-global-create-add-new'
  | 'office-global-create-import'
  | 'tech-global-create'
  | 'location-details'
  | 'appt-detail'
  | 'appt-action-menu'
  | 'account-details'
  | 'accounts-list-page'
  | 'job-details'
  | 'maintenance-plan-renew-modal'
  | 'mp-list-page-create'
  | 'mp-list-page-import'
  | 'maintenance-plans-trends-empty'
  | 'maintenance-plans-unused-credits-empty'
  | 'account-details-not-a-member-callout'

const MAINTENANCE_PLAN_WIZARD_OPENED_ACTION =
  'bz-maintenance-plan-wizard-opened'
const MAINTENANCE_PLAN_WIZARD_PLAN_ACTIVATED_ACTION =
  'bz-maintenance-plan-wizard-plan-activated'

const MAINTENANCE_PLAN_WIZARD_ONE_SHOT_PAYMENT_SUCCESS_ACTION =
  'bz-maintenance-plan-wizard-one-shot-payment-success'
const MAINTENANCE_PLAN_WIZARD_PAYMENT_SUBSCRIPTION_SUCCESS_ACTION =
  'bz-maintenance-plan-wizard-payment-subscription-success'

const MAINTENANCE_PLAN_FLAG_KEYS = ['mpw', 'globalMpw', 'importPlan']
type MaintenancePlanFlagKey = (typeof MAINTENANCE_PLAN_FLAG_KEYS)[number]

export const useMaintenancePlanWizardFlags = (
  flagKey: MaintenancePlanFlagKey,
  onRamp?: MaintenancePlanWizardOnramps,
) => {
  const [
    maintenancePlanWizardOpen,
    openMaintenancePlanWizardInner,
    closeMaintenancePlanWizardInner,
  ] = useQueryParamFlag(flagKey, { defaultOn: false })

  const openMaintenancePlanWizard = useCallback(() => {
    if (onRamp) {
      datadogRum.addAction(MAINTENANCE_PLAN_WIZARD_OPENED_ACTION, { onRamp })
    }
    openMaintenancePlanWizardInner()
  }, [openMaintenancePlanWizardInner, onRamp])

  // This is necessary to keep the wizard from being opened onMount when the job details page is mounted
  // due to the useLoadPreviousQueryStringOnMount hook which revives the query param state from localStorage.
  const clearMpwFlagFromQueryParamStorage = useCallback(() => {
    for (const key of Object.keys(localStorage)) {
      if (
        key.startsWith(LOAD_PREVIOUS_QUERY_STRING_ON_MOUNT_KEY_PREFIX) &&
        key.includes(flagKey)
      ) {
        localStorage.removeItem(key)
      }
    }
  }, [flagKey])

  const closeMaintenancePlanWizard = useCallback(() => {
    clearMpwFlagFromQueryParamStorage()
    closeMaintenancePlanWizardInner()
  }, [clearMpwFlagFromQueryParamStorage, closeMaintenancePlanWizardInner])

  return [
    maintenancePlanWizardOpen,
    openMaintenancePlanWizard,
    closeMaintenancePlanWizard,
  ] as const
}

export const MaintenancePlanWizard = React.memo<MaintenancePlanWizardProps>(
  ({ onClose, ...props }) => {
    const [isDirty, setIsDirty] = useState(false)

    const shouldBlock = useCallback<BlockerFunction>(
      ({ currentLocation, nextLocation }) => {
        return isDirty
      },
      [isDirty],
    )

    const blocker = useBlocker(
      'CreateOrEditMaintenancePlanProgressiveStepper',
      shouldBlock,
    )

    return ReactDOM.createPortal(
      <>
        <CreateOrEditMaintenancePlanProgressiveStepper
          {...props}
          dismiss={onClose}
          setIsDirty={setIsDirty}
        />
        {blocker.state === 'blocked' && (
          <OnsiteConfirmModal
            danger
            header="Are you sure you want to exit?"
            onCancel={blocker.reset}
            onConfirm={blocker.proceed}
            confirmText="Yes, Exit"
          >
            You have unsaved changes. If you exit, they will be lost.
            <br />
            Are you sure you want to exit?
          </OnsiteConfirmModal>
        )}
      </>,
      document.body,
    )
  },
)

const getInitialStep = ({
  preselectedLocationGuid,
  preselectedAccountGuid,
  preselectedPlanDefinitionGuid,
  finalMode,
}: {
  preselectedLocationGuid?: string
  preselectedAccountGuid?: string
  preselectedPlanDefinitionGuid?: string
  finalMode: 'new-from-scratch' | 'new-from-existing' | 'renew-existing'
}): StepDefinition => {
  if (preselectedPlanDefinitionGuid) {
    if (finalMode === 'new-from-existing') {
      return stepSelectPlanType
    }
    if (finalMode === 'renew-existing') {
      return stepConfigurePlan
    }
  }

  if (preselectedLocationGuid) {
    return stepSelectPlanType
  }

  if (preselectedAccountGuid) {
    return stepSelectLocation
  }

  return stepSelectAccount
}

const CreateOrEditMaintenancePlanProgressiveStepper = memo(
  ({
    existingPlan,
    mode,
    onRamp,
    dismiss,
    setIsDirty: externalSetIsDirty,
    accountGuid,
    locationGuid,
    jobGuid,
    isImport: isImportMode = false,
  }: CreateMaintenancePlanProps & {
    dismiss: () => void
    setIsDirty?: SetIsDirty
  }) => {
    const message = useMessage()
    const tzId = useExpectedCompanyTimeZoneId()
    const companyGuid = useExpectedCompanyGuid()
    const isImport = isImportMode && !existingPlan
    const isMobile = useIsMobile()
    const preselectedAccountGuid =
      existingPlan?.accountGuid ?? accountGuid ?? undefined

    const preselectedLocationGuid =
      existingPlan?.locationGuid ?? locationGuid ?? undefined

    const preselectedPlanDefinitionGuid =
      existingPlan?.maintenancePlanDefinitionGuid

    const finalMode = mode ?? 'new-from-scratch'
    const pageContainerRef = useRef<HTMLDivElement>(null)

    const scrollToTop = useCallback(() => {
      if (pageContainerRef.current) {
        pageContainerRef.current.scrollTo(0, 0)
      }
    }, [pageContainerRef])

    const [maintenancePlanActivationInput, setMaintenancePlanActivationInput] =
      useState<MaintenancePlanActivationInput | undefined>()
    const [submittedBillingAnchorInfo, setSubmittedBillingAnchorInfo] =
      useState<
        | {
            billingAnchorDate?: LocalDateString
            billingAnchorDay: string
          }
        | undefined
      >()

    const stepHeadingOverride = isImport
      ? 'Import Maintenance Plan'
      : 'Add Maintenance Plan'
    const stepSubheadingOverride = isImport
      ? "Add an existing maintenance plan to a customer's account"
      : "Add a maintenance plan to a customer's account"

    const [isUploading, setIsUploading] = useState(false)
    const [currentAccount, setCurrentAccount] = useState<Account | undefined>()
    const [currentComprehensiveAccount, setComprehensiveCurrentAccount] =
      useState<ComprehensiveAccountDetails | undefined>()
    const [selectedLocationGuid, setSelectedLocationGuid] = useState<string>(
      preselectedLocationGuid ?? '',
    )
    const [maintenancePlanConfig, setMaintenancePlanConfig] =
      useState<ComprehensiveMaintenancePlanConfigViewModel>()
    const [selectedPlanDefinitionGuid, setSelectPlanDefinitionGuid] = useState<
      string | undefined
    >(preselectedPlanDefinitionGuid)

    const submittedFormRef = useRef<
      ConfigureMaintenancePlanFormData | undefined
    >(undefined)

    const submittedForm = submittedFormRef.current

    const setIsDirty = useCallback(
      (isDirty: boolean) => {
        externalSetIsDirty?.(isDirty)
      },
      [externalSetIsDirty],
    )

    const [createdMaintenancePlanGuid, setCreatedMaintenancePlanGuid] =
      useState<string | undefined>(undefined)
    const [activatedMaintenancePlanGuid, setActivatedMaintenancePlanGuid] =
      useState<string | undefined>(undefined)
    const [activatedMaintenancePlanMeta, setActivatedMaintenancePlanMeta] =
      useState<
        | {
            paymentMethod: PaymentMethod | 'PAYMENT_LINK'
            paymentAmountUsc: number
          }
        | undefined
      >()

    const [oneShotInvoiceGuid, setOneShotInvoiceGuid] = useState<
      string | undefined
    >(undefined)
    const oneShotInvoiceLoader = useFetchAbridgedInvoiceByGuid({
      invoiceGuid: oneShotInvoiceGuid ?? '',
      pause: !oneShotInvoiceGuid,
    })

    const configureMaintenancePlanForm =
      useForm<ConfigureMaintenancePlanFormData>({
        resolver: zodResolver(configureMaintenancePlanFormSchema),
        defaultValues:
          finalMode === 'new-from-scratch' || !existingPlan
            ? createDefaultValuesForCreateMaintenancePlanForm({
                tzId,
                mode: 'new-from-scratch',
                isImport,
                mp: undefined,
              })
            : createDefaultValuesForCreateMaintenancePlanForm({
                tzId,
                mode: finalMode,
                isImport,
                mp: existingPlan,
              }),
      })

    const isFreePlan = configureMaintenancePlanForm.watch('isFreePlan')
    const isAutoRenewing = configureMaintenancePlanForm.watch('isAutoRenewing')

    const MaintenancePlansNotConfiguredView = useCallback(
      () => (
        <div className="col">
          <h2>Maintenance Plans Not Configured</h2>
          <div className="grey9 text-center">
            Speak to your back office administrator to configure maintenance
            plans
          </div>
          <Button className="center-vh mt-8" onClick={() => dismiss()}>
            Close
          </Button>
        </div>
      ),
      [dismiss],
    )

    const fetchAccountByAccountGuidQuery = useFetchAccountByAccountGuidQuery({
      accountGuid: currentAccount?.accountGuid ?? preselectedAccountGuid ?? '',
      opts: {
        enabled: !!(currentAccount?.accountGuid ?? preselectedAccountGuid),
        onSuccess: data => setCurrentAccount(data),
      },
    })

    const renewalTypeOption = useCompanyMaintenancePlanRenewalType(companyGuid)

    const mpQuery = trpc.maintenancePlans[
      'maintenance-plans-config:get'
    ].useQuery(undefined, {
      onSuccess: e => setMaintenancePlanConfig(e),
    })

    const selectedLocation = useMemo(
      () =>
        currentComprehensiveAccount
          ?.getAccountLocations()
          .find(l => l.location.locationGuid === selectedLocationGuid),
      [currentComprehensiveAccount, selectedLocationGuid],
    )

    const selectedPlanType = useMemo(() => {
      return maintenancePlanConfig?.plans.find(
        p => p.maintenancePlanDefinitionGuid === selectedPlanDefinitionGuid,
      )
    }, [maintenancePlanConfig, selectedPlanDefinitionGuid])
    const [currentStep, setCurrentStep] = useState<StepDefinition>(
      getInitialStep({
        preselectedLocationGuid,
        preselectedAccountGuid,
        preselectedPlanDefinitionGuid,
        finalMode,
      }),
    )

    const isLoading = useMemo(() => {
      if (preselectedAccountGuid && !currentAccount) return true
      if (
        preselectedPlanDefinitionGuid &&
        !selectedPlanType &&
        !currentAccount &&
        !currentComprehensiveAccount &&
        !selectedLocation
      )
        return true
      return false
    }, [
      preselectedAccountGuid,
      currentAccount,
      preselectedPlanDefinitionGuid,
      selectedPlanType,
      currentComprehensiveAccount,
      selectedLocation,
    ])

    const installedEquipment = useMemo(
      () =>
        (
          currentComprehensiveAccount
            ?.getAccountLocations()
            .filter(l => l.location.locationGuid === selectedLocationGuid) ?? []
        ).flatMap(l => l.location.installedEquipment ?? []),
      [currentComprehensiveAccount, selectedLocationGuid],
    )

    const visitViewModels = useMemo((): VisitViewModel[] => {
      if (!submittedForm) return []

      const equipmentMap = installedEquipment.reduce((acc, e) => {
        acc[e.installedEquipmentGuid] = {
          installedEquipmentGuid: e.installedEquipmentGuid,
          equipmentType: e.equipmentType,
          manufacturer: e.manufacturer ?? '',
        }
        return acc
      }, {} as Record<string, VisitEquipmentViewModel>)

      const jobsMap = (currentComprehensiveAccount?.getJobs() ?? []).reduce(
        (acc, j) => {
          acc[j.jobGuid] = j
          return acc
        },
        {} as Record<string, AccountJob>,
      )

      return submittedForm.visits.map((v): VisitViewModel => {
        const job = v.jobGuid ? jobsMap[v.jobGuid] : undefined
        return {
          ...v,
          visitGuid: nextGuid(),
          status: job
            ? accountJobToVisitStatus(job)
            : v.completedAtOverride || v.isCompletedOverride
            ? 'COMPLETED'
            : 'UNSCHEDULED',
          visitEquipment: v.installedEquipmentGuids
            .map(e => equipmentMap[e])
            .filter(x => !!x),
          visitJob: job ? accountJobToVisitJobViewModel(job) : undefined,
          maintenancePlanGuid: nextGuid(),
          companyGuid: companyGuid,
          issuedAt: BzDateTime.now(tzId).toIsoDateString(),
          expiresAt: BzDateTime.now(tzId).plusYears(1).toIsoDateString(),
          dueAt: BzDateTime.now(tzId).plusYears(1).toIsoDateString(),
        }
      })
    }, [
      submittedForm,
      installedEquipment,
      currentComprehensiveAccount,
      companyGuid,
      tzId,
    ])

    const planOptionSummaries = useMemo<MaintenancePlanTypeOption[]>(
      () =>
        (maintenancePlanConfig?.plans ?? [])
          .filter(
            p => p.isAvailableForSale && !isNullish(p.yearlyStaticPriceUsc),
          )
          .map(p => ({
            plan: p,
            subtotalAnnualPriceUsd: usCentsToUsd(
              p.yearlyStaticPriceUsc as number,
            ),
            nonPlanSubtotalAnnualPriceUsd: usCentsToUsd(
              p.yearlyStaticPriceUsc as number,
            ),
          })),
      [maintenancePlanConfig],
    )

    const maybeTaxRate = useMemo<TaxRateSetEventData | undefined>(
      () =>
        maintenancePlanConfig?.taxRate
          ? {
              taxRateGuid: maintenancePlanConfig.taxRate.pricebookTaxRateGuid,
              rate: maintenancePlanConfig.taxRate.rate,
            }
          : undefined,
      [maintenancePlanConfig],
    )

    const taxRateOrZero = useMemo<TaxRateSetEventData>(
      () => maybeTaxRate ?? zeroTaxRate,
      [maybeTaxRate],
    )

    const { hidePaymentStatusModal } = usePaymentStatusModalContext()

    const resetFormState = useCallback(() => {
      setSelectPlanDefinitionGuid(undefined)
      setMaintenancePlanActivationInput(undefined)
      setSelectPlanDefinitionGuid('')
      setSelectedLocationGuid('')
      setSubmittedBillingAnchorInfo(undefined)
      submittedFormRef.current = undefined
      configureMaintenancePlanForm.reset()
    }, [configureMaintenancePlanForm])

    useFormLogicWatcher({
      form: configureMaintenancePlanForm,
      currentAccount,
      selectedPlanType,
      selectedLocationGuid,
      defaultTaxRate: taxRateOrZero,
      reset: resetFormState,
    })

    const fetchComprehensiveAccountQuery = useFetchComprehensiveAccountDetails({
      accountGuid: currentAccount?.accountGuid ?? '',
      opts: {
        enabled: !!currentAccount?.accountGuid,
        onSuccess: data => {
          setComprehensiveCurrentAccount(data)
        },
      },
    })

    const refetchAccountData = useCallback(() => {
      fetchComprehensiveAccountQuery.refetch()
      fetchAccountByAccountGuidQuery.refetch()
    }, [fetchAccountByAccountGuidQuery, fetchComprehensiveAccountQuery])

    const primaryContactEmail = useMemo(() => {
      return currentComprehensiveAccount
        ?.getAccountContacts()
        .find(c => !!c.primary)?.contact.primaryEmailAddress?.emailAddress
    }, [currentComprehensiveAccount])

    const createMut =
      trpc.maintenancePlans['maintenance-plans:create-plan'].useMutation()

    const activateFreePlanMut =
      trpc.maintenancePlans[
        'maintenance-plans:activate-free-plan'
      ].useMutation()

    const activateImportedPlanMut =
      trpc.maintenancePlans['maintenance-plans:activate-imported'].useMutation()

    const createOneTimePaymentInvoiceMut =
      trpc.maintenancePlans[
        'maintenance-plans:create-one-time-payment-invoice'
      ].useMutation()

    const billingAnchorDay =
      configureMaintenancePlanForm.watch('billingAnchorDay')

    const { hiddenPaymentMethods } = useCompanyHiddenPaymentMethods()

    const onFinish = useCallback(() => {
      const errors = configureMaintenancePlanForm.formState.errors
      if (Object.keys(errors).length > 0) {
        message.error('Form validation failed')
        return
      }

      const {
        isImportedPlan,
        activationDate,
        isAutoRenewing,
        subtotalYearlyPriceUsc,
        billingPaymentInterval,
        adjustmentYearlyPriceUsc,
        taxRate,
        shouldSendActivationEmail,
        visits,
        billingAnchorDate,
      } = configureMaintenancePlanForm.getValues()
      const isOneShotPaymentPlan =
        !isAutoRenewing && subtotalYearlyPriceUsc - adjustmentYearlyPriceUsc > 0
      if (!currentAccount || !selectedLocationGuid || !selectedPlanType) {
        message.error('Missing required data')
        return
      }

      if (
        !selectedPlanType.yearlyStaticPriceUsc &&
        selectedPlanType.yearlyStaticPriceUsc !== 0
      ) {
        message.error('Only Flat-Rate plans are currently supported')
        return
      }

      setIsUploading(true)

      const configuration: MaintenancePlanConfiguredEventData = {
        ...selectedPlanType,
        coveredEquipment: [],
        numVisitsPerYear: selectedPlanType.numVisitCreditsPerYear,
        discounts: selectedPlanType.discounts.map(d => ({
          jobClass: d.discountJobClass,
          discountRate: d.discountRate,
        })),
        yearlyStaticPriceUsc: selectedPlanType.yearlyStaticPriceUsc,

        suppressActivationEmail: !shouldSendActivationEmail,
        initialVisits: visits,
      }

      const { intervalSubtotalPriceUsc, intervalTotalPriceUsc } =
        calcMaintenancePlanPricing({
          isFreePlan,
          isImportedPlan,
          subtotalYearlyPriceUsc,
          adjustmentYearlyPriceUsc,
          billingPaymentInterval,
          // This cast is necessary because we allow setting the tax rate to null
          taxRate: taxRate as TaxRateSetEventData,
        })

      const pricing = {
        subtotalPeriodPriceUsc: intervalSubtotalPriceUsc,
        discountPeriodAmountUsc: 0,
        paymentInterval: billingPaymentInterval,
        upchargePeriodAmountUsc: 0,
      }

      const maintenancePlanGuid = nextGuid()
      const req = {
        maintenancePlanGuid,
        accountGuid: currentAccount.accountGuid,
        locationGuid: selectedLocationGuid,
        // This is used to check if a tax rate was set during the adjust pricing step. If there
        // was, override the default mp tax rate
        taxRate: !isZeroTaxRate(taxRate)
          ? (taxRate as TaxRateSetEventData)
          : maybeTaxRate,
        configuration,
        pricing,
      } satisfies MaintenancePlanCreationRequest

      setMaintenancePlanActivationInput({
        ...req,
        companyGuid,
        totalPriceUsc: intervalTotalPriceUsc,
        customBillingStartDate: billingAnchorDate,
        activationDate: activationDate,
        paymentInterval: billingPaymentInterval,
        // This is used to check if a tax rate was set during the adjust pricing step. If there
        // was, override the default mp tax rate
        taxRateGuid: !isZeroTaxRate(taxRate)
          ? (taxRate as TaxRateSetEventData).taxRateGuid
          : maybeTaxRate?.taxRateGuid ?? null,
      })

      setSubmittedBillingAnchorInfo({
        billingAnchorDate,
        billingAnchorDay,
      })

      createMut.mutateAsync(req, {
        onSuccess: () => {
          if (isImportedPlan) {
            activateImportedPlanMut.mutateAsync(
              {
                maintenancePlanGuid: req.maintenancePlanGuid,
                activatedAt: BzDateFns.localDateStringToIsoDateString(
                  activationDate,
                  tzId,
                ),
                numDaysUntilAutoCancelation: 365,
              },
              {
                onSuccess: () => {
                  setIsUploading(false)
                  setActivatedMaintenancePlanGuid(maintenancePlanGuid)
                },
              },
            )
          } else if (isFreePlan) {
            activateFreePlanMut.mutateAsync(
              {
                maintenancePlanGuid: req.maintenancePlanGuid,
                numDaysUntilAutoCancelation: isAutoRenewing ? undefined : 365,
              },
              {
                onSuccess: () => {
                  setIsUploading(false)
                  setActivatedMaintenancePlanGuid(maintenancePlanGuid)
                },
              },
            )
          } else if (isOneShotPaymentPlan) {
            createOneTimePaymentInvoiceMut.mutateAsync(
              { maintenancePlanGuid: req.maintenancePlanGuid },
              {
                onSuccess: resp => {
                  setIsUploading(false)
                  setCreatedMaintenancePlanGuid(maintenancePlanGuid)
                  setOneShotInvoiceGuid(resp.invoiceGuid)
                },
              },
            )
          } else {
            // This is a fun hack to show the initializing plan spinner before bringing up the payment subscription workflow
            setTimeout(() => {
              setIsUploading(false)
            }, 1000)
          }
          if (onRamp) {
            datadogRum.addAction(
              MAINTENANCE_PLAN_WIZARD_PLAN_ACTIVATED_ACTION,
              {
                onRamp,
              },
            )
          }
          setIsDirty(false)
        },
        onError: e => {
          message.error(e.message)
        },
      })
    }, [
      configureMaintenancePlanForm,
      currentAccount,
      selectedLocationGuid,
      selectedPlanType,
      isFreePlan,
      maybeTaxRate,
      companyGuid,
      billingAnchorDay,
      createMut,
      message,
      onRamp,
      setIsDirty,
      activateImportedPlanMut,
      tzId,
      activateFreePlanMut,
      createOneTimePaymentInvoiceMut,
    ])

    const accountContact = useMemo(
      () =>
        currentComprehensiveAccount
          ?.getAccountContacts()
          ?.sort((a, b) => (b.primary ? 1 : 0) - (a.primary ? 1 : 0))[0],
      [currentComprehensiveAccount],
    )

    const onLocationSelected = useCallback(
      (locationGuid: string) => {
        setSelectedLocationGuid(locationGuid)
        setIsDirty(true)
      },
      [setIsDirty],
    )
    const [isConfirmModalOpen, openConfirmModal, closeConfirmModal] =
      useModalState()
    const [pendingAccount, setPendingAccount] = useState<Account | undefined>()

    const onAccountSelect = useCallback(
      (account: Account | undefined) => {
        if (account) {
          if (currentAccount) {
            setPendingAccount(account)
            openConfirmModal()
          } else {
            setCurrentAccount(account)
            setIsDirty(true)
            setCurrentStep(stepSelectLocation)
          }
        } else {
          setCurrentAccount(undefined)
          setIsDirty(true)
          setCurrentStep(stepSelectLocation)
        }
      },
      [
        currentAccount,
        openConfirmModal,
        setCurrentAccount,
        setIsDirty,
        setCurrentStep,
      ],
    )

    const onSelectMaintenancePlanType = useCallback(
      (maintenancePlanDefinition: MaintenancePlanDefinition) => {
        setSelectPlanDefinitionGuid(
          maintenancePlanDefinition.maintenancePlanDefinitionGuid,
        )
        setCurrentStep(stepConfigurePlan)
        setIsDirty(true)
        scrollToTop()
      },
      [setSelectPlanDefinitionGuid, setCurrentStep, setIsDirty, scrollToTop],
    )

    const confirmAccountChange = useCallback(() => {
      if (pendingAccount) {
        setCurrentAccount(pendingAccount)
        setPendingAccount(undefined)
        setIsDirty(true)
        closeConfirmModal()
        setCurrentStep(stepSelectLocation)
      }
    }, [
      pendingAccount,
      setCurrentAccount,
      setIsDirty,
      setCurrentStep,
      closeConfirmModal,
    ])

    const onCancelAccountChange = useCallback(() => {
      setPendingAccount(undefined)
      closeConfirmModal()
    }, [setPendingAccount, closeConfirmModal])

    const primaryContent = useMemo(() => {
      switch (currentStep.id) {
        case stepSelectAccount.id:
          return (
            <MaintenancePlanStepWrapper>
              <MaintenancePlanWizardPageSection title="Select Account">
                <AccountSearchAutocomplete
                  currentAccount={currentAccount}
                  setCurrentAccount={onAccountSelect}
                  showLabel={false}
                />
              </MaintenancePlanWizardPageSection>
            </MaintenancePlanStepWrapper>
          )
        case stepSelectLocation.id:
          return (
            <MaintenancePlanStepWrapper>
              {currentAccount && (
                <>
                  <MaintenancePlanWizardPageSection>
                    <SelectAccountPanel
                      selectedAccount={currentAccount}
                      onAccountSelect={onAccountSelect}
                      fetchComprehensiveAccountQuery={
                        fetchComprehensiveAccountQuery
                      }
                    />
                  </MaintenancePlanWizardPageSection>
                  <MaintenancePlanWizardPageSection>
                    <SelectLocationPanel
                      selectedAccountGuid={currentAccount.accountGuid}
                      onLocationSelected={onLocationSelected}
                      goToNextStep={() => {
                        setCurrentStep(stepSelectPlanType)
                        scrollToTop()
                      }}
                      fetchComprehensiveAccountQuery={
                        fetchComprehensiveAccountQuery
                      }
                      refetchAccountData={refetchAccountData}
                    />
                  </MaintenancePlanWizardPageSection>
                </>
              )}
            </MaintenancePlanStepWrapper>
          )
        case stepSelectPlanType.id:
          return (
            <MaintenancePlanStepWrapper>
              {currentAccount &&
              selectedLocation &&
              currentComprehensiveAccount ? (
                <>
                  <MaintenancePlanWizardPageSection>
                    <SelectedAccountAndLocationPanel
                      selectedComprehensiveAccount={currentComprehensiveAccount}
                      selectedLocation={selectedLocation}
                      goToSelectAccountAndLocationStep={() =>
                        setCurrentStep(stepSelectLocation)
                      }
                    />
                  </MaintenancePlanWizardPageSection>

                  <MaintenancePlanWizardPageSection>
                    <SelectMaintenancePlanTypePanel
                      pageContainerRef={pageContainerRef}
                      isImportedPlan={isImport}
                      maintenancePlanTypeOptions={planOptionSummaries}
                      onSelectMaintenancePlanType={onSelectMaintenancePlanType}
                    />
                  </MaintenancePlanWizardPageSection>
                </>
              ) : (
                <MaintenancePlanWizardLoadingSpinner />
              )}
            </MaintenancePlanStepWrapper>
          )
        case stepConfigurePlan.id:
          return (
            <MaintenancePlanStepWrapper>
              {currentAccount &&
              selectedLocation &&
              currentComprehensiveAccount &&
              selectedPlanType ? (
                <>
                  <MaintenancePlanWizardPageSection>
                    <SelectedAccountAndLocationPanel
                      selectedComprehensiveAccount={currentComprehensiveAccount}
                      selectedLocation={selectedLocation}
                      goToSelectAccountAndLocationStep={() =>
                        setCurrentStep(stepSelectLocation)
                      }
                    />
                  </MaintenancePlanWizardPageSection>
                  <MaintenancePlanWizardPageSection>
                    <ConfigureMaintenancePlanPanel
                      account={currentComprehensiveAccount}
                      selectedLocationGuid={selectedLocationGuid}
                      selectedPlanType={selectedPlanType}
                      goToSelectPlanTypeStep={() =>
                        setCurrentStep(stepSelectPlanType)
                      }
                      onSave={() => {
                        const config = configureMaintenancePlanForm.getValues()
                        submittedFormRef.current = config

                        setCurrentStep(stepFinalReview)
                        setIsDirty(true)
                        scrollToTop()
                      }}
                      refetch={() => {
                        fetchComprehensiveAccountQuery.refetch()
                      }}
                      renewalTypeOption={renewalTypeOption}
                      hiddenPaymentMethods={hiddenPaymentMethods}
                    />
                  </MaintenancePlanWizardPageSection>
                  <MaintenancePlanWizardPageSection>
                    <MaintenancePlanTermsAndConditions
                      maintenancePlanDefinition={selectedPlanType}
                    />
                  </MaintenancePlanWizardPageSection>
                </>
              ) : (
                <MaintenancePlanWizardLoadingSpinner />
              )}
            </MaintenancePlanStepWrapper>
          )
        case stepFinalReview.id:
          return (
            <MaintenancePlanStepWrapper>
              {currentAccount &&
                selectedLocation &&
                currentComprehensiveAccount && (
                  <MaintenancePlanWizardPageSection>
                    <SelectedAccountAndLocationPanel
                      selectedComprehensiveAccount={currentComprehensiveAccount}
                      selectedLocation={selectedLocation}
                      goToSelectAccountAndLocationStep={() =>
                        setCurrentStep(stepSelectLocation)
                      }
                    />
                  </MaintenancePlanWizardPageSection>
                )}
              {maintenancePlanConfig &&
                accountContact &&
                selectedLocationGuid &&
                selectedPlanType &&
                visitViewModels.length > 0 && (
                  <ReviewMaintenancePlanPanel
                    accountContact={accountContact}
                    selectedPlanType={selectedPlanType}
                    visits={visitViewModels}
                    goToConfigurePlanStep={() => {
                      setCurrentStep(stepConfigurePlan)
                    }}
                    onSubmit={onFinish}
                    hiddenPaymentMethods={hiddenPaymentMethods}
                  />
                )}
              {selectedPlanType && (
                <MaintenancePlanWizardPageSection>
                  <MaintenancePlanTermsAndConditions
                    maintenancePlanDefinition={selectedPlanType}
                  />
                </MaintenancePlanWizardPageSection>
              )}
            </MaintenancePlanStepWrapper>
          )
        default:
          return <></>
      }
    }, [
      currentStep.id,
      currentAccount,
      onAccountSelect,
      fetchComprehensiveAccountQuery,
      onLocationSelected,
      refetchAccountData,
      selectedLocation,
      currentComprehensiveAccount,
      isImport,
      planOptionSummaries,
      onSelectMaintenancePlanType,
      selectedPlanType,
      selectedLocationGuid,
      renewalTypeOption,
      hiddenPaymentMethods,
      maintenancePlanConfig,
      accountContact,
      visitViewModels,
      onFinish,
      scrollToTop,
      configureMaintenancePlanForm,
      setIsDirty,
    ])

    const onClose = useCallback(() => {
      dismiss()
    }, [dismiss])

    // This ensures that the hook is using the same data that's passed in to the payment workflow wizard
    const shouldShowDelayedPaymentBanner = useShouldShowDelayedPaymentBanner({
      isFreePlan: maintenancePlanActivationInput ? false : isFreePlan,
      isAutoRenewing: maintenancePlanActivationInput ? true : isAutoRenewing,
      billingAnchorDate: submittedBillingAnchorInfo?.billingAnchorDate,
      activationDate: maintenancePlanActivationInput?.activationDate,
    })

    const autoRenewingPaymentConfigurationInfo = useMemo(() => {
      if (
        !shouldShowDelayedPaymentBanner ||
        !accountContact ||
        !maintenancePlanActivationInput?.customBillingStartDate ||
        !submittedBillingAnchorInfo?.billingAnchorDate ||
        !submittedBillingAnchorInfo?.billingAnchorDay
      ) {
        return null
      }

      return (
        <DelayedPaymentBanner
          accountContact={accountContact}
          billingAnchorDay={submittedBillingAnchorInfo.billingAnchorDay}
          billingPaymentInterval={
            maintenancePlanActivationInput.paymentInterval
          }
          activationDate={maintenancePlanActivationInput.customBillingStartDate}
          billingAnchorDate={submittedBillingAnchorInfo.billingAnchorDate}
          intervalTotalPriceUsc={maintenancePlanActivationInput.totalPriceUsc}
        />
      )
    }, [
      shouldShowDelayedPaymentBanner,
      accountContact,
      maintenancePlanActivationInput,
      submittedBillingAnchorInfo,
    ])

    const content = useMemo(() => {
      if (isLoading) {
        return <MaintenancePlanWizardLoadingSpinner />
      }

      return (
        <FormProvider {...configureMaintenancePlanForm}>
          <TrpcQueryLoader
            query={mpQuery}
            loadingComponent={<MaintenancePlanWizardLoadingSpinner />}
            render={d => {
              if (d.plans.length > 0 && d.serviceItem) {
                return primaryContent
              }
              return <MaintenancePlansNotConfiguredView />
            }}
          />
        </FormProvider>
      )
    }, [
      MaintenancePlansNotConfiguredView,
      configureMaintenancePlanForm,
      isLoading,
      mpQuery,
      primaryContent,
    ])

    const activatedMaintenancePlanData = useMemo<
      ActivatedMaintenancePlanData | undefined
    >(() => {
      if (!activatedMaintenancePlanGuid) return
      const {
        isFreePlan,
        isImportedPlan,
        isAutoRenewing,
        billingPaymentInterval,
        billingAnchorDay,
        activationDate,
        accountGuid,
        locationGuid,
      } = configureMaintenancePlanForm.getValues()

      return {
        isFreePlan,
        isImportedPlan,
        isAutoRenewing,
        billingPaymentInterval,
        billingAnchorDay,
        activationDate,
        accountGuid,
        locationGuid,
        planDefinition: selectedPlanType,
      }
    }, [
      activatedMaintenancePlanGuid,
      configureMaintenancePlanForm,
      selectedPlanType,
    ])

    return (
      <>
        {isMobile ? (
          <OnsitePageContainer
            containerRef={pageContainerRef}
            title={
              <div className="text-xl font-semibold">{stepHeadingOverride}</div>
            }
            closeInsteadOfBack
            spaceForNav={false}
            className="mp-wizard-modal"
          >
            <FatDivider styleOverrides={{ marginTop: 16, marginBottom: 0 }} />
            {content}
          </OnsitePageContainer>
        ) : (
          <FullScreenModal
            containerRef={pageContainerRef}
            isOpen
            setIsOpen={onClose}
            className="mp-wizard-modal"
            zIndexClassName="z-[1010]"
          >
            <div className="flex w-full justify-center">
              <div className="flex w-full max-w-[754px] flex-col">
                <CreationStepHeading
                  {...currentStep}
                  bottomDivider
                  dividerClassName="mt-4"
                  heading={stepHeadingOverride}
                  subheading={stepSubheadingOverride}
                />
                {content}
              </div>
            </div>
          </FullScreenModal>
        )}
        {isConfirmModalOpen && pendingAccount && (
          <OnsiteConfirmModal
            header="Change customer"
            onCancel={onCancelAccountChange}
            onConfirm={confirmAccountChange}
            confirmText="Yes, Change Customer"
          >
            Are you sure you want to change the account to "
            {pendingAccount?.displayName}"? You will have to reconfigure any
            plan that has been selected.
          </OnsiteConfirmModal>
        )}
        {isUploading && (
          <OnsiteModal>
            <UploadingStatusContent
              header={
                isImport
                  ? 'Importing Plan...'
                  : isFreePlan
                  ? 'Activating Plan...'
                  : 'Initializing Plan...'
              }
              operationName={
                isImport
                  ? 'import'
                  : isFreePlan
                  ? 'activation'
                  : 'initialization'
              }
            />
          </OnsiteModal>
        )}
        {activatedMaintenancePlanGuid &&
          selectedPlanType &&
          activatedMaintenancePlanData && (
            <MaintenancePlanActivatedModal
              isImport={isImport}
              primaryContactEmail={primaryContactEmail}
              maintenancePlanGuid={activatedMaintenancePlanGuid}
              planDefinition={selectedPlanType}
              data={activatedMaintenancePlanData}
              onClose={onClose}
              paymentMethod={activatedMaintenancePlanMeta?.paymentMethod}
              paymentAmountUsc={activatedMaintenancePlanMeta?.paymentAmountUsc}
              jobGuid={jobGuid}
              closeText={
                jobGuid ? 'View Appointment Details' : 'Close This Page'
              }
            />
          )}
        {createdMaintenancePlanGuid &&
          !!oneShotInvoiceLoader.invoice &&
          !activatedMaintenancePlanGuid && (
            <PaymentWorkflowWizard
              meta={{
                invoice: oneShotInvoiceLoader.invoice,
                invoiceAmountDueUsd: usCentsToUsd(
                  oneShotInvoiceLoader.invoice.amountDueUsc,
                ),
                editableAmount: false,
              }}
              onPaymentSuccess={(paymentMethod, paymentAmountUsc) => {
                setIsUploading(false)
                hidePaymentStatusModal()
                setActivatedMaintenancePlanGuid(createdMaintenancePlanGuid)
                setActivatedMaintenancePlanMeta({
                  paymentMethod,
                  paymentAmountUsc,
                })
                datadogRum.addAction(
                  MAINTENANCE_PLAN_WIZARD_ONE_SHOT_PAYMENT_SUCCESS_ACTION,
                  {
                    onRamp,
                    paymentMethod,
                    paymentAmountUsc,
                    createdMaintenancePlanGuid,
                    invoice: oneShotInvoiceLoader.invoice,
                  },
                )
              }}
              mode="base"
              hideCloseButton
            />
          )}
        {!activatedMaintenancePlanGuid &&
          !isUploading &&
          maintenancePlanActivationInput &&
          !!isAutoRenewing &&
          isNullish(oneShotInvoiceLoader.invoice) && (
            <PaymentWorkflowWizard
              meta={{
                maintenancePlan: maintenancePlanActivationInput,
                editableAmount: false,
              }}
              paymentInfo={autoRenewingPaymentConfigurationInfo}
              hiddenOptions={['Payment Link']}
              onPaymentSuccess={(paymentMethod, paymentAmountUsc) => {
                setIsUploading(false)
                hidePaymentStatusModal()
                setActivatedMaintenancePlanGuid(
                  maintenancePlanActivationInput.maintenancePlanGuid,
                )
                setActivatedMaintenancePlanMeta({
                  paymentMethod,
                  paymentAmountUsc,
                })
                datadogRum.addAction(
                  MAINTENANCE_PLAN_WIZARD_PAYMENT_SUBSCRIPTION_SUCCESS_ACTION,
                  {
                    onRamp,
                    paymentMethod,
                    paymentAmountUsc,
                    maintenancePlanActivationInput,
                  },
                )
              }}
              onPaymentError={() => {
                setIsUploading(false)
              }}
              disabledOptions={[
                {
                  option: 'Check',
                  info: 'Cannot be used for auto-renewing plans',
                },
                {
                  option: 'Cash',
                  info: 'Cannot be used for auto-renewing plans',
                },
                {
                  option: 'Other',
                  info: 'Cannot be used for auto-renewing plans',
                },
              ]}
              hideCloseButton
            />
          )}
      </>
    )
  },
)

type MaintenancePlanStepWrapperProps = React.PropsWithChildren

const MaintenancePlanStepWrapper = React.memo<MaintenancePlanStepWrapperProps>(
  ({ children }) => {
    const isMobile = useIsMobile()
    return (
      <div
        className={classNames(
          'flex flex-col',
          isMobile ? 'mb-[40px]' : 'mb-[80px]',
        )}
      >
        {children}
      </div>
    )
  },
)

const MaintenancePlanWizardLoadingSpinner = React.memo(() => {
  return (
    <div className="center-children-vh h-full min-h-[500px] w-full">
      <LoadingSpinner />
    </div>
  )
})
