import { WithPartialKeys, isNullish } from '@breezy/shared'
import {
  faCheck,
  faCircleInfo,
  faClose,
} from '@fortawesome/pro-regular-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Form, Switch, Tooltip } from 'antd'
import classNames from 'classnames'
import React from 'react'
import {
  Controller,
  ControllerProps,
  FieldPath,
  FieldValues,
  GlobalError,
} from 'react-hook-form'
import { typedMemo } from '../../utils/react-utils'

export type CheckFieldProps<
  TFieldValues extends FieldValues = FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>,
> = WithPartialKeys<ControllerProps<TFieldValues, TName>, 'render'> & {
  errors: Partial<Record<keyof TFieldValues, GlobalError>>
  label: React.ReactNode
  className?: string
  disabled?: boolean
  defaultValue: TFieldValues[TName]
}

export const CheckField = typedMemo(
  <
    TFieldValues extends FieldValues = FieldValues,
    TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>,
  >({
    name,
    render,
    errors,
    label,
    className,
    disabled,
    defaultValue,
    ...rest
  }: CheckFieldProps<TFieldValues, TName>) => {
    // This component can render another form field when opened (like if it's a "notify a specific user" field, checking
    // it might open up a text field). That's signified by the existence of `render`. Otherwise it's just a checkbox.
    const justACheckBox = !render
    return (
      <Controller
        name={name}
        render={args => {
          // If it's just a checkbox then it's very straightforward. Otherwise, its checked-ness is based on if there a
          // non-undefined value.
          const checked = justACheckBox
            ? args.field.value
            : !isNullish(args.field.value)

          const onClick = () =>
            // If it's just a checkbox, then we toggle. Otherwise, toggling it off should clear the value. Toggling it
            // on should give it a non-undefined default value (could be empty string or empty array).
            justACheckBox
              ? args.field.onChange(!checked)
              : args.field.onChange(checked ? undefined : defaultValue)

          const content = (
            <div className="grid grid-cols-[auto_1fr] gap-x-3 gap-y-2">
              <Switch
                disabled={disabled}
                onChange={onClick}
                checked={checked}
                data-testid={name}
                checkedChildren={<FontAwesomeIcon icon={faCheck} />}
                unCheckedChildren={<FontAwesomeIcon icon={faClose} />}
              />
              <div
                className={classNames('w-fit select-none font-semibold', {
                  'cursor-pointer': !disabled,
                })}
                onClick={() => !disabled && onClick()}
              >
                {label}
              </div>
              {checked && render && (
                <div className="col-start-2 w-fit">
                  <Form.Item
                    className="mb-0"
                    validateStatus={
                      isNullish(errors[name]?.message) ? 'success' : 'error'
                    }
                    help={errors[name]?.message}
                  >
                    {render(args)}
                  </Form.Item>
                </div>
              )}
            </div>
          )

          // If this isn't just a check box (when it's open, we reveal a form field) We put the validation on that inner
          // form-field. But if that inner field is hidden (either because `checked` isn't true, meaning it isn't open,
          // or the inner field is non-existent because this field is just a checkbox) then we won't show validation
          // errors on the checkbox (such as a field being required or it being invalid to have one checked and another
          // not). So in that case we want to render the `Form.Item` on the outside, which will show validation messages
          // properly.
          if (!justACheckBox && checked) {
            return content
          }
          return (
            <Form.Item
              className="mb-0"
              validateStatus={
                isNullish(errors[name]?.message) ? 'success' : 'error'
              }
              help={errors[name]?.message}
            >
              {content}
            </Form.Item>
          )
        }}
        {...rest}
      />
    )
  },
)

export type CategorySectionProps = React.PropsWithChildren<{
  title: string
  subtitle: string
  extra?: React.ReactNode
  layout?: {
    leftContainerFlex: 1 | 2
    rightContainerFlex: 1 | 2
  }
}>

export const CategorySection = React.memo<CategorySectionProps>(
  ({
    title,
    subtitle,
    extra,
    layout = {
      leftContainerFlex: 1,
      rightContainerFlex: 1,
    },
    children,
  }) => {
    return (
      <div className="flex flex-row border-0 pt-6">
        <div className={`min-w-0`} style={{ flex: layout.leftContainerFlex }}>
          <div className="text-base font-semibold text-bz-gray-1000">
            {title}
          </div>
          <div className="max-w-[320px] text-bz-gray-900">{subtitle}</div>
          {extra}
        </div>
        <div
          style={{ flex: layout.rightContainerFlex }}
          className={`ml-6 mt-[-24px] min-w-[440px] divide-y divide-dashed divide-bz-gray-500`}
        >
          {children}
        </div>
      </div>
    )
  },
)

export type SubSectionProps = React.PropsWithChildren<{
  title?: string
  subtitle?: string
  tooltip?: string
}>

export const SubSection = React.memo<SubSectionProps>(
  ({ title, subtitle, tooltip, children }) => (
    <div className="mb-6 border-0 pt-6">
      <div className="text-base font-semibold text-bz-gray-1000">
        {title}
        {tooltip && (
          <Tooltip title={tooltip}>
            <FontAwesomeIcon
              icon={faCircleInfo}
              className="ml-2 text-bz-gray-700"
            />
          </Tooltip>
        )}
      </div>
      {subtitle && <div className="text-bz-gray-900">{subtitle}</div>}
      <div className="mt-4 space-y-4">{children}</div>
    </div>
  ),
)
