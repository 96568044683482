import { OnsitePageCollapsibleSection } from 'src/adam-components/OnsitePage/OnsitePageCollapsibleSection'
import {
  PaymentCard,
  PaymentCardPayment,
} from 'src/components/Cards/PaymentCard'

export interface PaymentsCollapsibleProps {
  payments: PaymentCardPayment[]
}

export const PaymentsCollapsible = (props: PaymentsCollapsibleProps) => {
  return (
    <OnsitePageCollapsibleSection
      smallTitle
      title="Payments"
      count={props.payments.length}
      defaultCollapsed={props.payments.length === 0}
    >
      <div className="flex flex-col gap-2">
        {props.payments.map(payment => (
          <PaymentCard key={payment.paymentRecordGuid} payment={payment} />
        ))}
      </div>
    </OnsitePageCollapsibleSection>
  )
}
