import { ColorPicker, ColorPickerProps } from 'antd'
import React from 'react'

type ColorPickerFieldProps = ColorPickerProps & {
  name: string
  ddActionName?: string
  onChange: (hexString: string) => void
}

export const ColorPickerField = React.memo(
  React.forwardRef<HTMLDivElement, ColorPickerFieldProps>(
    (
      { name, size = 'large', className, ddActionName, onChange, ...rest },
      ref,
    ) => (
      <div ref={ref} className={className}>
        <ColorPicker
          data-dd-action-name={ddActionName}
          data-testid={name}
          size={size}
          onChange={color => onChange(color.toHexString())}
          {...rest}
        />
      </div>
    ),
  ),
)
