import { BzDateFns, IsoDateString } from '@breezy/shared'

type AccountingSyncLoadingProps = {
  manualSyncLoading: boolean
  issuedAt?: IsoDateString
  lastPaymentAt?: IsoDateString
  lastUpdatedAt?: IsoDateString
  isFullyPaid?: boolean
  isVoided?: boolean
  accountingAutoSyncSettings?: {
    onIssued?: boolean
    onPayment?: boolean
    onFullyPaid?: boolean
    onVoided?: boolean
  }
}

// There are certain conditions (enabled via the company settings) where an invoice is auto synced: when issued,
// when a payment is made, when the invoice is fully paid, and when voided. We can get a race condition when one of these
// auto-syncs is happening (based on a 60 second cron) and the user manually syncs (like if they issue the invoice
// then compulsively hits the "sync" button). As a sketchy hack, we can say that if any of those events (provided
// the settings are set) happened within the last 60 seconds, we "pretend" we're in a loading state. When they
// complete, the live query will just make the button go away and say "Synced". If the crons fail for some reason
// (rare), then the button will spin forever (unless they refresh the page or do some kind of navigation that will
// cause this component to rerender), which is not that bad of a user experience (not as bad as hitting the button
// and getting a cryptic error message).
export const useAccountingSyncLoading = ({
  manualSyncLoading,
  issuedAt,
  lastPaymentAt,
  lastUpdatedAt,
  isFullyPaid,
  isVoided,
  accountingAutoSyncSettings,
}: AccountingSyncLoadingProps): boolean => {
  if (manualSyncLoading) {
    return true
  }

  // If the dates are greater than 60 seconds ago, that means they happened within the last 60 seconds.
  const sixtySecondsAgo = BzDateFns.subSeconds(BzDateFns.now(BzDateFns.UTC), 60)

  // If we auto sync on issued, then if the issued date was within the last 60 seconds, we're loading.
  if (
    accountingAutoSyncSettings?.onIssued &&
    issuedAt &&
    BzDateFns.parseISO(issuedAt, BzDateFns.UTC) > sixtySecondsAgo
  ) {
    return true
  }

  // Both the "onPayment" and "onFullyPaid" will be based on the last payment. The difference is the "onFullyPaid"
  // only triggers if we're also now fully paid.
  if (
    lastPaymentAt &&
    (accountingAutoSyncSettings?.onPayment ||
      accountingAutoSyncSettings?.onFullyPaid)
  ) {
    const lastPaymentDate = BzDateFns.parseISO(lastPaymentAt, BzDateFns.UTC)
    if (lastPaymentDate > sixtySecondsAgo) {
      // If we auto sync on any payment, then if the last payment was within the last 60 seconds, we're loading.
      // Otherwise, because of the outer `if`, we know that we must be `onFullyPaid`. If the last payment was within
      // 60 seconds and we're fully paid, that means the last payment was the one that fully paid it (otherwise
      // there would be a payment after it was fully paid which doesn't make sense). So if we're fully paid, then we
      // know the last payment was the fully-paying one, which means if they have "onFullyPaid" on then we are
      // triggering the auto sync.
      return accountingAutoSyncSettings?.onPayment || isFullyPaid || false
    }
  }

  // If we auto sync on voided, then if the last update was within the last 60 seconds, we're loading.
  if (lastUpdatedAt && accountingAutoSyncSettings?.onVoided && isVoided) {
    const lastUpdatedDate = BzDateFns.parseISO(lastUpdatedAt, BzDateFns.UTC)
    if (lastUpdatedDate > sixtySecondsAgo) {
      return true
    }
  }

  return false
}
