/* eslint-disable no-console */
import {
  breezyPhoneNumberToTwilioPhone,
  BzDateFns,
  externalCallId,
  nextGuid,
  twilioPhoneNumberToBreezyPhone,
} from '@breezy/shared'
import { Call, Device } from '@twilio/voice-sdk'
import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react'
import { useMutation, useQuery } from 'urql'
import { useCanUseIntegratedPhone } from '../../hooks/permission/useCanUseIntegratedPhone'
import { ACCOUNT_DETAILS_QUERY } from '../../pages/AccountDetailsPage/AccountDetailsPage.gql'
import { getAvatarText } from '../../pages/CommsPage/CommUtils'
import { usePhoneIdentity } from '../../providers/PrincipalUser'
import { useTwilioTrpc } from './TwilioTrpc'
import {
  ActiveCall,
  AudioDeviceOption,
  AudioSettings,
  BeginPhoneCallToData,
  emptyAudioSettings,
  IncomingCall,
  IntegratedPhoneStatus,
  OutboundCall,
  TwilioPhoneContextType,
} from './TwilioTypes'
import {
  ContactLite,
  useFetchContactLiteByPhoneNumber,
} from './useFetchContactLiteByPhoneNumber'
import { useFetchIntegratedPhoneCallGuidByExternalCallId } from './useFetchIntegratedPhoneCallGuidByExternalCallId'
import { UPSERT_USER_PHONE_STATUS_MUTATION } from './UserPhoneStatus.gql'

const debugLogEnabled = false
const debugLog = (...args: unknown[]) => {
  if (debugLogEnabled) {
    console.log(args)
  }
}

const updateAudioDevices = async (device: Device) => {
  await navigator.mediaDevices.getUserMedia({ audio: true })
  const devices = await navigator.mediaDevices.enumerateDevices()

  const audioInputDevices = devices.filter(
    device => device.kind === 'audioinput',
  )
  const audioOutputDevices = devices.filter(
    device => device.kind === 'audiooutput',
  )

  debugLog('Twilio Phone - Audio Input Devices:', audioInputDevices)
  debugLog('Twilio Phone - Audio Output Devices:', audioOutputDevices)

  if (audioOutputDevices.length > 0 && device.audio) {
    const defaultOutputDevice = audioOutputDevices[0]
    await device.audio.speakerDevices.set(defaultOutputDevice.deviceId)
    await device.audio.ringtoneDevices.set(defaultOutputDevice.deviceId)
  }

  if (audioInputDevices.length > 0 && device.audio) {
    const defaultInputDevice = audioInputDevices[0]
    await device.audio.setInputDevice(defaultInputDevice.deviceId)
  }
}

const initializeAndRegisterDevice = async (
  token: string,
  setup: (device: Device) => void,
): Promise<Device> => {
  debugLog('Twilio Phone - Initializing device', { token })
  const device = new Device(token, {
    logLevel: 3,
    codecPreferences: [Call.Codec.Opus, Call.Codec.PCMU],
    sounds: {
      incoming: '/sfx/phone_ring.mp3',
      outgoing: '/sfx/phone_ring.mp3',
    },
  })
  debugLog('Twilio Phone - Device initialized')
  setup(device)
  debugLog('Twilio Phone - Device setup')
  await updateAudioDevices(device)
  debugLog('Twilio Phone - Audio devices updated')
  try {
    await device.register()
    debugLog('Twilio Phone - Device registered')
  } catch (err) {
    debugLog('Twilio Phone - Error registering device:', err)
  }
  return device
}

const useEverInteractedWithWindow = () => {
  const [everInteracted, setEverInteracted] = useState(false)

  const handleClick = useCallback(() => {
    if (!everInteracted) {
      setEverInteracted(true)
    }
  }, [everInteracted])

  useEffect(() => {
    window.addEventListener('click', handleClick)

    return () => {
      window.removeEventListener('click', handleClick)
    }
  }, [handleClick])

  return everInteracted
}

const getLeadSourceFromTwilio = (call: ActiveCall | IncomingCall) => {
  return {
    leadSourceName:
      call.twilio?.customParameters.get('leadSourceName') ?? 'No Lead Source',
    leadSourceGuid: call.twilio?.customParameters.get('leadSourceGuid'),
  }
}

const getContactData = (contactLite: ContactLite | undefined) => {
  if (!contactLite) {
    return { avatarText: '?' }
  }

  return {
    contactName: contactLite?.fullName,
    accountGuid: contactLite?.accountGuid,
    contactGuid: contactLite?.contactGuid,
    avatarText: getAvatarText(contactLite),
  }
}

const getAudioSettingsFromDevice = async (
  device: Device,
): Promise<AudioSettings> => {
  if (!device || !device.audio) {
    return emptyAudioSettings
  }

  const devices = await navigator.mediaDevices.enumerateDevices()

  const outputDevices = devices
    .filter(d => d.kind === 'audiooutput')
    .map(d => ({
      deviceId: d.deviceId,
      label: d.label || `Speaker ${d.deviceId}`,
    }))

  const inputDevices = devices
    .filter(d => d.kind === 'audioinput')
    .map(d => ({
      deviceId: d.deviceId,
      label: d.label || `Microphone ${d.deviceId}`,
    }))

  const selectedDeviceIds = {
    outputDeviceId:
      device.audio.speakerDevices.get().size > 0
        ? Array.from(device.audio.speakerDevices.get())[0].deviceId
        : undefined,
    inputDeviceId: device.audio.inputDevice?.deviceId,
  }

  debugLog('Twilio Phone - Getting audio devices', {
    outputDevices,
    inputDevices,
    selectedDeviceIds,
  })

  return {
    audioDevices: {
      outputDevice: outputDevices,
      inputDevice: inputDevices,
    },
    selectedDeviceIds,
    setOutputDevice: async (opt: AudioDeviceOption) => {
      if (device?.audio) {
        await device.audio.speakerDevices.set(opt.deviceId)
        await device.audio.ringtoneDevices.set(opt.deviceId)
        debugLog('Twilio Phone - Output device set', { opt })
      }
    },
    setInputDevice: async (opt: AudioDeviceOption) => {
      if (device?.audio) {
        await device.audio.setInputDevice(opt.deviceId)
        debugLog('Twilio Phone - Input device set', { opt })
      }
    },
  }
}

const TwilioPhoneContext = createContext<TwilioPhoneContextType | undefined>(
  undefined,
)

export const TwilioPhoneProvider = ({
  children,
}: {
  children: React.ReactNode
}) => {
  const phoneIdentity = usePhoneIdentity()
  const companyGuid = phoneIdentity?.companyGuid
  const userGuid = phoneIdentity?.userGuid
  const identity = phoneIdentity?.phoneIdentity
  const canUseIntegratedPhone = useCanUseIntegratedPhone()
  const everInteracted = useEverInteractedWithWindow()
  const isInitializingRef = useRef(false)

  const [needsNewAccessToken, setNeedsNewAccessToken] = useState(false)
  const [device, setDevice] = useState<Device | null>(null)
  const [incomingCall, setIncomingCall] = useState<IncomingCall | null>(null)
  const [activeCall, setActiveCall] = useState<ActiveCall | null>(null)
  const [outboundCall, setOutboundCall] = useState<OutboundCall | null>(null)
  const [twilioCallSid, setTwilioCallSid] = useState<string | undefined>(
    undefined,
  )
  const [status, setStatusBase] =
    useState<IntegratedPhoneStatus>('disconnected')
  const [lastUpdatedStatusAt, setLastUpdatedStatusAt] = useState<Date | null>(
    null,
  )
  const [lastTokenAttempt, setLastTokenAttempt] = useState<Date | null>(null)
  const [audioSettings, setAudioSettings] =
    useState<AudioSettings>(emptyAudioSettings)

  const { getAccessTokenMutation, recordCallAnsweredMutation } = useTwilioTrpc()
  const fromPhoneNumberBreezyFormat = useMemo(() => {
    if (incomingCall) {
      return twilioPhoneNumberToBreezyPhone(incomingCall.from)
    }
    if (activeCall) {
      return twilioPhoneNumberToBreezyPhone(activeCall.from)
    }
    return undefined
  }, [incomingCall, activeCall])

  const contactLite = useFetchContactLiteByPhoneNumber(
    fromPhoneNumberBreezyFormat,
  )

  const integratedPhoneCallGuid =
    useFetchIntegratedPhoneCallGuidByExternalCallId(twilioCallSid)

  const [accountDetailsQuery] = useQuery({
    query: ACCOUNT_DETAILS_QUERY,
    pause: !contactLite,
    variables: { accountGuid: contactLite?.accountGuid ?? '' },
  })

  const comprehensiveAccount = accountDetailsQuery.data?.accountsByPk

  const [, upsertUserPhoneStatus] = useMutation(
    UPSERT_USER_PHONE_STATUS_MUTATION,
  )
  const upsertIsAvailable = useCallback(
    (s: IntegratedPhoneStatus) => {
      if (!companyGuid || !userGuid || !identity) return

      upsertUserPhoneStatus({
        obj: {
          integratedPhoneUserGuid: nextGuid(),
          companyGuid,
          userGuid,
          updatedAt: new Date().toISOString(),
          isAvailable: s === 'connected',
          identity,
        },
      })
        .catch(error => {
          console.error('Error updating user phone status', error)
        })
        .then(() => {
          debugLog('Twilio Phone - User phone status updated')
        })
    },
    [companyGuid, userGuid, upsertUserPhoneStatus, identity],
  )

  const setStatus = useCallback(
    (s: IntegratedPhoneStatus) => {
      setStatusBase(s)
      setLastUpdatedStatusAt(new Date())
      upsertIsAvailable(s)
    },
    [upsertIsAvailable],
  )

  // NOTE: Update Status in DB, so that it's never more than a minute stale
  useEffect(() => {
    if (!canUseIntegratedPhone) {
      return
    }

    const interval = setInterval(() => {
      if (
        lastUpdatedStatusAt &&
        BzDateFns.differenceInMinutes(new Date(), lastUpdatedStatusAt) < 1
      ) {
        return
      }

      upsertIsAvailable(status)
    }, 60000)

    return () => clearInterval(interval)
  }, [lastUpdatedStatusAt, status, upsertIsAvailable, canUseIntegratedPhone])

  const onDeviceError = useCallback(
    (error: Error) => {
      console.error('Twilio Phone - Twilio.Device Error:', error)

      if (
        error.name === 'AccessTokenExpired' ||
        error.message.includes('AccessTokenExpired')
      ) {
        console.log('Twilio Phone - Access token expired, requesting new token')
        setNeedsNewAccessToken(true)
      }
    },
    [setNeedsNewAccessToken],
  )

  const onDeviceRegistered = useCallback(() => {
    debugLog('Twilio Phone - Twilio.Device Ready to make and receive calls!')
    setStatus('connected')
  }, [setStatus])

  const clearState = useCallback(() => {
    setActiveCall(null)
    setIncomingCall(null)
    setOutboundCall(null)
    setTwilioCallSid(undefined)
    setStatus('connected')
  }, [setStatus])

  const disconnect = useCallback(
    (call: Call) => {
      debugLog('Twilio Phone - Disconnecting call', { call })
      call.reject()
      call.disconnect()
      clearState()
    },
    [clearState],
  )

  const onCallerDisconnected = useCallback(() => {
    debugLog('Twilio Phone - Caller has disconnected')
    clearState()
  }, [clearState])

  const onConnected = useCallback(() => {
    debugLog('Twilio Phone - Device connected event')
  }, [])

  const onIncomingCall = useCallback(
    (call: Call) => {
      debugLog('Twilio Phone - Incoming call', { call })
      call.on('disconnect', onCallerDisconnected)
      call.on('cancel', onCallerDisconnected)
      call.on('reject', onCallerDisconnected)
      setIncomingCall({
        twilio: call,
        from: twilioPhoneNumberToBreezyPhone(call.parameters.From),
        accept: () => {
          const twilioCallSid = call.customParameters.get('parentCallSid') ?? ''
          call.accept()
          setActiveCall({
            answeredAt: new Date(),
            twilio: call,
            from: twilioPhoneNumberToBreezyPhone(call.parameters.From),
            hangup: () => disconnect(call),
          })
          setTwilioCallSid(twilioCallSid)
          setIncomingCall(null)
          setStatus('busy')
          // Record that the call was answered
          recordCallAnsweredMutation.mutate(
            {
              externalCallId: externalCallId('Twilio', twilioCallSid),
            },
            {
              onSuccess: () => {
                debugLog('Twilio Phone - Call answered recorded successfully')
              },
              onError: (error: unknown) => {
                console.error(
                  'Twilio Phone - Error recording call answered:',
                  error,
                )
              },
            },
          )
        },
        reject: () => disconnect(call),
      })
    },
    [setStatus, disconnect, onCallerDisconnected, recordCallAnsweredMutation],
  )

  const onAudioDeviceChange = useCallback(async (device: Device) => {
    debugLog('Twilio Phone - Audio device changed')
    const newAudioSettings = await getAudioSettingsFromDevice(device)
    setAudioSettings(newAudioSettings)
  }, [])

  const addDeviceListeners = useCallback(
    (device: Device) => {
      console.log('Device Events', device.eventNames())
      device.on('registered', onDeviceRegistered)
      device.on('error', onDeviceError)
      device.on('incoming', onIncomingCall)
      device.on('connected', onConnected)
      device.audio?.on('deviceChange', () => onAudioDeviceChange(device))
    },
    [
      onDeviceRegistered,
      onDeviceError,
      onIncomingCall,
      onAudioDeviceChange,
      onConnected,
    ],
  )

  const beginVoiceCallTo = useCallback(
    async (req: BeginPhoneCallToData) => {
      const twilioPhoneNumber = breezyPhoneNumberToTwilioPhone(req.phoneNumber)
      if (incomingCall || activeCall || outboundCall) {
        console.warn(
          'Twilio Phone - Cannot make call while in another call state',
        )
        return
      }

      if (device) {
        debugLog(`Twilio Phone - Attempting to call ${req.phoneNumber} ...`)
        try {
          const call = await device.connect({
            params: { To: twilioPhoneNumber },
          })
          const startedAt = new Date()
          setStatus('busy')
          setOutboundCall({
            twilio: call,
            to: req.phoneNumber,
            startedAt,
            hangup: () => disconnect(call),
            ...getContactData(req.contact),
          })

          call.on('accept', () => {
            debugLog('Twilio Phone - Call accepted')
            setTwilioCallSid(call.parameters.CallSid)
          })
          call.on('audio', () => {
            debugLog('Twilio Phone - Call audio event')
          })
          call.on('connect', () => {
            debugLog('Twilio Phone - Call connected')
            setActiveCall({
              answeredAt: startedAt,
              twilio: call,
              from: req.phoneNumber,
              hangup: () => disconnect(call),
            })
            setOutboundCall(null)
          })
          call.on('disconnect', () => {
            debugLog('Twilio Phone - Call disconnected')
            clearState()
          })
          call.on('cancel', () => {
            debugLog('Twilio Phone - Call canceled')
            clearState()
          })
          call.on('messageReceived', message => {
            console.log(JSON.stringify(message.content))
            if ((message?.content?.type ?? '') === 'CALL_ANSWERED') {
              setActiveCall({
                answeredAt: new Date(),
                twilio: call,
                from: req.phoneNumber,
                hangup: () => disconnect(call),
              })
              setOutboundCall(null)
            }
          })
        } catch (error) {
          console.error('Twilio Phone - Error making outgoing call:', error)
          clearState()
        }
      } else {
        // TODO: Initialize Device if not initialized
        console.error(
          'Twilio Phone - Unable to make call. Device not initialized.',
        )
      }
    },
    [
      device,
      disconnect,
      clearState,
      setStatus,
      activeCall,
      incomingCall,
      outboundCall,
      setOutboundCall,
      setActiveCall,
    ],
  )

  useEffect(() => {
    if (!canUseIntegratedPhone) {
      return
    }

    const initializeDevice = async () => {
      if (
        (phoneIdentity &&
          everInteracted &&
          !device &&
          !isInitializingRef.current) ||
        (phoneIdentity && everInteracted && needsNewAccessToken)
      ) {
        debugLog('Twilio Phone - Initializing device')
        const now = new Date()
        if (
          lastTokenAttempt &&
          BzDateFns.differenceInSeconds(now, lastTokenAttempt) < 15
        ) {
          debugLog('Twilio Phone - Waiting before requesting new token')
          return
        }

        isInitializingRef.current = true
        setLastTokenAttempt(now)
        debugLog('Begin Twilio Token Mutation')
        try {
          const data = await getAccessTokenMutation.mutateAsync({ input: {} })
          debugLog('Twilio Phone - Access Token:', { data })
          await initializeAndRegisterDevice(data.token, d => {
            setNeedsNewAccessToken(false)
            addDeviceListeners(d)
            setDevice(d)
          })
          isInitializingRef.current = false
        } catch (error) {
          console.error('Twilio Phone - Error getting access token:', error)
          isInitializingRef.current = false
        }
      } else {
        debugLog('Twilio Phone - Skipping initializing device', {
          phoneIdentity,
          everInteracted,
          device,
          isInitializingRef: isInitializingRef.current,
          needsNewAccessToken,
        })
      }
    }

    initializeDevice()
  }, [
    everInteracted,
    device,
    addDeviceListeners,
    getAccessTokenMutation,
    needsNewAccessToken,
    lastTokenAttempt,
    phoneIdentity,
    canUseIntegratedPhone,
  ])

  // Mark user as unavailable when component is disposed
  useEffect(() => {
    if (!canUseIntegratedPhone) {
      return
    }

    return () => {
      // Use a background thread to update the database
      setTimeout(() => {
        upsertIsAvailable('disconnected')
      }, 0)
    }
  }, [upsertIsAvailable, canUseIntegratedPhone])

  useEffect(() => {
    const updateAudioSettings = async () => {
      if (device && device.audio) {
        await navigator.mediaDevices.getUserMedia({ audio: true })
        const newAudioSettings = await getAudioSettingsFromDevice(device)
        setAudioSettings(newAudioSettings)
        debugLog('Twilio Phone - Audio settings updated', { newAudioSettings })
      } else {
        setAudioSettings(emptyAudioSettings)
      }
    }

    updateAudioSettings()
  }, [device])

  const contextValue = useMemo(
    () => ({
      status,
      everInteracted,
      incomingCall: incomingCall
        ? {
            ...incomingCall,
            integratedPhoneCallGuid,
            phoneNumber: incomingCall.from,
            comprehensiveAccount: comprehensiveAccount,
            ...getLeadSourceFromTwilio(incomingCall),
            ...getContactData(contactLite),
          }
        : null,
      activeCall: activeCall
        ? {
            ...activeCall,
            integratedPhoneCallGuid,
            phoneNumber: activeCall.from,
            comprehensiveAccount: comprehensiveAccount,
            ...getLeadSourceFromTwilio(activeCall),
            ...getContactData(contactLite),
          }
        : null,
      outboundCall: outboundCall
        ? {
            ...outboundCall,
            integratedPhoneCallGuid,
            phoneNumber: outboundCall.to,
            comprehensiveAccount: comprehensiveAccount,
          }
        : null,
      beginVoiceCallTo,
      audioSettings,
      device,
    }),
    [
      device,
      status,
      everInteracted,
      incomingCall,
      activeCall,
      outboundCall,
      comprehensiveAccount,
      contactLite,
      beginVoiceCallTo,
      audioSettings,
      integratedPhoneCallGuid,
    ],
  )

  return (
    <TwilioPhoneContext.Provider value={contextValue}>
      {children}
    </TwilioPhoneContext.Provider>
  )
}

export const useTwilioPhone = (): TwilioPhoneContextType => {
  const context = useContext(TwilioPhoneContext)
  if (!context) {
    console.error('useTwilioPhone must be used within a TwilioPhoneProvider')
    return {
      status: 'disconnected',
      everInteracted: false,
      incomingCall: null,
      activeCall: null,
      outboundCall: null,
      beginVoiceCallTo: async (): Promise<void> => {
        console.error(
          'Twilio Phone - beginVoiceCallTo must be used within a TwilioPhoneProvider',
        )
        return Promise.resolve()
      },
      audioSettings: emptyAudioSettings,
      device: null,
    }
  }
  return context
}
/* eslint-enable no-console */
