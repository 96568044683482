import useIsMobile from '../../hooks/useIsMobile'
import { Composable } from '../../utils/Composable'
import { m } from '../../utils/react-utils'

type BzColumnProps = Composable & {
  readonly noPadding?: boolean
  readonly noPaddingMobile?: boolean
}

const BzColumn = m<BzColumnProps>(
  ({ children, noPadding, noPaddingMobile }) => {
    const isMobile = useIsMobile()
    const paddingCn =
      noPadding || (noPaddingMobile && isMobile) ? '' : 'p-3 md:p-4 lg:p-6'

    return (
      <div className={`flex flex-col ${paddingCn} gap-y-3 pb-2`}>
        {children}
      </div>
    )
  },
)

export default BzColumn
