import { useCallback, useState } from 'react'
import { useSyncInvoice } from '../../../hooks/useSyncInvoice'
import { useSyncPayout } from '../../../hooks/useSyncPayout'
import { typedMemo } from '../../../utils/react-utils'
import {
  BasicQuickbooksSyncButton,
  QuickbooksSyncButtonBaseProps,
} from '../QuickbooksOnline/QuickbooksSyncButton'

type QuickbooksAccountingSyncInvoiceButtonProps =
  QuickbooksSyncButtonBaseProps & {
    invoiceGuid: string
    invoiceDisplayId?: string
    isStale?: boolean
    loading?: boolean
    onSuccess?: () => void
  }

export const QuickbooksAccountingSyncInvoiceButton = typedMemo(
  ({
    invoiceGuid,
    invoiceDisplayId,
    isStale,
    loading,
    onSuccess,
    ...props
  }: QuickbooksAccountingSyncInvoiceButtonProps) => {
    const { onSyncClick, loading: syncLoading } = useSyncInvoice({
      accountingIntegrationType: 'QBD',
      invoiceGuid,
      invoiceDisplayId,
      onSuccess,
    })

    return (
      <BasicQuickbooksSyncButton
        {...props}
        isStale={isStale}
        loading={loading || syncLoading}
        onSyncClick={onSyncClick}
      />
    )
  },
)

type QuickbooksAccountingSyncPayoutButtonProps =
  QuickbooksSyncButtonBaseProps & {
    payoutGuid: string
    isStale?: boolean
    loading?: boolean
    onSuccess?: () => void
  }

export const QuickbooksAccountingSyncPayoutButton = typedMemo(
  ({
    payoutGuid,
    isStale,
    loading,
    onSuccess,
    ...props
  }: QuickbooksAccountingSyncPayoutButtonProps) => {
    const [localIsStale, setLocalIsStale] = useState(isStale)
    const localOnSuccess = useCallback(() => {
      setLocalIsStale(false)
      onSuccess?.()
    }, [onSuccess])

    const { onSyncClick, loading: syncLoading } = useSyncPayout({
      accountingIntegrationType: 'QBD',
      payoutGuid,
      onSuccess: localOnSuccess,
    })

    return (
      <BasicQuickbooksSyncButton
        {...props}
        isStale={localIsStale}
        loading={loading || syncLoading}
        onSyncClick={onSyncClick}
      />
    )
  },
)
