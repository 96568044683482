import {
  JobClass,
  Technician,
  TechnicianRole,
  dateRangeToUnitAndN,
  isNullish,
} from '@breezy/shared'
import { faArrowTrendUp } from '@fortawesome/pro-light-svg-icons'
import { faArrowRight } from '@fortawesome/pro-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Button, Select, Tabs } from 'antd'
import {
  ReactNode,
  memo,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react'
import { useQuery } from 'urql'
import { LoadingSpinner } from '../../../components/LoadingSpinner'
import { Card } from '../../../elements/Card/Card'
import { trpc } from '../../../hooks/trpc'
import { useCompanyMaintenancePlansEnabled } from '../../../hooks/useCompanyMaintenancePlansEnabled'
import {
  useExpectedCompanyGuid,
  useExpectedCompanyTimeZoneId,
} from '../../../providers/PrincipalUser'
import { NoDataOverlay } from '../NoDataOverlay'
import {
  StandardReportingDateRangePicker,
  useStandardReportingDateRangePickerState,
} from '../ReportingDateRangePicker/StandardReportingDateRangePicker'
import { GenerateReportModal } from './GenerateReportModal'
import { IndividualTechnicianPerformanceAvgTicketSizeChart } from './IndividualTechnicianPerformanceAvgTicketSizeChart'
import { IndividualTechnicianPerformanceConversionRateChart } from './IndividualTechnicianPerformanceConversionRateChart'
import { IndividualTechnicianPerformanceEarnedRevenueChart } from './IndividualTechnicianPerformanceEarnedRevenueChart'
import { IndividualTechnicianPerformanceGeneratedLeadsChart } from './IndividualTechnicianPerformanceGeneratedLeadsChart'
import { IndividualTechnicianPerformanceMembershipsSoldChart } from './IndividualTechnicianPerformanceMembershipsSoldChart'
import { IndividualTechnicianPerformanceOpportunityConversionRateChart } from './IndividualTechnicianPerformanceOpportunityConversionRateChart'
import { IndividualTechnicianPerformanceSoldRevenueChart } from './IndividualTechnicianPerformanceSoldRevenueChart'
import './IndividualTechnicianPerformanceWidget.less'
import {
  TEAM_PERFORMANCE_DASHBOARD_METRICS_QUERY,
  TeamPerformanceJob,
} from './team-performance-queries.gql'

const CARD_HEIGHT_CLASS_NAME = 'h-[469px]'

const TabValues = [
  'Conversion Rate',
  'Opp. Conversion Rate',
  'Earned Revenue',
  'Sold Revenue',
  'Avg. Ticket Size',
  'Memberships Sold',
  'Tech Generated Leads',
] as const

const TabValuesWithMaintenancePlansDisabled = TabValues.filter(
  value => value !== 'Memberships Sold',
)

type Tab = (typeof TabValues)[number]

export const IndividualTechnicianPerformanceWidget = memo(() => {
  const companyGuid = useExpectedCompanyGuid()
  const tzId = useExpectedCompanyTimeZoneId()
  const maintenancePlansEnabled = useCompanyMaintenancePlansEnabled()
  const fetchTechniciansQuery = trpc.user['users:get-technicians'].useQuery()
  const jobTypesQuery = trpc.jobTypes['job-types:get'].useQuery()

  const [activeTab, setActiveTab] = useState<Tab>('Conversion Rate')
  const [generateReportModalOpen, setGenerateReportModalOpen] = useState(false)
  const handleTabChange = useCallback((key: string) => {
    setActiveTab(key as Tab)
  }, [])
  const [selectedJobClass, setSelectedJobClass] = useState<JobClass>(
    JobClass.SERVICE,
  )

  const [dateRange, setDateRange] = useStandardReportingDateRangePickerState()
  const { timeUnit, timePastN } = useMemo(() => {
    return dateRangeToUnitAndN(tzId, dateRange)
  }, [dateRange, tzId])

  const [
    {
      data: teamPerformanceDashboardMetrics,
      fetching: fetchingTeamPerformanceDashboardMetrics,
    },
  ] = useQuery({
    query: TEAM_PERFORMANCE_DASHBOARD_METRICS_QUERY,
    variables: {
      where: {
        companyGuid: { _eq: companyGuid },
        _and: [
          { revenueRecognizedAt: { _gte: dateRange.start } },
          { revenueRecognizedAt: { _lte: dateRange.end } },
        ],
      },
    },
  })

  const jobs = useMemo(() => {
    if (fetchingTeamPerformanceDashboardMetrics) {
      return []
    } else {
      return teamPerformanceDashboardMetrics?.jobs ?? []
    }
  }, [
    fetchingTeamPerformanceDashboardMetrics,
    teamPerformanceDashboardMetrics?.jobs,
  ])

  const jobTypes = useMemo(() => {
    if (jobTypesQuery.isSuccess) {
      return jobTypesQuery.data ?? []
    } else {
      return []
    }
  }, [jobTypesQuery.data, jobTypesQuery.isSuccess])

  const technicians = useMemo(() => {
    if (fetchTechniciansQuery.isSuccess) {
      return fetchTechniciansQuery.data ?? []
    } else {
      return []
    }
  }, [fetchTechniciansQuery.data, fetchTechniciansQuery.isSuccess])

  const selectableJobTypes = useMemo(() => {
    return jobTypes.filter(jobType => {
      if (selectedJobClass === JobClass.SERVICE) {
        return jobType.jobClass === JobClass.SERVICE
      } else if (selectedJobClass === JobClass.MAINTENANCE) {
        return jobType.jobClass === JobClass.MAINTENANCE
      } else {
        return false
      }
    })
  }, [jobTypes, selectedJobClass])

  const [selectedJobTypeGuid, setSelectedJobTypeGuid] = useState<string>(
    selectableJobTypes[0]?.jobTypeGuid ?? 'ALL',
  )

  useEffect(() => {
    setSelectedJobTypeGuid('ALL')
  }, [selectableJobTypes])

  const filteredJobs = useMemo(() => {
    const filteredJobsByJobClass = jobs.filter(
      job => job.jobType.jobClass === selectedJobClass,
    )

    if (selectedJobTypeGuid === 'ALL') {
      return filteredJobsByJobClass
    } else {
      return filteredJobsByJobClass.filter(
        job => job.jobType.jobTypeGuid === selectedJobTypeGuid,
      )
    }
  }, [jobs, selectedJobClass, selectedJobTypeGuid])

  const selectableTechnicians = useMemo(() => {
    const validTechs = technicians.filter(tech => !tech.user.deactivatedAt)
    if (selectedJobClass === JobClass.SERVICE) {
      return validTechs.filter(technician =>
        technician.roles.some(
          role => role === TechnicianRole.SERVICE_TECHNICIAN,
        ),
      )
    } else if (selectedJobClass === JobClass.MAINTENANCE) {
      return validTechs.filter(technician =>
        technician.roles.some(
          role => role === TechnicianRole.MAINTENANCE_TECHNICIAN,
        ),
      )
    } else {
      // This should never happen, but we'll default to the full list of technicians
      return validTechs
    }
  }, [selectedJobClass, technicians])

  const [selectedTechnicianGuids, setSelectedTechnicianGuids] = useState<
    string[]
  >(technicians.map(technician => technician.user.id))

  useEffect(() => {
    setSelectedTechnicianGuids(
      selectableTechnicians.map(technician => technician.user.id),
    )
  }, [selectableTechnicians])

  const selectedTechnicianUserGuidToTechnicianMap: Record<string, Technician> =
    useMemo(() => {
      const map: Record<string, Technician> = {}
      technicians.forEach(technician => {
        if (selectedTechnicianGuids.includes(technician.user.id)) {
          map[technician.user.id] = technician
        }
      })
      return map
    }, [selectedTechnicianGuids, technicians])

  // A map of technician user guid to all of the jobs for which they were assigned to an appointment
  const selectedTechnicianUserGuidToAssignedJobsMap: Record<
    string,
    TeamPerformanceJob[]
  > = useMemo(() => {
    const map: Record<string, TeamPerformanceJob[]> = {}

    technicians.forEach(technician => {
      if (selectedTechnicianGuids.includes(technician.user.id)) {
        map[technician.user.id] = []
      }
    })

    filteredJobs.forEach(job => {
      job.jobAppointmentAssignments.forEach(assignment => {
        if (Array.isArray(map[assignment.technicianUserGuid])) {
          map[assignment.technicianUserGuid].push(job)
        }
      })
    })

    return map
  }, [filteredJobs, selectedTechnicianGuids, technicians])

  // A map of technician user guid to all of the jobs on which they have attributed Earned REvenue
  const selectedTechnicianUserGuidToEarnedRevenueJobsMap: Record<
    string,
    TeamPerformanceJob[]
  > = useMemo(() => {
    const map: Record<string, TeamPerformanceJob[]> = {}

    technicians.forEach(technician => {
      if (selectedTechnicianGuids.includes(technician.user.id)) {
        map[technician.user.id] = []
      }
    })

    filteredJobs.forEach(job => {
      job.jobTechnicianEarnedRevenueSummaries.forEach(summary => {
        const { technicianUserGuid } = summary
        if (
          !isNullish(technicianUserGuid) &&
          Array.isArray(map[technicianUserGuid])
        ) {
          map[technicianUserGuid].push(job)
        }
      })
    })

    return map
  }, [filteredJobs, selectedTechnicianGuids, technicians])

  // A map of technician user guid to all of the jobs on which they have attributed Sold REvenue
  const selectedTechnicianUserGuidToSoldRevenueJobsMap: Record<
    string,
    TeamPerformanceJob[]
  > = useMemo(() => {
    const map: Record<string, TeamPerformanceJob[]> = {}

    technicians.forEach(technician => {
      if (selectedTechnicianGuids.includes(technician.user.id)) {
        map[technician.user.id] = []
      }
    })

    filteredJobs.forEach(job => {
      job.jobTechnicianSoldRevenueSummaries.forEach(summary => {
        const { technicianUserGuid } = summary
        if (
          !isNullish(technicianUserGuid) &&
          Array.isArray(map[technicianUserGuid])
        ) {
          map[technicianUserGuid].push(job)
        }
      })
    })

    return map
  }, [filteredJobs, selectedTechnicianGuids, technicians])

  // A map of technician user guid to all of the jobs which they have turned over
  const selectedTechnicianUserGuidToTurnedOverJobsMap: Record<
    string,
    TeamPerformanceJob[]
  > = useMemo(() => {
    const map: Record<string, TeamPerformanceJob[]> = {}

    technicians.forEach(technician => {
      if (selectedTechnicianGuids.includes(technician.user.id)) {
        map[technician.user.id] = []
      }
    })

    filteredJobs.forEach(job => {
      job.jobTeamMembers.forEach(({ turnedOverJob, userGuid }) => {
        if (turnedOverJob && Array.isArray(map[userGuid])) {
          map[userGuid].push(job)
        }
      })
    })

    return map
  }, [filteredJobs, selectedTechnicianGuids, technicians])

  const titleAction = (
    <>
      <Select
        value={selectedJobClass}
        onChange={setSelectedJobClass}
        bordered={false}
        popupMatchSelectWidth={false}
        disabled={jobTypesQuery.isFetching}
      >
        <Select.Option key={JobClass.SERVICE} value={JobClass.SERVICE}>
          Service
        </Select.Option>
        <Select.Option key={JobClass.MAINTENANCE} value={JobClass.MAINTENANCE}>
          Maintenance
        </Select.Option>
      </Select>
      <Select
        value={selectedJobTypeGuid}
        onChange={setSelectedJobTypeGuid}
        bordered={false}
        popupMatchSelectWidth={false}
        disabled={jobTypesQuery.isFetching}
      >
        <Select.Option key="all" value="ALL">
          All Job Types
        </Select.Option>
        {selectableJobTypes.map(jobType => (
          <Select.Option key={jobType.jobTypeGuid} value={jobType.jobTypeGuid}>
            {jobType.name}
          </Select.Option>
        ))}
      </Select>
      <Select
        className="individual-technician-performance-widget-technician-select min-w-[152px]"
        mode="multiple"
        placeholder="Technicians (0)"
        maxTagCount={0}
        maxTagPlaceholder={`Technicians (${selectedTechnicianGuids.length})`}
        value={
          selectedTechnicianGuids.length > 0
            ? selectedTechnicianGuids
            : undefined
        }
        onChange={setSelectedTechnicianGuids}
        bordered={false}
        showSearch={false}
        popupMatchSelectWidth={false}
        disabled={fetchTechniciansQuery.isFetching}
      >
        {selectableTechnicians.map(technician => (
          <Select.Option key={technician.user.id} value={technician.user.id}>
            {`${technician.user.firstName} ${technician.user.lastName}`}
          </Select.Option>
        ))}
      </Select>
      <StandardReportingDateRangePicker
        disabled={fetchingTeamPerformanceDashboardMetrics}
        range={dateRange}
        setRange={setDateRange}
      />

      <Button
        type="link"
        className="text-sm"
        onClick={() => setGenerateReportModalOpen(true)}
      >
        Generate Report
        <FontAwesomeIcon className="ml-2" icon={faArrowRight} />
      </Button>
    </>
  )

  let cardBody: ReactNode = null
  if (
    fetchingTeamPerformanceDashboardMetrics ||
    fetchTechniciansQuery.isLoading ||
    jobTypesQuery.isLoading
  ) {
    cardBody = <LoadingSpinner />
  } else if (
    filteredJobs.length === 0 ||
    selectedTechnicianGuids.length === 0
  ) {
    cardBody = (
      <NoDataOverlay
        className="inset-[-16px]"
        icon={faArrowTrendUp}
        title="No Individual Technician Performance Data"
      >
        <div className="text-center">
          Individual Technician Performance metrics will be displayed here once
          data has been recorded. If you already have data recorded, you may
          need to try changing the filter parameters.
        </div>
      </NoDataOverlay>
    )
  } else {
    let content: React.ReactNode

    switch (activeTab) {
      case 'Conversion Rate':
        content = (
          <IndividualTechnicianPerformanceConversionRateChart
            technicianUserGuidToTechnicianMap={
              selectedTechnicianUserGuidToTechnicianMap
            }
            technicianUserGuidToAssignedJobsMap={
              selectedTechnicianUserGuidToAssignedJobsMap
            }
            dateRange={dateRange}
            timeUnit={timeUnit}
            timePastN={timePastN}
          />
        )
        break
      case 'Opp. Conversion Rate':
        content = (
          <IndividualTechnicianPerformanceOpportunityConversionRateChart
            technicianUserGuidToTechnicianMap={
              selectedTechnicianUserGuidToTechnicianMap
            }
            technicianUserGuidToAssignedJobsMap={
              selectedTechnicianUserGuidToAssignedJobsMap
            }
            dateRange={dateRange}
            timeUnit={timeUnit}
            timePastN={timePastN}
          />
        )
        break
      case 'Earned Revenue':
        content = (
          <IndividualTechnicianPerformanceEarnedRevenueChart
            technicianUserGuidToTechnicianMap={
              selectedTechnicianUserGuidToTechnicianMap
            }
            technicianUserGuidToEarnedRevenueJobsMap={
              selectedTechnicianUserGuidToEarnedRevenueJobsMap
            }
            dateRange={dateRange}
            timeUnit={timeUnit}
            timePastN={timePastN}
          />
        )
        break
      case 'Sold Revenue':
        content = (
          <IndividualTechnicianPerformanceSoldRevenueChart
            technicianUserGuidToTechnicianMap={
              selectedTechnicianUserGuidToTechnicianMap
            }
            technicianUserGuidToSoldRevenueJobsMap={
              selectedTechnicianUserGuidToSoldRevenueJobsMap
            }
            dateRange={dateRange}
            timeUnit={timeUnit}
            timePastN={timePastN}
          />
        )
        break
      case 'Avg. Ticket Size':
        content = (
          <IndividualTechnicianPerformanceAvgTicketSizeChart
            technicianUserGuidToTechnicianMap={
              selectedTechnicianUserGuidToTechnicianMap
            }
            technicianUserGuidToAssignedJobsMap={
              selectedTechnicianUserGuidToAssignedJobsMap
            }
            dateRange={dateRange}
            timeUnit={timeUnit}
            timePastN={timePastN}
          />
        )
        break
      case 'Memberships Sold':
        content = (
          <IndividualTechnicianPerformanceMembershipsSoldChart
            technicianUserGuidToTechnicianMap={
              selectedTechnicianUserGuidToTechnicianMap
            }
            technicianUserGuidToAssignedJobsMap={
              selectedTechnicianUserGuidToAssignedJobsMap
            }
            dateRange={dateRange}
            timeUnit={timeUnit}
            timePastN={timePastN}
          />
        )
        break
      case 'Tech Generated Leads':
        content = (
          <IndividualTechnicianPerformanceGeneratedLeadsChart
            technicianUserGuidToTechnicianMap={
              selectedTechnicianUserGuidToTechnicianMap
            }
            technicianUserGuidToTurnedOverJobsMap={
              selectedTechnicianUserGuidToTurnedOverJobsMap
            }
            dateRange={dateRange}
            timeUnit={timeUnit}
            timePastN={timePastN}
          />
        )
        break
    }

    cardBody = (
      <>
        <Tabs
          defaultActiveKey="1"
          activeKey={activeTab}
          onChange={handleTabChange}
          items={(maintenancePlansEnabled
            ? TabValues
            : TabValuesWithMaintenancePlansDisabled
          ).map(tabValue => ({
            key: tabValue,
            label: tabValue,
          }))}
        />

        <div className="h-full w-full">{content}</div>
      </>
    )
  }

  return (
    <>
      <Card
        title="Individual Technician Performance"
        titleAction={titleAction}
        titleMarginBottomClass="mb-0"
        className={CARD_HEIGHT_CLASS_NAME}
      >
        {cardBody}
      </Card>
      {generateReportModalOpen && (
        <GenerateReportModal
          open={generateReportModalOpen}
          onClose={() => setGenerateReportModalOpen(false)}
          initialDateRangeStart={dateRange.start}
          initialDateRangeEnd={dateRange.end}
        />
      )}
    </>
  )
})
