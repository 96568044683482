import { DateTimeFormatter, ENGLISH_LOCALE, Location } from '@breezy/shared'
import { useMemo } from 'react'
import { OnsitePageCollapsibleSection } from 'src/adam-components/OnsitePage/OnsitePageCollapsibleSection'
import {
  LocationCard,
  LocationCardProps,
} from 'src/components/Cards/LocationCard'

export interface LocationsCollapsibleProps {
  locations: Location[]
  onLocationAdd?: () => void
  onLocationMakeBillingAddress?: (location: Location) => void
  onEdit?: (location: Location) => void
}

export const LocationsCollapsible = (props: LocationsCollapsibleProps) => {
  const items: LocationCardProps['location'][] = useMemo(() => {
    const items: LocationCardProps['location'][] = []

    for (const location of props.locations) {
      items.push({
        locationGuid: location.locationGuid,
        displayName: location.displayName,
        address: {
          line1: location.address.line1,
          city: location.address.city,
          stateAbbreviation: location.address.stateAbbreviation,
          zipCode: location.address.zipCode,
        },
        yearBuilt: location.estimatedBuildDate
          ? location.estimatedBuildDate?.format(
              DateTimeFormatter.ofPattern('YYYY').withLocale(ENGLISH_LOCALE),
            )
          : undefined,
        squareFootage: location.estimatedSquareFootage,
      })
    }

    return items
  }, [props.locations])

  return (
    <OnsitePageCollapsibleSection
      title="Locations"
      smallTitle
      count={props.locations.length}
      defaultCollapsed={props.locations.length === 0}
      onAdd={props.onLocationAdd}
      addButtonTestId="locations-collapsible-add-button"
    >
      <div className="flex flex-col gap-2">
        {items.map((location, idx) => (
          <LocationCard
            key={location.locationGuid}
            location={location}
            onEdit={
              props.onEdit
                ? () => props.onEdit?.(props.locations[idx])
                : undefined
            }
            onMakeBillingAddress={
              props.onLocationMakeBillingAddress
                ? () =>
                    props.onLocationMakeBillingAddress?.(props.locations[idx])
                : undefined
            }
          />
        ))}
      </div>
    </OnsitePageCollapsibleSection>
  )
}
