import { PageHeader } from '@ant-design/pro-components'
import {
  BzDateTime,
  CalculatePaths,
  IsoDateString,
  PayoutEnriched,
  PayoutGuid,
  PermissionV2,
  TimeZoneId,
  bzExpect,
  convertPayoutsDetailsToCsvRows,
} from '@breezy/shared'
import { Table } from 'antd'
import { ColumnType, ColumnsType } from 'antd/lib/table'
import { MutableRefObject, useCallback, useMemo, useRef } from 'react'
import { ArrayParam, useQueryParams } from 'use-query-params'
import { QuickbooksAccountingSyncPayoutButton } from '../../components/AccountingIntegration/QuickbooksDesktop/QuickbooksDesktopAccountingSyncButton'
import { QuickbooksSyncPayoutButton } from '../../components/AccountingIntegration/QuickbooksOnline/QuickbooksSyncButton'
import { Page } from '../../components/Page/Page'
import { Authorized } from '../../components/Permissions/Authorized/Authorized'
import TrpcQueryLoader from '../../components/TrpcQueryLoader'
import {
  dateColumn,
  filteredTextColumn,
  moneyColumnUsc,
  numberColumnMonospaced,
  viewDetailsLinkColumn,
} from '../../components/datatables/CommonColumnDefinitions'
import FaIconButton from '../../elements/FaIconButton/FaIconButton'
import PageTitle from '../../elements/PageTitle/PageTitle'
import { trpc } from '../../hooks/trpc'
import {
  useAreAccountingPayoutAccountsConfigured,
  useQuickbooksDesktopEnabled,
  useQuickbooksOnlineEnabled,
} from '../../providers/CompanyFinancialConfigWrapper'
import {
  useExpectedCompanyGuid,
  useExpectedCompanyTimeZoneId,
  useIsImpersonating,
} from '../../providers/PrincipalUser'
import { downloadDataAsCsv } from '../../utils/export-to-csv'
import { DownloadIcon, PayoutIcon } from '../../utils/feature-icons'
import { m } from '../../utils/react-utils'

type TablePayout = {
  payoutGuid: PayoutGuid
  paidAt: IsoDateString
  externalPayoutSource: string
  expectedBankArrivalDate: IsoDateString
  netAmountUsc: number
  chargesAmountUsc: number
  refundsAmountUsc: number
  balanceAdjustmentUsc: number
  feesAmountUsc: number
  chargesCount: number
  refundsCount: number
  createdAt: IsoDateString
  qboSyncedAt?: IsoDateString
  accountingSyncedAt?: IsoDateString
}

type TableItemType = TablePayout

const getPayoutDetailsLink = (payout: TableItemType) =>
  CalculatePaths.payoutDetails(payout)

const getTableColumns = (
  tzId: TimeZoneId,
  showQuickbooksOnlineColumn: boolean,
  showQuickbooksDesktopColumn: boolean,
  payoutSources: Set<string>,
  refetch: () => void,
  filters?: Record<'externalPayoutSource', string[]>,
): ColumnsType<TableItemType> => {
  const cols: ColumnType<TableItemType>[] = [
    viewDetailsLinkColumn(getPayoutDetailsLink, 'payoutGuid'),
    dateColumn(tzId, 'Origination Date', 'paidAt'),
    dateColumn(tzId, 'Expected Deposit Date', 'expectedBankArrivalDate'),
    moneyColumnUsc('Amount', 'netAmountUsc'),
  ]

  if (payoutSources.size > 1) {
    cols.push(
      filteredTextColumn(
        'Source',
        'externalPayoutSource',
        Array.from(payoutSources).map(x => ({ text: x, value: x })),
        filters?.externalPayoutSource ?? [],
      ),
    )
  }

  cols.push(
    numberColumnMonospaced('Num Charges', 'chargesCount'),
    moneyColumnUsc('Total Charges', 'chargesAmountUsc'),
    numberColumnMonospaced('Num Refunds', 'refundsCount'),
    moneyColumnUsc('Total Refunds', 'refundsAmountUsc'),
    moneyColumnUsc('Total Fees', 'feesAmountUsc'),
    moneyColumnUsc('Balance Adjustment', 'balanceAdjustmentUsc'),
  )

  if (showQuickbooksOnlineColumn) {
    cols.push({
      key: 'Quickbooks Sync',
      title: 'QB',
      onCell: () => ({ className: 'p-1' }),
      render: (item: TableItemType) => (
        <QuickbooksSyncPayoutButton
          className="float-right mr-6"
          placement="left"
          size="sm"
          params={{ payoutGuid: item.payoutGuid, force: true }}
          loading={false}
          staleInfo={{
            updatedAt: item.createdAt,
            syncedAt: item.qboSyncedAt,
            stale: !item.qboSyncedAt || item.createdAt > item.qboSyncedAt,
          }}
          onSuccess={refetch}
        />
      ),
    })
  }
  if (showQuickbooksDesktopColumn) {
    cols.push({
      key: 'Quickbooks Desktop Sync',
      title: 'QB',
      render: (item: TableItemType) => {
        return (
          <QuickbooksAccountingSyncPayoutButton
            className="float-right mr-6"
            placement="left"
            size="sm"
            payoutGuid={item.payoutGuid}
            loading={false}
            isStale={
              !item.accountingSyncedAt ||
              item.createdAt > item.accountingSyncedAt
            }
            onSuccess={refetch}
          />
        )
      },
    })
  }
  return cols
}

const PayoutsListPage = () => {
  const tzId = useExpectedCompanyTimeZoneId()
  const payoutDetailsViewModels = useRef([])

  const downloadAsCsv = useCallback(() => {
    const dataRows = convertPayoutsDetailsToCsvRows(
      tzId,
      payoutDetailsViewModels.current,
    )
    downloadDataAsCsv(
      dataRows,
      `${BzDateTime.now(tzId).toLocalDateString()}-payouts.csv`,
    )
  }, [tzId])

  return (
    <Page requiresCompanyUser>
      <div className="card-no-fixed-height pt-2">
        <PageHeader
          className="px-2 py-2"
          title={<PageTitle title="Payouts" icon={PayoutIcon} />}
          extra={
            <Authorized
              to={PermissionV2.OFFICE_FINANCIAL_INFORMATION_PAYOUTS_VIEW}
            >
              <FaIconButton
                icon={DownloadIcon}
                onClick={downloadAsCsv}
                tooltip="Download All - CSV"
              />
            </Authorized>
          }
        />
        <Authorized to={PermissionV2.OFFICE_FINANCIAL_INFORMATION_PAYOUTS_VIEW}>
          <PageContent dataRef={payoutDetailsViewModels} />
        </Authorized>
      </div>
    </Page>
  )
}

type PageContentProps = {
  dataRef: MutableRefObject<PayoutEnriched[]>
}

const PageContent = m<PageContentProps>(({ dataRef }) => {
  const companyGuid = useExpectedCompanyGuid()
  const tzId = useExpectedCompanyTimeZoneId()

  const query = trpc.payouts['payouts:get-all'].useQuery({
    companyGuid,
    limit: 10000,
  })

  return (
    <TrpcQueryLoader
      query={query}
      render={data => {
        dataRef.current = data
        return (
          <PayoutsTable
            refetch={query.refetch}
            items={data.map(payout => ({
              ...payout,
              qboSyncedAt: payout.qboSyncInfo?.updatedAt,
              accountingSyncedAt: payout.accountingSyncInfo?.syncedAt,
              payoutGuid: bzExpect(payout.payoutGuid, 'PayoutGuid'),
              expectedBankArrivalDate: BzDateTime.fromIsoString(
                payout.paidAt,
                tzId,
              )
                .plusDays(3)
                .toIsoString(),
            }))}
          />
        )
      }}
    />
  )
})

type PayoutsTableProps = {
  items: TableItemType[]
  refetch: () => void
}

const PayoutsTable = m<PayoutsTableProps>(({ items, refetch }) => {
  const isImpersonating = useIsImpersonating()
  const companyTimeZoneId = useExpectedCompanyTimeZoneId()
  const payoutSources = useMemo(
    () => new Set<string>(items.map(i => i.externalPayoutSource)),
    [items],
  )

  const isQuickbooksDesktopEnabled = useQuickbooksDesktopEnabled()
  const areAccountingPayoutAccountsConfigured =
    useAreAccountingPayoutAccountsConfigured()
  const canShowQbdColumn =
    isImpersonating &&
    isQuickbooksDesktopEnabled &&
    areAccountingPayoutAccountsConfigured

  const isQuickbooksOnlineEnabled = useQuickbooksOnlineEnabled()
  const canShowQboColumn =
    isImpersonating &&
    areAccountingPayoutAccountsConfigured &&
    isQuickbooksOnlineEnabled

  const [query, setQuery] = useQueryParams({
    externalPayoutSource: ArrayParam,
  })
  const { externalPayoutSource: externalPayoutSourceFilter } = query

  return (
    <Table
      rowKey="payoutGuid"
      dataSource={items}
      scroll={{ x: true }}
      columns={getTableColumns(
        companyTimeZoneId,
        canShowQboColumn,
        canShowQbdColumn,
        payoutSources,
        refetch,
        {
          externalPayoutSource: (externalPayoutSourceFilter as string[]) ?? [],
        },
      )}
      pagination={{ pageSize: 100 }}
      onChange={(v, filters) =>
        setQuery({
          externalPayoutSource: filters['externalPayoutSource'] as string[],
        })
      }
      size="small"
    />
  )
})

export default PayoutsListPage
