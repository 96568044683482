import { gql } from '../../generated'

export const ONLINE_BOOKING_COMPANY_CONFIG_UPSERT = gql(/* GraphQL */ `
  mutation UpsertOnlineBookingConfig(
    $companyGuid: uuid!
    $config: OnlineBookingCompanyConfigInsertInput!
    $onConflict: OnlineBookingCompanyConfigOnConflict
  ) {
    deleteOnlineBookingServiceAreaZipCodes(
      where: { companyGuid: { _eq: $companyGuid } }
    ) {
      __typename
    }
    insertOnlineBookingCompanyConfigOne(
      object: $config
      onConflict: $onConflict
    ) {
      __typename
    }
  }
`)
